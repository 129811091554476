import React, { createContext, useCallback, useEffect, useState } from "react";

export type FeedbackTypeT = "error" | "warning" | "success" | "info";
export type OpenSnackbarT = (
  type: FeedbackTypeT,
  message: string,
  /* eslint-disable @typescript-eslint/ban-types */
  onClose?: Function
) => void;

interface FeedbackContextI {
  openSnackbar: OpenSnackbarT;
  showProgress: () => void;
  hideProgress: () => void;
  openProgress: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  type: FeedbackTypeT;
  setType: React.Dispatch<React.SetStateAction<FeedbackTypeT>>;

  closeOpenModal: boolean;
  setCloseOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeedbackContext = createContext<FeedbackContextI>({
  open: false,
  setOpen: () => {},
  message: "",
  setMessage: () => {},
  type: "info",
  setType: () => {},
  openSnackbar: (type: FeedbackTypeT, message: string) => {},
  showProgress: () => {},
  hideProgress: () => {},
  closeOpenModal: false,
  setCloseOpenModal: () => {},
  openProgress: false,
});
export const FeedbackProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState<FeedbackTypeT>("info");
  const [closeOpenModal, setCloseOpenModal] = useState(false);

  const showProgress = useCallback(
    () => setOpenProgress(true),
    [setOpenProgress]
  );
  const hideProgress = useCallback(
    () => setOpenProgress(false),
    [setOpenProgress]
  );

  const openSnackbar = useCallback(
    (type: FeedbackTypeT, message: string, onClose?: Function) => {
      setMessage(message);
      setType(type);
      hideProgress();
      setOpen(true);
    },
    [setMessage, setType, hideProgress, setOpen]
  );

  return (
    <FeedbackContext.Provider
      value={{
        openSnackbar,
        open,
        setOpen,
        message,
        setMessage,
        type,
        setType,
        showProgress,
        hideProgress,
        closeOpenModal,
        setCloseOpenModal,
        openProgress,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

import { randomNumberString, randomString } from "./string";
// import { createHash } from "crypto";
/**
 * ATTENTION: this must not be changed,
 * since the numbers are unuglifies as well in backend
 */

interface UglifyTimesI {
  [key: string]: number;
}

interface UnUglifyTimesI {
  [key: string]: string;
}
export const uglifyTimes = (times: UglifyTimesI) => {
  const tmp = Object.entries(times).map(([key, value]) => {
    return [key, uglifyNumber(value)];
  });
  return Object.fromEntries(tmp);
};

export const unUglifyTimes = (times: UnUglifyTimesI) => {
  const tmp = Object.entries(times).map(([key, value]) => {
    return [key, unUglifyNumber(value)];
  });
  return Object.fromEntries(tmp);
};

export const uglifyNumber = (num: number): string => {
  if (import.meta.env.VITE_NO_UGLIFY === "1") return num.toString();

  return hashNumber(num) + randomString(5) + num + randomString(10);
};

const hashNumber = (num: number): string => {
  let hash = 0;
  let string = num.toString();
  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  string = hash.toString();
  while (string.length < 5) {
    string = "0" + string;
  }

  return string.slice(-5);
};

export const unUglifyNumber = (str: string): number => {
  if (import.meta.env.VITE_NO_UGLIFY === "1") return parseInt(str);

  if (str.length < 21) return -1;

  const res = parseInt(str.slice(10, -10));

  // compare the hash
  const hash = hashNumber(res);
  if (hash !== str.slice(0, 5)) return -1;

  return isNaN(res) ? -1 : res;
};

export const uglifyTimestamp = (timestamp: number): string => {
  if (import.meta.env.VITE_NO_UGLIFY === "1") return timestamp.toString();

  return randomNumberString(4) + timestamp + randomNumberString(7);
};

export const unUglifyTimestamp = (str: string): number => {
  if (import.meta.env.VITE_NO_UGLIFY === "1") return parseInt(str);

  if (str.length < 11) return -1;

  const res = parseInt(str.slice(4, -7));

  return isNaN(res) ? -1 : res;
};

import { TestModusCategoryInfoT, TestModusQuestionT } from "../testModus/api";

export const subCategoryInfoExample: TestModusCategoryInfoT = {
  21: {
    count: 10,
    duration: 610,
    no: 3,
  },
};
export const questionExample: TestModusQuestionT = {
  id: 351,
  type: "multiple-choice",
  difficulty: 0.6,
  rating: "none",
  duration: 30,
  question:
    "1 Windrad erzeugt am Tag genug Strom, um 12 Haushalte 3 Tage lang zu versorgen. Wie lange würde 18 Haushalten die Tagesproduktion von 2 Windrädern reichen?",
  questionDecoded:
    "1 Windrad erzeugt am Tag genug Strom, um 12 Haushalte 3 Tage lang zu versorgen. Wie lange würde 18 Haushalten die Tagesproduktion von 2 Windrädern reichen?",
  answerOptions:
    '{"A":"4 Tage","B":"6 Tage","C":"3 Tage","D":"2 Tage","E":"Keine Antwort ist richtig."}',
  answer: "Boizenburg",
  comment: "",
  answerOptionsDecoded: {
    A: "4 Tage",
    B: "6 Tage",
    C: "3 Tage",
    D: "2 Tage",
    E: "Keine Antwort ist richtig.",
  },
  mainCategoryName: "Mathematik",
  mainCategoryId: 6,
  mainCategoryDesc: "",
  mainCategoryImg: "/maincategory/Konzentrationsvermögen.svg",
  subCategoryName: "Dreisatz",
  subCategoryId: 21,
  subCategoryDesc: "",
  subCategoryMemText: "",
  subCategoryMemTime: 0,
  points: 1,
  layout: "vertical",
  evaluation: "normal",
  example: false,
  isImage: false,
  noInSubCategory: 1,
};

import { get, post, put, del, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";
import {
  CandidateResultsDataT,
  CandidateResultsT,
  data2results,
} from "../candidate/api";
import { checkApiResult } from "../shared/lib/checkApi";
import { TransactionT } from "../user/api";

export type ProfessionDataT = {
  id: number;
  testId: string;
  name: string;
  targetValue: number;
  shortDesc: string;
  longDesc: string;
  demo: number;
  imgId?: number;
  type: string;
  parentId?: number;
  childId?: number;
  active: number;
  file?: {
    name?: string;
    filePath?: string;
  };
  numberTransactions?: number;
  numberCandidates?: number;
  checkResult?: string;
  countQuestions?: number;
  relationCount?: number;
  testResultCount?: number;
  resultCount?: number;
  transactionCount?: number;
  candidateCount?: number;
  duration?: number;
  expiredAt?: string;
};

export type ProfessionT = {
  id: number;
  testId: string;
  name: string;
  targetValue: number;
  expiredAt?: Date;
  shortDesc: string;
  longDesc: string;
  demo: boolean;
  imgId: number;
  type: string;
  parentId?: number;
  childId?: number;
  active: boolean;
  imgName: string;
  imgPath: string;
  numberTransactions?: number;
  numberCandidates?: number;
  checkResult?: string;
  countQuestions?: number;
  relationCount?: number;
  testResultCount?: number;
  resultCount?: number;
  transactionCount?: number;
  candidateCount?: number;
  duration?: number;
};

export const EMPTYPROFESSION: ProfessionT = {
  id: 0,
  testId: "",
  name: "",
  targetValue: 0,
  shortDesc: "",
  longDesc: "",
  demo: false,
  imgId: 0,
  type: "",
  active: true,
  imgName: "",
  imgPath: "",
};

const data2profession = (data: ProfessionDataT): ProfessionT => {
  checkApiResult("data2profession", data, {
    id: "number",
    testId: "string",
    name: "string",
    targetValue: "number",
    demo: "number",
    shortDesc: "string",
    longDesc: "string",
    type: "string",
    active: "number",
  });
  return {
    ...data,
    imgId: data.imgId || 0,
    imgName: data.file?.name || "",
    imgPath: data.file?.filePath || "",
    active: data.active ? true : false,
    numberTransactions: data.numberTransactions,
    numberCandidates: data.numberCandidates,
    checkResult: data.checkResult,
    countQuestions: data.countQuestions,
    demo: data.demo ? true : false,
    expiredAt: data.expiredAt ? new Date(data.expiredAt) : undefined,
  };
};

export const data2professions = (data: ProfessionDataT[]): ProfessionT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2profession(d));
};

const profession2data = (profession: ProfessionT): ProfessionDataT => {
  return {
    ...profession,
    active: profession.active ? 1 : 0,
    demo: profession.demo ? 1 : 0,
    expiredAt: profession.expiredAt ? profession.expiredAt.toISOString() : "",
  };
};

/** ************************************************************************
 *
 *
 */
interface FetchProfessionsI {
  onlyActive: boolean;
  check: boolean;
}
export const fetchProfessions = async ({
  onlyActive = false,
  check = false,
}: FetchProfessionsI): Promise<CallApiResultT<ProfessionT[]>> => {
  const res = await get<ProfessionDataT>("/profession", {
    ...(onlyActive ? { active: 1 } : {}),
    ...(check ? { check: 1 } : {}),
  });

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchProfessions] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2professions(res.data),
  };
};

/** ************************************************************************
 *
 * @returns addedProfession
 */
export const addProfession = async (
  profession: ProfessionT
): Promise<CallApiResultT<ProfessionT>> => {
  const res = await post("/profession", profession2data(profession));

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2profession(res.data as ProfessionDataT),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateProfession = async (
  profession: ProfessionT
): Promise<CallApiResultT<number>> => {
  const res = await put<number>(
    `/profession/${profession.id}`,
    profession2data(profession)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateProfessionTarget = async (
  profession: ProfessionT
): Promise<CallApiResultT<number>> => {
  const res = await put<number>(`/profession-settings/${profession.id}`, {
    targetValue: profession.targetValue,
  });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateProfessionExpired = async (
  profession: ProfessionT
): Promise<CallApiResultT<number>> => {
  const res = await put<number>(`/profession-settings/${profession.id}`, {
    expiredAt: profession.expiredAt,
  });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateProfessionSettingsBulk = async (
  transactions: TransactionT[]
): Promise<CallApiResultT<number>> => {
  const res = await put<number>(`/profession-settings/`, transactions);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteProfession = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`/profession/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const copyProfession = async (
  id: number,
  version = false
): Promise<CallApiResultT<ProfessionT>> => {
  const res = await post<ProfessionDataT>(`/profession-copy/${id}`, {
    version,
  });

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2profession(res.data as ProfessionDataT),
  };
};

/** ************************************************************************
 *
 *
 */
export const fetchTestResults = async (
  professionId: number
): Promise<CallApiResultT<CandidateResultsT>> => {
  const res = await get<CandidateResultsDataT>("/testresult", { professionId });
  console.log("fetchTestResults res:", res);
  if (!res.success || !res.data) {
    throw Error("[fetchTestResults] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: data2results(res.data),
  };
};

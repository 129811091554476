import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import { Fragment } from "react";
import { mm_ss } from "../../shared/helper/datetime";
import { ShowButton } from "../../shared/ShowButton";
import { CandidateResultsT } from "../api";
import { ResultNumbers } from "./ResultNumbers";
import { ResultPercent } from "./ResultPercent";
import { ResultsSubList } from "./ResultsSubList";
import { useNavigation } from "./useNavigation";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface ResultsMainPropsI {
  results: CandidateResultsT;
}

/* Results on level of main-categories */
export const ResultsMainList = ({ results }: ResultsMainPropsI) => {
  const { setNavigation, getNavigation } = useNavigation("main");
  return (
    <>
      {results.mainCategories.map((r) => (
        <Box
          component={Paper}
          sx={{ pt: 2, pb: 2, mt: 1, mb: 1, pl: 2 }}
          key={"main" + r.mainCategoryId}
        >
          <Grid container className="results-main-list">
            <Grid item xs={4} className="results-arrow-name-container">
              <div
                className="drop-arrow"
                onClick={() => setNavigation(r.mainCategoryId)}
              >
                {getNavigation(r.mainCategoryId) ? (
                  <ArrowDropDownIcon />
                ) : (
                  <ArrowRightIcon />
                )}
              </div>
              <Typography
                variant="body2"
                component="div"
                className="main-category-name semibold"
              >
                {r.mainCategoryName}
              </Typography>
            </Grid>
            <Grid item xs={8} className="result-numbers">
              <ResultNumbers
                correct={r.countCorrect}
                incorrect={r.countTotal - r.countCorrect}
              />
              <ResultPercent
                sumPoints={r.sumPoints}
                sumPointsCorrect={r.sumPointsCorrect}
                mainCategoryTarget={r.mainCategoryTarget}
              />{" "}
            </Grid>
            <Grid item xs={12}></Grid>
            {getNavigation(r.mainCategoryId) && (
              <ResultsSubList
                mainCategoryId={r.mainCategoryId}
                setShowMainCategoryId={(id) => setNavigation(r.mainCategoryId)}
                results={results}
              />
            )}
          </Grid>
        </Box>
      ))}
    </>
  );
};

import React, { useState, useContext, useEffect } from "react";

import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { MailTemplateT, updateMailTemplate } from "./api";

import { MailTemplateContext } from "./MailTemplateContext";
import { ErrorContext } from "../error/ErrorContext";

import { FeedbackContext } from "../feedback/FeedbackContext";

import Box from "@mui/material/Box";
import { sortBy } from "lodash";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { MailTemplateForm } from "./MailTemplateForm";
import { updateStateArrayById } from "../shared/helper/state";

export const MailTemplate = () => {
  const { openSnackbar } = useContext(FeedbackContext);

  const { mailTemplateList, setMailTemplateList } =
    useContext(MailTemplateContext);
  const { setError } = useContext(ErrorContext);

  const [select, setSelect] = useState(0);

  const onClick = (data: MailTemplateT) => {
    console.log("%cMailTemplate.tsx line:30 data", "color: #007acc;", data);
    updateMailTemplate(data)
      .then((res) => {
        if (res.success) {
          setMailTemplateList((p) =>
            updateStateArrayById<MailTemplateT>(p, data)
          );

          openSnackbar("success", "Eintrag gespeichert");
        } else {
          if (res.errors) {
            if (
              "email" in res.errors &&
              (res.errors as any).email[0].indexOf("already") > 0
            ) {
              setError("Diese E-Mail ist bereits vorhanden!");
            } else setError(res.error);
          }
        }
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <Box sx={{ minWidth: "80vw" }}>
      <Typography variant="h1" component="h1">
        Mail Templates
      </Typography>

      <StyledFormControl>
        <Select
          labelId="mail-template-select-label"
          id="mail-template-select"
          value={select}
          onChange={(e: SelectChangeEvent<number>) => {
            setSelect(e.target.value as number);
          }}
          label="Mail-Template"
        >
          <MenuItem value={0}>
            <Typography variant="body2">-</Typography>
          </MenuItem>
          {sortBy(mailTemplateList, [(e) => e.description.toLowerCase()]).map(
            (e) => (
              <MenuItem key={e.id} value={e.id}>
                <Typography variant="body2">{e.description}</Typography>
              </MenuItem>
            )
          )}
        </Select>
      </StyledFormControl>
      {select > 0 && (
        <MailTemplateForm
          mailTemplate={
            mailTemplateList.find((m) => m.id === select) || mailTemplateList[0]
          }
          onClick={onClick}
        />
      )}
    </Box>
  );
};

import { styled } from "@mui/system";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

export const StyledFormControl = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({
    margin: theme.spacing(1),
    //minWidth: 500,
    width: "100%",
  })
);

import { useContext, useEffect, useState } from "react";
import {
  fetchArticles,
  fetchOrderItems,
  ShopArticleT,
  ShopOrderItemT,
} from "./api";
import { ShopOrderContext } from "./ShopOrderContext";

import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

interface UseOrdersResultT extends UseResultT {
  orderList: ShopOrderItemT[];
  setOrderList: React.Dispatch<React.SetStateAction<ShopOrderItemT[]>>;
  articleList: ShopArticleT[];
  setArticleList: React.Dispatch<React.SetStateAction<ShopArticleT[]>>;
}

interface UseOrdersPropsI extends UsePropsI {}

export const useShopOrders = ({
  dontHideProgress = false,
}: UseOrdersPropsI): UseOrdersResultT => {
  const {
    orderList,
    setOrderList,
    articleList,
    setArticleList,
    loadingDone,
    setLoadingDone,
  } = useContext(ShopOrderContext);

  const [loadingDoneA, setLoadingDoneA] = useState(false);
  const [loadingDoneO, setLoadingDoneO] = useState(false);

  useLoadData({
    fetchFunc: fetchOrderItems,
    list: orderList,
    setList: setOrderList,
    setLoadingDone: setLoadingDoneO,
    dontHideProgress,
    omitLoadIfDefined: true,
    name: "fetchOrderItems",
  });

  useLoadData({
    fetchFunc: fetchArticles,
    list: articleList,
    setList: setArticleList,
    setLoadingDone: setLoadingDoneA,
    dontHideProgress,
    omitLoadIfDefined: true,
    name: "fetchArticles",
  });

  useEffect(() => {
    setLoadingDone(loadingDoneO && loadingDoneA);
  }, [loadingDoneO, loadingDoneA]);
  return {
    orderList,
    setOrderList,
    articleList,
    setArticleList,
    loadingDone: loadingDone,
  };
};

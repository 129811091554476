import { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { redirectAfterLoggedout } from "../shared/helper/logout";
import { fetchTokenAutoLogin, FetchTokenAutoLoginI, logMisc } from "./api";
import { AuthContext, buildAuthState } from "./AuthContext";
import { AutoLoginError } from "./AutoLoginError";
import { Error } from "./Error";
import { storeAuthToken } from "./localStore";
import { CustomerTestMailUuid } from "./CustomerTestMailUuid";
import { Loader } from "../shared/Loader";
import { TextExpiredError } from "./TextExpiredError";
import { fetchCSRFCookie } from "../shared/lib/api";
import { ApiBlockedError } from "./ApiBlockedError";

export const AutoLogin = (props: any) => {
  const { authState, setAuthState } = useContext(AuthContext);
  const params = useParams();

  const [loggedIn, setLoggedIn] = useState(0);
  const [logginError, setLoginError] = useState(0);
  const [testMail, setTestMail] = useState(false);

  const [errorAjax, setErrorAjax] = useState(false);
  const [cookieFetched, setCookieFetched] = useState(false);

  useEffect(() => {
    fetchCSRFCookie()
      .then(() => {
        console.log("%cAutoLogin.tsx line:30 ", "color: #007acc;");
        setCookieFetched(true);
      })
      .catch((e) => {
        console.error(e);
        setErrorAjax(true);
      });
  }, []);

  useEffect(() => {
    if (loggedIn) return;
    if (errorAjax) return;
    if (!cookieFetched) return;
    console.log(
      "%cAutoLogin.tsx line:38 useEffect now",
      "color: red;",
      params,
      authState,
      "loggedIn:",
      loggedIn,
      errorAjax,
      cookieFetched
    );

    fetchTokenAutoLogin(
      props.demo
        ? { uuid: "demo" }
        : (params as unknown as FetchTokenAutoLoginI)
    )
      .then((result) => {
        if (result.success && result.data && result.data.token) {
          console.log(
            "%cAutoLogin.tsx line:35 result.data",
            "color: #007acc;",
            result.data
          );
          if (result.data.token === "testmailuuid") {
            setTestMail(true);
            return;
          }
          if (!result.data.user) {
            redirectAfterLoggedout();
          } else {
            localStorage.clear();
            setAuthState(
              buildAuthState(
                true,
                result.data.token,
                "candidate",
                result.data.user
              )
            );
            storeAuthToken(result.data.token);
            // setAutoLogout();
            if (props.demo) {
              setTimeout(() => {
                setLoggedIn(1);
              }, 100);
            } else {
              setLoggedIn(1);
            }

            if (result.data.alreadyStarted) {
              setLoggedIn(2);
              if (result.data?.json) {
                const jsonData = JSON.parse(result.data.json);
                Object.entries(jsonData).forEach(([key, value]) => {
                  localStorage.setItem(key, (value + "") as string);
                });
              } else setLoginError(2);
            }
          }
        } else {
          console.log(
            "Login failed Error else: " + result.error,
            result.status
          );

          if (result.error.match(/expired/)) {
            setLoginError(3);
            return;
          }

          logMisc({
            uuid: params.uuid,
            error: "Login failed Error else: " + result.error,
            status: result.status,
          });
          if (result.status === 406) setLoginError(1);
          else setLoginError(2);
        }
      })
      .catch((e) => {
        console.log("Login failed error catch:", e);
        // logMisc({
        //   uuid: params.uuid,
        //   error: "Login failed Error else: ",
        //   exception: e,
        // });
        console.log(
          "%cAutoLogin.tsx line:93 e.error",
          "color: #007acc;",
          e.error
        );
        if (e.status === 400 && e.error.match(/expired/)) {
          setLoginError(3);
        } else setLoginError(1);
      });
  }, [params, setAuthState, authState, loggedIn, errorAjax, cookieFetched]);

  return errorAjax ? (
    <ApiBlockedError />
  ) : testMail ? (
    <CustomerTestMailUuid />
  ) : logginError == 1 ? (
    <AutoLoginError />
  ) : logginError == 2 ? (
    <Error />
  ) : logginError == 3 ? (
    <TextExpiredError />
  ) : loggedIn === 1 ? (
    <Navigate
      to={{
        pathname: authState.user.demo ? "/candidate/testmodus" : "/candidate",
      }}
    />
  ) : loggedIn === 2 ? ( // already started
    <Navigate
      to={{
        pathname: "/candidate/testmodus",
      }}
    />
  ) : (
    <Loader />
  );
};

import { TooltipIcon } from "../../shared/forms/TooltipIcon";
import { SelTreeT } from "../useSelTree";

interface AddRelationIconI {
  selTree: SelTreeT;
  onClick: () => void;
}
export const AddRelationIcon = ({ selTree, onClick }: AddRelationIconI) => {
  return (
    <TooltipIcon
      type="addRel"
      title={
        selTree.professionIsActive
          ? "Beruf ist aktiv - Zuordnung nicht möglich"
          : "Zuordnen"
      }
      iconProps={{
        ...(selTree.professionIsActive ? { color: "disabled" } : {}),
      }}
      {...(selTree.professionIsActive ? {} : { onClick })}
    />
  );
};

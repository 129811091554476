import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel, tabProps } from "../../shared/TabPanel";
import { EditorForm } from "./EditorForm";
import { QuestionT } from "../api";
import { FormErrorStateT } from "../../shared/helper/formError";

interface EditorTabsPropsI {
  questionState: QuestionT;
  setQuestionState: React.Dispatch<React.SetStateAction<QuestionT>>;
  errorState: FormErrorStateT;
  setErrorState: React.Dispatch<React.SetStateAction<FormErrorStateT>>;
}
export const EditorTabs = (props: EditorTabsPropsI) => {
  const [actTab, setActTab] = useState(0);

  useEffect(() => {
    if (props.errorState["answerOptions"].error) setActTab(1);
    else if (props.errorState["answer"].error) setActTab(2);
  }, [props, actTab, setActTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={actTab} onChange={handleChange}>
          <Tab label="Aufgabenstellung" {...tabProps(0)} />
          <Tab label="Optionen" {...tabProps(1)} />
          <Tab label="Antworten" {...tabProps(2)} />
          <Tab label="Kommentar" {...tabProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={actTab} index={0}>
        <EditorForm {...props} type="question" />
      </TabPanel>
      <TabPanel value={actTab} index={1}>
        <EditorForm {...props} type="answerOptions" />
      </TabPanel>
      <TabPanel value={actTab} index={2}>
        <EditorForm {...props} type="answer" />
      </TabPanel>
      <TabPanel value={actTab} index={3}>
        <EditorForm {...props} type="comment" />
      </TabPanel>
    </Box>
  );
};

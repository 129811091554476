import { Loader } from "../shared/Loader";
import { HeadCellT } from "../shared/table/EnhancedTable";
import { useTable } from "../shared/table/useTable";
import { HelpCategoryT } from "./api";
import { useContentHelp } from "./useHelp";
import { Help } from "./Help";

const headCellsDefault: HeadCellT<HelpCategoryT>[] = [
  { id: "id", label: "ID" },
  { id: "title", label: "Titel" },
  {
    id: "sortOrder",
    disablePadding: true,
    label: "Sortierung",
  },
];

export const HelpContentLoader = () => {
  useTable<HelpCategoryT>("help-category", headCellsDefault);

  const { loadingDone } = useContentHelp({});

  if (!loadingDone) return <Loader />;

  return <Help />;
};

import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FeedbackContext } from "../feedback/FeedbackContext";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { Grid, InputLabel, styled } from "@mui/material";
import { ProfessionImageSelect } from "./ProfessionImageSelect";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ProfessionT } from "./api";
import { ProfessionSelect } from "./ProfessionSelect";
import TypeSelect from "./TypeSelect";

const StyledImg = styled("img")({
  width: 200,
});

interface ProfessionFormPropsI {
  profession?: ProfessionT;
  onClick: (data: ProfessionT) => void;
  buttonLabel?: string;
}

const EMPTYCATEGORY: ProfessionT = {
  id: 0,
  name: "",
  testId: "",
  targetValue: 0,
  shortDesc: "",
  longDesc: "",
  demo: false,
  imgId: 0,
  active: false,
  imgName: "",
  imgPath: "",
  type: "",
};

export const ProfessionForm = (props: ProfessionFormPropsI) => {
  const [professionState, setProfessionState] = useState<ProfessionT>(
    props.profession ? props.profession : EMPTYCATEGORY
  );

  const [openImgDialog, setOpenImgDialog] = useState(false);

  const { showProgress } = useContext(FeedbackContext);
  const fields2check = ["name"];
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f) => {
      const error = professionState[f as keyof ProfessionT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });
    if (oneError) return;

    showProgress();
    props.onClick(professionState);
  };

  const insertField = ({
    id,
    label,
    rows = 1,
    required = false,
    float = false,
  }: {
    id: string;
    label: string;
    rows?: number;
    required?: boolean;
    float?: boolean;
  }) => {
    return (
      <StyledFormControl required={required} sx={{ width: "100%" }}>
        <TextField
          id={id}
          label={label}
          rows={rows}
          multiline={rows > 1}
          value={(professionState as any)[id] || ""}
          error={errorState[id]?.error}
          helperText={
            errorState[id]?.error
              ? errorState[id]?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setProfessionState((prevState) => {
              return {
                ...prevState,
                [id]: float ? e.target.value.replace(/,/, ".") : e.target.value,
              };
            })
          }
        />
      </StyledFormControl>
    );
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {insertField({
            id: "testId",
            label: "Test-Id",
            required: true,
          })}
        </Grid>
        <Grid item xs={6}>
          {insertField({
            id: "name",
            label: "Name",
            required: true,
          })}
        </Grid>
        <Grid item xs={6}>
          <StyledFormControl required={false} sx={{ width: "100%" }}>
            <InputLabel id="type-label">Typ</InputLabel>
            <TypeSelect
              id="type"
              labelId="type-label"
              label="Typ"
              value={professionState.type}
              setValue={(n: string) =>
                setProfessionState((p) => ({ ...p, type: n }))
              }
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={6}>
          <StyledFormControl required={false} sx={{ width: "100%" }}>
            <InputLabel htmlFor="parentId" shrink>
              Version von
            </InputLabel>
            <ProfessionSelect
              id="parentId"
              value={professionState.parentId || 0}
              filterIds={[professionState.id]}
              setValue={(n: number) =>
                setProfessionState((p) => ({ ...p, parentId: n }))
              }
            />
          </StyledFormControl>
        </Grid>

        <Grid item xs={6}>
          {insertField({ id: "targetValue", label: "Sollwert", float: true })}
        </Grid>
        <Grid item xs={6}>
          {insertField({
            id: "shortDesc",
            label: "Kurze Beschreibung",
          })}
        </Grid>

        <Grid item xs={6}>
          {
            <>
              {professionState.imgId ? (
                <StyledImg
                  src={
                    import.meta.env.VITE_IMGURL + "/" + professionState.imgPath
                  }
                  alt={professionState.imgName}
                  onClick={() => setOpenImgDialog(!openImgDialog)}
                />
              ) : (
                <AddPhotoAlternateIcon
                  onClick={() => setOpenImgDialog(!openImgDialog)}
                />
              )}
              <ProfessionImageSelect
                openImgDialog={openImgDialog}
                setOpenImgDialog={setOpenImgDialog}
                setProfessionState={setProfessionState}
              />
            </>
          }
        </Grid>

        <Grid item xs={6}>
          {insertField({
            id: "longDesc",
            label: "Lange Beschreibung",
            rows: 13,
          })}
        </Grid>
      </Grid>

      <StyledFormControl>
        <Button variant="contained" color="primary" onClick={onClick}>
          {props.buttonLabel || "Add"}
        </Button>
      </StyledFormControl>
    </div>
  );
};

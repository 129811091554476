import { useUsers } from "../user/useUsers";
import { AdminList } from "./AdminList";
import { Loader } from "../shared/Loader";

export const AdminLoader = () => {
  const { userList } = useUsers({ role: "admin" });

  if (userList.length === 0) return <Loader />;
  return <AdminList />;
};

import { useContext } from "react";

import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";
import { fetchTestResults } from "./api";
import { ProfessionContext } from "./ProfessionContext";
import { CandidateResultsT } from "../candidate/api";

export interface UseTestResultT extends UseResultT {
  testResultList: CandidateResultsT;
  setTestResultList: React.Dispatch<React.SetStateAction<CandidateResultsT>>;
}

export interface UseTestResultsPropsI extends UsePropsI {
  professionId: number;
}

export const useTestResults = ({
  dontHideProgress = false,
  professionId = 0,
}: UseTestResultsPropsI): UseTestResultT => {
  const { testResultList, setTestResultList, loadingDone, setLoadingDone } =
    useContext(ProfessionContext);

  useLoadData({
    fetchFunc: () => fetchTestResults(professionId),
    list: testResultList,
    setList: setTestResultList,
    setLoadingDone,
    dontHideProgress,
    omitLoadIfDefined: true,
  });

  return { testResultList, setTestResultList, loadingDone };
};

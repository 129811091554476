import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import BuildIcon from "@mui/icons-material/Build";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import LoginIcon from "@mui/icons-material/Login";
import LinkIcon from "@mui/icons-material/Link";
import { ReactElement } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import Crop32Icon from "@mui/icons-material/Crop32";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import WebIcon from "@mui/icons-material/Web";
import AssignmentIcon from "@mui/icons-material/Assignment";
import IosShareIcon from "@mui/icons-material/IosShare";
import { SvgIconProps } from "@mui/material";
import { stringToSlug } from "../../shared/helper/string";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import InfoIcon from "@mui/icons-material/Info";
import HotTubIcon from "@mui/icons-material/HotTub";
import DownloadIcon from "@mui/icons-material/Download";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import SettingsIcon from "@mui/icons-material/Settings";

interface IconPropsT extends SvgIconProps {
  "data-testid"?: string;
}

interface TooltipIconPropsI {
  type:
    | "add"
    | "delete"
    | "edit"
    | "password"
    | "sendPassword"
    | "sendWelcome"
    | "remove"
    | "profession"
    | "login"
    | "link"
    | "showPreview"
    | "hidePreview"
    | "mail"
    | "show"
    | "hide"
    | "small"
    | "normal"
    | "results"
    | "big"
    | "newTab"
    | "versionNewer"
    | "versionOlder"
    | "addRel"
    | "delRel"
    | "disabledRel"
    | "setActive"
    | "setInactive"
    | "copy"
    | "version"
    | "swap"
    | "download"
    | "filter"
    | "filterAlt"
    | "info"
    | "settings"
    | "demo"
    | "demoOff";

  onClick?: (e?: any) => void;
  title?: string | ReactElement;
  sx?: any;
  iconProps?: IconPropsT;
  off?: boolean;
  className?: string;
}

const getIcon = (props: TooltipIconPropsI): ReactElement => {
  switch (props.type) {
    case "add":
      return (
        <AddCircleOutlineIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          fontSize="inherit"
        />
      );
    case "delete":
      return (
        <DeleteIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "edit":
      return (
        <CreateIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "password":
      return (
        <VpnKeyIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "sendPassword":
      return (
        <ForwardToInboxIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "sendWelcome":
      return (
        <MarkEmailReadIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "remove":
      return (
        <RemoveCircleOutlineIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "profession":
      return (
        <BuildIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "login":
      return (
        <LoginIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "link":
      return (
        <LinkIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "addRel":
      return (
        <AddIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "info":
      return (
        <InfoIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "swap":
      return (
        <SwapCallsIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "delRel":
      return (
        <HighlightOffIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "disabledRel":
      return (
        <NotInterestedOutlinedIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );

    case "versionNewer":
      return (
        <ArchiveIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "versionOlder":
      return (
        <UnarchiveIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "version":
      return (
        <ContentCopyIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "copy":
      return (
        <CopyAllIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "newTab":
      return (
        <IosShareIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "showPreview":
      return (
        <WebIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "hidePreview":
      return (
        <AssignmentIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "mail":
      return (
        <ContactMailIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );

    /* display */
    case "show":
      return (
        <VisibilityIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "hide":
      return (
        <VisibilityOffIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "small":
      return (
        <CloseFullscreenIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "normal":
      return (
        <Crop32Icon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "big":
      return (
        <AspectRatioIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "results":
      return (
        <BackupTableIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
        />
      );
    case "setActive":
      return (
        <PlayArrowIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
          data-testid="tooltipicon-setActive"
        />
      );
    case "download":
      return (
        <DownloadIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
          data-testid="tooltipicon-setActive"
        />
      );
    case "setInactive":
      return (
        <PauseIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
          data-testid="tooltipicon-setInactive"
        />
      );
    case "filter":
      return props.off ? (
        <FilterListOffOutlinedIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
          data-testid="tooltipicon-ilter"
        />
      ) : (
        <FilterListOutlinedIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
          data-testid="tooltipicon-ilter"
        />
      );
    case "settings":
      return <SettingsIcon onClick={props.onClick} sx={props.sx} />;
    case "filterAlt":
      return props.off ? (
        <FilterAltOffOutlinedIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
          data-testid="tooltipicon-ilter"
        />
      ) : (
        <FilterAltOutlinedIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={props.sx}
          {...props.iconProps}
          data-testid="tooltipicon-ilter"
        />
      );
    case "demo":
      return props.off ? (
        <HotTubIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={{ ...props.sx, color: "lightgray" }}
          {...props.iconProps}
          data-testid="tooltipicon-ilter"
        />
      ) : (
        <HotTubIcon
          onClick={props.onClick}
          className={"tooltip-icon " + props.className}
          sx={{ ...props.sx, color: "red" }}
          {...props.iconProps}
          data-testid="tooltipicon-ilter"
        />
      );
  }
  return <></>;
};

export const TooltipIcon = (props: TooltipIconPropsI) => {
  if (props.title)
    return (
      <Tooltip
        title={<div className="tooltip-text">{props.title}</div>}
        data-testid={
          "tooltipicon-" +
          stringToSlug(typeof props.title === "string" ? props.title : "XxX")
        }
      >
        {getIcon(props)}
      </Tooltip>
    );
  else return getIcon(props);
};

import { useContext, useState } from "react";
import {
  fetchStatsQuestion,
  fetchStatsTransaction,
  StatsQuestionT,
  StatsTransactionT,
} from "./api";
import { StatsContext } from "./StatsContext";
import { useLoadData } from "../shared/hooks/useLoadData";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import React from "react";

export interface UseStatssResultT extends UseResultT {
  statsTransaction: StatsTransactionT;
  setStatsTransaction: React.Dispatch<React.SetStateAction<StatsTransactionT>>;
  statsQuestion: StatsQuestionT[];
  setStatsQuestion: React.Dispatch<React.SetStateAction<StatsQuestionT[]>>;
}

interface UseStatssPropsI extends UsePropsI {
  onlyActive?: boolean;
  check?: boolean;
}

export const useStats = ({
  dontShowProgress = false,
  dontHideProgress = false,
  onlyActive = false,
  check = false,
}: UseStatssPropsI): UseStatssResultT => {
  const {
    statsTransaction,
    setStatsTransaction,
    statsQuestion,
    setStatsQuestion,
  } = useContext(StatsContext);

  const [loadingDoneT, setLoadingDoneT] = useState(false);
  const [loadingDoneQ, setLoadingDoneQ] = useState(false);

  useLoadData({
    fetchFunc: fetchStatsTransaction,
    list: statsTransaction,
    setList: setStatsTransaction,
    setLoadingDone: setLoadingDoneT,
    dontHideProgress,
    dontShowProgress,
    name: "fetchStats",
  });

  useLoadData({
    fetchFunc: fetchStatsQuestion,
    list: statsQuestion,
    setList: setStatsQuestion,
    setLoadingDone: setLoadingDoneQ,
    dontHideProgress,
    dontShowProgress,
    name: "fetchStats",
  });

  return {
    statsTransaction,
    setStatsTransaction,
    statsQuestion,
    setStatsQuestion,
    loadingDone: loadingDoneT && loadingDoneQ,
  };
};

import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import addrs from "email-addresses";
import { isEmpty, omit, set } from "lodash";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { CandidateContext, CandidateTLen } from "../candidate/CandidateContext";
import { CountrySelect } from "../shared/forms/CountrySelect";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CandidateT } from "./api";
import { DatePicker } from "../shared/forms/DatePicker";
import GenderSelect from "../shared/forms/GenderSelect";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CountryContext } from "../country/CountryContext";

interface CandidateFormPropsI {
  candidate?: CandidateT;
  onClick: (data: CandidateT) => void;
  button: string;
  edit?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const EMPTYUSER: CandidateT = {
  id: 0,
  customerId: 0,
  professionId: 0,
  firstname: "",
  lastname: "",
  email: "",
  street: "",
  zipcode: "",
  city: "",
  country: "",
  phone: "",
  uuid: "",
  dateBirth: null,
  comment: "",
  createdAt: new Date(),
  schoolGraduation: "",
};

const fields2check = ["email", "firstname", "lastname"];

export const CandidateForm = (props: CandidateFormPropsI) => {
  const { showProgress } = useContext(FeedbackContext);
  const { schoolGraduationList } = useContext(CandidateContext);
  const { countryList } = useContext(CountryContext);

  if (props.candidate) {
    Object.entries(EMPTYUSER).forEach(([key, val]) => {
      if (props.candidate && !props.candidate[key as keyof CandidateT])
        (props.candidate[key as keyof CandidateT] as typeof val) = val;
    });
  }

  const [candidateState, setCandidateState] = useState(
    props.candidate ? props.candidate : EMPTYUSER
  );
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onChange = (val: string | number | null, fieldname: string) => {
    setCandidateState((prevState) => {
      return { ...prevState, [fieldname]: val };
    });
  };

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f) => {
      const error = candidateState[f as keyof CandidateT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });
    if (oneError) return;

    if (!isEmpty(candidateState.email)) {
      const error =
        candidateState.email.indexOf(".") > 0 && // apparently foo@bar is a valid email-adress
        addrs.parseOneAddress(candidateState.email)
          ? false
          : true;

      setErrorState((p) =>
        buildErrorState(p, "email", error, "Ungültige E-Mail")
      );
      oneError = oneError || error;
    }
    if (oneError) return;

    showProgress();
    props.onClick(candidateState);
  };

  return (
    <Box
      data-testid="candidateForm"
      className="candidate-form"
      sx={{ width: "800px" }}
    >
      <StyledFormControl sx={{ mb: "2px" }}>
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="lastname"
          label="Nachname"
          required={true}
          value={candidateState.lastname}
          error={errorState.lastname?.error}
          helperText={
            errorState.lastname?.error
              ? errorState.lastname?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) => onChange(e.target.value, "lastname")}
        />
      </StyledFormControl>

      <StyledFormControl
        sx={{ mb: "2px" }}
        required={true}
        data-testid="firstnameContainer"
      >
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="firstname"
          label="Vorname"
          required={true}
          value={candidateState.firstname}
          error={errorState.firstname?.error}
          helperText={
            errorState.firstname?.error
              ? errorState.firstname?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) => onChange(e.target.value, "firstname")}
        />
      </StyledFormControl>

      <StyledFormControl sx={{ mb: "2px" }}>
        <InputLabel htmlFor="genderselect">Anrede </InputLabel>
        <GenderSelect
          id="gender"
          label="Anrede"
          value={candidateState.gender}
          setValue={(v: string) => onChange(v, "gender")}
          labelId="genderselect"
          style={{
            height: "47px",
          }}
        />
      </StyledFormControl>
      <StyledFormControl sx={{ mb: "2px" }} data-testid="emailContainer">
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="email"
          label="E-Mail"
          type="email"
          required={true}
          error={errorState.email?.error}
          helperText={
            errorState.email?.error
              ? errorState.email?.helperText || "Bitte ausfüllen"
              : ""
          }
          value={candidateState.email}
          onChange={(e) => onChange(e.target.value, "email")}
        />
      </StyledFormControl>
      <StyledFormControl sx={{ mb: "2px" }} required={true}>
        <DatePicker
          id="dateBirth"
          label="Geburtsdatum"
          value={candidateState.dateBirth ? candidateState.dateBirth : null}
          setValue={(v: Date | null) => {
            setCandidateState((prevState) => ({ ...prevState, dateBirth: v }));
          }}
          size="medium"
          sx={{ width: "100%", height: "1em" }}
          clearSx={{ top: "12px" }}
          required={false}
          error={errorState.dateBirth?.error}
          helperText={
            errorState.dateBirth?.error
              ? errorState.dateBirth?.helperText || "Bitte ausfüllen"
              : ""
          }
        />
      </StyledFormControl>
      <StyledFormControl
        sx={{
          width: "calc(100% - 180px - 24px)",
          mb: "2px",
        }}
      >
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="street"
          label="Straße"
          value={candidateState.street}
          onChange={(e) => onChange(e.target.value, "street")}
        />
      </StyledFormControl>
      <StyledFormControl
        sx={{
          width: "180px",
          mb: "2px",
          mr: 0,
          pr: 0,
          // eslint-disable-next-line
          position: "relative" as "relative",
          left: "6px",
        }}
      >
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="streetno"
          label="Hausnummer"
          value={candidateState.streetno}
          onChange={(e) => onChange(e.target.value, "streetno")}
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: "200px", mb: "2px" }}>
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="zipcode"
          label="PLZ"
          value={candidateState.zipcode}
          onChange={(e) => {
            if (e.target.value.toString().length <= CandidateTLen.zipcode)
              onChange(e.target.value, "zipcode");
          }}
        />
      </StyledFormControl>
      <StyledFormControl
        sx={{
          width: "calc(100% - 200px - 24px)",
          mr: 0,
          mb: "2px",
          pr: 0,
          // eslint-disable-next-line
          position: "relative" as "relative",
          left: "6px",
        }}
      >
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="city"
          label="Ort"
          value={candidateState.city}
          onChange={(e) =>
            setCandidateState((prevState) => {
              return { ...prevState, city: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl sx={{ mb: "2px" }}>
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="phone"
          label="Telefon"
          value={candidateState.phone}
          onChange={(e) => onChange(e.target.value, "phone")}
        />
      </StyledFormControl>
      <StyledFormControl sx={{ mb: "2px" }}>
        <Autocomplete
          id="nationality"
          freeSolo
          options={countryList.map((l) => l.name)}
          sx={{ height: "47px" }}
          renderInput={(params) => (
            <TextField
              ref={params.InputProps.ref}
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  height: "47px",
                },
              }}
              label="Staatsangehörigkeit"
            />
          )}
          value={candidateState.nationality || ""}
          onChange={(e, v) => onChange(v, "nationality")}
          onInputChange={(e, v) => onChange(v, "nationality")}
        />
      </StyledFormControl>
      <StyledFormControl sx={{ mb: "2px" }}>
        <Autocomplete
          id="schoolGraduations"
          freeSolo
          options={schoolGraduationList.map((l) => l.name)}
          sx={{ height: "47px" }}
          renderInput={(params) => (
            <TextField
              ref={params.InputProps.ref}
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  height: "47px",
                },
              }}
              label="Schulabschluss"
            />
          )}
          value={candidateState.schoolGraduation || ""}
          onChange={(e, v) => onChange(v, "schoolGraduation")}
          onInputChange={(e, v) => onChange(v, "schoolGraduation")}
        />
      </StyledFormControl>
      <StyledFormControl sx={{ mb: "2px" }}>
        <TextField
          inputProps={{
            style: {
              height: "1em",
            },
          }}
          id="comment"
          label="Bemerkungen"
          value={candidateState.comment}
          onChange={(e) => onChange(e.target.value, "comment")}
        />
      </StyledFormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <StyledFormControl
          sx={{
            width: "200px",
            mr: "-5px",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => props.setOpen && props.setOpen(false)}
          >
            Abbrechen
          </Button>
        </StyledFormControl>
        <StyledFormControl
          sx={{
            width: "200px",
            mr: "-5px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            startIcon={<AddCircleOutlineIcon />}
          >
            Anlegen
          </Button>
        </StyledFormControl>
      </Box>
    </Box>
  );
};

import TableCell from "@mui/material/TableCell";
import React, { Key } from "react";
import { EditableEntry, EditableEntryPropsTypeT } from "./EditableEntry";
import { SxProps } from "@mui/material";

type SetStateObjectAnyT = (x: any) => void;

interface InsertCellPropsI<T extends { id: number }> {
  id: keyof T;
  type?: EditableEntryPropsTypeT;
  isObligatory?: boolean;
  stateObject: T;
  setStateObject: React.Dispatch<React.SetStateAction<T>> | SetStateObjectAnyT;
  onClickAway?: () => void;
  label?: string;
  maxLength?: number;
  checkDup?: (newEmail: string) => string;
  hidden?: boolean;
  checkContent?: (str: string) => string;
  placeHolder?: string;
  sx?: SxProps;
  disabled?: boolean;
  dataTestId?: string;
}

export const insertEditableCell = <T extends { id: number }>({
  id,
  type = "string" as EditableEntryPropsTypeT,
  isObligatory = false,
  stateObject,
  setStateObject,
  onClickAway,
  label,
  maxLength,
  checkDup,
  checkContent,
  placeHolder,
  sx,
  disabled,
  dataTestId,
}: InsertCellPropsI<T>) => {
  const key: string | number = (stateObject as any).subCategoryId
    ? `${(stateObject as any).subCategoryId}${stateObject.id}${String(id)}`
    : `${stateObject.id}--${String(id)}`;

  return type === "number" ? (
    <TableCell align="left" sx={sx} key={key}>
      <EditableEntry
        type="number"
        id={id as string}
        value={stateObject[id] as unknown as number}
        setValue={(v) =>
          setStateObject((prevState) => {
            return { ...prevState, [id]: v };
          })
        }
        onClickAway={onClickAway}
        isObligatory={isObligatory}
        setError={(b: boolean) =>
          setStateObject((prevState) => {
            return { ...prevState, error: b };
          })
        }
        label={label}
        disabled={disabled}
        dataTestId={dataTestId}
      />
    </TableCell>
  ) : (
    <TableCell component="th" scope="row" padding="none" key={key} sx={sx}>
      <EditableEntry
        id={id as string}
        value={stateObject[id] as unknown as string}
        setValue={(v) =>
          setStateObject((prevState) => {
            return { ...prevState, [id]: v };
          })
        }
        onClickAway={onClickAway}
        multiline={true}
        isObligatory={isObligatory}
        setError={(b: boolean) =>
          setStateObject((prevState) => {
            return { ...prevState, error: b };
          })
        }
        label={label}
        type={type as EditableEntryPropsTypeT}
        {...(maxLength ? { maxLength: maxLength } : {})}
        {...(checkDup ? { checkDup: checkDup } : {})}
        {...(checkContent ? { checkContent: checkContent } : {})}
        {...(placeHolder ? { placeHolder: placeHolder } : {})}
        disabled={disabled}
        dataTestId={dataTestId}
      />
    </TableCell>
  );
};

export const EditableCell = React.memo(
  <T extends { id: number }>(props: InsertCellPropsI<T>) => {
    if (props.hidden) return <></>;
    return insertEditableCell(props);
  }
) as typeof insertEditableCell;

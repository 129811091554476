import { useParams } from "react-router-dom";
import { Loader } from "../shared/Loader";
import { useTargets } from "./useTargets";
import { Targets } from "./Targets";

export const TargetLoader = ({
  professionId,
  close,
  onUpdate,
}: {
  professionId: number;
  close: () => void;
  onUpdate: (p: number, n: number) => void;
}) => {
  const params = useParams();
  const { loadingDone, targetList } = useTargets({
    professionId: professionId,
  });
  if (!loadingDone) return <Loader />;

  return (
    <Targets professionId={professionId} close={close} onUpdate={onUpdate} />
  );
};

/**
 * generic timer hook
 *
 * timer always cause up, if need backward, must be done outside
 *
 * @param endTime: int|optional: time when time is ended and endFunc() is called
 * @param endFunc: callback when timer is finished
 * @param name: string: distinct between multiple times
 *
 * @returns time: actual time as number
 * @returns clearTimer: func to be called outside to clear timer
 * @returns stopTimer: func to be called outside to stop timer w/o resetting it
 * @returns startTimer: func to be called outside to start timer
 * @returns restartTimer: func to be called outside to restart timer
 *
 */
import { useContext, useEffect, useState } from "react";
import {
  uglifyNumber,
  uglifyTimestamp,
  unUglifyTimestamp,
} from "../shared/helper/uglify";
import { LSKEYS, TestModusContext } from "./TestModusContext";
import { getUnixTimestamp } from "../shared/helper/datetime";

interface UseTimerOptionsI {
  endTime?: number;
  endFunc?: () => void;
  name?: string;
}
export const useTimer = (
  initialTime: number,
  { endTime = 999999999, endFunc, name = "" }: UseTimerOptionsI = {}
) => {
  const [time, setTime] = useState(initialTime);
  /** we store the interval to be later able to delete it */
  const [timeIntervalId, setTimeIntervalId] = useState(0);
  const { setInitialTime } = useContext(TestModusContext);

  /** we might have a callback when the time is up */
  useEffect(() => {
    if (endFunc && time === endTime) {
      endFunc();
    }
  }, [time, endTime, endFunc]);

  /**
   * clears timer, remove from local storage  and set time to -1
   */
  const clearTimer = () => {
    if (name) localStorage.removeItem(name);
    window.clearInterval(timeIntervalId);
    setTime(-1);
  };

  const stopTimer = () => {
    console.log("%cuseTimer.ts line:47 stopTimer", "color: #007acc;", name);
    window.clearInterval(timeIntervalId);
  };

  /** shortcut to clear + start */
  const restartTimer = () => {
    clearTimer();
    startTimer();
  };

  /**
   *
   * @param initialTimeOverwrite: might set initial time to a different value then now()
   *
   * stores initial time in local storage
   * startsInterval
   */
  const startTimer = (initialTimeOverwrite = 0, pauseTime = 0) => {
    const initialTimeAct = initialTimeOverwrite
      ? initialTimeOverwrite
      : initialTime;
    console.log(
      "%c⌚️ useTimer.ts line:66 startTimer",
      "color: #007acc;",
      name,
      initialTimeAct
    );
    if (initialTimeOverwrite && name in LSKEYS) {
      const k = Object.values(LSKEYS).find((k) => k === name);
      if (k) setInitialTime(k, initialTimeOverwrite);
    }
    if (endTime && initialTimeAct >= endTime) return;
    if (name && initialTimeAct <= 0)
      localStorage.setItem(name, uglifyTimestamp(getUnixTimestamp()));

    setTime(initialTimeAct);
    /** add 1 every second */
    const id = window.setInterval(() => {
      setTime((p) => {
        /** every 10 seconds we check for rounding differences / problems with milliseconds
         * and correct them regarding the time in local storage
         */
        if (p > 0 && p % 10 === 0 && name && name != LSKEYS.TIME_PAUSE) {
          // const timeAct = localStorage.getItem(name);
          // if (timeAct) {
          //   let newTime = Math.round(
          //     (Date.now() - unUglifyTimestamp(timeAct)) / 1000
          //   );
          //   console.log(
          //     "correction 🍺🍺",
          //     name,
          //     "timeAct:",
          //     new Date(unUglifyTimestamp(timeAct)).toLocaleTimeString(),
          //     "timeNOW:",
          //     new Date(Date.now()).toLocaleTimeString(),
          //     "noround:",
          //     (Date.now() - unUglifyTimestamp(timeAct)) / 1000,
          //     ", p:",
          //     p,
          //     "newTime:",
          //     newTime,
          //     "pauseTime:",
          //     pauseTime,
          //     "set to",
          //     newTime - pauseTime
          //   );
          //   return newTime - pauseTime;
          // }
        } else if (name === LSKEYS.TIME_PAUSE) {
          // console.log("timePause 🍺", p + 1);
          localStorage.setItem(LSKEYS.TIME_PAUSE, uglifyNumber(p + 1));
          localStorage.setItem(
            LSKEYS.IS_PAUSED,
            uglifyTimestamp(getUnixTimestamp())
          );
        }
        //  if (p % 3 === 0) console.log("time 🍺", name, ":", p + 1);
        return p + 1;
      });
    }, 1000);
    setTimeIntervalId(id);
    if (name === LSKEYS.TIME_ACT) setInitialTime(LSKEYS.TIME_ACT, 0);
  };

  return {
    time,
    setTime,
    clearTimer,
    stopTimer,
    startTimer,
    restartTimer,
  };
};

import { useContext, useEffect, useRef, useState } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { SubCategoryChangedT, SubCategoryContext } from "./SubCategoryContext";
import { deleteCategory, SubCategoryT, updateCategory } from "./api";
import { ErrorContext } from "../error/ErrorContext";

import { DeleteButton } from "../shared/forms/DeleteButton";
import { FeedbackContext } from "../feedback/FeedbackContext";

import { EditableCell } from "../shared/list/InsertCell";
import {
  debouncedSetUpdatedState,
  deleteStateFromArrayById,
} from "../shared/helper/state";
import { Active } from "../shared/forms/Active";
import { checkChanged } from "../shared/helper/object";

import Grid from "@mui/material/Grid";
import { InfoPopOver } from "../shared/InfoPopover";

interface SubCategoryPropsI {
  category: SubCategoryT;
  setShowSave: (showSave: boolean) => void;
}

export const SubCategory = ({ category, setShowSave }: SubCategoryPropsI) => {
  const { setCategoryList, categoryList, setChangedCategoryList } =
    useContext(SubCategoryContext);

  const [hasChanged, setHasChanged] = useState(false);
  const [categoryState, setCategoryState] =
    useState<SubCategoryChangedT>(category);

  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(category, categoryState, hasChanged, setHasChanged);
  }, [setHasChanged, hasChanged, category, categoryState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(categoryState, setChangedCategoryList);
  }, [categoryState, setChangedCategoryList]);

  const setActive = (active: boolean) => {
    showProgress();
    setCategoryState({ ...categoryState, active: active ? 1 : 0 }); // we don't use prevState here to avoid 2 versions in db and state
    updateCategory({ ...categoryState, active: active ? 1 : 0 })
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setCategoryList(
            categoryList.map((e) => {
              if (e.id === categoryState.id)
                return { ...e, active: active ? 1 : 0 };
              return e;
            })
          );
        } else setError("internal error");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={category.id} selected={hasChanged}>
        <EditableCell
          id="name"
          checkDup={(newName: string) => {
            return categoryList.some(
              (c) => c.name === newName && c.id !== categoryState.id
            )
              ? "Dieser Name wird bei einer anderen Kategorie verwendet!"
              : "";
          }}
          isObligatory={true}
          stateObject={categoryState}
          setStateObject={setCategoryState}
        />

        <EditableCell
          id="view"
          type="number"
          stateObject={categoryState}
          setStateObject={setCategoryState}
        />
        <EditableCell
          id="introText"
          key="introText"
          stateObject={categoryState}
          setStateObject={setCategoryState}
          maxLength={5000}
        />
        <EditableCell
          id="memTime"
          type="number"
          stateObject={categoryState}
          setStateObject={setCategoryState}
        />
        <EditableCell
          id="memText"
          key="memTime"
          stateObject={categoryState}
          setStateObject={setCategoryState}
          maxLength={5000}
        />

        <TableCell padding="none" sx={{ width: 150 }} align="center">
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={8}>
              <Active
                active={categoryState.active ? true : false}
                disableSetActive={
                  categoryState.active && categoryState.relationCount
                    ? true
                    : false
                }
                setActive={setActive}
              />
            </Grid>
            <Grid item xs={4}>
              <DeleteButton
                id={categoryState.id}
                deleteFunc={deleteCategory}
                successFunc={() =>
                  setCategoryList((p) =>
                    deleteStateFromArrayById(p, categoryState.id)
                  )
                }
                {...(categoryState.relationCount
                  ? {
                      disabled: true,
                      tooltipTitle:
                        "Diese Kategorie wird bereits in einem Beruf verwendet",
                    }
                  : {})}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right">
          <InfoPopOver id={category.id} list={category.mainCategories} />
        </TableCell>
      </TableRow>
    </>
  );
};

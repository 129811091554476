import Box from "@mui/system/Box";
import { useDrop } from "react-dnd";
import React from "react";
import {
  ELEMENT_HEIGHT,
  ELEMENT_HEIGHT_IMG,
  ELEMENT_PB,
  ELEMENT_WIDTH_IMG,
  ELEMENT_WIDTH,
  PositionT,
} from "../QuestionDnD";

interface DndDropPopsI {
  dropIndex: string;
  dropPosition: PositionT;
  onDrop: (
    dropIndex: string | number,
    val: string,
    dragPosition: PositionT,
    dropPosition: PositionT
  ) => void;
  accept: string;
  children?: React.ReactNode;
  isImage?: boolean;
}

export const DropContainer = React.memo(
  ({
    dropIndex,
    dropPosition,
    onDrop,
    accept,
    children,
    isImage = false,
  }: DndDropPopsI) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: accept,
      drop: (item: any) => {
        if (item && item.content)
          onDrop(dropIndex, item.dragIndex, item.position, dropPosition);
        return { dropIndex };
      },
      collect: (monitor) => {
        // setActContent(monitor.getItem().content);
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }));

    const isActive = canDrop && isOver;
    let backgroundColor = "#eeeeee";
    if (isActive) {
      backgroundColor = "darkgray";
    } else if (canDrop) {
      backgroundColor = "lightgray";
    }

    return (
      <Box
        ref={drop}
        role={"drop" + dropIndex}
        id={"drop" + dropIndex}
        data-testid={"drop" + dropIndex}
        className="dnd-drop-area"
        sx={{
          backgroundColor,
          height:
            children && React.Children.toArray(children).length > 1
              ? React.Children.toArray(children).length *
                  ((isImage ? ELEMENT_HEIGHT_IMG : ELEMENT_HEIGHT) +
                    ELEMENT_PB) -
                ELEMENT_PB
              : isImage
              ? ELEMENT_HEIGHT_IMG
              : ELEMENT_HEIGHT,
          mb: ELEMENT_PB + "px",
          maxWidth: isImage ? ELEMENT_WIDTH_IMG : ELEMENT_WIDTH,
          minWidth: 120,
          border: "1px dotted gray",
        }}
      >
        {children ? children : ""}
      </Box>
    );
  }
);

import { useContext, useEffect } from "react";
import { FeedbackContext } from "./FeedbackContext";

export const useHideProgress = (cond: boolean): boolean => {
  const { hideProgress } = useContext(FeedbackContext);

  useEffect(() => {
    if (!cond) return;
    hideProgress();
  }, [cond, hideProgress]);

  return cond;
};

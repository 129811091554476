import React, { useState, useContext, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { CandidateT, exportResult } from "../candidate/api";

import { CandidateContext } from "../candidate/CandidateContext";
import FileSaver from "file-saver";

import {
  ComparatorT,
  EnhancedTableHead,
  HeadCellT,
  NonOptionalKeys,
  OrderDirT,
  getComparator,
  handleRequestSort,
  stableSort,
} from "../shared/table/EnhancedTable";
import { usePagination } from "../shared/hooks/usePagination";
import { ProfessionContext } from "../profession/ProfessionContext";
import { splitLongLabel } from "../shared/helper/string";
import { Box } from "@mui/material";
import { CandidateResultEntry } from "./CandidateResultEntry";
import { date_hr } from "../shared/helper/datetime";

export interface FilteredCandidateT extends Partial<CandidateT> {
  data?: CandidateT;
  lastname: string;
  firstname: string;
  testedAt?: Date;
  createdAt: Date;
  resultPercent?: string;
  concentrationPercent?: string;
  resultsMain?: any[];
  targetValue: number;
  hasTarget: boolean;
  hasConcentration: boolean;
  hasOnlyConcentration: boolean;
}

const sortFunc = (a: FilteredCandidateT, b: FilteredCandidateT) =>
  a.testedAt && b.testedAt ? b.testedAt.getTime() - a.testedAt.getTime() : -1;

interface CandidateResultTablePropsI {
  selectedProfession: number;
  filter: string;
  saveCandidates: () => void;
  setShowSave: (show: boolean) => void;
  exportClicked?: boolean;
  setExportClicked?: (clicked: boolean) => void;
  exportType?: number;
}

export const CandidateResultTable = ({
  selectedProfession,
  filter,
  setShowSave,
  exportClicked,
  setExportClicked,
  exportType,
}: CandidateResultTablePropsI) => {
  const { candidateList } = useContext(CandidateContext);
  const { setError } = useContext(ErrorContext);
  const { openSnackbar } = useContext(FeedbackContext);

  const { professionList } = useContext(ProfessionContext);

  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination("candidate");

  useEffect(() => {
    setPage(0);
  }, [filter, selectedProfession]);

  useEffect(() => {
    if (exportClicked) {
      setExportClicked && setExportClicked(false);
      exportCandidates();
    }
  }, [exportClicked]);

  const [orderDir, setOrderDir] = useState<OrderDirT>("asc");
  const [orderBy, setOrderBy] =
    useState<NonOptionalKeys<FilteredCandidateT>>("lastname");

  const comparator: ComparatorT<FilteredCandidateT> = getComparator(
    orderDir,
    orderBy
  );

  const exportCandidates = () => {
    const head = headCells
      .filter((c) => hasConcentration || c.id !== "concentrationPercent")
      .map((h) => h.rawLabel);

    const data = filteredCandidateList.map((c) => [
      c.firstname,
      c.lastname,
      c.dateBirth ? date_hr(c.dateBirth) : "",
      c.testedAt ? date_hr(c.testedAt) : "",
      c.resultPercent + "%",
      c.hasTarget ? "erreicht" : "nicht erreicht",
      ...(c.hasConcentration ? [c.concentrationPercent + "%"] : []),
      c.comment,
      ...(c.resultsMain
        ?.filter((m: any) => !m.name.match(/Konzentration/))
        .map((m: any) => m.percent + "%") || []),
    ]);

    exportResult(exportType ? "CSV" : "XLSX", [head, ...data]).then((res) => {
      if (res.success && res.data) {
        openSnackbar("success", "Export erfolgreich");
        if (exportType === 0) {
          FileSaver.saveAs(
            new Blob([res.data], {
              //type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              type: "application/vnd.ms-excel;charset=utf-8",
              // type: "application/pdf",
            }),
            "export.xlsx"
          );
        } else
          FileSaver.saveAs(
            new File([res.data], "export.csv", {
              type: "text/csv;charset=utf-8",
            })
          );
      } else {
        setError(res.error);
      }
    });
  };

  const headCells: HeadCellT<FilteredCandidateT>[] = [
    {
      id: "lastname",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label">Nachname</Box>
        </Box>
      ),
      rawLabel: "Nachname",
    },
    {
      id: "firstname",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label">Vorname</Box>
        </Box>
      ),
      rawLabel: "Vorname",
    },
    {
      id: "dateBirth",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label">Geburtsdatum</Box>
        </Box>
      ),
      rawLabel: "Geburtsdatum",
    },
    {
      id: "testDate",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label">Testdatum</Box>
        </Box>
      ),
      rawLabel: "Testdatum",
    },
    {
      id: "resultSumPercent",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label">Ergebnis</Box>
        </Box>
      ),
      rawLabel: "Ergebnis",
    },
    {
      id: "hasTarget",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label with-tooltip">Sollprofil</Box>
        </Box>
      ),
      rawLabel: "Sollprofil",
      tooltip: (
        <>
          Das Sollprofil entspricht Ihren Angaben in der Übersicht „Meine Tests“
          auf der Startseite. Es drückt aus, welchen Prozentwert der
          Maximalpunktzahl Sie insgesamt und in den jeweiligen Testbereichen zum
          Bestehen voraussetzen. Im Allgemeinen sollte das Ergebnis nicht unter
          50 % liegen.
          <br />
          <br />
          Aufgrund der empirischen Erfahrungswerte ergeben sich zur Einschätzung
          der Ergebnisse folgende Orientierungsgrößen:
          <br />
          <ul>
            <li>
              86–100 %: sehr gutes Ergebnis, voll befriedigende Kompetenzen in
              allen Testkategorien, entspricht insgesamt einem sehr hohen
              Leistungsniveau
            </li>
            <li>
              71–85 %: gutes Ergebnis, in allen Testkategorien mindestens
              erfüllte Grundanforderungen, entspricht insgesamt einem hohen
              Leistungsniveau
            </li>
            <li>
              61–70 %: zufriedenstellendes Ergebnis, in den wichtigsten
              Testkategorien erfüllte Grundanforderungen, entspricht insgesamt
              einem überdurchschnittlichen Leistungsniveau
            </li>
            <li>
              50–60 %: akzeptables Ergebnis, weitgehend erfüllte
              Grundanforderungen, entspricht insgesamt einem ausreichenden
              Leistungsniveau
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "concentrationPercent",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label with-tooltip">
            Konzentrations-
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;test
          </Box>
        </Box>
      ),
      rawLabel: "Konzentrationstest",
      tooltip: (
        <>
          Bei Prüfungen mit mehreren Teilgebieten wird der Konzentrationstest
          gesondert ausgewertet. Die hier erreichten Punktzahlen fließen nicht
          in das Gesamtergebnis ein. Vergleichen Sie die Teilnehmerleistungen
          direkt miteinander.
        </>
      ),
    },
    {
      id: "comment",
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label">Bemerkungen</Box>
        </Box>
      ),
      rawLabel: "Bemerkungen",
      sortable: false,
    },
  ];

  const mapCandidates = (c: CandidateT): FilteredCandidateT => {
    const logs = c.logs
      ? c.logs.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
      : undefined;

    const profession = professionList.find((p) => p.id === c.professionId);

    const resultPercent =
      c.results && c.results.sumPoints > 0
        ? ((c.results.sumPointsCorrect / c.results.sumPoints) * 100).toFixed(0)
        : undefined;

    let hasTarget = Boolean(
      c.results?.professionTarget &&
        c.results?.professionTarget < parseFloat(resultPercent || "0")
    );
    if (hasTarget) {
      c.results?.mainCategories.forEach((m) => {
        if (m.concentration) return;
        const percent =
          m.sumPoints > 0 ? (m.sumPointsCorrect / m.sumPoints) * 100 : 0;

        if (m.mainCategoryTarget > percent) {
          hasTarget = false;
        }
      });
    }

    return {
      data: c,
      id: c.id,
      firstname: c.firstname,
      lastname: c.lastname,
      dateBirth: c.dateBirth,

      professionId: c.professionId,
      createdAt: c.createdAt,
      testedAt:
        c.results &&
        c.results.mainCategories &&
        c.results.mainCategories.length > 0 &&
        logs &&
        logs.length > 0
          ? logs[0].createdAt
          : undefined,
      resultPercent: resultPercent,
      comment: c.comment,

      concentrationPercent:
        c.results && c.results.concentrationPoints > 0
          ? (
              (c.results.concentrationPointsCorrect /
                c.results.concentrationPoints) *
              100
            ).toFixed(0)
          : undefined,
      resultsMain: c.results
        ? c.results.mainCategories.map((m) => ({
            name: m.mainCategoryName,
            percent: (m.sumPoints > 0
              ? (m.sumPointsCorrect / m.sumPoints) * 100
              : 0
            ).toFixed(0),
          }))
        : undefined,
      resultSumPercent: c.results
        ? c.results.sumPoints > 0
          ? (c.results.sumPointsCorrect / c.results.sumPoints) * 100
          : 0
        : -1,
      targetValue: profession?.targetValue || 0,
      hasTarget: hasTarget,
      hasConcentration:
        c.results && c.results.mainCategories.find((m) => m.concentration)
          ? true
          : false,
      hasOnlyConcentration:
        c.results &&
        c.results.mainCategories.find(
          (m) => !("concentration" in m && m.concentration)
        )
          ? false
          : true,
    };
  };

  let filteredCandidateList: FilteredCandidateT[] = candidateList
    .filter(
      (c) =>
        ((c.firstname || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          (c.lastname || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          (c.email || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0) &&
        c.professionId === selectedProfession &&
        c.results
    )
    .map(mapCandidates)
    .sort(sortFunc);

  const hasConcentration = filteredCandidateList.find(
    (c) => c.hasConcentration
  );

  /** all candidates have same profession, hence we can use results of first to get maincategory-names */
  const mainCategoryNames =
    filteredCandidateList && filteredCandidateList.length > 0
      ? filteredCandidateList[0].resultsMain?.map((m) => m.name)
      : [];

  mainCategoryNames?.forEach((name: string) => {
    if (name.match(/Konzentration/)) return;
    let label = splitLongLabel(name);
    const l = (
      <>
        {typeof label === "string" ? (
          <>
            {label}
            <br />
            &nbsp;
          </>
        ) : (
          <>
            {label[0]} &nbsp;&nbsp;&nbsp;&nbsp;{label[1]}
          </>
        )}
      </>
    );
    headCells.push({
      id: name as NonOptionalKeys<FilteredCandidateT>,
      label: (
        <Box className="rotated-label-container">
          <Box className="rotated-label">{l}</Box>
        </Box>
      ),
      rawLabel: name,
    });
  });

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            minWidth: 750,
            // "& .MuiTableRow-root:hover": {
            //   backgroundColor: "green",
            // },
          }}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          className="enhanced-table enhanced-table-rotate"
        >
          <EnhancedTableHead
            headCells={headCells
              .filter(
                (c) => selectedProfession === 0 || c.id !== "professionId"
              )
              .filter(
                (c) => hasConcentration || c.id !== "concentrationPercent"
              )}
            rowCount={filteredCandidateList.length}
            rotate={true}
            orderDir={orderDir}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(
                event,
                property,
                orderDir,
                setOrderDir,
                orderBy,
                setOrderBy
              )
            }
          />
          <TableBody className="enhanced-table-body">
            {stableSort<FilteredCandidateT>(
              filteredCandidateList.map((c) => {
                const r: any = { ...c };
                mainCategoryNames?.forEach((name: string) => {
                  if (name) {
                    const k = name as string as keyof FilteredCandidateT;
                    r[k] =
                      c.resultsMain?.find((re) => re.name === name)?.percent ||
                      0;
                  }
                });
                return r;
              }),
              comparator
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((candidate) => {
                if (
                  selectedProfession !== 0 &&
                  candidate.professionId !== selectedProfession
                )
                  return <></>;
                return (
                  <CandidateResultEntry
                    candidate={candidate}
                    key={candidate.id}
                    hasConcentration={hasConcentration ? true : false}
                    mainCategoryNames={mainCategoryNames}
                    setShowSave={setShowSave}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCandidateList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
        labelRowsPerPage="Einträge anzeigen:"
      />
    </>
  );
};

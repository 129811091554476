import { QuestionSwitch } from "./QuestionSwitch";
import {
  LSKEYS,
  TestModusContext,
  removeTestModusLocalStore,
  removeTestModusLocalStoreLSKEYS,
} from "./TestModusContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { addResult, saveEmergencyResult } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ErrorContext } from "../error/ErrorContext";
import { DIALOGOPTIONS, TestModusDialog } from "./TestModusDialog";
import { useTimer } from "./useTimer";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { TestModusHeader } from "./TestModusHeader";
import { Box, styled } from "@mui/material";
import { AuthContext } from "../auth/AuthContext";
import { CustomerLogo } from "../dashboard/candidate/CustomerLogo";
import { LayoutContext } from "./LayoutContext";
import { TestModusErrorModal } from "./TestModusErrorModal";
import { ButtonLeftSvg } from "./ButtonLeftSvg";
import { ButtonRightSvg } from "./ButtonRightSvg";

const StyledImg = styled("img")({
  height: "60px",
  position: "absolute",
  bottom: 0,
});

export const QuestionContainer = () => {
  const {
    questionList,
    setQuestionList,
    questionNo,
    setQuestionNo,
    setActSubCategory,
    subCategoryInfo,
    finished,
    setFinished,
    candidateSubCategoryTime,
    setCandidateSubCategoryTime,
    initialTimes,
    professionId,
    setInitialTime,
  } = useContext(TestModusContext);
  const { openSnackbar, showProgress, hideProgress } =
    useContext(FeedbackContext);
  const { setError } = useContext(ErrorContext);
  const { authState } = useContext(AuthContext);
  const { layout } = useContext(LayoutContext);

  const [errorSave, setErrorSave] = useState({
    error: false,
    attempt: 0,
  });

  const q = questionList[questionNo];

  const [dialogOpen, setDialogOpen] = useState(DIALOGOPTIONS.CLOSED);
  const [confirmOpen, setConfirmOpen] = useState(false);

  /** if there was a memory-timer on the sub-cat-intro, it must be removed from LS now */
  useEffect(() => {
    localStorage.removeItem(LSKEYS.TIME_MEM);
  }, []);

  useEffect(() => {
    if (dialogOpen === DIALOGOPTIONS.TIME)
      localStorage.setItem(LSKEYS.COUNTDOWN_TIME, "1");
  }, [dialogOpen]);

  useEffect(() => {
    /* mobile */
    const candidate = document.getElementsByClassName("candidate")[0];
    if (candidate && typeof candidate.scrollTo === "function")
      candidate.scrollTo(0, 0);
    /* desktop */
    const main = document.getElementById("main");
    if (main && typeof main.scrollTo === "function") main.scrollTo(0, 0);
  }, [questionNo]);

  /**
   *   _     _
   *  | |   (_)
   *  | |_   _   _ __ ___     ___
   *  | __| | | | '_ ` _ \   / _ \
   *  | |_  | | | | | | | | |  __/
   *   \__| |_| |_| |_| |_|  \___|
   *
   */
  const {
    time: timeAct,
    clearTimer: clearTimerAct,
    startTimer: startTimerAct,
  } = useTimer(initialTimes[LSKEYS.TIME_ACT], {
    name: LSKEYS.TIME_ACT,
  });

  /**
   * S U B C A T - T I M E
   *
   * start timer for act sub-category
   */
  const timerActStarted = useRef(false);
  useEffect(() => {
    if (timerActStarted.current || q.example) return;
    if (
      initialTimes[LSKEYS.TIME_ACT] > subCategoryInfo[q.subCategoryId].duration
    ) {
      setInitialTime(LSKEYS.TIME_ACT, 0);
      timerActStarted.current = true;
      return;
    }
    startTimerAct();
    timerActStarted.current = true;
  }, [startTimerAct, q.example]);

  /**
   * D I A L O G - T I M E
   * always 5 seconds
   */
  const {
    time: timeDialog,
    startTimer: startTimerDialog,
    clearTimer: clearTimerDialog,
  } = useTimer(0, {
    endTime: 5,
    endFunc: () => {
      clearTimerDialog();
      setDialogOpen(DIALOGOPTIONS.CLOSED);
      handleYesTime();
      localStorage.removeItem(LSKEYS.COUNTDOWN_TIME);
    },
    name: "timeDialog",
  });

  /** time for subcat is over */
  useEffect(() => {
    if (timeAct >= subCategoryInfo[q.subCategoryId].duration) {
      // we save time need for this subcat
      setCandidateSubCategoryTime((p) => ({
        ...p,
        [q.subCategoryId]: timeAct,
      }));
      /* we show dialog "subcat time is over" with 5 seconds countdown
        this will handle continuance */
      setDialogOpen(DIALOGOPTIONS.TIME);
      startTimerDialog();
      // remove timer of subcat
      clearTimerAct();
    }
  }, [
    timeAct,
    setDialogOpen,
    q.subCategoryId,
    subCategoryInfo,
    clearTimerAct,
    startTimerDialog,
    setCandidateSubCategoryTime,
  ]);

  /**
   *   ___    __ _  __   __   ___
   *  / __|  / _` | \ \ / /  / _ \
   *  \__ \ | (_| |  \ V /  |  __/
   *  |___/  \__,_|   \_/    \___|
   */

  /** ********************************************
   *
   */
  const saveAnswers = useCallback(() => {
    console.log(
      "%cQuestionContainer.tsx line:178 saveAnswers",
      "color: #007acc;"
    );
    clearTimerAct();
    showProgress();
    if (authState.user.demo) {
      hideProgress();
      setFinished(true);
      removeTestModusLocalStore();
      return;
    }

    addResult(
      questionList,
      {
        ...candidateSubCategoryTime, // put it like this to avoid timing issues
        [q.subCategoryId]: timeAct,
      },
      professionId
    )
      .then((result) => {
        console.log(
          "%cQuestionContainer.tsx line:196 result",
          "color: #007acc;",
          result
        );
        if (result.success) {
          if (
            authState.user.role === "customer" ||
            authState.user.role === "admin"
          )
            removeTestModusLocalStoreLSKEYS();
          else removeTestModusLocalStore();
          openSnackbar("success", "Eintrag gespeichert");
          setFinished(true);
        } else {
          if (result.status === 406) {
            localStorage.setItem(LSKEYS.FINISHED, "1");
            setFinished(true);
            removeTestModusLocalStore();
            window.location.href = "/candidate";
          }
          setError(result.error);
        }
      })
      .catch((error) => {
        console.log(
          "%cQuestionContainer.tsx line:203 error",
          "color: #007acc;",
          error
        );
        showProgress();
        saveEmergencyResult({
          questionList,
          candidateSubCategoryTime,
          professionId,
        });
        setErrorSave({ error: true, attempt: 0 });
        // try repeatedly with 5 seconds delay
        const intervalId = setInterval(() => {
          console.log("########## try", errorSave.attempt, finished);
          if (finished) return;
          setErrorSave((p) => ({ ...p, attempt: p.attempt + 1 }));

          showProgress();
          addResult(
            questionList,
            {
              ...candidateSubCategoryTime, // put it like this to avoid timing issues
              [q.subCategoryId]: timeAct,
            },
            professionId
          )
            .then((result) => {
              console.log(
                "%cQuestionContainer.tsx line:226 result",
                "color: #007acc;",
                result
              );
              if (result.success) {
                removeTestModusLocalStore();
                openSnackbar("success", "Eintrag gespeichert");
                setFinished(true);
                setErrorSave({ error: false, attempt: 0 });
                clearInterval(intervalId);
              } else setError(result.error);
            })
            .catch((error) => {
              console.log(
                "%cQuestionContainer.tsx line:235 catch error",
                "color: #007acc;",
                error
              );
            });
        }, 5000);

        // setError(error);
      });
  }, [
    questionList,
    openSnackbar,
    setFinished,
    setError,
    showProgress,
    hideProgress,
    clearTimerAct,
    candidateSubCategoryTime,
    q.subCategoryId,
    timeAct,
    professionId,
  ]);

  const handleYesLast = useCallback(() => {
    setCandidateSubCategoryTime((p) => ({
      ...p,
      [q.subCategoryId]: timeAct,
      total: p.total + timeAct,
    }));
    if (questionNo < questionList.length - 1) {
      setQuestionNo((p) => p + 1);
      if (questionList[questionNo + 1].subCategoryId !== q.subCategoryId)
        clearTimerAct();
    } else saveAnswers();
    setDialogOpen(DIALOGOPTIONS.CLOSED);
  }, [
    setQuestionNo,
    questionNo,
    questionList,
    saveAnswers,
    setCandidateSubCategoryTime,
    timeAct,
    q.subCategoryId,
    clearTimerAct,
  ]);

  /** dialog with "time for subcat is over"
   * either 5 seconds countdown is over, or user clicks "weiter"
   */
  const handleYesTime = useCallback(() => {
    /* last question? */
    console.log(
      "%cQuestionContainer.tsx line:280 questionNo, questionList.length - 1",
      "color: red;",
      questionNo,
      questionList.length - 1
    );
    if (questionNo === questionList.length - 1) return saveAnswers();

    /** however, might be, that we indeed are in the last subcat, but not on the last questions
     * we have to check if there is a next question with another subcat
     */
    const questionNoBefore = questionNo;
    let newQuestionNo = 0;
    for (let i = questionNo + 1; i < questionList.length; i++) {
      if (questionList[i].subCategoryId !== q.subCategoryId) {
        console.log(
          "%cQuestionContainer.tsx line:293 found new question: i",
          "color: red;",
          i
        );
        setQuestionNo(i);
        newQuestionNo = i;
        break;
      }
    }
    clearTimerDialog();
    setDialogOpen(DIALOGOPTIONS.CLOSED);

    console.log(
      "%cQuestionContainer.tsx line:308 newQuestionNo, questionList.length - 1",
      "color: red;",
      newQuestionNo,
      questionList.length - 1,
      questionNoBefore
    );

    if (
      newQuestionNo < questionList.length &&
      questionNoBefore !== newQuestionNo &&
      newQuestionNo > 0
    ) {
      console.log(
        "%cQuestionContainer.tsx line:324 clearTimerAct",
        "color: #007acc;"
      );
      clearTimerAct();
    } else {
      console.log("%cQuestionContainer.tsx line:328 saveA", "color: #007acc;");
      saveAnswers();
    }
  }, [
    setQuestionNo,
    q.subCategoryId,
    questionList,
    questionNo,
    clearTimerAct,
    clearTimerDialog,
    saveAnswers,
  ]);

  const nextQuestion = () => {
    if (q.noInSubCategory === subCategoryInfo[q.subCategoryId].count) {
      setDialogOpen(DIALOGOPTIONS.LAST);
    } else {
      setQuestionNo((p) => p + 1);
      setActSubCategory(questionList[questionNo + 1].subCategoryId);
    }
  };

  const handleNo = useCallback(() => {
    setDialogOpen(DIALOGOPTIONS.CLOSED);
  }, []);

  const noInSubCategory =
    (q.evaluation === "single" || q.type === "klickmatrix") && q.countTotal
      ? q.noInSubCategory - q.countTotal + 1
      : q.noInSubCategory;

  const handleKeyDown = (e: any) => {
    const leftArrowCode = 37;
    const rightArrowCode = 39;
    if (e.which === rightArrowCode) {
      e.preventDefault();
      if (questionList.length > questionNo + 1) nextQuestion();
    }
    if (e.which === leftArrowCode) {
      e.preventDefault();
      setQuestionNo((p) => {
        return questionNo > 0 && noInSubCategory > 1 ? p - 1 : p;
      });
    }
  };

  useEffect(() => {
    window.document.addEventListener("keydown", handleKeyDown);

    return () => {
      window.document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <TestModusDialog
        open={dialogOpen === DIALOGOPTIONS.LAST}
        handleYes={handleYesLast}
        handleNo={handleNo}
        type="last"
      />

      <TestModusDialog
        open={dialogOpen === DIALOGOPTIONS.TIME}
        handleYes={handleYesTime}
        type="time"
        timeClose={5 - timeDialog}
      />

      <CustomerLogo className="left" />

      <Box component="main" id="main" data-testid="main">
        <TestModusHeader
          question={q}
          timeTotal={candidateSubCategoryTime.total + timeAct}
          timeAct={timeAct}
          subCategoryInfo={subCategoryInfo}
        />

        <QuestionSwitch question={q} setQuestionList={setQuestionList} />
      </Box>

      <Box
        component="footer"
        className="pager"
        data-testid={"question-container" + questionNo}
      >
        {layout && layout.logo && (
          <StyledImg src="/img/logo_trans.png" className="degeba-logo-trans" />
        )}
        <Box className="pager-inner">
          <Box
            className="btn left"
            onClick={() => {
              setQuestionNo((p) => p - 1);
            }}
            data-testid="button-last-question"
            sx={{
              visibility:
                questionNo > 0 && noInSubCategory > 1 ? "visible" : "hidden",
              ...(layout && layout.primaryColor && layout.secondaryColor
                ? {
                    backgroundColor: layout.primaryColor + " !important",
                    fill: layout.secondaryColor + " !important",
                  }
                : {
                    fill: "#fff",
                  }),
            }}
          >
            <ButtonLeftSvg />
          </Box>
          <Box
            className="btn right"
            onClick={nextQuestion}
            data-testid="button-next-question"
            sx={{
              visibility:
                questionList.length > questionNo + 1 ? "visible" : "hidden",

              ...(layout && layout.primaryColor && layout.secondaryColor
                ? {
                    backgroundColor: layout.primaryColor + " !important",
                    fill: layout.secondaryColor + " !important",
                  }
                : {
                    fill: "#fff",
                  }),
            }}
          >
            <ButtonRightSvg />
          </Box>

          {questionList.length === questionNo + 1 && (
            <>
              {" "}
              <ConfirmDialog
                open={confirmOpen}
                handleYes={() => {
                  saveAnswers();
                  setConfirmOpen(false);
                }}
                handleNo={() => setConfirmOpen(false)}
                title="Speichern &amp; beenden"
                content="Möchten Sie den Test beenden und die Ergebnisse speichern?"
              />
              <input
                type="button"
                className="save-test"
                onClick={() => setConfirmOpen(true)}
                value="Speichern &amp; Beenden"
              />
            </>
          )}
        </Box>
      </Box>
      <TestModusErrorModal open={errorSave.error} attempt={errorSave.attempt} />
    </>
  );
};

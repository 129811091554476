import { addUser, UserT } from "../user/api";
import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { AdminForm } from "./AdminForm";

import { Dialog } from "../shared/Dialog";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { UserContext } from "../user/UserContext";
import { addStateToArray } from "../shared/helper/state";
import { AddButton } from "../shared/forms/AddButton";

export const AddAdmin = () => {
  const { setUserList, setAllUserEmails } = useContext(UserContext);
  const { setError } = useContext(ErrorContext);

  const [open, setOpen] = useState(false);
  const { openSnackbar } = useContext(FeedbackContext);

  const onClick = (data: UserT) => {
    if (!data.email) return;
    data.role = "admin";
    addUser(data)
      .then((res) => {
        if (res.success) {
          setUserList((p) => addStateToArray<UserT>(p, res.data as UserT));
          setAllUserEmails((p) =>
            p.concat([{ email: res.data?.email || "", id: res.data?.id || 0 }])
          );
          openSnackbar("success", "Eintrag gespeichert");
          setOpen(false);
        } else {
          if (res.errors) {
            if (
              "email" in res.errors &&
              (res.errors as any).email[0].indexOf("already") > 0
            ) {
              setError("Diese E-Mail ist bereits vorhanden!");
            } else setError(res.error);
          }
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <AddButton onClick={() => setOpen(true)} title="Admin" />

      <Dialog open={open} setOpen={setOpen} title="Neuer Admin">
        <AdminForm onClick={onClick} button="Speichern" />
      </Dialog>
    </>
  );
};

import { useEffect, useContext, useRef } from "react";
import { CallApiResultT } from "../lib/api";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { UsePropsI } from "../types/hooks";

interface UseLoadDataPropsI extends UsePropsI {
  list: any[] | any;
  setList: React.Dispatch<React.SetStateAction<any[] | any>>;
  setLoadingDone: React.Dispatch<React.SetStateAction<boolean>>;
  fetchFunc: (props?: any) => Promise<CallApiResultT<any[] | any>>;
  omitLoadIfDefined?: boolean;
  name?: string;
}

export const useLoadData = ({
  fetchFunc,
  list,
  setList,
  setLoadingDone,
  dontShowProgress = false,
  dontHideProgress = false,
  omitLoadIfDefined = false,
  name = "",
}: UseLoadDataPropsI): void => {
  const { setError } = useContext(ErrorContext);
  const { showProgress, hideProgress } = useContext(FeedbackContext);

  const firstCallDone = useRef(false);

  useEffect(() => {
    if (firstCallDone.current) return;
    if (omitLoadIfDefined && list.length > 0) return setLoadingDone(true);
    firstCallDone.current = true;
    if (!dontShowProgress) showProgress();

    fetchFunc()
      .then((result: any) => {
        if (result.success) {
          setList(result.data);
          setLoadingDone(true);
        } else setError(result.error);
        if (!dontHideProgress) hideProgress();
      })
      .catch((error) => {
        setError(error);
      });
  }, [
    fetchFunc,
    list,
    setList,
    setError,
    showProgress,
    hideProgress,
    dontHideProgress,
    setLoadingDone,
    omitLoadIfDefined,
    dontShowProgress,
    name,
  ]);
};

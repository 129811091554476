import React, { createContext, useState } from "react";
import { ContextPropsT } from "../shared/types/Context";
import { StatsQuestionT, StatsTransactionT } from "./api";

interface StatsContextPropsType extends ContextPropsT {
  statsTransaction: StatsTransactionT;
  setStatsTransaction: React.Dispatch<React.SetStateAction<StatsTransactionT>>;
  statsQuestion: StatsQuestionT[];
  setStatsQuestion: React.Dispatch<React.SetStateAction<StatsQuestionT[]>>;
}

export interface StatsChangedT extends StatsTransactionT {
  error?: boolean;
}

export const StatsContext = createContext<StatsContextPropsType>({
  statsTransaction: {},
  setStatsTransaction: () => {},
  statsQuestion: [],
  setStatsQuestion: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
});
export const StatsProvider = ({ children }: { children: React.ReactNode }) => {
  const [statsTransaction, setStatsTransaction] = useState<StatsTransactionT>(
    {}
  );
  const [statsQuestion, setStatsQuestion] = useState<StatsQuestionT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <StatsContext.Provider
      value={{
        statsTransaction,
        setStatsTransaction,
        statsQuestion,
        setStatsQuestion,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </StatsContext.Provider>
  );
};

import React, { Fragment, Key } from "react";
import { styled } from "@mui/system";
import { IMGPATH } from "../../../testModus/api";
import { QuestionResultsT } from "../../api";
import { ClickMatrixMappingT } from "../../../testModus/questionTypes/QuestionClickMatrix";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ResultIcon } from "../ResultIcon";
import { Loader } from "../../../shared/Loader";

const StyledImg = styled("img")({});

interface AnswerClickMatrixPropsI {
  question: QuestionResultsT;
}

enum CMVALS {
  EMPTY = 0,
  EMPTY_WRONG = 1,
  CLICKED_WRONG = 2,
  CORRECT = 3,
}

export const ResultClickMatrix = React.memo(
  ({ question }: AnswerClickMatrixPropsI) => {
    const clickMatrix: CMVALS[][] = [];

    console.log("ResultClickMatrix question:", question);

    const c = (question.candidateAnswerDecoded as ClickMatrixMappingT) || {};
    const a = question.answerDecoded || {};

    if (question && question.answerOptionsDecoded) {
      (question.answerOptionsDecoded.content as any[]).forEach(
        (obj: any, rowIdx: number) =>
          Object.entries(obj).map(
            ([rowIdent, option]: [rowIdent: string | number, option: any]) => {
              clickMatrix[rowIdx] = [];
              Object.keys(option).forEach((col: any) => {
                clickMatrix[rowIdx][col] = CMVALS.EMPTY;
                if (c[rowIdx]) {
                  if (
                    c[rowIdx].some(
                      (e) => parseInt(e.toString()) === parseInt(col)
                    )
                  ) {
                    clickMatrix[rowIdx][col] = CMVALS.CLICKED_WRONG;
                  }
                }
                if (a[rowIdx]) {
                  if (
                    (a[rowIdx] as number[]).some(
                      (e) => parseInt(e.toString()) === parseInt(col)
                    )
                  ) {
                    clickMatrix[rowIdx][col] =
                      clickMatrix[rowIdx][col] === CMVALS.EMPTY
                        ? CMVALS.EMPTY_WRONG
                        : CMVALS.CORRECT;
                  }
                }
              });
            }
          )
      );
    }

    if (!question) return <Loader />;

    return (
      <TableContainer component={Paper}>
        <Table>
          {question &&
            question.answerOptionsDecoded &&
            question.answerOptionsDecoded.titlerow && (
              <TableHead>
                <TableRow>
                  {question.answerOptionsDecoded.titlerow.map((c: any) => (
                    <TableCell key={c} sx={{ background: "lightgray" }}>
                      {c}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
          <TableBody
            data-testid="questionclickmatrix"
            sx={
              {
                //outline: "5px solid blue",
              }
            }
          >
            {question &&
              question.answerOptionsDecoded &&
              question.answerOptionsDecoded.content.map(
                (obj: any, rowIdx: number) =>
                  Object.entries(obj).map(
                    ([rowIdent, option]: [
                      rowIdent: string | number,
                      option: any
                    ]) => {
                      return (
                        <TableRow key={"row" + rowIdx}>
                          <Fragment>
                            {question.answerOptionsDecoded.titlecolumn && (
                              <TableCell sx={{ background: "lightgray" }}>
                                {rowIdent}
                              </TableCell>
                            )}
                            {option.map(
                              (
                                o:
                                  | string
                                  | { w: number; h: number; img: string },
                                col: number
                              ) => {
                                return (
                                  <ClickMatrixCell
                                    key={"cell" + rowIdx + "|" + col}
                                    clicked={
                                      clickMatrix[col] &&
                                      clickMatrix[rowIdx][col]
                                    }
                                    w={typeof o === "string" ? 0 : o.w}
                                    h={typeof o === "string" ? 0 : o.h}
                                    img={typeof o === "string" ? "" : o.img}
                                    row={rowIdx}
                                    col={col}
                                    content={typeof o === "string" ? o : ""}
                                  />
                                );
                              }
                            )}
                            <TableCell
                              key={"result" + rowIdx}
                              sx={{
                                width: 30,
                                display: "inline-block",
                                textAlign: "center",
                              }}
                              component="span"
                            >
                              {question.correctDecoded[rowIdx] ? (
                                <ResultIcon correct />
                              ) : (
                                <ResultIcon incorrect />
                              )}
                            </TableCell>
                          </Fragment>
                        </TableRow>
                      );
                    }
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

const ClickMatrixCell = React.memo(
  ({
    clicked,
    content,
    img,
    w,
    h,
    row,
    col,
  }: {
    clicked: CMVALS;
    content: string;
    img: string;
    w: any;
    h: any;
    row: number;
    col: number;
  }) => {
    if (img)
      return (
        <TableCell>
          <StyledImg
            key={"img" + row + "|" + col}
            src={IMGPATH + img}
            alt={img}
            width={w ? w : 25}
            height={h ? h : 25}
            sx={{
              //outline: clicked === CMVALS.EMPTY_WRONG ? "5px solid red" : "",
              background:
                clicked === CMVALS.CORRECT
                  ? "lightgreen"
                  : clicked === CMVALS.CLICKED_WRONG
                  ? "red"
                  : clicked === CMVALS.EMPTY_WRONG
                  ? "pink"
                  : "",
            }}
          ></StyledImg>
        </TableCell>
      );

    return (
      <TableCell>
        <Box
          key={"cell" + row + "|" + col}
          sx={{
            width: 30,
            display: "inline-block",
            background:
              clicked === CMVALS.CORRECT
                ? "lightgreen"
                : clicked === CMVALS.CLICKED_WRONG
                ? "red"
                : clicked === CMVALS.EMPTY_WRONG
                ? "pink"
                : "",
            textAlign: "center",
          }}
          component="span"
        >
          {content}
        </Box>
      </TableCell>
    );
  }
);

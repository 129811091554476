import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface MoveButtonPropsI {
  direction: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const MoveButton = ({
  direction,
  onClick,
  disabled = false,
}: MoveButtonPropsI) => {
  if (direction === "up")
    if (disabled) return <FileUploadIcon color="disabled" />;
    else return <FileUploadIcon onClick={onClick} />;
  else if (disabled) return <FileDownloadIcon color="disabled" />;
  else return <FileDownloadIcon onClick={onClick} />;
};

import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import addrs from "email-addresses";
import { isEmpty } from "lodash";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { EMPTYUSER, UserContext } from "../user/UserContext";
import { UserT } from "../user/api";
import GenderSelect from "../shared/forms/GenderSelect";
import { Box, Grid, InputLabel } from "@mui/material";
import { CountrySelect } from "../shared/forms/CountrySelect";

interface UserFormPropsI {
  user?: UserT;
  onClick: (data: UserT) => void;
  button: string;
}

const fields2check = ["email", "firstname", "lastname"];

export const CustomerForm = (props: UserFormPropsI) => {
  const { allUserEmails } = useContext(UserContext);
  const { showProgress } = useContext(FeedbackContext);
  const [userState, setUserState] = useState(
    props.user ? props.user : EMPTYUSER
  );
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f) => {
      const error = userState[f as keyof UserT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });

    if (!isEmpty(userState.email)) {
      const error =
        userState.email.indexOf(".") > 0 && // apparently foo@bar is a valid email-adress
        addrs.parseOneAddress(userState.email)
          ? false
          : true;

      setErrorState((p) => buildErrorState(p, "email", error));
      oneError = oneError || error;
    }
    if (oneError) return;
    if (userState.emailInvoice && !isEmpty(userState.emailInvoice)) {
      const error =
        userState.emailInvoice.indexOf(".") > 0 && // apparently foo@bar is a valid email-adress
        addrs.parseOneAddress(userState.emailInvoice)
          ? false
          : true;

      setErrorState((p) => buildErrorState(p, "emailInvoice", error));
      oneError = oneError || error;
    }
    if (oneError) return;

    if (!isEmpty(userState.email)) {
      if (!props.user || props.user.email !== userState.email) {
        const error = allUserEmails.some((u) => u.email === userState.email);

        setErrorState((p) =>
          buildErrorState(
            p,
            "email",
            error,
            "Diese E-Mail ist bereits vorhanden!"
          )
        );
        oneError = oneError || error;
      }
    }
    if (oneError) return;

    if (!props.user) showProgress();
    props.onClick(userState);
  };

  return (
    <Box sx={{ maxWidth: 620 }}>
      <StyledFormControl required={false} sx={{ width: 600 }}>
        <InputLabel htmlFor="genderselect">Anrede</InputLabel>
        <GenderSelect
          id="gender"
          value={userState.gender}
          setValue={(v: string) =>
            setUserState((prevState) => {
              return { ...prevState, gender: v };
            })
          }
          label="Anrede"
          labelId="genderselect"
        />
      </StyledFormControl>
      <StyledFormControl required={true} sx={{ width: 600 }}>
        <TextField
          id="firstname"
          label="Vorname"
          value={userState.firstname}
          error={errorState.firstname?.error}
          helperText={
            errorState.firstname?.error
              ? errorState.firstname?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, firstname: e.target.value };
            })
          }
        />
      </StyledFormControl>

      <StyledFormControl sx={{ width: 600 }}>
        <TextField
          id="lastname"
          label="Nachname"
          value={userState.lastname}
          error={errorState.lastname?.error}
          helperText={
            errorState.lastname?.error
              ? errorState.lastname?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, lastname: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <TextField
          id="email"
          label="E-Mail"
          type="email"
          error={errorState.email?.error}
          helperText={
            errorState.email?.error
              ? errorState.email?.helperText || "Ungültige E-Mail"
              : ""
          }
          value={userState.email}
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, email: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <TextField
          id="email"
          label="Abweichende Rechnungs-E-Mail"
          type="email"
          error={errorState.emailInvoice?.error}
          helperText={
            errorState.emailInvoice?.error
              ? errorState.emailInvoice?.helperText || "Ungültige E-Mail"
              : ""
          }
          value={userState.emailInvoice}
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, emailInvoice: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <TextField
          id="company"
          label="Firma"
          type="text"
          value={userState.company}
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, company: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              id="street"
              label="Strasse"
              type="text"
              value={userState.street}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, street: e.target.value };
                })
              }
              sx={{ width: 500 }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="streetno"
              label="Nr."
              type="text"
              value={userState.streetno}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, streetno: e.target.value };
                })
              }
              sx={{ width: 90 }}
            />
          </Grid>
        </Grid>
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              id="zipcode"
              label="PLZ"
              type="text"
              value={userState.zipcode}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, zipcode: e.target.value };
                })
              }
              sx={{ width: 190 }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="city"
              label="Ort"
              type="text"
              value={userState.city}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, city: e.target.value };
                })
              }
              sx={{ width: 400 }}
            />
          </Grid>
        </Grid>
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <CountrySelect
          id="country"
          value={userState.countryId?.toString() || "0"}
          setValue={(v: string) =>
            setUserState((prevState) => {
              return { ...prevState, countryId: parseInt(v) };
            })
          }
          sx={{ width: 600, height: 50 }}
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <TextField
          id="phone"
          label="Telefon"
          type="text"
          value={userState.phone}
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, phone: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <TextField
          id="fax"
          label="Fax"
          type="text"
          value={userState.fax}
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, fax: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl sx={{ width: 600 }}>
        <Grid container spacing={2}>
          <Grid item sx={{ ml: 1 }}>
            <input
              type="checkbox"
              id="shippingAddress"
              checked={userState.shippingAddress}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingAddress: e.target.checked };
                })
              }
            />
          </Grid>
          <Grid item>Abweichende Lieferadresse</Grid>
        </Grid>
      </StyledFormControl>

      {userState.shippingAddress && (
        <>
          <StyledFormControl required={false}>
            <InputLabel htmlFor="shippingGenderselect">Anrede</InputLabel>
            <GenderSelect
              id="shippingGender"
              value={userState.shippingGender}
              setValue={(v: string) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingGender: v };
                })
              }
              label="Anrede"
              labelId="shippingGenderselect"
            />
          </StyledFormControl>
          <StyledFormControl required={true}>
            <TextField
              id="shippingFirstname"
              label="Vorname"
              value={userState.shippingFirstname}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingFirstname: e.target.value };
                })
              }
            />
          </StyledFormControl>

          <StyledFormControl sx={{ width: 600 }}>
            <TextField
              id="shippingLastname"
              label="Nachname"
              value={userState.shippingLastname}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingLastname: e.target.value };
                })
              }
            />
          </StyledFormControl>
          <StyledFormControl sx={{ width: 600 }}>
            <TextField
              id="shippingCompany"
              label="Firma"
              type="text"
              value={userState.shippingCompany}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingCompany: e.target.value };
                })
              }
            />
          </StyledFormControl>
          <StyledFormControl sx={{ width: 600 }}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  id="shippingStreet"
                  label="Strasse"
                  type="text"
                  value={userState.shippingStreet}
                  onChange={(e) =>
                    setUserState((prevState) => {
                      return { ...prevState, shippingStreet: e.target.value };
                    })
                  }
                  sx={{ width: 500 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="shippingStreetno"
                  label="Nr."
                  type="text"
                  value={userState.shippingStreetno}
                  onChange={(e) =>
                    setUserState((prevState) => {
                      return { ...prevState, shippingStreetno: e.target.value };
                    })
                  }
                  sx={{ width: 90 }}
                />
              </Grid>
            </Grid>
          </StyledFormControl>
          <StyledFormControl sx={{ width: 600 }}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  id="shippingZipcode"
                  label="PLZ"
                  type="text"
                  value={userState.shippingZipcode}
                  onChange={(e) =>
                    setUserState((prevState) => {
                      return { ...prevState, shippingZipcode: e.target.value };
                    })
                  }
                  sx={{ width: 190 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="shippingCity"
                  label="Ort"
                  type="text"
                  value={userState.shippingCity}
                  onChange={(e) =>
                    setUserState((prevState) => {
                      return { ...prevState, shippingCity: e.target.value };
                    })
                  }
                  sx={{ width: 400 }}
                />
              </Grid>
            </Grid>
          </StyledFormControl>
          <StyledFormControl sx={{ width: 600 }}>
            <CountrySelect
              id="shippingCountry"
              value={userState.shippingCountryId?.toString() || "0"}
              setValue={(v: string) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingCountryId: parseInt(v) };
                })
              }
              sx={{ width: 600, height: 50 }}
            />
          </StyledFormControl>

          <StyledFormControl sx={{ width: 600 }}>
            <TextField
              id="shippingPhone"
              label="Telefon"
              type="text"
              value={userState.shippingPhone}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingPhone: e.target.value };
                })
              }
            />
          </StyledFormControl>
          <StyledFormControl sx={{ width: 600 }}>
            <TextField
              id="shippingFax"
              label="Fax"
              type="text"
              value={userState.shippingFax}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, shippingFax: e.target.value };
                })
              }
            />
          </StyledFormControl>
        </>
      )}

      <Box>
        <StyledFormControl sx={{ width: 600 }}>
          <Button variant="contained" color="primary" onClick={onClick}>
            {props.button}
          </Button>
        </StyledFormControl>
      </Box>
    </Box>
  );
};

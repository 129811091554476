import { useContext } from "react";
import { fetchCategories, SubCategoryT } from "./api";
import { SubCategoryContext } from "./SubCategoryContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface useSubCategoriesResultT extends UseResultT {
  categoryList: SubCategoryT[];
  setCategoryList: React.Dispatch<React.SetStateAction<SubCategoryT[]>>;
}

interface UseSubCategoriesPropsI extends UsePropsI {
  onlyActive?: boolean;
}

export const useSubCategories = ({
  onlyActive = false,
  dontHideProgress = false,
}: UseSubCategoriesPropsI): useSubCategoriesResultT => {
  const { categoryList, setCategoryList, loadingDone, setLoadingDone } =
    useContext(SubCategoryContext);

  useLoadData({
    fetchFunc: () => fetchCategories(onlyActive),
    list: categoryList,
    setList: setCategoryList,
    setLoadingDone,
    dontHideProgress,
  });

  return { categoryList, setCategoryList, loadingDone };
};

import { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import { addCategory, SubCategoryT } from "./api";
import { SubCategoryContext } from "./SubCategoryContext";
import { ErrorContext } from "../error/ErrorContext";
import { SubCategoryForm } from "./SubCategoryForm";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { addStateToArray } from "../shared/helper/state";
import { AddButton } from "../shared/forms/AddButton";

export const AddSubCategory = () => {
  const { setCategoryList } = useContext(SubCategoryContext);
  const { setError } = useContext(ErrorContext);

  const { openSnackbar } = useContext(FeedbackContext);

  const [open, setOpen] = useState(false);

  const onClick = (data: SubCategoryT) => {
    if (!data.name) return;

    addCategory(data)
      .then((result) => {
        if (result.success && result.data) {
          console.log(
            "%cAddSubCategory.tsx line:30 result",
            "color: #007acc;",
            result
          );
          setCategoryList((p) =>
            addStateToArray(p, result.data as SubCategoryT)
          );
          setOpen(false);
          openSnackbar("success", "Eintrag gespeichert");
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };
  const handleClose = (reason: string) => {
    if (reason === "cancel") setOpen(false);
  };

  return (
    <>
      <AddButton title=" Unterkategorie" onClick={() => setOpen(true)} />

      <Dialog
        open={open}
        onClose={(e: object, reason: string) => handleClose(reason)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neue Kategorie</DialogTitle>
        <DialogContent>
          <SubCategoryForm onClick={onClick} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose("cancel")}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CartItemT } from "./api";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { calcPrice, conf } from "./shop";
import { addToCart, removeFromCart } from "./cart";
import { formatAmount } from "../shared/helper/string";
import { DeleteButton } from "../shared/forms/DeleteButton";
import { TableRowRest } from "./ShopOrderEntry";

interface ShopCartPropsI {
  cartItem: CartItemT;
  setCartChanged: React.Dispatch<React.SetStateAction<number>>;
  isCheckout: boolean;
}

export const ShopCartEntry = ({
  cartItem,
  setCartChanged,
  isCheckout,
}: ShopCartPropsI) => {
  console.log(
    "%cShopCartEntry.tsx line:16 article",
    "color: orange;",
    cartItem
  );

  const article = cartItem.article;
  const isPremium =
    cartItem.type === "premium" || cartItem.type === "premium_online";
  const isOnline =
    cartItem.type === "online" || cartItem.type === "premium_online";
  const isBasic = cartItem.type === "basic";

  const [cartItemState, setCartItemState] = useState<CartItemT>(cartItem);

  useEffect(() => {
    console.log(
      "%cShopCartEntry.tsx line:44 useEffect",
      "color: #007acc;",
      cartItemState,
      cartItem,
      cartItemState !== cartItem
    );
    if (cartItemState !== cartItem) {
      cartItemState.price =
        calcPrice(cartItemState.type, cartItemState.numberLicenses || 0) *
          (cartItemState.numberLicenses || 0) +
        calcPrice(cartItemState.type, cartItemState.numberBooks || 0) *
          (cartItemState.numberBooks || 0) +
        conf.basic.solution * (cartItemState.numberSolutions || 0) +
        (cartItemState.branding ? conf.basic.branding : 0);

      addToCart(cartItemState);
    }
  }, [cartItem, cartItemState]);

  let imgRowSpan = isBasic
    ? 5
    : isPremium && cartItem.type !== "premium_online"
    ? 4
    : 3;

  if (isCheckout && cartItemState.numberSolutions === 0) {
    imgRowSpan--;
  }
  if (cartItem.type === "basic" || cartItem.type === "premium") {
    if (isCheckout && cartItemState.numberBooks === 0) {
      imgRowSpan--;
    }

    if (isCheckout && !cartItemState.branding) {
      imgRowSpan--;
    }
  } else {
    if (isCheckout && cartItemState.numberLicenses === 0) {
      imgRowSpan--;
    }
  }

  let name = article?.name;
  if (
    article &&
    cartItem.type === "premium_online" &&
    name &&
    name?.indexOf("Nachbestellung") < 0
  ) {
    name = cartItem.orderName
      ? cartItem.orderName
      : "Online-Premium-Paket: " + article.name;
    name = name.replace("Online-Einstellungstest ", "");
  } else if (
    article &&
    cartItem.type === "premium" &&
    name &&
    name?.indexOf("Nachbestellung") < 0
  ) {
    name = cartItem.orderName
      ? cartItem.orderName
      : "Premium-Paket Papierversion: " + article.name;
    name = name.replace("Einstellungstest ", "");
  }
  name = name.replace("Nachbestellung ", "");

  return (
    <>
      <TableRow hover tabIndex={-1} className={"table-row-details"} sx={{}}>
        <TableCell
          component="td"
          scope="row"
          padding="normal"
          rowSpan={imgRowSpan}
          className="article-thumbnail-cell"
        >
          <Box sx={{ ml: 1 }}>
            <img
              src={
                cartItem.type === "premium"
                  ? article?.imagePathPremium
                  : cartItem.type === "premium_online"
                  ? article?.imagePathPremiumOnline
                  : article?.imagePath
              }
              alt=""
              className="article-thumbnail"
            />
          </Box>
        </TableCell>
        <TableCell
          component="td"
          scope="row"
          padding="normal"
          className="article-name-cell"
        >
          <Box sx={{ width: 500 }}>
            <b>
              <span>{isPremium && "Nachbestellung "}</span>
              {name} | {cartItem.artnr}
            </b>
            {isPremium && (
              <>
                <br />
                {/* Testniveau: {level} */}
              </>
            )}
          </Box>
        </TableCell>
        <TableCell component="td" scope="row" padding="normal"></TableCell>
        <TableCell component="td" scope="row" padding="normal"></TableCell>
        <TableCell component="td" scope="row" padding="normal">
          <Box>
            {!isCheckout && (
              <DeleteButton
                sx={{ color: "text.secondary" }}
                id={cartItem.articleId}
                deleteFunc={(n: number) =>
                  new Promise((resolve) => {
                    removeFromCart(cartItem);
                    setCartChanged((prev) => prev + 1);
                    resolve(n);
                    return n;
                  })
                }
                successFunc={() => {}}
              />
            )}
          </Box>
        </TableCell>
      </TableRow>
      {(!isCheckout ||
        (cartItemState.numberLicenses !== undefined &&
          cartItemState.numberLicenses > 0) ||
        (cartItemState.numberBooks !== undefined &&
          cartItemState.numberBooks > 0)) && (
        <TableRow hover tabIndex={-1} className={"table-row-details"}>
          <TableCell align="left" padding="normal">
            <Box>
              {isOnline || cartItem.type === "premium_online"
                ? "Lizenzen"
                : "Prüfungshefte"}
              :
            </Box>
          </TableCell>

          <TableCell align="left" padding="normal" className="article-quantity">
            <Box>
              {isCheckout ? (
                <>
                  {isOnline
                    ? cartItemState.numberLicenses || 0
                    : cartItemState.numberBooks || 0}
                </>
              ) : (
                <TextField
                  size="small"
                  value={
                    isOnline
                      ? Number(cartItemState.numberLicenses).toString()
                      : Number(cartItemState.numberBooks).toString()
                  }
                  label="Anzahl"
                  onChange={(e) => {
                    if (isOnline) {
                      setCartItemState((p) => ({
                        ...p,
                        numberLicenses:
                          parseInt(e.target.value) >= 0
                            ? parseInt(e.target.value)
                            : undefined,
                      }));
                    } else {
                      setCartItemState((p) => ({
                        ...p,
                        numberBooks:
                          parseInt(e.target.value) >= 0
                            ? parseInt(e.target.value)
                            : undefined,
                      }));
                    }
                    setCartChanged((prev) => prev + 1);
                  }}
                  type="number"
                />
              )}
            </Box>
          </TableCell>
          <TableCell
            align="left"
            padding="normal"
            className="article-price-single"
          >
            <Box>
              {isOnline
                ? formatAmount(
                    calcPrice(cartItem.type, cartItemState.numberLicenses || 0)
                  )
                : formatAmount(
                    calcPrice(cartItem.type, cartItemState.numberBooks || 0)
                  )}
              &nbsp;&euro;
            </Box>
          </TableCell>
          <TableCell
            align="left"
            padding="normal"
            className="article-price-sum"
          >
            <Box>
              {isOnline
                ? formatAmount(
                    calcPrice(
                      cartItem.type,
                      cartItemState.numberLicenses || 0
                    ) *
                      (cartItemState.numberLicenses
                        ? cartItemState.numberLicenses
                        : 0)
                  )
                : formatAmount(
                    calcPrice(cartItem.type, cartItemState.numberBooks || 0) *
                      (cartItemState.numberBooks
                        ? cartItemState.numberBooks
                        : 0)
                  )}
              &nbsp; &euro;
            </Box>
          </TableCell>
        </TableRow>
      )}

      {!isOnline &&
        cartItem.type !== "premium_online" &&
        (!isCheckout ||
          (cartItemState.numberSolutions !== undefined &&
            cartItemState.numberSolutions > 0)) && (
          <TableRow hover tabIndex={-1} className={"table-row-details"}>
            <TableCell align="left" padding="normal">
              <Box>
                {isPremium ? (
                  <>Weitere Lösungsschablonen:</>
                ) : isBasic ? (
                  <>Lösungsschablonen:</>
                ) : (
                  <> </>
                )}
              </Box>
            </TableCell>
            <TableCell
              align="left"
              padding="normal"
              className="article-quantity"
            >
              <Box>
                {isCheckout ? (
                  <>{cartItemState.numberSolutions}</>
                ) : (
                  <TextField
                    size="small"
                    value={Number(cartItemState.numberSolutions).toString()}
                    label="Anzahl"
                    onChange={(e) => {
                      setCartItemState((p) => ({
                        ...p,
                        numberSolutions:
                          parseInt(e.target.value) >= 0
                            ? (e.target.value as unknown as number)
                            : undefined,
                      }));
                      setCartChanged((prev) => prev + 1);
                    }}
                    type="number"
                    sx={{ width: 100 }}
                  />
                )}
              </Box>
            </TableCell>
            <TableCell align="left" padding="normal">
              <Box>{formatAmount(conf.basic.solution)} &euro;</Box>
            </TableCell>
            <TableCell align="left" padding="normal">
              <Box>
                {formatAmount(
                  calcPrice(cartItem.type, cartItemState.numberSolutions || 0) *
                    (cartItemState.numberSolutions
                      ? cartItemState.numberSolutions
                      : 0)
                )}{" "}
                &euro;
              </Box>
            </TableCell>
          </TableRow>
        )}

      {isBasic && (!isCheckout || cartItemState.branding) && (
        <TableRow hover tabIndex={-1} className={"table-row-details"}>
          <TableCell align="left" padding="normal">
            <Box>Branding mit Firmenlogo</Box>
          </TableCell>
          <TableCell
            align="left"
            padding="normal"
            colSpan={2}
            className={
              "article-branding" + (isCheckout ? " checkout" : " edit")
            }
          >
            <Box>
              {isCheckout ? (
                cartItemState.branding ? (
                  "Ja"
                ) : (
                  "Nein"
                )
              ) : (
                <RadioGroup
                  name="branding"
                  value={cartItemState.branding ? "1" : "0"}
                  onChange={(e) => {
                    cartItemState.branding = e.target.value === "1";
                    setCartChanged((prev) => prev + 1);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Ja" />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Nein"
                  />
                </RadioGroup>
              )}
            </Box>
          </TableCell>
          <TableCell align="left" padding="normal">
            <Box>
              {cartItemState.branding ? (
                <>{formatAmount(conf.basic.branding)} &euro;</>
              ) : (
                <></>
              )}
            </Box>
          </TableCell>
        </TableRow>
      )}
      <TableRowRest />
      <></>
    </>
  );
};

import { Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  CandidateResultsT,
  SubResultT,
  QuestionResultsT,
  CandidateT,
} from "../candidate/api";
import { Loader } from "../shared/Loader";
import { ResultsData } from "../candidate/results/ResultsData";
import { useTestResults } from "../profession/useTestResults";
import { AuthContext } from "../auth/AuthContext";
import { useContext } from "react";
import { ProfessionContext } from "../profession/ProfessionContext";

export const getMainIdx = (
  results: CandidateResultsT,
  mainCategoryId: number
) =>
  results.mainCategories.findIndex((r) => r.mainCategoryId === mainCategoryId);

export const getSubIdx = (subs: SubResultT[], subCategoryId: number) =>
  subs.findIndex((r) => r.subCategoryId === subCategoryId);

export const getQuestionIdx = (subs: QuestionResultsT[], questionId: number) =>
  subs.findIndex((r) => r.id === questionId);

interface CustomerResultsLoaderPropsI {
  professionId: number;
}
export const CustomerResultsLoader = ({
  professionId,
}: CustomerResultsLoaderPropsI) => {
  const { loadingDone, testResultList } = useTestResults({
    professionId: professionId,
  });
  const { authState } = useContext(AuthContext);
  const { professionList } = useContext(ProfessionContext);

  const profession =
    professionList.find((p) => p.id === professionId) || professionList[0];

  if (loadingDone) {
    const fakeCandidate: CandidateT = {
      id: 0,
      customerId: 0,
      firstname: authState.user?.firstname || "Martha",
      lastname: authState.user?.lastname || "Mustermensch",
      street: authState.user?.address?.street || "Musterstraße",
      streetno: authState.user?.address?.streetno || "1",
      zipcode: authState.user?.address?.zipcode || "12345",
      city: authState.user?.address?.city || "Musterstadt",
      professionId: professionId,
      professionName: profession.name,
      testId: profession.testId,
      testDate: new Date(),
      results: testResultList,
      email: "",
      createdAt: new Date(),
      uuid: "",
    };

    return <ResultsData candidate={fakeCandidate} />;
  }

  return <Loader />;
};

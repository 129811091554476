import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./auth/AuthContext";
import { getStoredAuthToken } from "./auth/localStore";
import {
  LSKEYS,
  TestModusContext,
  removeTestModusLocalStore,
} from "./testModus/TestModusContext";
import { time_hr } from "./shared/helper/datetime";
import { unUglifyTimestamp } from "./shared/helper/uglify";

export const DebugToolBox = () => {
  const { authState } = useContext(AuthContext);

  const { actMainCategory, actSubCategory } = useContext(TestModusContext);
  const [timeAct, setTimeAct] = useState(0);

  useEffect(() => {
    const i = unUglifyTimestamp(localStorage.getItem(LSKEYS.TIME_ACT) || "0");
    setTimeAct(i);
    if (!i)
      setTimeout(
        () =>
          setTimeAct(
            unUglifyTimestamp(localStorage.getItem(LSKEYS.TIME_ACT) || "0")
          ),
        1000
      );
  }, []);

  if (import.meta.env.VITE_DEBUG_TOOLBOX != "1") return <></>;
  return (
    <Box
      sx={{
        border: "1px solid darkgray",
        background: "lightgray",
        fontSize: 12,
        p: 2,
        position: "fixed",
        bottom: 0,
        right: 0,
        display: "flex",
        gap: 5,
        zIndex: 1000,
      }}
    >
      <TextField
        label="Bearer"
        value={getStoredAuthToken() || "n.n."}
        inputProps={{ style: { fontSize: 12 } }}
        onFocus={(e) => {
          e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
          //document.execCommand("copy");
          //navigator.clipboard.writeText(e.currentTarget.value);
        }}
      />
      <Box>
        <table>
          <tbody>
            <tr>
              <td>UserId</td>
              <td>{authState && authState.user && authState.user.id}</td>
            </tr>
            <tr>
              <td>MainCat</td>
              <td>{actMainCategory}</td>
            </tr>
            <tr>
              <td>SubCat</td>
              <td>{actSubCategory}</td>
            </tr>
            <tr>
              <td>TimeAct</td>
              <td
                onClick={() =>
                  setTimeAct(
                    unUglifyTimestamp(
                      localStorage.getItem(LSKEYS.TIME_ACT) || "0"
                    )
                  )
                }
              >
                {time_hr(new Date(timeAct))}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Button onClick={removeTestModusLocalStore} variant="contained">
        RM LocalStore
      </Button>
    </Box>
  );
};

import { useContext, useEffect } from "react";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { useProfessions } from "../profession/useProfessions";
import { Loader } from "../shared/Loader";
import { StatsTabs } from "./StatsTabs";
import { useStats } from "./useStats";

export const StatsLoader = () => {
  const { hideProgress } = useContext(FeedbackContext);

  const { loadingDone } = useStats({ dontHideProgress: true });
  const { loadingDone: loadingDoneProf } = useProfessions({
    dontHideProgress: true,
    onlyActive: true,
  });

  useEffect(() => {
    if (!loadingDone || !loadingDoneProf) return;
    hideProgress();
  }, [loadingDone, loadingDoneProf, hideProgress]);

  if (!loadingDone || !loadingDoneProf) return <Loader />;

  return <StatsTabs />;
};

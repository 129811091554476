import { useTestModusQuestions } from "../testModus/useTestModusQuestions";

import { TestModusStart } from "../testModus/TestModusStart";

import { useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import {
  removeTestModusLocalStoreLSKEYS,
  TestModusContext,
} from "../testModus/TestModusContext";
import { useContext, useEffect, useState } from "react";
import { useLayout } from "../testModus/useLayout";
import { AuthContext } from "../auth/AuthContext";
import { useLoadData } from "../shared/hooks/useLoadData";
import { fetchLogo } from "../auth/api";
import { Loader } from "../shared/Loader";

export const CustomerTestModusLoader = () => {
  const { setProfessionId } = useContext(TestModusContext);
  const params = useParams();
  const { questionList } = useTestModusQuestions({
    professionId: parseInt(params.professionId || "0"),
  });
  const { authState, setAuthState } = useContext(AuthContext);

  removeTestModusLocalStoreLSKEYS();

  useEffect(() => {
    console.log(
      "%cCustomerTestModusLoader.tsx line:29 set now",
      "color: #007acc;",
      params.professionId
    );
    setProfessionId(parseInt(params.professionId || "0"));
  }, [params.professionId, setProfessionId]);

  useEffect(() => {
    setAuthState((p) => ({
      ...p,
      user: {
        ...p.user,
        professionName: params.professionName,
        professionId: parseInt(params.professionId || "0"),
      },
    }));
  }, [params.professionId, params.professionName]);

  const [logo, setLogo] = useState<string>("");
  const [loadingDone, setLoadingDone] = useState<boolean>(false);
  const [layoutDone, setLayoutDone] = useState<boolean>(false);

  const { setLayout } = useLayout({
    layout: {
      logo: logo || "",
      primaryColor: authState.settings?.primaryColor || "",
      secondaryColor: authState.settings?.secondaryColor || "",
    },
  });

  useLoadData({
    fetchFunc: () => fetchLogo(),
    list: [logo],
    setList: (f) => setLogo(f.filePath as string),
    setLoadingDone: setLoadingDone,
    dontHideProgress: false,
  });

  useEffect(() => {
    console.log(
      "%cCustomerTestModusLoader.tsx line:69 useEffect layoutDone, loadingDone, logo",
      "color: orange;",
      layoutDone,
      loadingDone,
      logo
    );
    if (layoutDone || !loadingDone) return;

    console.log(
      "%cCustomerTestModusLoader.tsx line:72 setLayout now, logo:",
      "color: orange;",
      logo
    );
    setLayout({
      logo: logo,
      primaryColor: authState.settings?.primaryColor || "",
      secondaryColor: authState.settings?.secondaryColor || "",
    });
    setTimeout(() => setLayoutDone(true), 200);
  }, [logo, layoutDone, loadingDone, setLayout, authState.settings]);

  if (questionList.length === 0 || !loadingDone || !layoutDone)
    return <Loader />;

  return (
    <Paper className="customer-testmodus candidate">
      <TestModusStart />
    </Paper>
  );
};

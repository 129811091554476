import { useEffect, useState } from "react";

type IdentT = "main" | "sub" | "question" | "remove";
export const useNavigation = (ident: IdentT) => {
  const last = localStorage.getItem("resultNav:" + ident) || "0";
  const [numbers, setNumbers] = useState<number[]>(last.split(",").map(Number));
  const [id, setId] = useState<number>(0);

  const setNavigation = (id: number) => {
    // check if id is in nummbers
    const index = numbers.indexOf(id);
    if (index === -1) {
      // add id to numbers
      setNumbers((p) => [...p, id]);
    } else {
      // remove id from numbers
      setNumbers((p) => p.filter((n) => n !== id));
    }
  };

  const getNavigation = (id: number) => {
    return numbers.indexOf(id) !== -1;
  };

  useEffect(() => {
    if (ident === "question")
      localStorage.setItem("resultNav:" + ident, id.toString());
    else
      localStorage.setItem("resultNav:" + ident, numbers.join(",").toString());
  }, [numbers, ident, id]);

  if (ident === "remove") {
    localStorage.removeItem("resultNav:main");
    localStorage.removeItem("resultNav:sub");
    localStorage.removeItem("resultNav:question");
  }

  return {
    getNavigation,
    setNavigation,
    showQuestionId: id,
    setShowQuestionId: setId,
  };
};

export const removeNavigation = () => {
  localStorage.removeItem("resultNav:main");
  localStorage.removeItem("resultNav:sub");
  localStorage.removeItem("resultNav:question");
};

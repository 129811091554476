import { get, post, put, del, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";
import { checkApiResult } from "../shared/lib/checkApi";

export interface SubCategoryT {
  id: number;
  name: string;
  view: number;
  introText: string;
  memTime: number;
  memText: string;
  active: number;
  order: number;
  relationCount?: number;
  mainCategories?: {
    id: number;
    name: string;
  }[];
}

interface SubCategoryDataT extends SubCategoryT {}

const data2category = (data: SubCategoryDataT): SubCategoryT => {
  checkApiResult("data2category", data, {
    id: "number",
    name: "string",
    view: "number",
    introText: "string",
    active: "number",
    // order: "number",
  });
  return data as SubCategoryT;
};

const data2categories = (data: SubCategoryDataT[]): SubCategoryT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2category(d));
};

const category2data = (category: SubCategoryT): SubCategoryDataT => {
  return category as SubCategoryDataT;
};

const categories2data = (categories: SubCategoryT[]): SubCategoryDataT[] => {
  return categories.map((category) => category2data(category));
};

const APIPATH = "/subcategory";

/** ************************************************************************
 *
 *
 */
export const fetchCategories = async (
  onlyActive = false
): Promise<CallApiResultT<SubCategoryT[]>> => {
  const res = await get(APIPATH, onlyActive ? { active: 1 } : {});

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchCategories(sub)] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2categories(res.data),
  };
};

/** ************************************************************************
 *
 * @returns addedCategory
 */
export const addCategory = async (
  category: SubCategoryT
): Promise<CallApiResultT<SubCategoryT>> => {
  // console.log("addCategory xxx", category, category2data(category));

  const res = await post(APIPATH, category2data(category));

  if (!res.data) {
    throw Error("[addCategory(sub)] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2category(res.data as SubCategoryDataT),
  };
};

/** ************************************************************************
 * e.g. for active
 * @returns status
 */
export const updateCategory = async (
  category: SubCategoryT
): Promise<CallApiResultT<number>> => {
  // console.log("updateCategory", category, category2data(category));

  const res = await put<number>(
    `${APIPATH}/${category.id}`,
    category2data(category)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateCategoryBulk = async (
  categories: SubCategoryT[]
): Promise<CallApiResultT<number>> => {
  // console.log("updateCategory", categories, categories2data(categories));

  const res = await put<number>(APIPATH, categories2data(categories));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteCategory = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`);

  return res;
};

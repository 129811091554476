import { Loader } from "../../shared/Loader";
import { useContext } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { useTransactions } from "../../user/useTransactions";
import { CustomerDashboard } from "./CustomerDashboard";
import { useProfessions } from "../../profession/useProfessions";
import { useCandidates } from "../../candidate/useCandidates";
import { useShopOrders } from "../../order/useShopOrders";
import { useHideProgress } from "../../feedback/useHideProgress";

export const CustomerDashboardLoader = () => {
  const { authState } = useContext(AuthContext);
  const { loadingDone: loadingDoneT, transactionList } = useTransactions({
    userId: authState.user?.id,
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneP } = useProfessions({
    onlyActive: true,
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneC } = useCandidates({
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneO } = useShopOrders({
    dontHideProgress: true,
  });

  if (
    !useHideProgress(
      loadingDoneC && loadingDoneP && loadingDoneO && loadingDoneT
    )
  )
    return <Loader />;

  return <CustomerDashboard transactionList={transactionList} />;
};

import { useContext } from "react";
import { fetchProfessions, ProfessionT } from "./api";
import { ProfessionContext } from "./ProfessionContext";
import { useLoadData } from "../shared/hooks/useLoadData";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import React from "react";

export interface UseProfessionsResultT extends UseResultT {
  professionList: ProfessionT[];
  setProfessionList: React.Dispatch<React.SetStateAction<ProfessionT[]>>;
}

interface UseProfessionsPropsI extends UsePropsI {
  onlyActive?: boolean;
  check?: boolean;
}

/* must be defined here to avoid unnecessary re-rendering */
const fetchFuncActive = () =>
  fetchProfessions({ onlyActive: true, check: false });
const fetchFunc = () => fetchProfessions({ onlyActive: false, check: false });

export const useProfessions = ({
  dontShowProgress = false,
  dontHideProgress = false,
  onlyActive = false,
}: UseProfessionsPropsI): UseProfessionsResultT => {
  const { professionList, setProfessionList, loadingDone, setLoadingDone } =
    useContext(ProfessionContext);

  useLoadData({
    fetchFunc: onlyActive ? fetchFuncActive : fetchFunc,
    list: professionList,
    setList: setProfessionList,
    setLoadingDone,
    dontHideProgress,
    dontShowProgress,
    name: "fetchProfessions",
  });

  return { professionList, setProfessionList, loadingDone };
};

import { Box, styled } from "@mui/material";
import { LayoutContext } from "../../testModus/LayoutContext";
import { useContext } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { Customer } from "../../customer/Customer";
import { CustomerLogo } from "./CustomerLogo";

const StyledImg = styled("img")({
  width: 200,
});

export const CustomerHead = () => {
  const { layout } = useContext(LayoutContext);
  const { authState } = useContext(AuthContext);

  return (
    <>
      <Box className="diagonal-box-container">
        <Box
          className="diagonal-box"
          sx={{
            background:
              "linear-gradient(to left, " +
              (layout && layout.primaryColor ? layout.secondaryColor : "#fff") +
              "50%, " +
              (layout && layout.primaryColor
                ? layout.primaryColor
                : "rgb(0, 141, 211)") +
              "50%) !important",
          }}
        ></Box>
      </Box>
      <Box
        sx={{ width: "100%", mt: "5px", mb: "5px" }}
        className="profession-font"
      >
        {authState.user.professionName}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <CustomerLogo />
      </Box>
    </>
  );
};

import { useNavigate } from "react-router-dom";
import { Button, Grid, Switch, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../../shared/helper/formError";

import { EMPTYQUESTION, QuestionT, RatingT, updateQuestion } from "../api";
import { QuestionContext } from "../QuestionContext";
import { EditorTabs } from "./EditorTabs";
import { Preview } from "./Preview";
import { checkChanged } from "../../shared/helper/object";
import { usePrompt } from "../../shared/hooks/usePrompt";
import { PROMPT_WARN } from "../../shared/const/const";
import RatingSelect from "../Select/RatingSelect";

export const Editor = () => {
  const params = useParams<{ questionId: string }>();
  const navigate = useNavigate();
  const { setError } = useContext(ErrorContext);
  const { showProgress, openSnackbar } = useContext(FeedbackContext);

  const { questionList, setQuestionList } = useContext(QuestionContext);

  if (!params.questionId) {
    setError("fehlerhafte Parameter qid");
  }
  const questionId = parseInt(params.questionId || "0");
  const question = questionList.find((c) => c.id === questionId);

  const fields2check = ["answerOptions", "answer"];
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const [questionState, setQuestionState] = useState(question || EMPTYQUESTION);

  const [hasChanged, setHasChanged] = useState(false);
  useEffect(() => {
    checkChanged(question, questionState, hasChanged, setHasChanged);
  }, [setHasChanged, hasChanged, question, questionState]);

  usePrompt(PROMPT_WARN, hasChanged);

  if (!questionState) {
    setError("qnf error");
    return <></>;
  }

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f) => {
      const error = questionState[f as keyof QuestionT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });

    if (oneError) return;

    showProgress();
    updateQuestion(questionState)
      .then((res) => {
        if (res.success) {
          openSnackbar("success", "Eintrag gespeichert");
          setHasChanged(false);
          setQuestionList((p) =>
            p.map((q) => (q.id === questionId ? questionState : q))
          );
        } else setError(res.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Grid
        // does edit + preview
        container
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid item xs={6}>
          <Grid
            // only for spanning over whole height
            container
            sx={{
              width: "100%",
              height: "100%",
            }}
            direction="column"
            justifyContent="space-between"
          >
            <Grid item xs="auto">
              <Grid
                // this is for the edit-fields
                container
                sx={{
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Grid item xs={12} sx={{ mb: 2 }}>
                  {questionState.id} | {questionState.type}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="duration"
                    label="Zeit"
                    value={questionState.duration}
                    type="number"
                    onChange={(e) =>
                      setQuestionState((prevState) => {
                        return {
                          ...prevState,
                          duration: e.target.value as unknown as number,
                        };
                      })
                    }
                    sx={{ width: 60 }}
                  />
                  <TextField
                    id="difficulty"
                    label="Schwierigkeit"
                    value={questionState.difficulty}
                    type="number"
                    onChange={(e) =>
                      setQuestionState((prevState) => {
                        return {
                          ...prevState,
                          difficulty: e.target.value as unknown as number,
                        };
                      })
                    }
                    sx={{ width: 60 }}
                  />
                  <RatingSelect
                    id="rating"
                    label="Einstufung"
                    value={questionState.rating}
                    setValue={(v: RatingT) =>
                      setQuestionState((prevState) => {
                        return { ...prevState, rating: v };
                      })
                    }
                    selectSx={{ height: 53 }}
                  />
                  <TextField
                    id="points"
                    label="Punkte"
                    value={questionState.points}
                    type="number"
                    onChange={(e) =>
                      setQuestionState((prevState) => {
                        return {
                          ...prevState,
                          points: e.target.value as unknown as number,
                        };
                      })
                    }
                    sx={{ width: 60 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  Bsp:{" "}
                  <Switch
                    checked={questionState.example}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setQuestionState((p) => ({
                        ...p,
                        example: event.target.checked,
                      }));
                    }}
                  />
                  Horizontal:{" "}
                  <Switch
                    checked={
                      questionState.layout === "horizontal" ? true : false
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setQuestionState((p) => ({
                        ...p,
                        layout: event.target.checked
                          ? "horizontal"
                          : "vertical",
                      }));
                    }}
                  />
                  Einzelwe.{" "}
                  <Switch
                    checked={
                      questionState.evaluation === "single" ? true : false
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setQuestionState((p) => ({
                        ...p,
                        evaluation: event.target.checked ? "single" : "normal",
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={true} sx={{}}>
              <EditorTabs
                questionState={questionState}
                setQuestionState={setQuestionState}
                errorState={errorState}
                setErrorState={setErrorState}
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={onClick}
                variant="contained"
                sx={{ width: "100%" }}
              >
                Speichern
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            // only for spanning over whole height
            container
            sx={{
              width: "100%",
              height: "100%",
            }}
            direction="column"
            justifyContent="space-between"
          >
            <Grid item xs={true}>
              <Preview question={questionState} />
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                sx={{ width: "100%" }}
              >
                Abbrechen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

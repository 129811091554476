import { Loader } from "../shared/Loader";
import { useCustomer } from "./useCustomer";
import { CustomerData } from "./CustomerData";

export const CustomerDataLoader = () => {
  const { user, loadingDone } = useCustomer({});

  if (!loadingDone) return <Loader />;

  return <CustomerData user={user} />;
};

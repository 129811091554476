import { memo } from "react";
import { SxProps, Theme } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { deDE } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

interface DatePickerPropsI {
  id: string;
  value?: Date | null;
  setValue: (value: Date | null) => void;
  label?: string;
  labelId?: string;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
  clearSx?: SxProps<Theme>;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

export const DatePicker = memo((props: DatePickerPropsI) => {
  dayjs.extend(utc);
  return (
    <Box>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="de"
        localeText={
          deDE.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <MuiDatePicker
          label={props.label || "Geburtsdatum"}
          format="DD.MM.YYYY"
          onChange={(newValue: dayjs.Dayjs | null) => {
            if (!newValue) props.setValue(null);
            if (newValue && newValue.isValid()) {
              props.setValue(
                newValue
                  .utc(true)
                  .hour(0)
                  .minute(0)
                  .second(0)
                  .toDate() /* date picker returns random time */
              );
            }
          }}
          sx={{ width: "100%" }}
          value={props.value ? dayjs(props.value?.toISOString()) : null}
          slotProps={{
            textField: {
              inputProps: {
                sx: props.sx,
              },
              required: props.required,
              error: props.error,
              helperText: props.helperText,
            },
          }}
        />
      </LocalizationProvider>
      <Box
        sx={{
          position: "absolute",
          right: "40px",
          top: "15px",
          color: "primary.dark",
          ...props.clearSx,
        }}
      >
        <ClearIcon onClick={() => props.setValue(null)} />
      </Box>
    </Box>
  );
});

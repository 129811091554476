import { Box, Button, Modal } from "@mui/material";
import { CloseModalButton } from "../../shared/modal/CloseModalButton";

interface CandidatePrivacyI {
  showPrivacy: boolean;
  setShowPrivacy: (show: boolean) => void;
}

export const CandidatePrivacy = ({
  showPrivacy,
  setShowPrivacy,
}: CandidatePrivacyI) => {
  const setClose = () => setShowPrivacy(false);
  return (
    <Modal open={showPrivacy} className="modal-backdrop" onClose={setClose}>
      <>
        <Box className="modal modal800">
          <CloseModalButton close={setClose} />
          <Box sx={{ mr: 5 }} className="privacy">
            <h1>Datenschutzerklärung zur Nutzung von degeba Online-Tests</h1>
            <h2>Datenschutz</h2>
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </p>
            <p>
              Wenn Sie diese Website und den Testservice nutzen, werden
              verschiedene personenbezogene Daten erhoben. Personenbezogene
              Daten sind Daten, mit denen Sie persönlich identifiziert werden
              können. Die vorliegende Datenschutzerklärung erläutert, welche
              Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch,
              wie und zu welchem Zweck das geschieht.
            </p>
            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
              bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>
            <h2>Online-Test als Testteilnehmer/in</h2>
            <p>
              Während des Onlinetests und zur Auswertung werden Ihre Antworten,
              die resultierenden Ergebnisse, die Testdauer, Ihre Zugangsdaten
              und technische Analysedaten gespeichert. Zudem kann das testende
              Unternehmen / die testende Institution bestimmte personenbezogene
              Daten (wie Name, Geschlecht, E-Mail-Adresse und Sprache)
              speichern, um die Testergebnisse zu verwalten.
            </p>
            <h3>Hinweis zur verantwortlichen Stelle</h3>
            <p>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </p>
            <p>
              Marcus Mery, Bettinastr. 69, 63067 Offenbach am Main
              <br />
              Telefon: +49 (69) 40 56 49 73
              <br />
              E-Mail: kontakt(at)degeba.de
            </p>
            <p>
              Verantwortliche Stelle ist die natürliche oder juristische Person,
              die allein oder gemeinsam mit anderen über die Zwecke und Mittel
              der Verarbeitung von personenbezogenen Daten (z. B. Namen,
              E-Mail-Adressen o. Ä.) entscheidet.
            </p>
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
            <p>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
              Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>
            <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
            <p>
              Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
              Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist
              der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
              Unternehmen seinen Sitz hat. Eine Liste der
              Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem
              Link entnommen werden:{" "}
              <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="blank"
                rel="nofollow"
              >
                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
              </a>
              .
            </p>
            <h3>SSL- bzw. TLS-Verschlüsselung</h3>
            <p>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers „https://“
              anzeigt, und an dem Schloss-Symbol in Ihrer Browserzeile.
            </p>
            <p>
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </p>
            <h3>Auskunft, Sperrung, Löschung</h3>
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten können
              Sie sich jederzeit unter der im Impressum angegebenen Adresse an
              uns wenden.
            </p>
            <h3>Web Fonts</h3>
            <p>
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              eine so genannte Web Fonts, die von CDN Fonts bereitgestellt
              werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten
              Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
              anzuzeigen.
            </p>
            <p>
              Zu diesem Zweck muss der von Ihnen verwendete Browser je nach
              Schriftart Verbindung zu{" "}
              <a href=" https://www.cdnfonts.com" target="blank" rel="nofollow">
                https://www.cdnfonts.com
              </a>{" "}
              aufnehmen. Hierdurch erlangt CDN Fonts Kenntnis darüber, dass über
              Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von
              CDN Fonts erfolgt im Interesse einer einheitlichen und
              ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
              berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
              dar.
            </p>
            <p>
              Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
              Standardschrift von Ihrem Computer genutzt. Dies führt dazu, dass
              Sie nicht den vollen Funktionsumfang bezüglich der Designvorlagen
              nutzen können und Vorlagen nicht richtig dargestellt werden.
            </p>
            <p>
              Weitere Informationen zu CDN Fonts finden Sie unter{" "}
              <a
                href="https://www.cdnfonts.com/terms-of-service"
                target="blank"
                rel="nofollow"
              >
                https://www.cdnfonts.com/terms-of-service.
              </a>
            </p>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button onClick={setClose} color="primary" variant="contained">
                Schließen
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

import React, { useState, useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { SearchField } from "../shared/forms/SearchField";

import {
  EnhancedTableHead,
  HeadCellT,
  stableSort,
  getComparator,
  OrderDirT,
  handleRequestSort,
  NonOptionalKeys,
  ComparatorT,
} from "../shared/table/EnhancedTable";
import { usePagination } from "../shared/hooks/usePagination";

import Typography from "@mui/material/Typography";
import { useSearch } from "../shared/hooks/useSearch";
import { ShopOrderContext } from "./ShopOrderContext";
import { ShopOrderItemT } from "./api";
import { AdminShopOrderEntry } from "./AdminShopOrderEntry";

const headCells: HeadCellT<ShopOrderItemT>[] = [
  { id: "id", disablePadding: false, label: "ID" },
  {
    id: "createdAt",
    disablePadding: true,
    label: "Datum",
  },
  {
    id: "customer",
    disablePadding: true,
    label: "E-mail",
  },
  {
    id: "type",
    disablePadding: false,
    label: "Typ",
  },
  { id: "content", disablePadding: false, label: "Inhalt" },
  { id: "orderId", disablePadding: false, label: "Download" },
];

export const AdminShopOrderTable = () => {
  const { orderList } = useContext(ShopOrderContext);
  const [orderDir, setOrderDir] = useState<OrderDirT>("desc");
  const [orderBy, setOrderBy] = useState<NonOptionalKeys<ShopOrderItemT>>("id");
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("order");
  const [filter, setFilter] = useSearch("ordersearch");

  const comparator: ComparatorT<ShopOrderItemT> = getComparator(
    orderDir,
    orderBy
  );

  const filteredOrderList = orderList.filter(
    (e) =>
      (e.content || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
      ((e.customer && e.customer.email) || "")
        .toLowerCase()
        .indexOf(filter.toLowerCase()) >= 0
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredOrderList.length - page * rowsPerPage);

  return (
    <Typography variant="body1" component="span">
      <SearchField
        value={filter}
        setValue={setFilter}
        id="customersearch"
        handleChangePage={handleChangePage}
      />

      <TableContainer sx={{ mt: 2 }}>
        <Table sx={{ minWidth: "80vw" }} size="small">
          <EnhancedTableHead
            headCells={headCells}
            orderDir={orderDir}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(
                event,
                property,
                orderDir,
                setOrderDir,
                orderBy,
                setOrderBy
              )
            }
            rowCount={filteredOrderList.length}
          />
          <TableBody>
            {stableSort<ShopOrderItemT>(filteredOrderList, comparator)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order) => (
                <AdminShopOrderEntry key={order.id} order={order} />
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 54 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredOrderList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
      />
    </Typography>
  );
};

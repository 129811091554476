import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export enum DIALOGOPTIONS {
  CLOSED = 0,
  LAST = 1,
  TIME = 2,
  MEM = 3,
  MEMCLICK = 4,
}

interface TestModusDialogPropsI {
  type: "last" | "time" | "mem" | "memclick";
  open: boolean;
  handleNo?: () => void;
  handleYes: () => void;
  timeClose?: number;
}

/* Dialog is closed from outside via props.open */
export const TestModusDialog = React.memo((props: TestModusDialogPropsI) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={() => props.handleNo && props.handleNo()}
        data-testid={"testModusDialog" + props.type}
      >
        <DialogTitle data-testid="dialog-leave-subcategory">
          {props.type === "last"
            ? "Unterkategorie verlassen?"
            : props.type === "memclick"
            ? "Einprägen beenden?"
            : "Zeit abgelaufen"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="candidate-dialog-content">
            {props.type === "last" ? (
              <>
                Weiter zum nächsten Abschnitt? Sie können dann nicht mehr zur
                aktuellen Unterkategorie zurückkehren.
              </>
            ) : props.type === "memclick" ? (
              <>
                Weiter zu den Aufgaben? Sie können dann nicht mehr auf diese
                Seite zurückkehren.
              </>
            ) : props.type === "mem" ? (
              <>
                Die Merkzeit für diesen Abschnitt ist abgelaufen.{" "}
                {props.timeClose && props.timeClose}
              </>
            ) : (
              <>
                Die Bearbeitungszeit für diesen Abschnitt ist abgelaufen.{" "}
                {props.timeClose && props.timeClose}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(props.type === "last" || props.type === "memclick") && (
            <Button
              variant="outlined"
              autoFocus
              onClick={() => props.handleNo && props.handleNo()}
              color="primary"
              data-testid="button-leave-sub-handleNo"
            >
              Abbrechen
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => props.handleYes()}
            color="primary"
            autoFocus
            data-testid="button-leave-sub-handleYes"
          >
            Weiter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { CloseModalButton } from "../shared/modal/CloseModalButton";
import { useContext, useState } from "react";
import { CandidateT, updateCandidateSettings } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";

interface CandidateDisabilityModalPropsI {
  candidate: CandidateT;
  setShowDisability: (value: React.SetStateAction<number>) => void;
  setCandidateList: React.Dispatch<React.SetStateAction<CandidateT[]>>;
}

export const CandidateDisabilityModal = ({
  candidate,
  setShowDisability,
  setCandidateList,
}: CandidateDisabilityModalPropsI) => {
  const closeModal = () => {
    setShowDisability(0);
  };

  console.log("🎸 candidate", candidate);

  const [percent, setPercent] = useState(
    candidate.candidateSettings?.compensation_percent || 0
  );
  const [pause, setPause] = useState(
    candidate.candidateSettings?.compensation_pause || 0
  );
  const { openSnackbar, showProgress, hideProgress } =
    useContext(FeedbackContext);

  const onSave = () => {
    showProgress();
    updateCandidateSettings(candidate.id, percent, pause).then(() => {
      openSnackbar("success", "Eintrag gespeichert");
      closeModal();
      setCandidateList((p) => {
        const index = p.findIndex((c) => c.id === candidate.id);
        const newCandidate = { ...p[index] };
        console.log("🎸 percent:", percent, "pause:", pause);
        if (percent == 0 && pause == 0) {
          console.log("🎸 both 0");
          newCandidate.candidateSettings = null;
        } else {
          newCandidate.candidateSettings = {
            ...newCandidate.candidateSettings,
            compensation_percent: percent,
            compensation_pause: pause,
          };
        }
        p[index] = newCandidate;
        return p;
      });
      hideProgress();
    });
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Nachteilsausgleich einstellen
      </DialogTitle>
      <DialogContent sx={{ width: 600 }}>
        <Box sx={{ mb: 1 }}>
          <CloseModalButton close={closeModal} />
        </Box>
        <Typography variant="h2"> Bearbeitungszeit verlängern</Typography>
        <Box sx={{ mt: 1, mb: 2 }}>
          Über den Schieberegler können Sie einstellen, wie viel Zeit der
          Teilnehmer zur Bearbeitung der Aufgaben zusätzlich erhalten soll
          (Angabe in Prozent, bezogen auf die ursprüngliche Bearbeitungszeit).
        </Box>
        <Box sx={{ ml: 1, mr: 1, mb: 4 }}>
          <div className="target-value">{percent}</div>
          <Slider
            size="small"
            aria-label="Small"
            marks={[
              {
                value: 0,
                label: "0%",
              },
              {
                value: 50,
                label: "50%",
              },
              {
                value: 100,
                label: "100%",
              },
            ]}
            valueLabelDisplay="auto"
            value={percent}
            onChange={(event: Event, newValue: number | number[]) => {
              setPercent(newValue as number);
            }}
          />
        </Box>
        <Typography variant="h2">Pausen einräumen</Typography>
        <Box sx={{ mt: 1, mb: 2 }}>
          Sie können dem Teilnehmer die Möglichkeit zu pausieren einräumen.
          Geben Sie dazu die Pausenzeit in Minuten an. Das Zeitbudget gilt für
          den gesamten Test und kann vom Teilnehmer nach eigenem Ermessen
          eingeteilt werden.
        </Box>
        <TextField
          size="medium"
          value={pause}
          label="Minuten"
          onChange={(e) => {
            setPause(e.target.value as unknown as number);
          }}
          type="number"
          sx={{ width: 100, mb: 3 }}
          inputProps={{
            min: 0,
            max: 1000,
          }}
        />

        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={onSave}
            >
              Speichern
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Button variant="outlined" sx={{ mt: 2 }} onClick={closeModal}>
              Abbrechen
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

import React, { createContext, useState } from "react";
import { FileT } from "./api";
import { ContextPropsT } from "../shared/types/Context";
type FilesFetchedT = {
  [type: string]: boolean;
};

export interface FileChangedT extends FileT {
  error?: boolean;
}

interface FileContextPropsType extends ContextPropsT {
  fileList: FileT[];
  setFileList: React.Dispatch<React.SetStateAction<FileT[]>>;
  changedFileList: FileChangedT[];
  setChangedFileList: React.Dispatch<React.SetStateAction<FileChangedT[]>>;
  filesFetched: FilesFetchedT;
  setFilesFetched: React.Dispatch<React.SetStateAction<FilesFetchedT>>;
}

export const FileContext = createContext<FileContextPropsType>({
  fileList: [],
  setFileList: () => {},
  changedFileList: [],
  setChangedFileList: () => {},
  filesFetched: {},
  setFilesFetched: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
});
export const FileProvider = ({ children }: { children: React.ReactNode }) => {
  const [loadingDone, setLoadingDone] = useState(false);
  const [fileList, setFileList] = useState<FileT[]>([]);
  const [changedFileList, setChangedFileList] = useState<FileChangedT[]>([]);
  const [filesFetched, setFilesFetched] = useState<FilesFetchedT>({});

  return (
    <FileContext.Provider
      value={{
        fileList,
        setFileList,
        filesFetched,
        setFilesFetched,
        changedFileList,
        setChangedFileList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

import React, { Fragment, useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HelpIcon from "@mui/icons-material/Help";

import ExitToApp from "@mui/icons-material/ExitToApp";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Divider from "@mui/material/Divider";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BrushIcon from "@mui/icons-material/Brush";
import { AuthContext } from "../../auth/AuthContext";

export const Sidebar = () => {
  const { authState } = useContext(AuthContext);

  return (
    <Fragment>
      {authState.user.hasTransaction && (
        <>
          <List>
            <SidebarEntry
              to="/customer/dashboard"
              text="Dashboard"
              icon={<HomeIcon />}
            />
          </List>
          <Divider />
          <List>
            <SidebarEntry
              to="/customer/candidate"
              text="Teilnehmer/innen"
              icon={<PeopleIcon />}
            />
            <SidebarEntry
              to="/customer/mailtemplates"
              icon={<ContactMailIcon />}
              text="Mailvorlagen"
            />
          </List>
          <Divider />
        </>
      )}
      <List>
        {authState.user.hasTransaction && (
          <SidebarEntry
            to="/customer/settings"
            icon={<BrushIcon />}
            text="Design"
          />
        )}
        <SidebarEntry
          to="/customer/orders"
          icon={<ListAltIcon />}
          text="Meine Bestellungen"
        />
        <SidebarEntry
          to="/customer/data"
          icon={<SettingsIcon />}
          text="Stammdaten"
        />
        {authState.user.hasTransaction && (
          <SidebarEntry to="/customer/help" icon={<HelpIcon />} text="Hilfe" />
        )}
        <SidebarEntry
          to="/customer/logout"
          text="Logout"
          icon={<ExitToApp />}
        />
      </List>
    </Fragment>
  );
};

interface SidebarEntryPropsI {
  to: string;
  text: string;
  icon: React.ReactNode;
}

const SidebarEntry = ({ to, text, icon }: SidebarEntryPropsI) => (
  <Link
    component={RouterLink}
    to={to}
    underline="none"
    sx={{
      color: "text.secondary",
    }}
  >
    <ListItem
      sx={{
        backgroundColor: useLocation().pathname === to ? "lightgray" : "white",
      }}
      button
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} className="sidebar-entry" />
    </ListItem>
  </Link>
);

import { Fragment } from "react";
import { CandidateLogsT } from "../api";
import { Box, Grid, Typography } from "@mui/material";
import { datetime_hr, mm_ss } from "../../shared/helper/datetime";

interface CandidateLogsPropsI {
  logs?: CandidateLogsT[];
}

const LOG_TYPES: { [k: string]: string } = {
  login: "Login",
  "test-begin": "Test begonnen",
  "test-end": "Test beendet",
  "auto-end": "Test vom System bei Erreichen des Zeitlimits beendet",
};

export const CandidateLogs = ({ logs }: CandidateLogsPropsI) => {
  // {candidate?.results && (
  //   <ResultsMainList results={candidate.results} logs={candidate.logs} />
  // )}

  let duration = 0;
  if (logs) {
    const testBegin = logs.find((l) => l.type === "test-begin");
    let testEnd = logs.find((l) => l.type === "test-end");
    if (!testEnd) testEnd = logs.find((l) => l.type === "auto-end");
    if (testBegin && testEnd) {
      duration =
        (testEnd.createdAt.getTime() - testBegin.createdAt.getTime()) / 1000;
    }
  }

  return (
    <Typography variant="body2" component="div" sx={{ mb: 1 }}>
      <span className="semibold">Protokoll</span>
      <br />
      <br />
      {logs &&
        logs.length > 0 &&
        logs.map((l: CandidateLogsT) => (
          <Fragment key={l.type + "" + l.createdAt.getTime()}>
            <Grid container spacing={1}>
              <Grid item sx={{ width: "115px" }}>
                {datetime_hr(l.createdAt)}
              </Grid>
              <Grid item xs={8}>
                {LOG_TYPES[l.type]}
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Fragment>
        ))}
      {Boolean(duration) && (
        <Box>Benötigte Bearbeitungszeit: {mm_ss(duration)}</Box>
      )}

    </Typography>
  );
};

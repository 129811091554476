import { useContext } from "react";
import { fetchUsers, UserT } from "./api";
import { UserContext } from "./UserContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseUsersResultT extends UseResultT {
  userList: UserT[];
  setUserList: React.Dispatch<React.SetStateAction<UserT[]>>;
}

interface UseUsersPropsI extends UsePropsI {
  role?: string;
}

export const useUsers = ({
  role,
  dontHideProgress = false,
}: UseUsersPropsI): UseUsersResultT => {
  const { userList, setUserList, loadingDone, setLoadingDone } =
    useContext(UserContext);
  useLoadData({
    fetchFunc: () => fetchUsers(role),
    list: userList,
    setList: setUserList,
    setLoadingDone,
    dontHideProgress,
  });

  return { userList, setUserList, loadingDone };
};

import { useContext, useEffect, useRef } from "react";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { useMainCategories } from "../mainCategory/useMainCategories";
import { useProfessions } from "../profession/useProfessions";
import { useQuestions } from "../question/useQuestions";
import { useSubCategories } from "../subCategory/useSubCategories";
import { RelationTable } from "./RelationTable";
import { useRelations } from "./useRelations";
import { Loader } from "../shared/Loader";
import { useSelTree } from "./useSelTree";

export const RelationLoader = () => {
  const { hideProgress } = useContext(FeedbackContext);
  const { selTree } = useSelTree();

  const { loadingDone: lProf } = useProfessions({
    check: false,
    dontHideProgress: true,
  });
  const { loadingDone: lMain } = useMainCategories({
    onlyActive: true,
    dontHideProgress: true,
  });
  const { loadingDone: lSub } = useSubCategories({
    onlyActive: true,
    dontHideProgress: true,
  });
  const { loadingDone: lQuest } = useQuestions({
    dontHideProgress: true,
    subCategoryId: selTree.subCategoryId || -1,
  });

  const { loadingDone: lRel } = useRelations({ dontHideProgress: true });

  const hideDone = useRef(false);
  useEffect(() => {
    if (!(lProf && lMain && lSub && lQuest && lRel)) return;
    if (hideDone.current) {
      return;
    }
    hideDone.current = true;
    hideProgress();
  }, [hideProgress, lProf, lMain, lSub, lQuest, lRel]);

  if (!(lProf && lMain && lSub && lQuest && lRel)) {
    return <Loader />;
  }
  return (
    <>
      <RelationTable />
    </>
  );
};

import { addUser, UserT } from "../user/api";
import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { CustomerForm } from "./CustomerForm";

import { Dialog } from "../shared/Dialog";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { UserContext } from "../user/UserContext";
import { addStateToArray } from "../shared/helper/state";
import { AddButton } from "../shared/forms/AddButton";

interface AddCustomerPropsI {
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}
export const AddCustomer = ({ setFilter }: AddCustomerPropsI) => {
  const { setUserList, setAllUserEmails } = useContext(UserContext);
  const { setError } = useContext(ErrorContext);

  const [open, setOpen] = useState(false);
  const { openSnackbar, hideProgress } = useContext(FeedbackContext);

  const onClick = (data: UserT) => {
    if (!data.email) return;
    data.role = "customer";
    addUser(data)
      .then((res) => {
        if (res.success) {
          setUserList((p) => addStateToArray<UserT>(p, res.data as UserT));
          setAllUserEmails((p) =>
            p.concat([{ email: res.data?.email || "", id: res.data?.id || 0 }])
          );
          openSnackbar("success", "Eintrag gespeichert");
          setFilter(res.data?.email || "");
          setOpen(false);
        } else {
          console.log(
            "%csrc/components/customer/AddCustomer.tsx:36 res",
            "color: #007acc;",
            res
          );
          if (res.errors) {
            if (
              "email" in res.errors &&
              (res.errors as any).email[0].indexOf("already") > 0
            ) {
              setError("Diese E-Mail ist bereits vorhanden!");
            }
          } else setError(res.error);
          hideProgress();
        }
        if (res.error) {
          if (
            typeof res.error === "string" &&
            res.error.indexOf("already") > 0
          ) {
            setError("Diese E-Mail ist bereits vorhanden!");
          } else setError(res.error);
          hideProgress();
        }
      })
      .catch((error) => {
        console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <>
      <AddButton onClick={() => setOpen(true)} title="Kunde" />

      <Dialog open={open} setOpen={setOpen} title="Neuer Kunde">
        <CustomerForm onClick={onClick} button="Speichern" />
      </Dialog>
    </>
  );
};

import { QuestionT } from "./api";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface QuestionDetailsPropsI {
  question: Partial<QuestionT>;
}
export const QuestionDetails = ({ question }: QuestionDetailsPropsI) => {
  console.log("q:", question.question);
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Typography variant="body2">Schwierigkeit:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">{question.difficulty}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2"> Dauer</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2"> {question.duration}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2"> Wertung</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2"> {question.evaluation}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2"> Optionen</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2"> {question.answerOptions}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2"> Antwort</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2"> {question.answer}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2"> Kommentar</Typography>
      </Grid>
    </Grid>
  );
};

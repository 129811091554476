import { useContext, useEffect, useState, useRef } from "react";
import { fetchTargets, TargetT } from "./api";
import { TargetContext } from "./TargetContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseTargetsResultT extends UseResultT {
  targetList: TargetT[];
  setTargetList: React.Dispatch<React.SetStateAction<TargetT[]>>;
}

interface UseTargetsPropsI extends UsePropsI {
  professionId: number;
}

export const useTargets = ({
  professionId,
  dontHideProgress = false,
}: UseTargetsPropsI): UseTargetsResultT => {
  const { targetList, setTargetList } = useContext(TargetContext);

  const [temp, setTemp] = useState<TargetT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const [allDone, setAlldone] = useState(false);

  useLoadData({
    fetchFunc: () => fetchTargets(professionId),
    list: temp,
    setList: setTemp,
    setLoadingDone,
    dontHideProgress,
  });

  const setDone = useRef(false);
  useEffect(() => {
    if (setDone.current) return;
    if (loadingDone) {
      setDone.current = true;
      setTargetList(temp);
      setAlldone(true);
    }
  }, [loadingDone, temp, setTargetList]);

  return { targetList, setTargetList, loadingDone: allDone };
};

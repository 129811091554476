import { useContext, useEffect, useState } from "react";
import {
  fetchHelp,
  fetchHelpCategories,
  fetchHelpEntries,
  HelpCategoryT,
  HelpT,
} from "./api";
import { HelpContext } from "./HelpContext";

import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseHelpResultT extends UseResultT {
  helpCategoryList: HelpCategoryT[];
  setHelpCategoryList: React.Dispatch<React.SetStateAction<HelpCategoryT[]>>;
}

export interface UseHelpContentResultT extends UseResultT {
  helpList: HelpT[];
  setHelpList: React.Dispatch<React.SetStateAction<HelpT[]>>;
}

interface UseHelpPropsI extends UsePropsI {}

export const useHelp = ({
  dontHideProgress = false,
}: UseHelpPropsI): UseHelpResultT => {
  const {
    helpCategoryList,
    setHelpCategoryList,
    helpEntryList,
    setHelpEntryList,
    loadingDone,
    setLoadingDone,
  } = useContext(HelpContext);

  const [loadingDoneEntries, setLoadingDoneEntries] = useState(false);

  useLoadData({
    fetchFunc: () => fetchHelpCategories(),
    list: helpCategoryList,
    setList: setHelpCategoryList,
    setLoadingDone,
    dontHideProgress,
  });

  useLoadData({
    fetchFunc: () => fetchHelpEntries(),
    list: helpEntryList,
    setList: setHelpEntryList,
    setLoadingDone: setLoadingDoneEntries,
    dontHideProgress,
  });

  return {
    helpCategoryList,
    setHelpCategoryList,
    loadingDone: loadingDone && loadingDoneEntries,
  };
};

export const useContentHelp = ({
  dontHideProgress = false,
}: UseHelpPropsI): UseHelpContentResultT => {
  const { helpList, setHelpList, loadingDone, setLoadingDone } =
    useContext(HelpContext);

  useLoadData({
    fetchFunc: () => fetchHelp(),
    list: helpList,
    setList: setHelpList,
    setLoadingDone,
    dontHideProgress,
  });

  useEffect(() => {
    console.log(
      "%cuseHelp.ts line:78 loadingDone",
      "color: #007acc;",
      loadingDone
    );
  }, [loadingDone]);

  return {
    helpList,
    setHelpList,
    loadingDone: loadingDone,
  };
};

import React, { ReactElement } from "react";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";
import { AutoLogin } from "./AutoLogin";
import { AutoLoginError } from "./AutoLoginError";
import { LSKEYS } from "../testModus/TestModusContext";

export const CandidateLoggedOut = (): ReactElement => {
  if (localStorage.getItem(LSKEYS.FINISHED)) {
    localStorage.removeItem(LSKEYS.FINISHED);
    return <AutoLoginError />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="login-box"
      >
        <Box className="logo degeba-logo"></Box>
        <Typography component="h1" variant="h5">
          Testbereich
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          <br />
          <br />
          Bitte nutzen Sie zur Teilnahme am Test ausschließlich Ihren
          persönlichen Einladungs-Link. Die Teilnahme ist einmalig bis zum
          Ablauf der genannten Bearbeitungsfrist möglich.
        </Typography>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

import React, { createContext, useState } from "react";
import { FileProvider } from "../file/FileContext";
import { ContextPropsT } from "../shared/types/Context";
import { MainCategoryT } from "./api";

// createContext: erzeugt ein Context obects, das alles möglich sein kann
// in diesem Fall ist es Variable und Setz-Funktion auf dem useState-Hook!
// daher muss die Typisierung dem useState-Hook folgen
// unklar warum erst Dispatch, dann SetStateAction, vermutlich wird es weitergereicht

interface MainCategoryContextPropsType extends ContextPropsT {
  categoryList: MainCategoryT[];
  setCategoryList: React.Dispatch<React.SetStateAction<MainCategoryT[]>>;
  changedCategoryList: MainCategoryChangedT[];
  setChangedCategoryList: React.Dispatch<
    React.SetStateAction<MainCategoryChangedT[]>
  >;
}

export interface MainCategoryChangedT extends MainCategoryT {
  error?: boolean;
}

export const MainCategoryContext = createContext<MainCategoryContextPropsType>({
  categoryList: [],
  setCategoryList: () => {},
  changedCategoryList: [],
  setChangedCategoryList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
});
export const MainCategoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [categoryList, setCategoryList] = useState<MainCategoryT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const [changedCategoryList, setChangedCategoryList] = useState<
    MainCategoryT[]
  >([]);

  return (
    <MainCategoryContext.Provider
      value={{
        categoryList,
        setCategoryList,
        changedCategoryList,
        setChangedCategoryList,
        loadingDone,
        setLoadingDone,
      }}
    >
      <FileProvider>{children}</FileProvider>
    </MainCategoryContext.Provider>
  );
};

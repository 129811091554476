import { AddSubCategory } from "./AddSubCategory";
import { SubCategoryTable } from "./SubCategoryTable";
import { useSubCategories } from "./useSubCategories";
import { Loader } from "../shared/Loader";

export const SubCategoryLoader = () => {
  const { categoryList, loadingDone } = useSubCategories({});

  if (categoryList.length === 0) {
    if (loadingDone) return <AddSubCategory />;
    else return <Loader />;
  }

  return <SubCategoryTable />;
};

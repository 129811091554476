import { jsonDecode } from "../../../shared/helper/json";
import {
  replaceImages,
  replaceImagesAsObject,
} from "../../../testModus/helper/img";

export const decodeAnswerOptionsMultipleChoice = (options: string): any => {
  const res = replaceImagesAsObject(options, "'");

  return {
    answerOptionsDecoded: jsonDecode(res.strDecoded),
    answerOptionsMaxHeight: res.maxHeight,
    answerOptionsMaxWidth: res.maxWidth,
  };
};
export const decodeQuestionOptionsMultipleChoice = (
  question: string
): string => {
  return replaceImages(question, '"');
};

import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export type ProfessionTypeT = "basic" | "premium" | "root" | "test";

interface TestAccountSelectPropI {
  id: string;
  value?: string;
  setValue: (value: string) => void;
  label?: string;
  labelId?: string;
  showAll?: boolean;
  size?: "small" | "medium";
}

export const TestAccountSelect = (props: TestAccountSelectPropI) => {
  return (
    <Select
      labelId={props.labelId}
      id={props.id}
      value={props.value ? props.value : ""}
      onChange={(e: SelectChangeEvent<string>) => {
        props.setValue(e.target.value);
      }}
      label={props.label}
      size={props.size ? props.size : "medium"}
      sx={{ fontSize: (theme) => theme.typography.body2.fontSize }}
    >
      {props.showAll && <MenuItem value="all">Alle</MenuItem>}
      <MenuItem value="1">Testkonten</MenuItem>
      <MenuItem value="0">Aktivkonten</MenuItem>
    </Select>
  );
};

export default TestAccountSelect;

import { DeleteButton } from "../shared/forms/DeleteButton";
import { EditAdmin } from "./EditAdmin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { deleteUser, UserT } from "../user/api";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { useState } from "react";
import { ChangePassword } from "../customer/ChangePassword";

export const Admin = ({
  user,
  deleteSuccessFunc,
  onClickPassword,
}: {
  user: UserT;
  deleteSuccessFunc: () => void;
  onClickPassword: (i: number) => void;
}) => {
  const [dialogSendPasswordOpen, setDialogSendPasswordOpen] = useState(false);

  return (
    <Card
      variant="outlined"
      sx={{
        display: "inline-block",
        p: 1,
        width: 350,
        height: 180,
        background: (theme) => theme.palette.grey[100],
        mr: 1,
        mb: 1,
      }}
    >
      <CardContent data-testid={user.email}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {user.firstname} {user.lastname}
          </Grid>
          <Grid item xs={12}>
            {user.email}
          </Grid>
          <Grid item xs={2}>
            <EditAdmin user={user} />
          </Grid>
          <Grid item xs={2}>
            <DeleteButton
              id={user.id}
              deleteFunc={deleteUser}
              successFunc={deleteSuccessFunc}
            />
          </Grid>
          <Grid item xs={2}>
            <ChangePassword userId={user.id} />
          </Grid>
          <Grid item xs={2}>
            <TooltipIcon
              type="sendPassword"
              title="Passwort zurücksetzen"
              onClick={() => setDialogSendPasswordOpen(true)}
            />
            <ConfirmDialog
              open={dialogSendPasswordOpen}
              handleYes={() => {
                onClickPassword(user.id);
                setDialogSendPasswordOpen(false);
              }}
              handleNo={() => setDialogSendPasswordOpen(false)}
              title="Mail verschicken"
              content="Soll die Mail mit dem Link versendet werden?"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

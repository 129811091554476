import { useState } from "react";
import { PROMPT_WARN } from "../const/const";
import { usePrompt } from "./usePrompt";

export const useShowSave = () => {
  const [showSave, setShowSave] = useState(false);

  usePrompt(PROMPT_WARN, showSave);

  return [showSave, setShowSave] as const;
};

import { debounce } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ErrorContext } from "../error/ErrorContext";
import { ContextPropsT } from "../shared/types/Context";
import { fetchUsers, UserT, UserTransactionT } from "./api";

export const EMPTYUSER: UserT = {
  id: 0,
  gender: "",
  firstname: "",
  lastname: "",
  email: "",
  emailInvoice: "",
  password: "",
  role: "",
  active: 0,
  testAccount: false,
  uuid: "",
  forceChangePassword: 0,
  professionIds: [],
};

type UserTLenT = {
  [key: string | number]: number;
};

export const UserTLen: UserTLenT = {
  gender: 1,
  zipcode: 10,
  country: 2,
  phone: 20,
};

export type AllUserEmailsT = {
  id: number;
  email: string;
};

interface UserContextPropsType extends ContextPropsT {
  userList: UserT[];
  setUserList: React.Dispatch<React.SetStateAction<UserT[]>>;
  changedUserList: UserT[];
  setChangedUserList: React.Dispatch<React.SetStateAction<UserT[]>>;
  transactionList: UserTransactionT;
  setTransactionList: React.Dispatch<React.SetStateAction<UserTransactionT>>;
  allUserEmails: AllUserEmailsT[];
  setAllUserEmails: React.Dispatch<React.SetStateAction<AllUserEmailsT[]>>;
}

export const UserContext = createContext<UserContextPropsType>({
  userList: [],
  setUserList: () => {
    /* do nothing */
  },
  changedUserList: [],
  setChangedUserList: () => {
    /* do nothing */
  },
  transactionList: [],
  setTransactionList: () => {
    /* do nothing */
  },
  allUserEmails: [],
  setAllUserEmails: () => {
    /* do nothing */
  },
  loadingDone: false,
  setLoadingDone: () => {
    /* do nothing */
  },
});

export interface UserChangedT extends UserT {
  error?: boolean;
}

export const debouncedSetAllUserEmails = debounce(
  (
    id: number,
    email: string,
    setAllUserEmails: (value: React.SetStateAction<AllUserEmailsT[]>) => void
  ) => {
    console.log("debouncedSetAllUserEmails");
    setAllUserEmails((p) =>
      p.map((e) => (e.id === id ? { email: email, id: id } : e))
    );
  },
  300
);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userList, setUserList] = useState<UserT[]>([]);
  const [changedUserList, setChangedUserList] = useState<UserT[]>([]);
  const [transactionList, setTransactionList] = useState<UserTransactionT>([]);
  const [allUserEmails, setAllUserEmails] = useState<AllUserEmailsT[]>([]);
  const { setError } = useContext(ErrorContext);
  const [loadingDone, setLoadingDone] = useState(false);

  const firstCallDone = useRef(false);
  useEffect(() => {
    if (firstCallDone.current) return;
    firstCallDone.current = true;
    fetchUsers()
      .then((result) => {
        if (result.success && result.data) {
          setAllUserEmails(
            result.data?.map((u) => ({ email: u.email, id: u.id }))
          );
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  }, [setAllUserEmails, setError]);

  return (
    <UserContext.Provider
      value={{
        userList,
        setUserList,
        changedUserList,
        setChangedUserList,
        transactionList,
        setTransactionList,
        allUserEmails,
        setAllUserEmails,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import { TooltipIcon } from "../../shared/forms/TooltipIcon";
import { SelTreeT } from "../useSelTree";

interface DelRelationIconI {
  selTree: SelTreeT;
  onClick: () => void;
}
export const DelRelationIcon = ({ selTree, onClick }: DelRelationIconI) => {
  return (
    <TooltipIcon
      type="delRel"
      title={
        selTree.professionIsActive
          ? "Beruf ist aktiv - Entfernen nicht möglich"
          : "Zuordnung entfernen"
      }
      iconProps={{
        ...(selTree.professionIsActive ? { color: "disabled" } : {}),
      }}
      {...(selTree.professionIsActive ? {} : { onClick })}
    />
  );
};

import { useContext, useEffect, useState, useRef } from "react";
import { fetchTransactions, TransactionT, UserTransactionT } from "../user/api";
import { UserContext } from "../user/UserContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseUsersResultT extends UseResultT {
  transactionList: UserTransactionT;
  setTransactionList: React.Dispatch<React.SetStateAction<UserTransactionT>>;
}

interface UseUsersPropsI extends UsePropsI {
  userId?: number;
}

export const useTransactions = ({
  userId,
  dontHideProgress = false,
}: UseUsersPropsI): UseUsersResultT => {
  const { transactionList, setTransactionList } = useContext(UserContext);

  const [temp, setTemp] = useState<TransactionT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const [allDone, setAlldone] = useState(false);

  useLoadData({
    fetchFunc: () => fetchTransactions(userId),
    list: temp,
    setList: setTemp,
    setLoadingDone,
    dontHideProgress,
  });

  const setDone = useRef(false);
  useEffect(() => {
    if (setDone.current) return;
    if (loadingDone) {
      setDone.current = true;
      setTransactionList((p) => {
        temp.forEach((t) => {
          if (!p[t.userId]) p[t.userId] = [];
          p[t.userId].push(t);
        });
        return p;
      });
      setAlldone(true);
    }
  }, [loadingDone, temp, setTransactionList]);

  return { transactionList, setTransactionList, loadingDone: allDone };
};

import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ProfessionContext } from "../profession/ProfessionContext";
import { YearSelect } from "../shared/forms/YearSelect";
import { actYear } from "../shared/helper/datetime";
import { cachebreaker } from "../shared/helper/url";
import { fetchCSVTransaction } from "./api";
import { StatsContext } from "./StatsContext";

export const SalesStatsTable = () => {
  const { statsTransaction: stats } = useContext(StatsContext);
  const { professionList } = useContext(ProfessionContext);
  const sortedProfessionList = professionList.sort((a, b) =>
    a.name < b.name ? -1 : 1
  );

  const [year, setYear] = useState(actYear());

  const data = year in stats ? stats[year] : [];

  const { setError } = useContext(ErrorContext);

  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const onClick = () => {
    showProgress();
    fetchCSVTransaction(year)
      .then((res) => {
        if (res.success && res.data) {
          const url =
            import.meta.env.VITE_IMGURL +
            "/csv/" +
            res.data?.filename +
            "?" +
            cachebreaker();

          console.log("url:", url);
          window.location.href = url;
          openSnackbar("success", "CSV generiert");
        } else {
          setError(res.error);
        }
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h1">Verkaufsstatistik {year}</Typography>
        </Grid>
        <Grid item>
          <YearSelect id="year" value={year} setValue={setYear} />
        </Grid>
        <Grid item>
          <Button onClick={onClick} variant="contained">
            Download CSV
          </Button>
        </Grid>
      </Grid>
      {isEmpty(data) ? (
        <>Keine Daten vorhanden</>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Monat</TableCell>
                {sortedProfessionList.map((p) => (
                  <TableCell key={"prof" + p.id}>
                    {p.id} - {p.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(data).map(([month, data2]) => (
                <TableRow key={"M" + month}>
                  <TableCell>{month}</TableCell>

                  {sortedProfessionList.map((p) => (
                    <TableCell key={"sum" + p.id}>
                      {p.id in data2 ? data2[p.id] : 0}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

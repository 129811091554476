import { Box, Card, Grid, IconButton, Modal, Typography } from "@mui/material";
import { ShowButton } from "../../shared/ShowButton";
import { CandidateResultsT, QuestionResultsT } from "../api";
import { getMainIdx, getSubIdx } from "./ResultsLoader";
import { ResultsQuestion } from "./ResultsQuestion";
import { useNavigation } from "./useNavigation";
import { CloseModalButton } from "../../shared/modal/CloseModalButton";
import { Fragment, useEffect, useState } from "react";

interface ResultsSubPropsI {
  mainCategoryId: number;
  subCategoryId: number;
  setShowMainCategoryId: React.Dispatch<React.SetStateAction<number>>;
  setShowSubCategoryId: React.Dispatch<React.SetStateAction<number>>;
  results: CandidateResultsT;
}

export const ResultsQuestionList = ({
  mainCategoryId,
  subCategoryId,
  results,
  setShowMainCategoryId,
  setShowSubCategoryId,
}: ResultsSubPropsI) => {
  const { showQuestionId, setShowQuestionId } = useNavigation("question");

  const mainIdx = getMainIdx(results, mainCategoryId);
  const subs =
    mainIdx >= 0 ? results.mainCategories[mainIdx].subCategories : null;
  if (!subs) return <>Error rsl - s mainIdx:{mainIdx}</>;
  const subIdx = getSubIdx(subs, subCategoryId);
  const questions = subIdx >= 0 ? subs[subIdx].questions : null;
  if (!questions) return <>Error rsl - q subIdx:{subIdx}</>;

  console.log(
    "%cResultsQuestionList.tsx line:35 questions",
    "color: gold",
    questions
  );

  /* position of modal */
  const [xy, setXy] = useState({ x: 0, y: 0 });
  const onClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    i: number
  ) => {
    // get position of event
    setXy({ x: e.clientX, y: e.clientY });
    setShowQuestionId(i);
  };

  let index = 0;
  const filteredQuestions = questions;
  for (let i = 0; i < filteredQuestions.length; i++) {
    const q = filteredQuestions[i];
    if (q.evaluation === "single" || q.type === "klickmatrix") {
      q.indexInSubCategory = index + 1 + "-" + (index + q.countTotal);
      index += q.countTotal;
    } else {
      q.indexInSubCategory = ++index + "";
    }
  }

  return (
    <>
      <Grid item xs={12} className="results-questions-list">
        {filteredQuestions?.map((q, index) => (
          <Fragment key={"question" + q.id}>
            {showQuestionId === q.id && (
              <>
                <Modal
                  open={true}
                  onClose={() => setShowQuestionId(0)}
                  className="modal-backdrop"
                  sx={{ backgroundColor: "red", sx: { zIndex: 2 } }}
                >
                  <Box className="modal modal-result-question">
                    <ResultsQuestion
                      questionId={showQuestionId}
                      mainCategoryId={mainCategoryId}
                      subCategoryId={subCategoryId}
                      setShowQuestionId={setShowQuestionId}
                      setShowMainCategoryId={setShowMainCategoryId}
                      setShowSubCategoryId={setShowSubCategoryId}
                      results={results}
                    />
                    <CloseModalButton close={() => setShowQuestionId(0)} />
                  </Box>
                </Modal>

                <Box className="modal-result-question-container"></Box>
              </>
            )}
            <Typography
              key={"question" + q.id}
              variant="body2"
              component="div"
              onClick={(e) => onClick(e, q.id)}
              className={
                "question-button " +
                (q.countCorrect / q.countTotal >= 0.6 ? "correct" : "incorrect")
              }
            >
              {q.indexInSubCategory}
            </Typography>
          </Fragment>
        ))}
      </Grid>
    </>
  );
};

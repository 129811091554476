import { TextField } from "@mui/material";
import {
  buildErrorState,
  FormErrorStateT,
} from "../../shared/helper/formError";
import { QuestionT } from "../api";

interface EditorFormPropsI {
  type: keyof QuestionT;
  questionState: QuestionT;
  setQuestionState: React.Dispatch<React.SetStateAction<QuestionT>>;
  errorState: FormErrorStateT;
  setErrorState: React.Dispatch<React.SetStateAction<FormErrorStateT>>;
}

export const EditorForm = ({
  type,
  questionState,
  setQuestionState,
  errorState,
  setErrorState,
}: EditorFormPropsI) => {
  return (
    <TextField
      id={type}
      value={questionState[type] || ""}
      error={errorState[type] && errorState[type].error}
      helperText={
        errorState[type] && errorState[type].error
          ? errorState[type].helperText || "Bitte ausfüllen"
          : ""
      }
      onChange={(e) => {
        if (errorState[type] && errorState[type].error && e.target.value)
          setErrorState((p) => buildErrorState(p, type, false));

        setQuestionState({
          ...questionState,
          [type]: e.target.value,
        });
      }}
      multiline
      minRows={15}
      maxRows={25}
      sx={{ width: "100%" }}
    />
  );
};

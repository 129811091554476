import { clone } from "lodash";
import { ViewT, VIEW } from "./RelationTable";

interface ViewReducerPropsPayloadT {
  action: string;
  type?: VIEW;
  size?: number;
}

export const viewReducer = (
  prevState: ViewT,
  payload: ViewReducerPropsPayloadT
): ViewT => {
  const newView = clone(prevState);
  if (
    payload.action === "set" &&
    payload.type &&
    payload.size !== undefined &&
    payload.size >= 0
  ) {
    if (payload.size === 9) {
      Object.keys(VIEW).forEach((k) => (newView[k as VIEW] = 1));
    }
    newView[payload.type] = payload.size;
    if (payload.type !== VIEW.QUEST) {
      newView[VIEW.QUEST] =
        12 - newView[VIEW.PROF] - newView[VIEW.MAIN] - newView[VIEW.SUB];
    }
  }
  if (
    payload.action === "reset" &&
    payload.type &&
    payload.size !== undefined &&
    payload.size >= 0
  ) {
    newView[payload.type] = payload.size;
    newView[VIEW.QUEST] =
      12 - newView[VIEW.PROF] - newView[VIEW.MAIN] - newView[VIEW.SUB];
  }
  if (
    payload.action === "setAll" &&
    payload.size !== undefined &&
    payload.size >= 0
  ) {
    newView[VIEW.PROF] = payload.size;
    newView[VIEW.MAIN] = payload.size;
    newView[VIEW.SUB] = payload.size;
    newView[VIEW.QUEST] =
      12 - newView[VIEW.PROF] - newView[VIEW.MAIN] - newView[VIEW.SUB];
  }
  if (payload.action === "resetAll") {
    newView[VIEW.PROF] = 3;
    newView[VIEW.MAIN] = 3;
    newView[VIEW.SUB] = 3;
    newView[VIEW.QUEST] = 3;
  }
  console.log("newView:", newView);
  return newView;
};

import { get, post, del, CallApiResultT, put } from "../shared/lib/api";
import { isArray, isObject } from "lodash";
import { humanFileSize } from "../shared/helper/file";

interface FileDataT {
  id: number;
  name: string;
  size: number;
  type: string;
  updatedAt: string;
  filePath: string;
  countMainCategories: number;
  countProfessions: number;
}

export interface FileT extends Omit<FileDataT, "updatedAt" | "size"> {
  updatedAt: Date;
  size: string;
}

const data2file = (data: FileDataT): FileT => {
  if (!(data.id && data.name !== null)) {
    // console.log("data2file: invalid data");
    throw new Error("data2file: invalid data");
  }
  return {
    ...data,
    size: humanFileSize(data.size),
    updatedAt: new Date(data.updatedAt),
  };
};

const data2files = (data: FileDataT[]): FileT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2file(d));
};

export const file2data = (file: FileT): FileDataT => {
  return {
    ...file,
    updatedAt: "",
    size: 0,
  };
};

const files2data = (files: FileT[]): FileDataT[] => {
  return files.map((file) => file2data(file));
};

const APIPATH = "/file";

/** ************************************************************************
 *
 * @returns
 */
export const uploadFile = async (
  data: any
): Promise<CallApiResultT<FileT[]>> => {
  const res = await post<FileDataT[]>("/file-upload", data, {
    "Content-Type": "multipart/form-data",
  });

  console.log("uploadFile", res.data, res.errors);

  if (!(res.data && isObject(res.data))) {
    if (res.errors) {
      if ((res.errors as any).file) throw Error((res.errors as any).file);
      else throw Error("[uploadFile] " + JSON.stringify(res.errors));
    } else throw Error("[uploadFile] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2files(res.data as FileDataT[]),
  };
};

/** ************************************************************************
 *
 *
 */
export const fetchFiles = async (
  type: string
): Promise<CallApiResultT<FileT[]>> => {
  const res = await get(APIPATH, { type: type });

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchFiles] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2files(res.data),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteFile = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateFileBulk = async (
  files: FileT[]
): Promise<CallApiResultT<number>> => {
  console.log("updateFileBulk", files, files2data(files));

  const res = await put<number>(APIPATH, files2data(files));

  return res;
};

import { ConfirmDialog } from "../ConfirmDialog";
import { useContext, useState } from "react";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { TooltipIcon } from "./TooltipIcon";
import { CallApiResultT } from "../lib/api";
import { Box, Button, Modal, SxProps, Theme } from "@mui/material";
import { MailTemplateProvider } from "../../mailTemplate/MailTemplateContext";
import { MailTemplateLoader } from "../../mailTemplate/MailTemplateLoader";
import { CloseModalButton } from "../modal/CloseModalButton";
import { CandidateT } from "../../candidate/api";

interface MailButtonPropsI {
  id?: number;
  candidates?: CandidateT[];
  successFunc?: () => void;
  successMsg?: string;
  className?: string;
  textYes?: string;
  textNo?: string;
  sx?: SxProps<Theme>;
  button?: boolean;
}

export const MailButton = ({ candidates = [], ...props }: MailButtonPropsI) => {
  console.log(
    "%csrc/components/shared/forms/MailButton.tsx:34 candidates",
    "color: #007acc;",
    candidates
  );

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {Boolean(dialogOpen) && (
        <Modal
          open={true}
          onClose={() => setDialogOpen(false)}
          className="modal-backdrop"
        >
          <Box className="modal modal700">
            <Box sx={{ mr: 5 }}>
              <MailTemplateProvider>
                {candidates.length == 1 ? (
                  <MailTemplateLoader
                    type="candidate"
                    candidate={candidates[0]}
                    setDialogOpen={setDialogOpen}
                    onSend={props.successFunc}
                  />
                ) : (
                  <MailTemplateLoader
                    type="candidates"
                    candidates={candidates}
                    setDialogOpen={setDialogOpen}
                    onSend={props.successFunc}
                  />
                )}
              </MailTemplateProvider>
            </Box>
            <CloseModalButton close={() => setDialogOpen(false)} />
          </Box>
        </Modal>
      )}
      <Button
        variant="contained"
        onClick={(e) => setDialogOpen(true)}
        sx={props.sx}
        data-testid="MailButton"
      >
        Mail senden
      </Button>
    </>
  );
};

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ProfessionT } from "../../profession/api";
import { ProfessionCheckResult } from "../../profession/ProfessionCheckResult";

interface AdminProfessionWidgetI {
  professionList: ProfessionT[];
}

export const AdminProfessionWidget = ({
  professionList,
}: AdminProfessionWidgetI) => {
  const filteredProfessionList = professionList.filter(
    (p) => p.active && p.checkResult !== "ok"
  );

  if (filteredProfessionList.length === 0) return <></>;
  return (
    <Box sx={{ m: 2 }}>
      <Typography component="h2" color="error">
        Achtung, fehlerhafter aktiver Beruf
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Beruf</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProfessionList.map((profession) => (
              <TableRow key={profession.id}>
                <TableCell>{profession.name}</TableCell>
                <TableCell>
                  <ProfessionCheckResult
                    result={profession.checkResult || ""}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

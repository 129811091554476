import React, { useContext, useEffect, useRef, useState } from "react";

import {
  deleteStateFromArrayById,
  debouncedSetUpdatedState,
} from "../shared/helper/state";
import { deleteUser, UserT } from "../user/api";
import {
  AllUserEmailsT,
  debouncedSetAllUserEmails,
  UserChangedT,
  UserTLen,
} from "../user/UserContext";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { EditableCell } from "../shared/list/InsertCell";
import { DeleteButton } from "../shared/forms/DeleteButton";
import { GenderSelect } from "../shared/forms/GenderSelect";
import { CountrySelect } from "../shared/forms/CountrySelect";

import Grid from "@mui/material/Grid";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { Active } from "../shared/forms/Active";
import { checkChanged } from "../shared/helper/object";
import { Transactions } from "./Transactions";
import { ChangePassword } from "./ChangePassword";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { NonOptionalKeys } from "../shared/table/EnhancedTable";
import { Box, Checkbox, Modal, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MailTemplateProvider } from "../mailTemplate/MailTemplateContext";
import { MailTemplateLoader } from "../mailTemplate/MailTemplateLoader";
import { CloseModalButton } from "../shared/modal/CloseModalButton";
import { EditCustomer } from "./EditCustomer";
import { CountryContext } from "../country/CountryContext";
import { date_hr } from "../shared/helper/datetime";
import { TestAccountButton } from "./TestAccountButton";

interface CustomerPropsI {
  user: UserT;
  setShowSave: (showSave: boolean) => void;
  onClickPassword: (i: number) => void;
  onClickSetActive: (
    b: boolean,
    userState: UserChangedT,
    setUserState: React.Dispatch<React.SetStateAction<UserChangedT>>
  ) => void;
  onClickSetTestAccount: (
    b: boolean,
    userState: UserChangedT,
    setUserState: React.Dispatch<React.SetStateAction<UserChangedT>>
  ) => void;
  setChangedUserList: React.Dispatch<React.SetStateAction<UserT[]>>;
  onClickUserLogin: (userState: UserChangedT) => void;
  allUserEmails: AllUserEmailsT[];
  setAllUserEmails: React.Dispatch<React.SetStateAction<AllUserEmailsT[]>>;
  setUserList: React.Dispatch<React.SetStateAction<UserT[]>>;
  cellIsHidden: (i: NonOptionalKeys<UserT>) => boolean;
}

export const Customer = React.memo(
  ({
    user,
    setShowSave,
    onClickPassword,
    onClickSetActive,
    onClickSetTestAccount,
    setChangedUserList,
    onClickUserLogin,
    setAllUserEmails,
    setUserList,
    cellIsHidden,
  }: CustomerPropsI) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [showEditCustomer, setShowEditCustomer] = useState(false);
    const { countryList } = useContext(CountryContext);

    const [userState, setUserState] = useState<UserChangedT>(user);

    const [dialogSendPasswordOpen, setDialogSendPasswordOpen] = useState(false);
    const [dialogSendWelcomeOpen, setDialogSendWelcomeOpen] = useState(false);
    const [showShippingAddress, setShowShippingAddress] = useState(false);

    useEffect(() => {
      setShowSave(hasChanged);
    }, [setShowSave, hasChanged]);

    useEffect(() => {
      checkChanged(user, userState, hasChanged, setHasChanged, ["mailLogs"]);
    }, [setHasChanged, hasChanged, user, userState]);

    const firstUpdate = useRef(true);
    useEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      debouncedSetUpdatedState(userState, setChangedUserList);
    }, [userState, setChangedUserList]);

    const firstUpdate2 = useRef(true);
    useEffect(() => {
      if (firstUpdate2.current) {
        firstUpdate2.current = false;
        return;
      }
      debouncedSetAllUserEmails(
        userState.id,
        userState.email,
        setAllUserEmails
      );
    }, [firstUpdate2, userState.id, userState.email, setAllUserEmails]);

    return (
      <>
        <TableRow
          hover
          tabIndex={-1}
          key={user.id}
          selected={hasChanged}
          data-testid={user.email}
        >
          {!cellIsHidden("id") && (
            <TableCell padding="none" align="left">
              <Typography variant="body2">{user.id}</Typography>
            </TableCell>
          )}
          {!cellIsHidden("testAccount") && (
            <TableCell padding="none" align="left">
              <TestAccountButton
                testAccount={user.testAccount}
                setFunc={(testAccount: boolean) =>
                  onClickSetTestAccount(testAccount, userState, setUserState)
                }
              />
            </TableCell>
          )}
          {!cellIsHidden("firstname" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.firstname}&nbsp;
              </Box>
            </TableCell>
          )}
          {!cellIsHidden("lastname" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.lastname}&nbsp;
              </Box>
            </TableCell>
          )}
          {!cellIsHidden("email" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.email}&nbsp;
              </Box>
            </TableCell>
          )}
          {!cellIsHidden("emailInvoice" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.emailInvoice}&nbsp;
              </Box>
            </TableCell>
          )}
          {!cellIsHidden("gender" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <GenderSelect
                id="gender"
                value={userState.gender}
                setValue={(v: string) =>
                  setUserState((prevState) => {
                    return { ...prevState, gender: v };
                  })
                }
              />
            </TableCell>
          )}
          <TableCell>
            <Box display="flex">
              <input
                type="checkbox"
                value="1"
                checked={Boolean(userState.shippingAddress)}
                onChange={(e) =>
                  setUserState((p) => ({
                    ...p,
                    shippingAddress: e.target.checked,
                  }))
                }
              />
              {userState.shippingAddress ? (
                <Box
                  className="drop-arrow"
                  onClick={() => setShowShippingAddress((p) => !p)}
                  sx={{ pt: 1 }}
                >
                  {showShippingAddress ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowRightIcon />
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </TableCell>
          {!cellIsHidden("company" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.company}&nbsp;
              </Box>
            </TableCell>
          )}
          <TableCell>
            <Box onClick={() => setShowEditCustomer(true)}>
              {userState.street}&nbsp;{userState.streetno}
              <br />
              {userState.zipcode}&nbsp;
              {userState.city}
              <br />
              {countryList.find((c) => c.id === userState.countryId)?.name}
            </Box>
          </TableCell>
          {!cellIsHidden("phone" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.phone}&nbsp;
              </Box>
            </TableCell>
          )}{" "}
          {!cellIsHidden("fax" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.fax}&nbsp;
              </Box>
            </TableCell>
          )}
          <TableCell>
            <Typography variant="body2">
              {user.createdAt && date_hr(user.createdAt)}
            </Typography>
          </TableCell>
          <TableCell>
            <Checkbox
              id="newsletter"
              checked={userState.newsletter ? true : false}
              onChange={(e) =>
                setUserState((prevState) => {
                  return { ...prevState, newsletter: e.target.checked };
                })
              }
              hidden={cellIsHidden("newsletter" as NonOptionalKeys<UserT>)}
            />
          </TableCell>
          {!cellIsHidden("candidateCount" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Typography variant="body2">{user.candidateCount}</Typography>
            </TableCell>
          )}
          {!cellIsHidden("candidateResultCount" as NonOptionalKeys<UserT>) && (
            <TableCell>
              <Typography variant="body2">
                {user.candidateResultCount}
              </Typography>
            </TableCell>
          )}
          <TableCell padding="none" align="center">
            <Grid
              container
              spacing={0}
              justifyContent="space-between"
              sx={{ width: 200 }}
            >
              <Grid item xs={6}>
                <Active
                  active={userState.active ? true : false}
                  setActive={() =>
                    onClickSetActive(
                      userState.active ? false : true,
                      userState,
                      setUserState
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Transactions
                  userId={user.id}
                  professionIds={user.professionIds || []}
                />
              </Grid>
              <Grid item xs={3}>
                <span data-testid={"deleteContainer" + user.email}>
                  <DeleteButton
                    id={user.id}
                    deleteFunc={deleteUser}
                    {...((user.candidateResultCount || 0) > 0
                      ? {
                          disabled: true,
                          tooltipTitle:
                            "Der Kunde hat bereit aktive Kandidaten",
                        }
                      : {})}
                    successFunc={() => {
                      setUserList((p) => deleteStateFromArrayById(p, user.id));
                      setAllUserEmails((p) =>
                        p.filter((u) => u.email !== user.email)
                      );
                    }}
                  />
                </span>
              </Grid>
              <Grid item xs={3}>
                <ChangePassword userId={user.id} />
              </Grid>
              <Grid item xs={3}>
                <TooltipIcon
                  type="sendPassword"
                  title="Passwort zurücksetzen"
                  onClick={(e) => setDialogSendPasswordOpen(true)}
                />
                <ConfirmDialog
                  open={dialogSendPasswordOpen}
                  handleYes={() => {
                    onClickPassword(user.id);
                    setDialogSendPasswordOpen(false);
                  }}
                  handleNo={() => setDialogSendPasswordOpen(false)}
                  title={"Passwort zurücksetzen"}
                  content="Soll die Passswort-Mail versendet werden?"
                />
              </Grid>
              <Grid item xs={3}>
                <TooltipIcon
                  type="sendWelcome"
                  title="Begrüssungs-Mail"
                  onClick={(e) => setDialogSendWelcomeOpen(true)}
                  sx={{
                    color: userState.mailLogs?.find(
                      (m) => m.mailTemplateId === 2
                    )
                      ? "green"
                      : "black",
                  }}
                  key={userState.id + "" + userState.mailLogs?.length}
                />
                {Boolean(dialogSendWelcomeOpen) && (
                  <Modal
                    open={true}
                    onClose={() => setDialogSendWelcomeOpen(false)}
                    className="modal-backdrop"
                  >
                    <Box className="modal modal700">
                      <Box sx={{ mr: 5 }}>
                        <MailTemplateProvider>
                          <MailTemplateLoader
                            type="customer"
                            customer={user}
                            setDialogOpen={setDialogSendWelcomeOpen}
                            onSend={(mailTemplateStateId: boolean | number) => {
                              if (typeof mailTemplateStateId === "boolean") {
                                // damnit typescript!
                                return;
                              }
                              setUserState((prevState) => ({
                                ...prevState,
                                mailLogs: prevState.mailLogs
                                  ? prevState.mailLogs.concat([
                                      {
                                        userId: user.id,
                                        mailTemplateId: mailTemplateStateId,
                                        createdAt: new Date(),
                                      },
                                    ])
                                  : [
                                      {
                                        userId: user.id,
                                        mailTemplateId: mailTemplateStateId,
                                        createdAt: new Date(),
                                      },
                                    ],
                              }));

                              console.log(
                                "%cCustomer.tsx line:375 mailLogs",
                                "color: #007acc;",
                                user.mailLogs
                              );
                            }}
                          />
                        </MailTemplateProvider>
                      </Box>
                      <CloseModalButton
                        close={() => setDialogSendWelcomeOpen(false)}
                      />
                    </Box>
                  </Modal>
                )}
              </Grid>
              <Grid item xs={3}>
                <TooltipIcon
                  type="login"
                  title="Als Kunde einloggen"
                  onClick={() => onClickUserLogin(user)}
                />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        {showShippingAddress && (
          <TableRow>
            <TableCell></TableCell>
            {!cellIsHidden("shippingFirstname" as NonOptionalKeys<UserT>) && (
              <TableCell>
                <Box onClick={() => setShowEditCustomer(true)}>
                  {userState.shippingFirstname}&nbsp;
                </Box>
              </TableCell>
            )}
            {!cellIsHidden("shippingLastname" as NonOptionalKeys<UserT>) && (
              <TableCell>
                <Box onClick={() => setShowEditCustomer(true)}>
                  {userState.shippingLastname}&nbsp;
                </Box>
              </TableCell>
            )}
            <TableCell></TableCell>
            {!cellIsHidden("shippingGender" as NonOptionalKeys<UserT>) && (
              <TableCell>
                <GenderSelect
                  id="shippingGender"
                  value={userState.shippingGender}
                  setValue={(v: string) =>
                    setUserState((prevState) => {
                      return { ...prevState, shippingGender: v };
                    })
                  }
                />
              </TableCell>
            )}
            <TableCell>&nbsp;</TableCell>

            {!cellIsHidden("shippingCompany" as NonOptionalKeys<UserT>) && (
              <TableCell>
                <Box onClick={() => setShowEditCustomer(true)}>
                  {userState.shippingCompany}&nbsp;
                </Box>
              </TableCell>
            )}

            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.shippingStreet}&nbsp;
                {userState.shippingStreetno}
                <br />
                {userState.shippingZipcode}
                &nbsp;
                {userState.shippingCity}
                <br />
                {
                  countryList.find((c) => c.id === userState.shippingCountryId)
                    ?.name
                }
              </Box>
            </TableCell>
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.shippingPhone}
              </Box>
            </TableCell>
            <TableCell>
              <Box onClick={() => setShowEditCustomer(true)}>
                {userState.shippingFax}
              </Box>
            </TableCell>
          </TableRow>
        )}

        {showEditCustomer && (
          <EditCustomer
            user={user}
            open={showEditCustomer}
            setOpen={setShowEditCustomer}
            setUserState={setUserState}
          />
        )}
      </>
    );
  }
);

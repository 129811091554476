import Button from "@mui/material/Button";

import { FileT, deleteFile, uploadFile } from "../file/api";
import { useCallback, useContext, useEffect, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { humanFileSize } from "../shared/helper/file";
import { uniqBy } from "lodash";
import { DeleteButton } from "../shared/forms/DeleteButton";

interface FileUploadPropsI {
  type: string;
  setFileList: React.Dispatch<React.SetStateAction<FileT[]>>;
  fileList?: FileT[];
  maxNoFiles?: number;
  onUpload?: (files: FileT[]) => void;
}

const filesize = parseInt(import.meta.env.VITE_FILESIZE || "4");

export const CustomerFileUpload = ({
  type,
  setFileList,
  fileList = [],
  maxNoFiles = 200,
  onUpload,
}: FileUploadPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    onClickUpload();
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newfiles = acceptedFiles.filter((f: File) => {
        if (f.size > filesize * 1024000) {
          openSnackbar(
            "info",
            "Die Dateigröße darf maximal " + filesize + " MB betragen"
          );
          return false;
        }
        return true;
      });

      setFiles(newfiles);
    },
    [openSnackbar, fileList]
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, multiple: maxNoFiles === 1 ? false : true });

  const onClickUpload = (checkDoubles = 0) => {
    if (files.length === 0) return;

    const formData = new FormData();
    const tmpFiles =
      checkDoubles === 2
        ? files.filter(
            (f: File) => !fileList.some((f2: FileT) => f2.name === f.name)
          )
        : files;
    setFiles(tmpFiles);
    if (tmpFiles.length === 0) return;
    showProgress();

    formData.append("type", type);
    for (let i = 0; i < tmpFiles.length; i++) {
      formData.append("files[]", tmpFiles[i]);
    }
    uploadFile(formData)
      .then((result) => {
        if (result && result.data) {
          setFileList((p) => uniqBy(p.concat(result.data || []), "name"));

          openSnackbar("success", result.data.length + " Dateien hochgeladen ");
          setFiles([]);
          onUpload && onUpload(result.data);
        } else setError(result.error);
      })
      .catch((error) => {
        console.log("uploadFile catch", error);
        setError(error);
      });
  };

  let totalSize = 0;
  let countFiles = 0;
  files.forEach((file, idx) => {
    totalSize += file.size;
    countFiles++;
  });
  if (countFiles > maxNoFiles) {
    openSnackbar("warning", "max. " + maxNoFiles + " Dateien!");
    setFiles(files.slice(0, 200));
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box {...getRootProps()}>
        <Button
          sx={{
            // background: isDragActive ? "darkgrey" : "#f5f5f5",
            border: "1px solid gray",
            height: "30px",
            verticalAlign: "middle",
            color: "gray",
            width: 150,
            p: "2px",
            fontSize: "0.8rem",
          }}
        >
          <input {...getInputProps()} />
          {/* <Typography variant="body2" sx={{ display: "inline" }}> */}
          {isDragActive ? "Datei hier Droppen" : "Datei wählen"}
          {/* </Typography> */}
        </Button>
      </Box>
      <Box>
        {files.length ? (
          files.map((file, idx) => {
            return (
              <Typography variant="body2" key={"file" + idx}>
                {file.name} - {humanFileSize(file.size)}{" "}
              </Typography>
            );
          })
        ) : (
          <Typography variant="body2" component="div">
            {" "}
            {fileList && fileList.length > 0 && fileList[0].name ? (
              <>
                {fileList[0].name}
                <DeleteButton
                  id={fileList[0].id}
                  deleteFunc={deleteFile}
                  successFunc={() => setFileList([])}
                  sx={{ fontSize: 20, position: "relative", top: 4 }}
                  confirmContent="Soll das Logo wirklich entfernt werden?"
                />
              </>
            ) : (
              "Keine Datei ausgewählt"
            )}
          </Typography>
        )}
        <input {...getInputProps()} />
      </Box>
    </Box>
  );
};

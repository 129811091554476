import { Box, Modal } from "@mui/material";
import { ProfessionProvider } from "../profession/ProfessionContext";
import { CloseModalButton } from "../shared/modal/CloseModalButton";
import { CustomerResultsLoader } from "./CustomerResultsLoader";
import { removeNavigation } from "../candidate/results/useNavigation";

interface CustomerResultsModalPropsI {
  setShowResults: (value: React.SetStateAction<number>) => void;
  professionId: number;
}

export const CustomerResultsModal = ({
  setShowResults,
  professionId,
}: CustomerResultsModalPropsI) => {
  const closeModal = () => {
    removeNavigation();
    setShowResults(0);
  };

  return (
    <Modal open={true} onClose={closeModal} className="modal-backdrop">
      <Box className="modal modal-full modal-a4">
        <CustomerResultsLoader professionId={professionId} />
        <CloseModalButton close={closeModal} />
      </Box>
    </Modal>
  );
};

import { Key } from "react";
import * as Sentry from "@sentry/react";

export const checkApiResult = (
  ident: string,
  data: any,
  params: { [k: string | number]: string }
) => {
  Sentry.setContext("data", { decoded: data, encoded: JSON.stringify(data) });
  Object.entries(params).forEach(([key, type]) =>
    _checkApiResult(ident, data, key, type)
  );
};

export const _checkType = (variable: any, type: string) =>
  (type === "array" && Array.isArray(variable)) ||
  (type !== "array" && typeof variable === type);

export const _checkTypes = (variable: any, type: string | string[]) => {
  if (Array.isArray(type)) {
    return type.filter((t) => _checkType(variable, t)).length > 0;
  }
  return _checkType(variable, type);
};

export const _checkApiResult = (
  ident: string,
  data: any,
  key: string | number,
  type: string | string[]
) => {
  //console.log("_checkApiResult", data, key, type, ident);
  let e = null;
  if (data[key] === undefined) {
    e = new Error(ident + " / mising " + key);
  } else if (!_checkTypes(data[key], type)) {
    Sentry.setContext("checker", {
      desired: type,
      received: typeof data[key],
    });
    console.error({
      desired: type,
      received: typeof data[key],
    });
    e = new Error(ident + " / wrong type for " + key);
  }

  if (e) {
    console.error(e);
    Sentry.captureException(e);
    throw e;
  }
};

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export type ProfessionTypeT = "basic" | "premium" | "root" | "test";

export const TRANSLATE_TYPE: Record<ProfessionTypeT, string> = {
  basic: "Basis",
  premium: "Premium",
  root: "Stamm",
  test: "Testberuf",
};

interface TypeSelectPropI {
  id: string;
  value?: string;
  setValue: (value: string) => void;
  label?: string;
  labelId?: string;
  showAll?: boolean;
  size?: "small" | "medium";
  radio?: boolean;
}

export const TypeSelect = (props: TypeSelectPropI) => {
  return props.radio ? (
    <RadioGroup
      aria-label="type"
      name="type"
      value={props.value ? props.value : ""}
      onChange={(e: SelectChangeEvent<string>) => {
        props.setValue(e.target.value);
      }}
      row
    >
      {props.showAll && (
        <FormControlLabel
          value="all"
          control={
            <Radio
              sx={{
                ml: 0,
                pr: 0,
                pl: 0,
                position: "relative",
                bottom: 1,
              }}
            />
          }
          label="Alle"
        />
      )}
      <FormControlLabel
        value="basic"
        control={
          <Radio
            sx={{
              ml: 0,
              pr: 0,
              pl: 0,
              position: "relative",
              bottom: 1,
            }}
          />
        }
        label="Basis"
      />
      <FormControlLabel
        value="premium"
        control={
          <Radio
            sx={{
              ml: 0,
              pr: 0,
              pl: 0,
              position: "relative",
              bottom: 1,
            }}
          />
        }
        label="Premium"
      />
      <FormControlLabel
        value="root"
        control={
          <Radio
            sx={{
              ml: 0,
              pr: 0,
              pl: 0,
              position: "relative",
              bottom: 1,
            }}
          />
        }
        label="Stamm"
      />
      <FormControlLabel
        value="test"
        control={
          <Radio
            sx={{
              ml: 0,
              pr: 0,
              pl: 0,
              mr: 0,
              position: "relative",
              bottom: 1,
            }}
          />
        }
        label="Testberuf"
        sx={{ mr: 0 }}
      />
    </RadioGroup>
  ) : (
    <Select
      labelId={props.labelId}
      id={props.id}
      value={props.value ? props.value : ""}
      onChange={(e: SelectChangeEvent<string>) => {
        props.setValue(e.target.value);
      }}
      label={props.label}
      size={props.size ? props.size : "medium"}
      sx={{ fontSize: (theme) => theme.typography.body2.fontSize }}
    >
      {props.showAll && <MenuItem value="all">Alle</MenuItem>}
      <MenuItem value="basic">Basis</MenuItem>
      <MenuItem value="premium">Premium</MenuItem>
      <MenuItem value="root">Stamm</MenuItem>
      <MenuItem value="test">Testberuf</MenuItem>
    </Select>
  );
};

export default TypeSelect;

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { CandidateChangedT, CandidateContext } from "./CandidateContext";
import { SetCandidateProfession } from "./SetCandidateProfession";
import { ProfessionContext } from "../profession/ProfessionContext";
import {
  debouncedSetUpdatedState,
  updateStateArrayById,
} from "../shared/helper/state";
import { useContext, useEffect, useRef, useState } from "react";
import { CandidateLink } from "./CandidateLink";
import { EditableCell } from "../shared/list/InsertCell";
import { checkChanged } from "../shared/helper/object";
import { date_hr } from "../shared/helper/datetime";
import { Box, Checkbox, Modal } from "@mui/material";
import { CloseModalButton } from "../shared/modal/CloseModalButton";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { EditableEntry } from "../shared/list/EditableEntry";
import { Tooltip } from "../shared/forms/Tooltip";
import { MailTemplateLoader } from "../mailTemplate/MailTemplateLoader";
import { MailTemplateProvider } from "../mailTemplate/MailTemplateContext";
import { CandidateResultsModal } from "./CandidateResultsModal";
import { CandidateT } from "./api";
import { CandidateDisabilityModal } from "./CandidateDisabilityModal";

interface CandidatePropsI {
  candidate: CandidateT;
  setShowSave: (showSave: boolean) => void;
  showProfession?: boolean;
  addToDelete: (i: number) => void;
  addToMail: (i: number) => void;
  addToProfession: (i: number) => void;
  forceRerender?: number;
  disability?: boolean;
}
/* eslint-disable no-extra-boolean-cast */
const isEmpty = (x: any) => !Boolean(x);

export const CandidateEntry = ({
  candidate,
  setShowSave,
  showProfession,
  addToDelete,
  addToMail,
  addToProfession,
  forceRerender,
  disability,
}: CandidatePropsI) => {
  const {
    setCandidateList,
    setChangedCandidateList,
    candidatesToDelete,
    candidatesToMail,
    candidatesToProfession,
  } = useContext(CandidateContext);

  const toDelete = candidatesToDelete.includes(candidate.id);
  const toMail = candidatesToMail.includes(candidate.id);
  const toProfession = candidatesToProfession.includes(candidate.id);

  const [openProfession, setOpenProfession] = useState(false);
  const [dialogSendPasswordOpen, setDialogSendPasswordOpen] = useState(false);
  const { professionList } = useContext(ProfessionContext);
  const [hasChanged, setHasChanged] = useState(false);

  const [candidateState, setCandidateState] =
    useState<CandidateChangedT>(candidate);

  useEffect(() => {
    setCandidateState(candidate);
  }, [forceRerender]);

  const [showResults, setShowResults] = useState(0);
  const [showDisability, setShowDisability] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(
      {
        ...candidate,
        professionId: null,
        professionName: null,
        results: null,
        mailLogs: null,
        resultsPercent: null,
        resultsMain: null,
        professionExpiredAt: null,
        candidateSettings: null,
      },
      {
        ...candidateState,
        professionId: null,
        professionName: null,
        results: null,
        mailLogs: null,
        resultsPercent: null,
        resultsMain: null,
        professionExpiredAt: null,
        candidateSettings: null,
      },
      hasChanged,
      setHasChanged
    );
  }, [setHasChanged, hasChanged, candidate, candidateState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(candidateState, setChangedCandidateList);
  }, [candidateState, setChangedCandidateList]);

  const onSend = (addComment: number) => {
    if (addComment) {
      candidate.comment = "Einladung verschickt. " + (candidate.comment || "");
      setCandidateList((p) =>
        updateStateArrayById<CandidateT>(p, {
          ...candidate,
          comment: candidate.comment,
        })
      );
      setCandidateState(candidate);
    }
  };

  const onChangeProfession = (newProfession: number) => {
    setCandidateList((p) =>
      updateStateArrayById<CandidateT>(p, {
        ...candidate,
        professionId: newProfession,
      })
    );
  };

  const SeperatorBox = ({ pos }: { pos: number }) => (
    <Box
      className={
        "seperator-box" +
        ((pos === 1 && (candidateState.streetno || candidateState.zipcode)) ||
        (pos === 2 && (candidateState.city || candidateState.phone)) ||
        (pos === 3 && (candidateState.phone || candidateState.country)) ||
        (pos === 4 &&
          (candidateState.country || candidateState.schoolGraduation))
          ? ""
          : "candidate-placeholder")
      }
    >
      &nbsp;|&nbsp;
    </Box>
  );

  const checkDelete = () => candidatesToDelete.length > 0;

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={candidate.id}
        data-testid={candidate.email}
        selected={hasChanged}
        className={showDetails ? "table-row-details" : ""}
      >
        <TableCell component="th" scope="row" padding="normal">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            className="candidate-name-arrow"
            sx={{ paddingTop: "0px", marginTop: "5px !important" }}
          >
            <Box
              className="drop-arrow"
              onClick={() => setShowDetails((p) => !p)}
              sx={{ position: "relative", top: "1px" }}
            >
              {showDetails ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </Box>
            <EditableEntry
              id="lastname"
              isObligatory={true}
              label="Nachname"
              value={candidateState.lastname}
              setValue={(v) =>
                setCandidateState((prevState) => {
                  return { ...prevState, lastname: v as string };
                })
              }
              disabled={checkDelete()}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              height: showDetails ? "22px" : "0px",
            }}
          >
            {/* ***** Details from Candidate on click on arrow ***** */}
            {showDetails && (
              <Box
                className="details"
                display="flex"
                sx={{
                  pt: 0,
                  position: "relative",
                  top: "-9px",
                  pb: 0,
                  pl: "24px",
                  // border: "1px solid green",
                  marginRight: "-1000px", // avoid influence on td width
                }}
              >
                <EditableEntry
                  id="street"
                  label="Straße"
                  value={candidateState.street || ""}
                  setValue={(v) =>
                    setCandidateState((p) => ({
                      ...p,
                      street: v as string,
                    }))
                  }
                  placeHolder="Straße"
                  placeHolderClassName="candidate-placeholder"
                  disabled={checkDelete()}
                />
                &nbsp;
                <EditableEntry
                  id="streetno"
                  label="Hausnummer"
                  value={candidateState.streetno || ""}
                  setValue={(v) =>
                    setCandidateState((p) => ({
                      ...p,
                      streetno: v as string,
                    }))
                  }
                  placeHolder="Hausnummer"
                  placeHolderClassName="candidate-placeholder"
                  disabled={checkDelete()}
                />
                <SeperatorBox pos={1} />
                <EditableEntry
                  id="zipcode"
                  label="PLZ"
                  value={candidateState.zipcode || ""}
                  setValue={(v) =>
                    setCandidateState((p) => ({
                      ...p,
                      zipcode: v as string,
                    }))
                  }
                  placeHolder="PLZ"
                  placeHolderClassName="candidate-placeholder"
                  disabled={checkDelete()}
                />
                &nbsp;
                <EditableEntry
                  id="city"
                  label="Ort"
                  value={candidateState.city || ""}
                  setValue={(v) =>
                    setCandidateState((p) => ({ ...p, city: v as string }))
                  }
                  placeHolder="Ort"
                  placeHolderClassName="candidate-placeholder"
                  disabled={checkDelete()}
                />
                <SeperatorBox pos={2} />
                <EditableEntry
                  id="phone"
                  label="Telefon"
                  value={candidateState.phone || ""}
                  setValue={(v) =>
                    setCandidateState((p) => ({ ...p, phone: v as string }))
                  }
                  placeHolder="Telefon"
                  placeHolderClassName="candidate-placeholder"
                  disabled={checkDelete()}
                />
                <SeperatorBox pos={3} />
                <EditableEntry
                  id="nationality"
                  label="Staatsangehörigkeit"
                  type="nationality"
                  value={candidateState.nationality || ""}
                  setValue={(v) =>
                    setCandidateState((p) => ({
                      ...p,
                      nationality: v as string,
                    }))
                  }
                  placeHolder="Staatsangehörigkeit"
                  placeHolderClassName="candidate-placeholder"
                  disabled={checkDelete()}
                />
                <SeperatorBox pos={4} />
                <EditableEntry
                  id="schoolGraduation"
                  label="Schulabschluss"
                  value={candidateState.schoolGraduation || ""}
                  type="schoolGraduation"
                  setValue={(v) =>
                    setCandidateState((p) => ({
                      ...p,
                      schoolGraduation: v as string,
                    }))
                  }
                  placeHolder="Schulabschluss"
                  placeHolderClassName="candidate-placeholder"
                  disabled={checkDelete()}
                />
              </Box>
            )}
          </Box>
        </TableCell>
        <EditableCell
          id="firstname"
          isObligatory={true}
          label="Vorname"
          stateObject={candidateState}
          setStateObject={setCandidateState}
          disabled={checkDelete()}
          dataTestId="CandidateFirstname"
        />
        <EditableCell
          id="dateBirth"
          type="date"
          isObligatory={true}
          label="Geburtsdatum"
          stateObject={candidateState}
          setStateObject={setCandidateState}
          disabled={checkDelete()}
        />
        <EditableCell
          id="gender"
          type="gender"
          isObligatory={true}
          label="Anrede"
          stateObject={candidateState}
          setStateObject={setCandidateState}
          disabled={checkDelete()}
        />
        <EditableCell
          id="email"
          isObligatory={true}
          label="E-Mail"
          stateObject={candidateState}
          setStateObject={setCandidateState}
          disabled={checkDelete()}
        />
        {disability && (
          <TableCell component="th" scope="row" padding="normal">
            <TooltipIcon
              type="settings"
              onClick={() =>
                candidate.testPending || candidate.testDate
                  ? {}
                  : setShowDisability(candidate.id)
              }
              sx={{
                color:
                  candidate.testPending || candidate.testDate
                    ? candidate.candidateSettings &&
                      (candidate.candidateSettings.compensation_percent ||
                        candidate.candidateSettings.compensation_pause)
                      ? "bluescale.light"
                      : "grayscale.light"
                    : candidate.candidateSettings
                    ? "bluescale.main"
                    : "black",
              }}
              title={
                candidate.testPending
                  ? "Nachteilsausgleich einstellen nicht möglich - Test läuft"
                  : candidate.testDate
                  ? "Nachteilsausgleich einstellen nicht möglich - Test beendet"
                  : "Nachteilsausgleich einstellen"
              }
            />
          </TableCell>
        )}
        <EditableCell
          id="comment"
          isObligatory={true}
          label="Bemerkungen"
          stateObject={candidateState}
          setStateObject={setCandidateState}
          disabled={checkDelete()}
        />
        <TableCell component="th" scope="row" padding="normal">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: "-10px",
            }}
          >
            <TooltipIcon
              type="mail"
              onClick={() =>
                setDialogSendPasswordOpen(candidate.professionId > 0)
              }
              title={
                candidate.professionId > 0
                  ? "Mail mit Link verschicken"
                  : "Mail verschicken nicht möglich - kein Test ausgewählt"
              }
              iconProps={{
                ...(candidate.professionId > 0
                  ? { "data-testid": "CandidateSendMail" }
                  : {
                      color: "disabled",
                      "data-testid": "CandidateSendMailDisabled",
                    }),
              }}
            />
            {Boolean(dialogSendPasswordOpen) && (
              <Modal
                open={true}
                onClose={() => setDialogSendPasswordOpen(false)}
                className="modal-backdrop"
              >
                <Box className="modal modal700">
                  <Box sx={{ mr: 5 }}>
                    <MailTemplateProvider>
                      <MailTemplateLoader
                        type="candidate"
                        candidate={candidate}
                        setDialogOpen={setDialogSendPasswordOpen}
                        onSend={onSend}
                      />
                    </MailTemplateProvider>
                  </Box>
                  <CloseModalButton
                    close={() => setDialogSendPasswordOpen(false)}
                  />
                </Box>
              </Modal>
            )}
            <Checkbox
              className="checkbox-mail"
              checked={toMail}
              onChange={() => addToMail(candidate.id)}
            />
          </Box>
        </TableCell>
        {showProfession && (
          <TableCell component="th" scope="row" padding="normal">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              {!isEmpty(candidate.results) || candidate.testPending ? (
                <>
                  <Box>
                    {candidate.professionId > 0
                      ? professionList.find(
                          (p) => candidate && p.id === candidate?.professionId
                        )?.name +
                        " | " +
                        professionList.find(
                          (p) => candidate && p.id === candidate?.professionId
                        )?.testId
                      : "kein Test zugeordnet"}
                  </Box>
                </>
              ) : (
                <>
                  <Box onClick={() => setOpenProfession(true)}>
                    {candidate.professionId > 0
                      ? professionList.find(
                          (p) => candidate && p.id == candidate?.professionId // double ==, might be string
                        )?.name
                      : "kein Test zugeordnet"}
                  </Box>
                  <Box sx={{ ml: 1, position: "relative", top: "0px" }}>
                    <TooltipIcon
                      type="profession"
                      onClick={() =>
                        professionList.length > 0 && setOpenProfession(true)
                      }
                      title={
                        professionList.length > 0
                          ? "Test zuordnen"
                          : "Test zuordnen nicht möglich - keine Lizenzen vorhanden"
                      }
                      iconProps={{
                        "data-testid": "CandidateEditProfession",
                        ...(professionList.length > 0
                          ? {}
                          : {
                              color: "disabled",
                            }),
                      }}
                    />
                  </Box>
                </>
              )}
              {(!candidate.professionId ||
                (candidate.professionId > 0 &&
                  !candidate.testPending &&
                  !candidate.testDate)) && (
                <Box sx={{ position: "relative", top: "-10px" }}>
                  <Checkbox
                    className="checkbox-profession"
                    checked={toProfession}
                    onChange={() => addToProfession(candidate.id)}
                  />
                </Box>
              )}
            </Box>
            <SetCandidateProfession
              candidates={[candidate]}
              setCandidateList={setCandidateList}
              setCandidateState={setCandidateState}
              onChange={onChangeProfession}
              open={openProfession}
              setOpen={setOpenProfession}
            />
          </TableCell>
        )}
        <TableCell component="th" scope="row" padding="normal">
          <Box>
            {candidate.professionId > 0 && candidate.results ? (
              <Tooltip title="Auswertung anzeigen">
                <a onClick={() => setShowResults(candidate.id || 0)}>
                  {candidate.results.sumPoints > 0
                    ? (
                        (candidate.results.sumPointsCorrect /
                          candidate.results.sumPoints) *
                        100
                      ).toFixed(0)
                    : 0.0}{" "}
                  %
                </a>
              </Tooltip>
            ) : candidate.testPending ? (
              <Box>Test läuft</Box>
            ) : (
              <></>
            )}
          </Box>
        </TableCell>
        <TableCell component="th" scope="row" padding="normal">
          <Box>
            {candidate.professionId > 0 && candidate.testDate ? (
              <>{date_hr(candidate.testDate)}</>
            ) : (
              <></>
            )}
          </Box>
        </TableCell>

        <TableCell component="th" scope="row" padding="normal">
          <CandidateLink candidate={candidate} />
        </TableCell>
        <TableCell component="th" scope="row" padding="normal" sx={{ pl: 0 }}>
          <Checkbox
            className="checkbox-delete"
            checked={toDelete}
            onChange={() => addToDelete(candidate.id)}
          />
        </TableCell>
      </TableRow>

      {showResults > 0 && (
        <CandidateResultsModal
          showResults={showResults}
          setShowResults={setShowResults}
        />
      )}

      {showDisability > 0 && (
        <CandidateDisabilityModal
          candidate={candidate}
          setShowDisability={setShowDisability}
          setCandidateList={setCandidateList}
        />
      )}
    </>
  );
};

import { Box } from "@mui/material";
import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { HexColorPicker, HexColorInput, RgbaColorPicker } from "react-colorful";

const rgbToHex = (r: number, g: number, b: number) =>
  "#" +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

const hexToRgb = (hex: string) => {
  const parsed = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m: any, r: string, g: string, b: string) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g);

  if (parsed) return parsed.map((x: string) => parseInt(x, 16));
  else return [0, 0, 0];
};

interface ColorPickerPropsI {
  color: { r: number; g: number; b: number; a: number };
  setColor: (c: { r: number; g: number; b: number; a: number }) => void;
}

export const ColorPicker = ({ color, setColor }: ColorPickerPropsI) => {
  const [hex, setHex] = useState(rgbToHex(color.r, color.g, color.b));

  const onChangeRGBA = (v: number, name: string) => {
    setColor({ ...color, [name]: v });
    setHex(rgbToHex(color.r, color.g, color.b));
  };

  const onChangePicker = (v: any) => {
    setColor(v);
    setHex(rgbToHex(v.r, v.g, v.b));
  };

  return (
    <Box className="color-picker">
      <RgbaColorPicker color={color} onChange={onChangePicker} />
      <Box className="outer-color-input-container">
        <Box className="color-input-container hex">
          <input
            type="text"
            name="hex"
            value={hex}
            className="hex"
            onChange={(e) => {
              setHex(e.target.value);
              if (!e.target.value.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i))
                return;
              const [r, g, b]: number[] = hexToRgb(e.target.value);
              setColor({ ...color, r: Number(r), g: Number(g), b: Number(b) });
            }}
          />
          <Box className="color-input-label">HEX</Box>
        </Box>
        <Box className="color-input-container">
          <input
            type="text"
            name="r"
            value={color.r}
            onChange={(e) => onChangeRGBA(parseInt(e.target.value), "r")}
            className="r"
          />
          <Box className="color-input-label">R</Box>
        </Box>
        <Box className="color-input-container">
          <input
            type="text"
            name="g"
            value={color.g}
            onChange={(e) => onChangeRGBA(parseInt(e.target.value), "g")}
            className="g"
          />
          <Box className="color-input-label">G</Box>
        </Box>
        <Box className="color-input-container">
          <input
            type="text"
            name="b"
            value={color.b}
            onChange={(e) => onChangeRGBA(parseInt(e.target.value), "b")}
            className="b"
          />
          <Box className="color-input-label">B</Box>
        </Box>
        <Box className="color-input-container">
          <input
            type="text"
            name="a"
            value={color.a}
            onChange={(e) => onChangeRGBA(parseInt(e.target.value), "a")}
            className="a"
          />
          <Box className="color-input-label">A</Box>
        </Box>
      </Box>
      <br />
    </Box>
  );
};

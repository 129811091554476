import { Box, Typography } from "@mui/material";
import { CandidateResultsT } from "../api";
import { ResultIcon } from "./ResultIcon";
import { ResultPercent } from "./ResultPercent";

interface ResultSummaryPropsI {
  results: CandidateResultsT;
}

export const ResultSummary = ({ results }: ResultSummaryPropsI) => {
  console.log("%cResultSummary.tsx line:8 results", "color: #007acc;", results);
  return (
    <Box
      className="result-summary result-summary-1"
      sx={{ marginTop: -3, mb: 3 }}
    >
      <Typography variant="h3" component="div" className="">
        {" "}
        {results.countTotal} Aufgaben
      </Typography>
      <div className=" result-icon-container">
        <Typography variant="h3" component="div">
          {results.countCorrect}
        </Typography>{" "}
        <ResultIcon correct />
      </div>
      <div className="result-icon-container">
        <Typography variant="h3" component="div">
          {results.countTotal - results.countCorrect}
        </Typography>
        <ResultIcon incorrect />
      </div>
    </Box>
  );
};

import { Grid } from "@mui/material";
import Box from "@mui/system/Box";
import React from "react";
import {
  DnDMappingT,
  ELEMENT_HEIGHT,
  ELEMENT_HEIGHT_IMG,
  ELEMENT_WIDTH,
  ELEMENT_WIDTH_IMG,
} from "../../../testModus/questionTypes/QuestionDnD";
import { QuestionResultsT } from "../../api";
import { ResultIcon } from "../ResultIcon";

interface AnswersPropsI {
  question: QuestionResultsT;
}

export const ResultDnD = React.memo(({ question }: AnswersPropsI) => {
  console.log("question:", question);

  const answers = question.answerOptionsDecoded;
  console.log("answers:", answers);

  const answersDecoded = question.answerDecoded as DnDMappingT;
  console.log("answersDecoded:", answersDecoded);

  //   const options = JSON.parse(question.answerOptions);
  //   const answer = JSON.parse(question.answer)[0];
  //   console.log("options:", options, answer);

  return (
    <>
      {Object.keys(answers.given).map((p: any, index: number) => {
        const correctAnswer = answersDecoded ? answersDecoded[p] : "";
        const candidateAnswer = ((question.candidateAnswerDecoded &&
          question.candidateAnswerDecoded[p]) ||
          "-") as string;
        console.log("answer correctAnswer:", correctAnswer, candidateAnswer);
        const correct = correctAnswer === candidateAnswer;

        return (
          <Box display="flex" key={question.id + ":" + index} gap={2}>
            <Box
              width={"25%"}
              sx={{
                border: "0.5px solid darkgrey",
                height: question.isImage ? ELEMENT_HEIGHT_IMG : ELEMENT_HEIGHT,
                width: question.isImage ? ELEMENT_WIDTH_IMG : ELEMENT_WIDTH,
                maxWidth: "100%",
                mb: 2,
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: answers.given[p],
                }}
              />
            </Box>
            <Box
              width={"25%"}
              border={1}
              borderColor={correct ? "success.main" : "error.main"}
              sx={{
                // border: correct ? "1px success solid" : "error.main",
                height: question.isImage ? ELEMENT_HEIGHT_IMG : ELEMENT_HEIGHT,
                width: question.isImage ? ELEMENT_WIDTH_IMG : ELEMENT_WIDTH,
                mb: 1,
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: answers.options[candidateAnswer],
                }}
              />
              {correct ? <ResultIcon correct /> : <ResultIcon incorrect />}
            </Box>
            <Box
              width={"25%"}
              sx={{
                border: "0.5px solid darkgrey",
                height: question.isImage ? ELEMENT_HEIGHT_IMG : ELEMENT_HEIGHT,
                width: question.isImage ? ELEMENT_WIDTH_IMG : ELEMENT_WIDTH,
                mb: 2,
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    answersDecoded &&
                    answersDecoded[p] &&
                    answers.options[answersDecoded[p]],
                }}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
});

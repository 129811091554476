import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { RatingT } from "../api";
import { FormControl, SxProps } from "@mui/material";

interface RatingSelectPropI {
  id: string;
  value?: RatingT;
  setValue: (value: RatingT) => void;
  label?: string;
  formSx?: SxProps;
  selectSx?: SxProps;
}

export const RatingSelect = (props: RatingSelectPropI) => {
  const selectElement = (
    <Select
      id={props.id}
      value={props.value ? props.value : ""}
      onChange={(e: SelectChangeEvent<string>) => {
        props.setValue(e.target.value as RatingT);
      }}
      label="{props.label || ''}"
      size="small"
      sx={{
        fontSize: (theme) => theme.typography.body2.fontSize,
        ...props.selectSx,
      }}
    >
      <MenuItem value="easy">leicht</MenuItem>
      <MenuItem value="medium">mittel</MenuItem>
      <MenuItem value="hard">schwer</MenuItem>
      <MenuItem value="none">ohne LMS</MenuItem>
    </Select>
  );
  return props.label ? (
    <FormControl sx={props.formSx}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      {selectElement}
    </FormControl>
  ) : (
    selectElement
  );
};

export default RatingSelect;

import { addUser, UserT } from "../user/api";
import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { HelpCategoryForm } from "./HelpCategoryForm";

import { Dialog } from "../shared/Dialog";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { UserContext } from "../user/UserContext";
import { addStateToArray } from "../shared/helper/state";
import { AddButton } from "../shared/forms/AddButton";
import { addHelpCategory, HelpCategoryT } from "./api";
import { HelpContext } from "./HelpContext";
import { Box } from "@mui/material";

interface AddCustomerPropsI {
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}
export const AddHelpCategory = ({ setFilter }: AddCustomerPropsI) => {
  const { setError } = useContext(ErrorContext);

  const [open, setOpen] = useState(false);
  const { openSnackbar } = useContext(FeedbackContext);
  const { setHelpCategoryList } = useContext(HelpContext);

  const onClick = (data: HelpCategoryT) => {
    addHelpCategory(data)
      .then((res) => {
        if (res.success) {
          setHelpCategoryList((p) =>
            addStateToArray<HelpCategoryT>(p, res.data as HelpCategoryT)
          );
          openSnackbar("success", "Eintrag gespeichert");
          setFilter(res.data?.title || "");
          setOpen(false);
        } else {
          if (res.errors) {
            if (
              "email" in res.errors &&
              (res.errors as any).email[0].indexOf("already") > 0
            ) {
              setError("Diese E-Mail ist bereits vorhanden!");
            } else setError(res.error);
          }
        }
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <>
      <AddButton onClick={() => setOpen(true)} title="Kategorie" />

      <Dialog open={open} setOpen={setOpen} title="Neue Kategorie">
        <Box sx={{ width: 600 }}>
          <HelpCategoryForm onClick={onClick} button="Speichern" />
        </Box>
      </Dialog>
    </>
  );
};

import { Typography } from "@mui/material";

interface ProfessionCheckResultI {
  result: string;
}
export const ProfessionCheckResult = ({ result }: ProfessionCheckResultI) => {
  if (result === "no relmain") {
    return (
      <Typography color="error" variant="body2" component="span">
        Fehlende Hauptkategorie
      </Typography>
    );
  }

  if (result === "no relquestion") {
    return (
      <Typography color="error" variant="body2" component="span">
        Kategorie ohne Fragen
      </Typography>
    );
  }

  if (result === "no relsub") {
    return (
      <Typography color="error" variant="body2" component="span">
        Hauptkategorie ohne Unterkategorie
      </Typography>
    );
  }

  const matches = result.match(/question duration 0:(\d+)/);

  if (matches) {
    return (
      <Typography color="error" variant="body2" component="span">
        Frage ohne Dauer: {matches[1]}
      </Typography>
    );
  }

  return (
    <Typography color="success" variant="body2" component="span">
      {result}
    </Typography>
  );
};

import React, {
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect,
} from "react";
import update from "immutability-helper";

import Typography from "@mui/material/Typography";
import CreateIcon from "@mui/icons-material/Create";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import type { Identifier, XYCoord } from "dnd-core";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  MailTemplateT,
  updateMailTemplate,
  addMailTemplate,
  deleteMailTemplate,
  move as saveMove,
} from "./api";

import { MailTemplateContext } from "./MailTemplateContext";
import { ErrorContext } from "../error/ErrorContext";

import { FeedbackContext } from "../feedback/FeedbackContext";

import Box from "@mui/material/Box";
import { MailTemplateForm } from "./MailTemplateForm";
import { updateStateArrayById } from "../shared/helper/state";
import { DeleteButton } from "../shared/forms/DeleteButton";
import { useDrag, useDrop } from "react-dnd";
import { Paper } from "@mui/material";
import { CandidateContext } from "../candidate/CandidateContext";

export const CustomerMailTemplate = () => {
  const { openSnackbar } = useContext(FeedbackContext);

  const { mailTemplateList, setMailTemplateList } =
    useContext(MailTemplateContext);
  const { setError } = useContext(ErrorContext);

  const [selectedMailTemplate, setSelectedMailTemplate] = useState(
    mailTemplateList[0]?.id || -1
  );
  const showAddMailTemplate = () => {
    setSelectedMailTemplate(-1);
  };

  const { candidateList } = useContext(CandidateContext);

  useEffect(() => {
    if (mailTemplateList.length > 1) {
      if (!mailTemplateList.find((t) => t.id === selectedMailTemplate)) {
        setSelectedMailTemplate(
          mailTemplateList.find((t) => t.id !== -1)?.id || -1
        );
      }
    } else setSelectedMailTemplate(-1);
  }, [mailTemplateList]);

  const onClick = (data: MailTemplateT) => {
    if (selectedMailTemplate === -1) {
      return addMailTemplate(data).then((res) => {
        if (res.success && res.data) {
          setMailTemplateList((p) => [...p, res.data as MailTemplateT]);
          setSelectedMailTemplate(res.data.id);
          openSnackbar("success", "Eintrag gespeichert");
        } else throw res.error;
      });
    }

    updateMailTemplate(data)
      .then((res) => {
        if (res.success) {
          setMailTemplateList((p) =>
            updateStateArrayById<MailTemplateT>(p, data)
          );

          openSnackbar("success", "Eintrag gespeichert");
        } else {
          if (res.errors) {
            setError(res.error);
          }
        }
      })
      .catch((error) => {
        setError(error);
      });
  };
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setMailTemplateList((prev) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      })
    );
  }, []);

  return (
    <DndProvider options={HTML5toTouch}>
      <Box className="customer-mail-templates" display="flex" sx={{ gap: 2 }}>
        <Box sx={{ minWidth: "580px" }}>
          <Paper
            sx={{
              m: 2,
              p: 2,
              pl: 1,
              mr: 0,
              display: "flex",
              flexDirection: "column",
              //height: 240,
            }}
          >
            <Typography variant="h1" component="h1" sx={{ pl: 1, pb: 2 }}>
              Maileditor
            </Typography>

            {selectedMailTemplate != 0 && (
              <MailTemplateForm
                mailTemplate={
                  mailTemplateList.find((m) => m.id === selectedMailTemplate) ||
                  mailTemplateList.find((m) => m.userId === 0) ||
                  mailTemplateList[0]
                }
                onClick={onClick}
              />
            )}
          </Paper>
        </Box>
        <Box>
          <Paper
            sx={{
              m: 2,
              ml: 0,
              p: 2,
              display: "flex",
              flexDirection: "column",
              minWidth: "640px",
            }}
          >
            <Typography variant="h1" component="h1" sx={{ pb: 3 }}>
              Mailvorlagen
            </Typography>
            <Box className="tile-container">
              {mailTemplateList
                .filter((m) => m.userId > 0)
                .map((m, index) => (
                  <DragableTile
                    id={m.id}
                    index={index}
                    key={"tile" + m.id}
                    moveCard={moveCard}
                    {...{
                      m,
                      selectedMailTemplate,
                      setSelectedMailTemplate,
                      mailTemplateList,
                      setMailTemplateList,
                    }}
                  />
                ))}
              <Box
                className={
                  "tile tile-add " +
                  (-1 === selectedMailTemplate ? "active" : "")
                }
                onClick={showAddMailTemplate}
              >
                <Box>
                  <AddCircleIcon className="icon add-icon" />
                </Box>
                <Box sx={{ mb: "14px" }}>
                  <Typography variant="body1" className="tile-body">
                    Weitere Vorlage erstellen
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </DndProvider>
  );
};

interface DragableTilePropsI {
  id: number;
  index: number;
  m: MailTemplateT;
  selectedMailTemplate: number;
  setSelectedMailTemplate: (id: number) => void;
  mailTemplateList: MailTemplateT[];
  setMailTemplateList: React.Dispatch<React.SetStateAction<MailTemplateT[]>>;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DragableTile = ({
  id,
  index,
  m,
  selectedMailTemplate,
  setSelectedMailTemplate,
  mailTemplateList,
  setMailTemplateList,
  moveCard,
}: DragableTilePropsI) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "mailTemplate",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DragItem, monitor) {
      console.log(
        "%cCustomerMailTemplate.tsx line:207 drop item",
        "color: orange;",
        item
      );
      saveMove(mailTemplateList.map((p) => p.id).filter((p) => p > 0));
    },
    hover(item: DragItem, monitor) {
      // console.log(
      //   "%cCustomerMailTemplate.tsx line:183 hover item",
      //   "color: #007acc;",
      //   item
      // );
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleY
      ) {
        return;
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleY
      ) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "mailTemplate",
    item: () => {
      return { id: id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box
      key={m.id}
      ref={ref}
      data-handler-id={handlerId}
      sx={{ opacity: opacity }}
      className="tile-dnd-wrapper"
    >
      <Box className="more-vert-icon-container">
        <MoreVertIcon className="more-vert-icon" />
      </Box>
      <Box
        className={"tile " + (m.id === selectedMailTemplate ? "active" : "")}
      >
        <Box>
          <MailOutlineIcon className="icon send-icon" />
          <Typography variant="body1" className="tile-heading" lang="de">
            {m.description}
          </Typography>
        </Box>
        <Box
          onClick={() => setSelectedMailTemplate(m.id)}
          sx={{ cursor: "pointer" }}
        >
          <CreateIcon className="icon create-icon" />
          <Typography variant="body1" className="tile-body">
            Vorlage editieren
          </Typography>
        </Box>
        <DeleteButton
          deleteFunc={deleteMailTemplate}
          className="icon delete-icon"
          id={m.id}
          confirmTitle="Vorlage löschen?"
          textYes="Ja, löschen"
          successFunc={() => {
            setMailTemplateList((p) => p.filter((p) => p.id !== m.id));
          }}
        />
      </Box>
    </Box>
  );
};

import { useContext } from "react";
import { fetchQuestions } from "./api";
import { QuestionContext } from "./QuestionContext";
import { QuestionT } from "./api";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseQuestionsResultT extends UseResultT {
  questionList: QuestionT[];
  setQuestionList: React.Dispatch<React.SetStateAction<QuestionT[]>>;
}

interface UseQuestionsPropsI extends UsePropsI {
  subCategoryId?: number;
}

export const useQuestions = ({
  subCategoryId = 0,
  dontHideProgress,
}: UseQuestionsPropsI): UseQuestionsResultT => {
  const { questionList, setQuestionList, loadingDone, setLoadingDone } =
    useContext(QuestionContext);

  useLoadData({
    fetchFunc: subCategoryId
      ? () => fetchQuestions({ subCategoryId })
      : () => fetchQuestions({}),
    list: questionList,
    setList: setQuestionList,
    setLoadingDone,
    dontHideProgress,
    omitLoadIfDefined: subCategoryId ? true : false,
  });

  return { questionList, setQuestionList, loadingDone };
};

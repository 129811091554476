import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

export const Copyright = (props: any) => {
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
        sx={{ m: 3, mt: 0 }}
        component="div"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            Version {import.meta.env.VITE_GITVERSION}{" "}
            {import.meta.env.VITE_GITDATE}
          </Grid>
          <Grid item>
            {"Copyright © "}
            <Link color="inherit" href="https://www.degeba.de/" target="_blank">
              degeba
            </Link>{" "}
            {new Date().getFullYear()}
          </Grid>
        </Grid>
      </Typography>
    </>
  );
};

import { ReactElement } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";

export const Error = (): ReactElement => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="login-box"
      >
        <Box className="logo degeba-logo"></Box>
        <Typography component="h1" variant="h5">
          Allgemeiner Serverfehler
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          <br />
          <br />
          Sie sehen diese Seite aufgrund eines unerwarteten technischen
          Problems. Bitte versuchen Sie es erneut oder wenden Sie sich an:
          <br />
          <br />
          <a href="mailto:kundenbetreuung@degeba.com">
            kundenbetreuung@degeba.com
          </a>
          <br />
          Tel.: +49 (69) 94 94 64 70 (Mo–Fr, 9–18 Uhr)
          <br />
          <br />
          Wir bitten, die entstandenen Unannehmlichkeiten zu entschuldigen.
          <br />
          <br />
          <b>Ihr degeba-Team</b>
        </Typography>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

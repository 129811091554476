import React, { ReactElement } from "react";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";

export const CustomerTestMailUuid = (): ReactElement => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="login-box"
      >
        <Box className="logo degeba-logo"></Box>
        <Typography component="h1" variant="h5">
          Testbereich
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          <br />
          <br />
          Der Zugriff per Einladungs-Link ist nur für registrierte
          Teilnehmer*innen möglich. Als Prüfungsperson können Sie den Test über
          das Dashboard Ihrer Testverwaltung aufrufen.
        </Typography>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

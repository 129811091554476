import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { FileContext } from "../file/FileContext";
import { useContext } from "react";
import { sortBy } from "lodash";

interface ImageSelectPropI {
  id: string;
  value: number;
  setValue: (value: number) => void;
  label?: string;
  labelId?: string;
}

export const ImageSelect = (props: ImageSelectPropI) => {
  const { fileList } = useContext(FileContext);

  return (
    <Select
      size="small"
      labelId={props.labelId}
      id={props.id}
      value={
        props.value && fileList.some((e) => e.id === props.value)
          ? props.value
          : 0
      }
      onChange={(e: SelectChangeEvent<number>) => {
        props.setValue(e.target.value as number);
      }}
      label="{props.label}"
      sx={{ width: "100%" }}
    >
      <MenuItem value={0}>
        <Typography variant="body2">Kein Bild</Typography>
      </MenuItem>
      {sortBy(fileList, [(e) => e.name.toLowerCase()]).map((e) => (
        <MenuItem key={e.id} value={e.id}>
          <Typography variant="body2">{e.name}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

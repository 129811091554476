import {
  useContext,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  Fragment,
} from "react";

import Box from "@mui/material/Box";
import Grid, { GridSize } from "@mui/material/Grid";

import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";

import { RelationContext } from "./RelationContext";
import { QuestionRelationTable } from "./QuestionRelationTable";
import { ProfessionContext } from "../profession/ProfessionContext";
import { MainCategoryContext } from "../mainCategory/MainCategoryContext";
import { ellipsisSx } from "../shared/styles/sx";
import { MainCategoryT } from "../mainCategory/api";
import { MainCategory } from "./MainCategory";
import { RelationT, SubRelationT, updateRelationBulk } from "./api";
import { SubCategoryT } from "../subCategory/api";
import { SubCategory } from "./SubCategory";
import { SubCategoryContext } from "../subCategory/SubCategoryContext";
import { RELATIONREDUCERACTIONS } from "./relationReducer";
import Typography from "@mui/material/Typography";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import Button from "@mui/material/Button";
import { useSelTree } from "./useSelTree";
import { useView } from "./useView";
import { ProfessionCheckResult } from "../profession/ProfessionCheckResult";
import { RefreshButton } from "../profession/RefreshButton";
import { VersionsButton } from "../profession/VersionsButton";
import { noop } from "lodash";
import { Checkbox } from "@mui/material";
import { useQuestions } from "../question/useQuestions";
import { Tooltip } from "../shared/forms/Tooltip";
import TypeSelect from "../profession/TypeSelect";

export interface FilteredMainCategoryT extends MainCategoryT {
  minOrder: number;
  maxOrder: number;
}

export interface FilteredSubCategoryT extends SubCategoryT {
  minOrder: number;
  maxOrder: number;
  relationId: number;
}

export const orderSort = <
  T extends {
    order: number;
  }
>(
  a: T,
  b: T
) => a.order - b.order;

export enum VIEW {
  PROF = "PROF",
  MAIN = "MAIN",
  SUB = "SUB",
  QUEST = "QUEST",
}
export type ViewT = { [key: string]: number };

const headerSx = {
  ...ellipsisSx,
  fontWeight: "bold",
  mb: 2,
  textDecoration: "underline",
};

export const RelationTable = () => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar } = useContext(FeedbackContext);
  const { professionList } = useContext(ProfessionContext);
  const { categoryList: mainCategoryList } = useContext(MainCategoryContext);
  const { categoryList: subCategoryList } = useContext(SubCategoryContext);
  const { relationList, setRelationList } = useContext(RelationContext);
  const [checkedProfessionList, setCheckedProfessionList] = useState<number[]>(
    []
  );

  const [filteredMainCategoryList, setFilteredMainCategoryList] = useState<
    FilteredMainCategoryT[]
  >([]);

  const [filteredSubCategoryList, setFilteredSubCategoryList] = useState<
    FilteredSubCategoryT[]
  >([]);

  const [showVersions, setShowVersions] = useState(false);
  const [selectedType, setSelectedType] = useState("basic");

  const [
    filterSubCategoriesCheckedProfessions,
    setFilterSubCategoriesCheckedProfessions,
  ] = useState(false);
  const [
    filterSubCategoriesCheckedProfessionsInvertion,
    setFilterSubCategoriesCheckedProfessionsInvertion,
  ] = useState(false);

  /* which profession/category was clicked on to choose this path in tree */
  const { selTree, setSelTree } = useSelTree();

  const [view, setView] = useView();

  const setChecked = (professionId: number, checked: boolean) => {
    if (checked) {
      setCheckedProfessionList((p) => [...p, professionId]);
    } else {
      setCheckedProfessionList((prev) =>
        prev.filter((p) => p !== professionId)
      );
    }
  };

  /** ************************************************************************
   *
   * updateFilteredMainCategoryList
   *
   */
  const updateFilteredMainCategoryList = useCallback(
    (professionId: number) => {
      const maxOrder = Math.max(
        ...relationList
          .filter((r) => r.professionId === professionId)
          .map((r) => r.mainCategoryOrder)
      );
      const minOrder = Math.min(
        ...relationList
          .filter((r) => r.professionId === professionId)
          .map((r) => r.mainCategoryOrder)
      );
      setFilteredMainCategoryList(
        mainCategoryList
          /* change the order for this cat to those from the profession-context */
          .map((c) => {
            return {
              ...c,
              order:
                relationList.find(
                  (r: RelationT) =>
                    r.professionId === professionId && r.mainCategoryId === c.id
                )?.mainCategoryOrder || c.order,
              minOrder: minOrder,
              maxOrder: maxOrder,
            };
          })
          .sort(orderSort)
      );
    },
    [mainCategoryList, relationList]
  );

  useLayoutEffect(() => {
    updateFilteredMainCategoryList(selTree.professionId);
  }, [updateFilteredMainCategoryList, selTree.professionId]);

  /**
   * which S U B C A T S to show
   */
  const updateFilteredSubCategoryList = useCallback(
    (mainCategoryId: number) => {
      selTree.professionIsActive && noop(); // only to trigger re-render

      /** one entry with profession, maincat and a list of all sub-relations */
      const relationEntry = relationList.find(
        (r: RelationT) =>
          r.professionId === selTree.professionId &&
          r.mainCategoryId === mainCategoryId
      );
      /** all entries with the checked professions */
      const relationEntriesCheckedProfessions =
        filterSubCategoriesCheckedProfessions
          ? filterSubCategoriesCheckedProfessionsInvertion
            ? relationList.filter(
                (r: RelationT) =>
                  !checkedProfessionList.includes(r.professionId) &&
                  r.mainCategoryId === mainCategoryId
              )
            : relationList.filter(
                (r: RelationT) =>
                  checkedProfessionList.includes(r.professionId) &&
                  r.mainCategoryId === mainCategoryId
              )
          : [];

      const subRelations = filterSubCategoriesCheckedProfessions
        ? relationEntriesCheckedProfessions
            .map((r: RelationT) => r.subRelations)
            .flat()
        : relationEntry?.subRelations;

      const minOrder = relationEntry
        ? Math.min(
            ...relationEntry.subRelations.map(
              (s: SubRelationT) => s.subCategoryOrder
            )
          )
        : 0;
      const maxOrder = relationEntry
        ? Math.max(
            ...relationEntry.subRelations.map(
              (s: SubRelationT) => s.subCategoryOrder
            )
          )
        : 0;
      // if used more than here make reducer (nth)
      setFilteredSubCategoryList(
        subCategoryList
          /* change the order for this cat to those from the profession-context */
          .filter((s) =>
            !filterSubCategoriesCheckedProfessions ||
            subRelations?.find((r) => r.subCategoryId === s.id)?.id
              ? true
              : false
          )
          .map((s) => {
            return {
              ...s,
              order: relationEntry
                ? relationEntry?.subRelations.find(
                    (r) => r.subCategoryId === s.id
                  )?.subCategoryOrder || s.order
                : s.order,
              minOrder: minOrder,
              maxOrder: maxOrder,
              relationId:
                relationEntry?.subRelations.find(
                  (r) => r.subCategoryId === s.id
                )?.id || 0,
              // rel_sub.id (!)
            };
          })
          .sort(orderSort)
      );
    },
    [
      relationList,
      selTree.professionId,
      selTree.professionIsActive,
      subCategoryList,
      filterSubCategoriesCheckedProfessions,
      filterSubCategoriesCheckedProfessionsInvertion,
      checkedProfessionList,
    ]
  );

  useQuestions({});

  useEffect(() => {}, [selTree]);

  useLayoutEffect(() => {
    selTree.professionIsActive && noop(); // only to trigger re-render
    updateFilteredSubCategoryList(selTree.mainCategoryId);
  }, [
    selTree.professionIsActive,
    updateFilteredSubCategoryList,
    selTree.mainCategoryId,
  ]);

  /** ************************************************************************
   *
   * chooseMainCategory
   *
   */
  const chooseMainCategory = useCallback(
    (mainCategoryId: number, subCategoryId = 0) => {
      updateFilteredSubCategoryList(mainCategoryId);

      setSelTree({
        mainCategoryId: mainCategoryId,
        subCategoryId: subCategoryId,
      });
    },
    [updateFilteredSubCategoryList, setSelTree]
  );

  const chooseSubCategory = useCallback(
    (subCategoryId: number) => {
      setSelTree({
        subCategoryId: subCategoryId,
      });
      updateFilteredMainCategoryList(selTree.professionId);
    },
    [selTree, setSelTree, updateFilteredMainCategoryList]
  );

  /** ************************************************************************
   *
   * moveMainCategory
   *
   */
  const moveMainCategory = useCallback(
    (direction: string, mainCategoryId: number) => {
      const actCategory: FilteredMainCategoryT | undefined =
        filteredMainCategoryList
          ? filteredMainCategoryList.find((c) => c.id === mainCategoryId)
          : undefined;

      const actOrder = actCategory ? (actCategory as any).order : 0;
      if (direction === "up" && actOrder === 1) return;

      if (filteredMainCategoryList) {
        const changeCategory: FilteredMainCategoryT | undefined =
          direction === "down"
            ? filteredMainCategoryList.find((c) => (c.order || 0) > actOrder)
            : filteredMainCategoryList
                .reverse()
                .find((c) => (c.order || 0) < actOrder);

        if (actCategory && changeCategory)
          setRelationList({
            action: RELATIONREDUCERACTIONS.MOVEMAIN,
            data: {
              professionId: selTree.professionId,
              categories: [actCategory, changeCategory],
            },
          });

        chooseMainCategory(mainCategoryId);

        if (changeCategory)
          updateRelationBulk([
            {
              type: "main",
              professionId: selTree.professionId,
              mainCategoryId: mainCategoryId,
              mainCategoryOrder: changeCategory.order,
            },
            {
              type: "main",
              professionId: selTree.professionId,
              mainCategoryId: changeCategory.id,
              mainCategoryOrder: actOrder,
            },
          ])
            .then(() => {
              openSnackbar("success", "Reihenfolge gespeichert");
            })
            .catch((e) => setError(e));
      }
    },
    [
      chooseMainCategory,
      filteredMainCategoryList,
      openSnackbar,
      selTree.professionId,
      setError,
      setRelationList,
    ]
  );

  const isMainCategorySelected = (mainCategoryId: number) =>
    relationList.some(
      (r: RelationT) =>
        r.professionId === selTree.professionId &&
        r.mainCategoryId === mainCategoryId
    );

  const isSubCategorySelected = (subCategoryId: number) =>
    relationList
      .find(
        (r: RelationT) =>
          r.professionId === selTree.professionId &&
          r.mainCategoryId === selTree.mainCategoryId
      )
      ?.subRelations.find(
        (sr: SubRelationT) => sr.subCategoryId === subCategoryId
      );

  const isSubCategorySelectedByOther = (subCategoryId: number) =>
    relationList.find(
      (r: RelationT) =>
        r.professionId === selTree.professionId &&
        r.mainCategoryId !== selTree.mainCategoryId &&
        r.subRelations.some((s) => s.subCategoryId === subCategoryId)
    );

  /** ************************************************************************
   *
   * moveSubCategory
   *
   */

  const moveSubCategory = useCallback(
    (direction: string, subCategoryId: number) => {
      const actCategory: FilteredSubCategoryT | undefined =
        filteredSubCategoryList
          ? filteredSubCategoryList.find((c) => c.id === subCategoryId)
          : undefined;

      const actOrder = actCategory
        ? (actCategory as FilteredSubCategoryT).order
        : 0;
      if (direction === "up" && actOrder === 1) return;

      if (filteredSubCategoryList) {
        const changeCategory: FilteredSubCategoryT | undefined =
          direction === "down"
            ? filteredSubCategoryList.find((c) => (c.order || 0) > actOrder)
            : filteredSubCategoryList
                .reverse()
                .find((c) => (c.order || 0) < actOrder);

        if (actCategory && changeCategory)
          setRelationList({
            action: RELATIONREDUCERACTIONS.MOVESUB,
            data: {
              professionId: selTree.professionId,
              mainCategoryId: selTree.mainCategoryId,
              categories: [actCategory, changeCategory],
            },
          });

        if (actCategory && changeCategory)
          updateRelationBulk([
            {
              type: "sub",
              id: (actCategory as FilteredSubCategoryT).relationId || 0,
              subCategoryId: subCategoryId,
              subCategoryOrder: changeCategory.order,
            },
            {
              type: "sub",
              id: changeCategory.relationId || 0,
              subCategoryId: changeCategory.id,
              subCategoryOrder: actOrder,
            },
          ])
            .then(() => {
              openSnackbar("success", "Reihenfolge gespeichert");
            })
            .catch((e) => setError(e));
      }
    },
    [
      filteredSubCategoryList,
      selTree.mainCategoryId,
      selTree.professionId,
      setError,
      setRelationList,
      openSnackbar,
    ]
  );

  const paintProfessionList = () => {
    return (
      <>
        {paintViewSetter(VIEW.PROF)}
        <Typography variant="body2" sx={{ ...headerSx, mb: 0 }}>
          Berufe
        </Typography>
        <Grid container spacing={0}>
          <Grid item>
            <Checkbox
              onChange={(event) =>
                event.target.checked
                  ? setCheckedProfessionList(professionList.map((p) => p.id))
                  : setCheckedProfessionList([])
              }
              sx={{ p: 0 }}
              size="small"
            />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2">(alle)</Typography>
          </Grid>
        </Grid>
        {professionList
          .filter(
            (e) => e.type === selectedType && (!e.childId || showVersions)
          )
          .map((p) => (
            <Grid container spacing={0} key={"cont" + p.id}>
              <Grid item>
                <Checkbox
                  sx={{ p: 0, pb: 1, "& .MuiSvgIcon-root": { fontSize: 18 } }}
                  onChange={(event) => setChecked(p.id, event.target.checked)}
                  checked={Boolean(checkedProfessionList.includes(p.id))}
                />
              </Grid>
              <Grid item xs={11}>
                <Tooltip
                  key={"tt" + p.id}
                  title={
                    p.name + " " + p.testId + " " + (!p.active && "(inakt.)")
                  }
                >
                  <Box
                    key={p.id}
                    onClick={(e) => {
                      updateFilteredMainCategoryList(p.id);

                      setSelTree({
                        professionId: p.id,
                        mainCategoryId: 0,
                        subCategoryId: 0,
                        professionIsActive: p.active,
                      });
                    }}
                    sx={{
                      ...(p.id === selTree.professionId
                        ? { background: "lightgray", fontWeight: "bold" }
                        : {}),
                      mb: 1,
                      ...ellipsisSx,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ backgroundColor: p.childId ? "lightgray" : "" }}
                    >
                      {p.name} {p.testId} {!p.active && "(inakt.)"}{" "}
                      {p.checkResult && p.checkResult !== "ok" ? (
                        <ProfessionCheckResult result={p.checkResult} />
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
      </>
    );
  };

  const paintMainCategoryList = () => {
    return (
      <>
        {paintViewSetter(VIEW.MAIN)}
        <Typography variant="body2" sx={headerSx}>
          Hauptkategorien
        </Typography>
        {selTree.professionId > 0 &&
          filteredMainCategoryList.map((c) => (
            <MainCategory
              key={
                "main" +
                c.id +
                "_prof" +
                selTree.professionId +
                (isMainCategorySelected(c.id) ? "_sel" : "")
              }
              selTree={selTree}
              setSelTree={setSelTree}
              mainCategory={c}
              moveMainCategory={moveMainCategory}
              onClick={() => chooseMainCategory(c.id)}
              isMainCategorySelected={isMainCategorySelected}
            />
          ))}
      </>
    );
  };

  const paintSubCategoryList = () => {
    return (
      <>
        {paintViewSetter(VIEW.SUB)}

        <Typography variant="body2" sx={headerSx}>
          Unterkategorien
          {
            <TooltipIcon
              type="filter"
              iconProps={{
                fontSize: "small",
                ...(filterSubCategoriesCheckedProfessions
                  ? { color: "warning" }
                  : {}),
              }}
              onClick={() => {
                setFilterSubCategoriesCheckedProfessions((p) => !p);
                updateFilteredSubCategoryList(selTree.mainCategoryId);
              }}
              title={
                filterSubCategoriesCheckedProfessions
                  ? "Filter nach HK + Berufauswahl ist aktiv - Click zum abschalten"
                  : "Filter nach HK + Berufauswahl"
              }
            />
          }
          {
            <TooltipIcon
              type="swap"
              off={true}
              iconProps={{
                fontSize: "small",
                ...(filterSubCategoriesCheckedProfessionsInvertion
                  ? { color: "warning" }
                  : {}),
              }}
              onClick={() => {
                if (!filterSubCategoriesCheckedProfessionsInvertion)
                  setFilterSubCategoriesCheckedProfessions(true);
                setFilterSubCategoriesCheckedProfessionsInvertion((p) => !p);
                updateFilteredSubCategoryList(selTree.mainCategoryId);
              }}
              title={
                filterSubCategoriesCheckedProfessionsInvertion
                  ? "Filter nach HK invertierter Berufsauswahl ist aktiv - Click zum abschalten"
                  : "Filter nach HK invertierter Berufsauswahl"
              }
            />
          }
        </Typography>
        {selTree.mainCategoryId > 0 &&
          filteredSubCategoryList.map((c) => (
            <SubCategory
              key={
                "sub" +
                c.id +
                "_prof" +
                selTree.professionId +
                (isSubCategorySelected(c.id) ? "_sel" : "")
              }
              selTree={selTree}
              setSelTree={setSelTree}
              subCategory={c}
              moveSubCategory={moveSubCategory}
              onAddRel={chooseSubCategory}
              isSubCategorySelected={isSubCategorySelected}
              isSubCategorySelectedByOther={isSubCategorySelectedByOther}
              onClick={chooseSubCategory}
              mainCategoryList={filteredMainCategoryList}
            />
          ))}
      </>
    );
  };

  const paintQuestionList = () => {
    return (
      <>
        {paintViewSetter(VIEW.QUEST)}
        <Typography variant="body2" sx={headerSx}>
          Fragen
        </Typography>
        {selTree.professionId > 0 &&
          selTree.mainCategoryId > 0 &&
          selTree.subCategoryId > 0 && (
            <QuestionRelationTable
              selTree={selTree}
              isSubCategorySelected={
                isSubCategorySelected(selTree.subCategoryId) ? true : false
              }
              small={view.QUEST < 9 ? true : false}
              checkedProfessionList={checkedProfessionList}
            />
          )}
      </>
    );
  };
  /** ************************************************************************
   *
   * main Component
   *
   */

  const getGridSizeView = (type: VIEW) => {
    return view[type] as GridSize;
  };

  const paintViewSetter = (type: VIEW) => {
    if (view[type] === 12) return <></>;
    return (
      <Grid container spacing={0}>
        <Grid item>
          <TooltipIcon
            onClick={() => setView({ action: "set", type, size: 0 })}
            type="hide"
            title="Ausblenden"
          />
        </Grid>
        {view[type] !== 1 && (
          <Grid item>
            <TooltipIcon
              onClick={() => setView({ action: "set", type, size: 1 })}
              type="small"
              title="Verkleinern"
            />
          </Grid>
        )}
        {view[type] !== 3 && (
          <Grid item>
            <TooltipIcon
              onClick={() => setView({ action: "set", type, size: 3 })}
              type="normal"
              title="Standard"
            />
          </Grid>
        )}
        {view[type] !== 1 && view[type] < 9 && (
          <Grid item>
            <TooltipIcon
              onClick={() => setView({ action: "set", type, size: 9 })}
              type="big"
              title="Gross"
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const resetView = (type: VIEW) => {
    if (view[type] === 0) setView({ action: "reset", type, size: 3 });
  };

  const paintResetView = (type: VIEW, name: string) => {
    return (
      <Box
        sx={{ backgroundColor: "lightgray", border: "1px solid gray" }}
        onClick={() => resetView(type)}
      >
        <Grid
          container
          spacing={0}
          sx={{ backgroundColor: "lightgray", border: "1px solid gray" }}
          onClick={() => resetView(type)}
        >
          <Grid item>
            <TooltipIcon
              onClick={() => resetView(type)}
              type="show"
              title="Anzeigen"
              sx={{}}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{}}>
              {name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <TypeSelect
            value={selectedType}
            setValue={(v) => {
              setCheckedProfessionList([]);
              setSelectedType(v);
            }}
            id="selected-type"
            showAll={false}
            size="small"
          />
        </Grid>
        <Grid item>
          <VersionsButton
            showVersions={showVersions}
            setShowVersions={setShowVersions}
            showText={false}
          />
        </Grid>
        <Grid item>
          <RefreshButton selTree={selTree} setSelTree={setSelTree} />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            onClick={() => setView({ action: "setAll", size: 0 })}
          >
            Nur Fragen
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => setView({ action: "setAll", size: 1 })}
          >
            Kompakt
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => setView({ action: "resetAll" })}
          >
            Alles einblenden
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        {(view[VIEW.PROF] === 0 ||
          view[VIEW.MAIN] === 0 ||
          view[VIEW.SUB] === 0 ||
          view[VIEW.QUEST] === 0) && (
          <>
            <Grid item xs={3}>
              {view[VIEW.PROF] === 0 &&
                paintResetView(
                  VIEW.PROF,
                  professionList.find((p) => p.id === selTree.professionId)
                    ?.name || "Berufe"
                )}
            </Grid>
            <Grid item xs={3}>
              {view[VIEW.MAIN] === 0 &&
                paintResetView(
                  VIEW.MAIN,
                  mainCategoryList.find((p) => p.id === selTree.mainCategoryId)
                    ?.name || "Hauptkategorien"
                )}
            </Grid>{" "}
            <Grid item xs={3}>
              {view[VIEW.SUB] === 0 &&
                paintResetView(
                  VIEW.SUB,
                  subCategoryList.find((p) => p.id === selTree.subCategoryId)
                    ?.name || "Unterkategorien"
                )}
            </Grid>
            <Grid item xs={3}>
              {view[VIEW.QUEST] === 0 && paintResetView(VIEW.QUEST, "Fragen")}
            </Grid>
          </>
        )}
        {view.PROF > 0 && (
          <Grid item xs={getGridSizeView(VIEW.PROF)}>
            {paintProfessionList()}
          </Grid>
        )}
        {view.MAIN > 0 && (
          <Grid item xs={getGridSizeView(VIEW.MAIN)}>
            {paintMainCategoryList()}
          </Grid>
        )}
        {view.SUB > 0 && (
          <Grid item xs={getGridSizeView(VIEW.SUB)}>
            {paintSubCategoryList()}
          </Grid>
        )}
        {/* {view !== VIEW.START && view !== VIEW.PROF && view !== VIEW.MAIN && ( */}
        {view.QUEST > 0 && (
          <Grid item xs={getGridSizeView(VIEW.QUEST)}>
            {paintQuestionList()}
          </Grid>
        )}
      </Grid>
    </>
  );
};

import { ReactElement } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";

export const ApiBlockedError = (): ReactElement => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 460,
        }}
        className="login-box"
      >
        <Box className="logo degeba-logo"></Box>
        <Typography component="h1" variant="h5">
          Überprüfen Sie Ihre Einstellungen
        </Typography>
        <Typography variant="body2" className="api-blocked-error">
          <br />
          <br />
          Sie versuchen die Testplattform der degeba | Deutsche Gesellschaft für
          Bewerberauswahl zu starten. Dabei werden Daten von der Seite
          „api.degeba.de“ nachgeladen. Anscheinend lässt Ihre individuelle
          Konfiguration dies nicht zu. Das kann folgende Ursachen haben:
          <br />
          <br />
          <ul>
            <li>Firewall-Einstellungen</li>
            <li>
              Werbeblocker (&#8222;AdBlocker&#8220;) und ähnliche
              Browser-Erweiterungen
            </li>

            <li>
              Andere Sicherheitsbeschränkungen (z.B. durch Antiviren-Software)
            </li>
          </ul>
          <br />
          Bitte heben Sie die Einschränkungen auf, um fortzufahren. Bei Fragen
          erreichen Sie das degeba-Serviceteam unter +49 (69) 94 94 64 70 oder
          per Mail an <a href="mailto:support@degeba.de">support@degeba.de</a>.
          <br />
        </Typography>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

import { get, CallApiResultT } from "../shared/lib/api";

type StatsTransactionDataT = {
  [year: number]: {
    [month: number]: {
      [professionId: number]: number;
    };
  };
};

export interface StatsTransactionT extends StatsTransactionDataT {}

export const fetchStatsTransaction = async (): Promise<
  CallApiResultT<StatsTransactionT>
> => {
  const res = await get<StatsTransactionDataT>("/stats-transaction");
  if (!res.data) {
    throw Error("[fetchStats] " + res.error);
  }
  return res;
};

type FetchCSVTransactionResultT = {
  filename: string;
};
export const fetchCSVTransaction = async (
  year = 0
): Promise<CallApiResultT<FetchCSVTransactionResultT>> => {
  const res = await get<FetchCSVTransactionResultT>("/stats-transaction", {
    csv: 1,
    year,
  });
  if (!res.data) {
    throw Error("[fetchStats] " + res.error);
  }
  return res;
};

/** ************************************************************************
 *
 *
 */

type StatsQuestionDataT = {
  id: number;
  question: string;
  type: string;
  difficulty: number;
  countResultsCorrect: number;
  countResultsWrong: number;
  percentTotalCorrect: number;
};
export interface StatsQuestionT extends StatsQuestionDataT {}

export const fetchStatsQuestion = async (): Promise<
  CallApiResultT<StatsQuestionT[]>
> => {
  const res = await get<StatsQuestionDataT[]>("/stats-question");
  if (!res.data) {
    throw Error("[fetchStats] " + res.error);
  }
  return res;
};

type FetchCSVQuestionResultT = {
  filename: string;
};
export const fetchCSVQuestion = async (): Promise<
  CallApiResultT<FetchCSVQuestionResultT>
> => {
  const res = await get<FetchCSVQuestionResultT>("/stats-question", {
    csv: 1,
  });
  if (!res.data) {
    throw Error("[fetchStats] " + res.error);
  }
  return res;
};

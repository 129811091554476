import { useContext } from "react";
import { FileT, fetchFiles } from "./api";
import { FileContext } from "./FileContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseFilesResultT extends UseResultT {
  fileList: FileT[];
  setFileList: React.Dispatch<React.SetStateAction<FileT[]>>;
}

interface UseFilesPropsI extends UsePropsI {
  type: string;
}

export const useFiles = ({
  type,
  dontHideProgress = false,
}: UseFilesPropsI): UseFilesResultT => {
  const { fileList, setFileList, loadingDone, setLoadingDone } =
    useContext(FileContext);

  useLoadData({
    fetchFunc: () => fetchFiles(type),
    list: fileList,
    setList: setFileList,
    setLoadingDone,
    dontHideProgress,
  });

  return { fileList, setFileList, loadingDone };
};

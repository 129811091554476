import React, { useContext, useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { SearchField } from "../shared/forms/SearchField";
import Grid from "@mui/material/Grid";

import { Profession } from "./Profession";
import {
  EnhancedTableHead,
  HeadCellT,
  stableSort,
  getComparator,
  OrderDirT,
  handleRequestSort,
  NonOptionalKeys,
  ComparatorT,
} from "../shared/table/EnhancedTable";

import { AddProfession } from "./AddProfession";
import { FileProvider } from "../file/FileContext";

import Box from "@mui/material/Box";
import { ProfessionContext } from "./ProfessionContext";
import { ProfessionT } from "./api";
import { useSearch } from "../shared/hooks/useSearch";
import { useNavigation } from "../candidate/results/useNavigation";
import { RefreshButton } from "./RefreshButton";
import { usePagination } from "../shared/hooks/usePagination";
import { VersionsButton } from "./VersionsButton";
import { TypeSelect } from "./TypeSelect";

const headCells: HeadCellT<ProfessionT>[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  { id: "testId", numeric: false, disablePadding: true, label: "Test-ID" },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "targetValue",
    numeric: false,
    disablePadding: true,
    label: "Sollwert",
  },
  {
    id: "childId",
    numeric: false,
    disablePadding: true,
    label: "Typ",
  },
  {
    id: "countQuestions",
    numeric: true,
    disablePadding: true,
    label: "# Fragen",
  },
  {
    id: "candidateCount",
    numeric: true,
    disablePadding: true,
    label: "# Kand.",
  },
  {
    id: "checkResult",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "demo",
    numeric: false,
    disablePadding: true,
    label: "Aktionen",
    colSpan: 1,
  },
];

export const ProfessionTable = () => {
  const [orderDir, setOrderDir] = useState<OrderDirT>("asc");
  const [orderBy, setOrderBy] = useState<NonOptionalKeys<ProfessionT>>("name");
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("profession");
  const [filter, setFilter] = useSearch("profsearch");
  const [selectedType, setSelectedType] = useState<string>("all");

  const { professionList } = useContext(ProfessionContext);
  const [showVersions, setShowVersions] = useState(false);

  const [filteredProfessionList, setFilteredProfessionList] =
    useState<ProfessionT[]>(professionList);

  useNavigation("remove");

  const comparator: ComparatorT<ProfessionT> = getComparator(orderDir, orderBy);

  useEffect(() => {
    const f = professionList.filter(
      (e) =>
        (!e.childId || showVersions) &&
        (e.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          e.testId.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          e.id.toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0) &&
        (selectedType === "all" || e.type === selectedType)
    );
    setFilteredProfessionList(f);
  }, [filter, professionList, selectedType, showVersions]);

  useEffect(() => {
    console.log(
      "%cProfessionTable.tsx line:123 filteredProfessionList",
      "color: #007acc;",
      filteredProfessionList
    );
  });

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredProfessionList.length - page * rowsPerPage);

  return (
    <Box sx={{ minWidth: "85vw" }}>
      <FileProvider>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <TypeSelect
                  value={selectedType}
                  setValue={setSelectedType}
                  id="selected-type"
                  showAll={true}
                  size="small"
                />
              </Grid>
              <Grid item>
                <SearchField
                  value={filter}
                  setValue={setFilter}
                  id="profsearch"
                  handleChangePage={handleChangePage}
                />
              </Grid>
              <Grid item>
                <RefreshButton />
              </Grid>
              <Grid item>
                <VersionsButton
                  showVersions={showVersions}
                  setShowVersions={setShowVersions}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <AddProfession />
          </Grid>
        </Grid>

        <TableContainer>
          <Table
            sx={{ minWidth: 750, width: "99%" }}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              orderDir={orderDir}
              orderBy={orderBy}
              onRequestSort={(event, property) =>
                handleRequestSort(
                  event,
                  property,
                  orderDir,
                  setOrderDir,
                  orderBy,
                  setOrderBy
                )
              }
              rowCount={filteredProfessionList.length}
            />
            <TableBody>
              {stableSort<ProfessionT>(filteredProfessionList, comparator)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((profession, index) => {
                  return (
                    <Profession
                      profession={profession}
                      key={profession.id}
                      setShowVersions={setShowVersions}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 29 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredProfessionList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => handleChangePage(newPage)}
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(
              event as React.ChangeEvent<HTMLInputElement>
            )
          }
        />
      </FileProvider>
    </Box>
  );
};

import { ReactElement } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const ModalReloaded = ({
  setClose,
}: {
  setClose: () => void;
}): ReactElement => {
  return (
    <div>
      <Dialog open={true} onClose={setClose}>
        <DialogTitle data-testid="dialog-leave-subcategory">
          Achtung!
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="candidate-dialog-content">
            Aus Sicherheitsgründen ist die Nutzung der Browser-Steuerfunktionen
            (z. B. Seite neu laden, Vor-/Zurück-Button) während des Tests
            eingeschränkt. <br />
            <br /> Verwenden Sie stattdessen die Steuerelemente der
            Test-Oberfläche. Andernfalls kann es zu Fehlern im Testablauf kommen
            und Ihre Ergebnisse werden gegebenenfalls nicht gewertet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={setClose}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

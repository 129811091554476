import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { memo, useContext, useState } from "react";
import { Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { CountryT } from "../../country/api";
import { CountryContext } from "../../country/CountryContext";

interface CountrySelectPropI {
  id: string;
  value?: string | number[];
  setValue: ((value: string) => void) | ((value: number[]) => void);
  label?: string;
  labelId?: string;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
  multiple?: boolean;
}

function isStringArrayFunction(fn: any): fn is (value: number[]) => void {
  return (
    typeof fn === "function" &&
    fn.length === 1 && // Check number of parameters
    Array.isArray(fn([]))
  ); // Check return type
}

export const CountrySelect = memo((props: CountrySelectPropI) => {
  const { countryList } = useContext(CountryContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <Typography variant="body2" component="span">
      <Select
        open={open}
        multiple={props.multiple}
        id={props.labelId}
        onClose={handleClose}
        onOpen={handleOpen}
        value={props.value as typeof props.value}
        onChange={(e: SelectChangeEvent<typeof props.value>) => {
          const {
            target: { value },
          } = e;
          if (value)
            if (isStringArrayFunction(props.setValue)) {
              // props.setValue(
              //   // On autofill we get a stringified value.
              //   (typeof value === "string" ? value.split(",") : value).filter(
              //     (e) => {
              //       return parseInt(e) !== 0;
              //     }
              //   ) as number[]
              // );
            } else {
              props.setValue(value as string);
            }
          handleClose();
        }}
        label={props.label}
        size={props.size ? props.size : "small"}
        sx={{
          ...(props.size === "medium"
            ? {}
            : { fontSize: (theme) => theme.typography.body2.fontSize }),
          ...props.sx,
        }}
        data-testid="CountrySelect"
        style={props.style}
      >
        <MenuItem value="0">-</MenuItem>
        {countryList.map((country: CountryT) => (
          <MenuItem key={country.id} value={country.id}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </Typography>
  );
});

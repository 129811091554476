import { IconButton, SxProps, Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CloseModalButtonPropsI {
  close: () => void;
  sx?: SxProps<Theme>;
}

export const CloseModalButton = (props: CloseModalButtonPropsI) => {
  return (
    <IconButton className="close-button" onClick={props.close} sx={props.sx}>
      <CloseIcon />
    </IconButton>
  );
};

import { useTestModusQuestions } from "../testModus/useTestModusQuestions";

import { TestModusStart } from "../testModus/TestModusStart";

import { useParams } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import {
  TestModusContext,
  removeTestModusLocalStoreLSKEYS,
} from "../testModus/TestModusContext";
import { useContext, useEffect } from "react";
import { Loader } from "../shared/Loader";
import { AuthContext } from "../auth/AuthContext";

export const AdminTestModusLoader = () => {
  const { setProfessionId } = useContext(TestModusContext);
  const params = useParams();
  const { questionList } = useTestModusQuestions({
    professionId: parseInt(params.professionId || "0"),
  });
  const { setAuthState } = useContext(AuthContext);

  useEffect(() => {
    removeTestModusLocalStoreLSKEYS();
  }, []);

  useEffect(() => {
    setProfessionId(parseInt(params.professionId || "0"));
  }, [params.professionId, setProfessionId]);

  useEffect(() => {
    setAuthState((p) => ({
      ...p,
      user: {
        ...p.user,
        professionName: params.professionName,
        professionId: parseInt(params.professionId || "0"),
      },
    }));
  }, [params.professionId, params.professionName]);

  if (questionList.length === 0) return <Loader />;

  return (
    <Box className="candidate page" data-testid="candidate">
      <CssBaseline />
      <TestModusStart />
    </Box>
  );
};

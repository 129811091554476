import { Box, Paper, Typography, styled } from "@mui/material";
import { useContext, useState } from "react";
import { fetchLogo, saveUserSettings, updateLogo } from "../auth/api";
import { ErrorContext } from "../error/ErrorContext";
import { FileT } from "../file/api";
import { CustomerFileUpload } from "./CustomerFileUpload";
import { useLoadData } from "../shared/hooks/useLoadData";
import { ColorPicker } from "./ColorPicker";
import { TestModusHeader } from "../testModus/TestModusHeader";
import { questionExample, subCategoryInfoExample } from "./questionExamples";
import { QuestionSwitch } from "../testModus/QuestionSwitch";
import { debounce } from "lodash";
import { AuthContext } from "../auth/AuthContext";
import { ButtonRightSvg } from "../testModus/ButtonRightSvg";
import { ButtonLeftSvg } from "../testModus/ButtonLeftSvg";

const StyledImg = styled("img")({});

const StyledLogo = styled("img")({
  height: "60px",
  position: "absolute",
  bottom: 0,
});

const debouncedSaveUserSettings = debounce(
  (v: string, k: string, f: () => void) => {
    saveUserSettings(k, v).catch((error) => {
      if (error.response.status === 403) {
        window.location.href = "/customer";
      }
    });
    if (f) f();
  },
  1000
);

export const CustomerLayout = () => {
  const [fileList, setFileList] = useState<FileT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const { setError } = useContext(ErrorContext);

  const { authState, setAuthState } = useContext(AuthContext);
  const pColor =
    authState.settings && authState.settings.primaryColor
      ? authState.settings.primaryColor
      : "rgb(0, 141, 211, 1)";
  const sColor =
    authState.settings && authState.settings.secondaryColor
      ? authState.settings.secondaryColor
      : "rgb(255, 255, 255, 1)";

  const pColorArr = pColor.substring(4, pColor.length - 1).split(",");
  const sColorArr = sColor.substring(4, sColor.length - 1).split(",");
  const pColorObj = {
    r: parseInt(pColorArr[0]),
    g: parseInt(pColorArr[1]),
    b: parseInt(pColorArr[2]),
    a: parseFloat(pColorArr[3]),
  };
  const sColorObj = {
    r: parseInt(sColorArr[0]),
    g: parseInt(sColorArr[1]),
    b: parseInt(sColorArr[2]),
    a: parseFloat(sColorArr[3]),
  };

  const [color1, setColor1] = useState({ ...pColorObj });
  const [color2, setColor2] = useState({ ...sColorObj });
  const [colorKey, setColorKey] = useState(1);

  const onChangeColor = (
    no: number,
    c: { r: number; g: number; b: number; a: number }
  ) => {
    if (no === 1) setColor1(c);
    else setColor2(c);
    setColorKey(colorKey + 1);

    if (no === 1)
      debouncedSaveUserSettings(
        "rgb(" + c.r + ", " + c.g + ", " + c.b + ", " + c.a + ")",
        "primaryColor",
        () =>
          setAuthState({
            ...authState,
            settings: {
              ...authState.settings,
              primaryColor:
                "rgb(" + c.r + ", " + c.g + ", " + c.b + ", " + c.a + ")",
            },
          })
      );
    else
      debouncedSaveUserSettings(
        "rgb(" + c.r + ", " + c.g + ", " + c.b + ", " + c.a + ")",
        "secondaryColor",
        () =>
          setAuthState({
            ...authState,
            settings: {
              ...authState.settings,
              secondaryColor:
                "rgb(" + c.r + ", " + c.g + ", " + c.b + ", " + c.a + ")",
            },
          })
      );
  };

  const onUpload = (files: FileT[]) => {
    updateLogo(files[0])
      .then((result) => {
        if (result && result.data) {
          setFileList([files[0]]);
        } else setError(result.error);
      })
      .catch((error) => {
        console.error("uploadFile catch", error);
        setError(error);
      });
  };

  useLoadData({
    fetchFunc: () => fetchLogo(),
    list: fileList,
    setList: (f) => setFileList([f]),
    setLoadingDone: setLoadingDone,
    dontHideProgress: false,
  });

  if (!loadingDone) return <></>;

  return (
    <Box
      className="customer-design"
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "stretch",
        justifyContent: "stretch",
        // width: "100%",
        height: "90%",
        // border: "1px solid blue",
        flexDirection: "row",
        minWidth: "1300px",
      }}
    >
      <Box sx={{ minWidth: "540px" }}>
        <Paper
          sx={{
            m: 2,
            p: 2,
            pl: 1,
            mr: 0,
            display: "flex",
            flexDirection: "column",
            //height: 240,
            height: "95%",
          }}
        >
          <Typography variant="h1" component="h1" sx={{ pl: 1, pb: 2 }}>
            Ihr Design
          </Typography>
          <Typography variant="body1" component="p" sx={{ pl: 1, pb: 2 }}>
            Legen Sie Ihr Logo fest (PNG, JPG, GIF, SVG)
          </Typography>
          <CustomerFileUpload
            type="logo"
            fileList={fileList}
            setFileList={setFileList}
            maxNoFiles={1}
            onUpload={onUpload}
          />

          <Typography variant="h2" component="h2" sx={{ mt: 2, mb: -1, pb: 0 }}>
            Hauptfarbe
          </Typography>
          <ColorPicker color={color1} setColor={(c) => onChangeColor(1, c)} />
          <Typography variant="h2" component="h2" sx={{ mt: 2, mb: -1, pb: 0 }}>
            Akzentfarbe
          </Typography>
          <ColorPicker color={color2} setColor={(c) => onChangeColor(2, c)} />
        </Paper>
      </Box>
      <Box sx={{ flex: "1" }}>
        <Paper
          sx={{
            m: 2,
            ml: 0,
            p: 2,
            pl: 1,
            mr: 0,
            display: "flex",
            flexDirection: "column",
            height: "95%",
            width: "95%",
            minWidth: "700px",
          }}
          id="customer-layout-preview"
        >
          <Typography variant="h1" component="h1" sx={{ pl: 1, pb: 2 }}>
            Vorschau
          </Typography>
          {fileList && fileList.length > 0 && fileList[0].filePath ? (
            <Box className="image-container">
              <StyledImg
                src={import.meta.env.VITE_IMGURL + "/" + fileList[0].filePath}
                alt={fileList[0].name}
                sx={{ width: 200 }}
              />
            </Box>
          ) : (
            <Box
              className="logo degeba-logo"
              sx={{
                height: 100,
                backgroundPosition: "left",
                // width: "100% !important",
              }}
            ></Box>
          )}
          <Box component="main" id="main" data-testid="main">
            <TestModusHeader
              question={questionExample}
              timeTotal={0}
              timeAct={10}
              subCategoryInfo={subCategoryInfoExample}
              testmodusHeadSx={{
                backgroundColor:
                  "rgb(" +
                  color1.r +
                  "," +
                  color1.g +
                  "," +
                  color1.b +
                  "," +
                  color1.a +
                  ") !important",
              }}
              testmodusHeadInnerSx={{
                color:
                  "rgb(" +
                  color2.r +
                  "," +
                  color2.g +
                  "," +
                  color2.b +
                  "," +
                  color2.a +
                  ") !important",
              }}
              testTimerSx={{
                backgroundColor:
                  "rgb(" +
                  color2.r +
                  "," +
                  color2.g +
                  "," +
                  color2.b +
                  "," +
                  color2.a +
                  ") !important",
                color:
                  "rgb(" +
                  color1.r +
                  "," +
                  color1.g +
                  "," +
                  color1.b +
                  "," +
                  color1.a +
                  ") !important",
              }}
              key={"testmodus-header" + colorKey}
            />
            <Box
              data-color={
                "rgb(" +
                color1.r +
                "," +
                color1.g +
                "," +
                color1.b +
                "," +
                color1.a +
                ")"
              }
              data-colorkey={colorKey}
            ></Box>
            <QuestionSwitch
              question={questionExample}
              setQuestionList={() => {}}
            />

            <Box
              className="pager"
              sx={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                // mt: 10,
                // border: "1px solid red",
              }}
            >
              {fileList && fileList.length > 0 && fileList[0].filePath && (
                <StyledLogo src="/img/logo_trans.png" />
              )}
              <Box className="pager-inner" sx={{ pt: 5 }}>
                <Box
                  className="btn left"
                  sx={{
                    backgroundColor:
                      "rgb(" +
                      color1.r +
                      "," +
                      color1.g +
                      "," +
                      color1.b +
                      "," +
                      color1.a +
                      ") !important",

                    fill:
                      "rgb(" +
                      color2.r +
                      "," +
                      color2.g +
                      "," +
                      color2.b +
                      "," +
                      color2.a +
                      ") !important",
                  }}
                >
                  <ButtonLeftSvg />
                </Box>
                <Box
                  className="btn right"
                  data-testid="button-next-question"
                  sx={{
                    backgroundColor:
                      "rgb(" +
                      color1.r +
                      "," +
                      color1.g +
                      "," +
                      color1.b +
                      "," +
                      color1.a +
                      ") !important",

                    fill:
                      "rgb(" +
                      color2.r +
                      "," +
                      color2.g +
                      "," +
                      color2.b +
                      "," +
                      color2.a +
                      ") !important",
                  }}
                >
                  <ButtonRightSvg />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

import { Box, Button, Card, Typography } from "@mui/material";
import { TargetT, deleteTargets, updateTargets } from "./api";
import Slider from "@mui/material/Slider";
import { useContext, useEffect, useState } from "react";
import { TargetContext } from "./TargetContext";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { set } from "lodash";

const TargetEntry = ({
  target,
  setHasChanges,
  targetList,
  setTargetList,
}: {
  target: TargetT;
  setHasChanges: (hasChanges: boolean) => void;
  targetList: TargetT[];
  setTargetList: (targetList: TargetT[]) => void;
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "primary.light",
        mb: 2,
        pt: 0,
        pb: 0,
        pl: 3,
        pr: 3,
      }}
      className={target.name === "Gesamtergebnis" ? "target-total" : ""}
    >
      <div className="target-name">{target.name}</div>
      <div className="target-value">{target.target}</div>

      <Slider
        size="small"
        aria-label="Small"
        marks={[
          {
            value: 0,
            label: "0%",
          },
          {
            value: 50,
            label: "50%",
          },
          {
            value: 100,
            label: "100%",
          },
        ]}
        valueLabelDisplay="auto"
        value={target.target}
        onChange={(event: Event, newValue: number | number[]) => {
          console.log(
            "%cTargets.tsx line:47 newValue, target.mainCategoryId",
            "color: #007acc;",
            newValue,
            target.mainCategoryId
          );
          const newTargetList = targetList.map((t) => {
            if (t.mainCategoryId === target.mainCategoryId) {
              return { ...t, target: newValue as number };
            }
            return t;
          });
          setTargetList(newTargetList);
          setHasChanges(true);
        }}
      />
    </Card>
  );
};

export const tooltip = (
  <>
    Hier geben Sie an, welchen Prozentwert der Maximalpunktzahl Sie insgesamt
    und in den jeweiligen Testbereichen zum Bestehen voraussetzen. Mit Ausnahme
    der Kategorie &quot;Konzentrationsvermögen&quot; (falls in Ihrem Test
    vorhanden) sollte das Ergebnis im Allgemeinen nicht unter 50 % liegen.
    <br />
    <br />
    Aufgrund der empirischen Erfahrungswerte ergeben sich zur Einschätzung der
    Ergebnisse folgende Orientierungsgrößen:
    <br />
    <ul>
      <li>
        86–100 %: sehr gutes Ergebnis, voll befriedigende Kompetenzen in allen
        Testkategorien, entspricht insgesamt einem sehr hohen Leistungsniveau
      </li>
      <li>
        71–85 %: gutes Ergebnis, in allen Testkategorien mindestens erfüllte
        Grundanforderungen, entspricht insgesamt einem hohen Leistungsniveau
      </li>
      <li>
        61–70 %: zufriedenstellendes Ergebnis, in den wichtigsten Testkategorien
        erfüllte Grundanforderungen, entspricht insgesamt einem
        überdurchschnittlichen Leistungsniveau
      </li>
      <li>
        50–60 %: akzeptables Ergebnis, weitgehend erfüllte Grundanforderungen,
        entspricht insgesamt einem ausreichenden Leistungsniveau
      </li>
    </ul>
  </>
);

export const Targets = ({
  professionId,
  close,
  onUpdate,
}: {
  professionId: number;
  close: () => void;
  onUpdate: (p: number, n: number) => void;
}) => {
  const { targetList, setTargetList } = useContext(TargetContext);

  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmResetTargets, setShowConfirmResetTargets] = useState(false);

  const [differsDefault, setDiffersDefault] = useState(false);
  useEffect(() => {
    console.log(
      "%cTargets.tsx line:124 targetList",
      "color: #007acc;",
      targetList
    );
    setDiffersDefault(false);
    targetList.forEach((t) => {
      if (t.target !== t.standard) setDiffersDefault(true);
    });
  }, [targetList]);

  const resetTargets = () => {
    deleteTargets(professionId).then((result) => {
      if (result.success && result.data) {
        setTargetList(result.data);
        setHasChanges(false);
        setShowConfirmResetTargets(false);
        onUpdate(professionId, result.data[0].target);
      }
    });
  };

  return (
    <>
      <Box sx={{ width: "650px" }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Sollprofil{" "}
          <TooltipIcon
            type="info"
            title={tooltip}
            sx={{ position: "relative", top: "3px" }}
          />
        </Typography>
        <Box sx={{ width: "100%" }}>
          {[targetList.find((t) => t.name === "Gesamtergebnis") as TargetT]
            .concat(
              targetList
                .filter((target) => target.name !== "Gesamtergebnis")
                .sort((a, b) => (a.name < b.name ? -1 : 1))
            )
            .map((target) => (
              <TargetEntry
                key={"target:" + target.mainCategoryId}
                target={target}
                setHasChanges={setHasChanges}
                targetList={targetList}
                setTargetList={setTargetList}
              />
            ))}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: "12px",
              width: "300px",
              color: "white",
              position: "relative",
              right: 0,
            }}
            disabled={!differsDefault}
            onClick={() => setShowConfirmResetTargets(true)}
          >
            Zurücksetzen
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasChanges}
            sx={{
              fontSize: "12px",
              width: "300px",
              color: "white",
              position: "relative",
              right: 0,
            }}
            onClick={() => {
              updateTargets(targetList);
              setHasChanges(false);
              onUpdate(professionId, targetList[0].target);
              close();
            }}
          >
            Profil Speichern
          </Button>
        </Box>
      </Box>

      {showConfirmResetTargets && (
        <ConfirmDialog
          open={showConfirmResetTargets}
          handleYes={() => {
            resetTargets();
          }}
          handleNo={() => setShowConfirmResetTargets(false)}
          title="Sollprofil zurücksetzen?"
          content="Wollen Sie das Sollprofil wieder auf die Ausgangswerte zurücksetzen?"
          textYes="Jetzt zurücksetzen"
          textNo="Abbrechen"
        />
      )}
    </>
  );
};

import { useContext } from "react";
import { fetchRelations } from "./api";
import { RelationContext } from "./RelationContext";
import { RelationT } from "./api";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";
import {
  RELATIONREDUCERACTIONS,
  RelationReducerPropsPayloadT,
} from "./relationReducer";

export interface useRelationsResultT extends UseResultT {
  relationList: RelationT[];
  setRelationList: React.Dispatch<RelationReducerPropsPayloadT>;
}

interface UseRelationsPropsI extends UsePropsI {}

export const useRelations = ({
  dontHideProgress,
}: UseRelationsPropsI): useRelationsResultT => {
  const {
    relationList,
    setRelationList,

    loadingDone,
    setLoadingDone,
  } = useContext(RelationContext);

  useLoadData({
    fetchFunc: () => fetchRelations(),
    list: relationList,
    setList: (data) =>
      setRelationList({
        action: RELATIONREDUCERACTIONS.INIT,
        data: data as RelationT[],
      }),
    setLoadingDone: setLoadingDone,
    dontHideProgress: true,
  });

  return {
    relationList,
    setRelationList,
    loadingDone,
  };
};

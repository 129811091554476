import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { QuestionResultsT } from "../../api";
import { ResultIcon } from "../ResultIcon";

interface AnswerClozeTextPropsI {
  question: QuestionResultsT;
}

export const ResultClozeText = ({ question }: AnswerClozeTextPropsI) => {
  if (question.answerOptionsDecoded.format === "table") {
    const header =
      question.answerOptionsDecoded.data[1][0].type === "separator"
        ? question.answerOptionsDecoded.data.slice(0, 1)[0]
        : undefined;
    const data = header
      ? question.answerOptionsDecoded.data.slice(2)
      : question.answerOptionsDecoded.data;

    return (
      <TableContainer>
        <Table>
          {header && (
            <TableHead>
              <TableRow>
                {header.map((o: any, idx2: number) => {
                  return (
                    <TableCell
                      sx={{ fontWeight: "bold", backgroundColor: "#e0e0e0" }}
                      key={"clozetextpart" + idx2}
                    >
                      {clozeTextPart({
                        o,
                        question,
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.map((line: any, idx: number) => {
              return (
                <TableRow key={"row" + idx}>
                  {line.map((o: any, idx2: number) => (
                    <TableCell key={"cell" + idx + idx2}>
                      {clozeTextPart({
                        o,
                        question,
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <Box>
        {(question.answerOptionsDecoded.data as any[])?.map((o, idx3) => {
          return (
            <span key={"clozetextpart" + idx3}>
              {clozeTextPart({ o, question })}
            </span>
          );
        })}
      </Box>
    </>
  );
};

interface ClozeTextPartPropsI {
  o: any;
  question: QuestionResultsT;
}

const clozeTextPart = ({ question, o }: ClozeTextPartPropsI) => {
  if (o.type === "label") return <b>{o.content}</b>;

  const candidateAnswer =
    question.candidateAnswerDecoded && question.candidateAnswerDecoded[o.index]
      ? question.candidateAnswerDecoded[o.index]
      : "______";

  return (
    <>
      {candidateAnswer}{" "}
      {candidateAnswer === o.correct ? (
        <ResultIcon correct />
      ) : (
        <>
          <ResultIcon incorrect />({o.correct})
        </>
      )}
    </>
  );
};

import React, { createContext, useState } from "react";
import { QuestionT } from "./api";
import { ContextPropsT } from "../shared/types/Context";

// createContext: erzeugt ein Context obects, das alles möglich sein kann
// in diesem Fall ist es Variable und Setz-Funktion auf dem useState-Hook!
// daher muss die Typisierung dem useState-Hook folgen
// unklar warum erst Dispatch, dann SetStateAction, vermutlich wird es weitergereicht

interface QuestionContextPropsT extends ContextPropsT {
  questionList: QuestionT[];
  setQuestionList: React.Dispatch<React.SetStateAction<QuestionT[]>>;
  changedQuestionList: QuestionChangedT[];
  setChangedQuestionList: React.Dispatch<
    React.SetStateAction<QuestionChangedT[]>
  >;
  forceReload: boolean;
  setForceReload: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface QuestionChangedT extends QuestionT {
  error?: boolean;
}

export const questionContextDefaults: QuestionContextPropsT = {
  questionList: [],
  setQuestionList: () => {},
  changedQuestionList: [],
  setChangedQuestionList: () => {},
  forceReload: false,
  setForceReload: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
};

export const QuestionContext = createContext<QuestionContextPropsT>(
  questionContextDefaults
);
export const QuestionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [questionList, setquestionList] = useState<QuestionT[]>([]);
  const [changedquestionList, setChangedquestionList] = useState<QuestionT[]>(
    []
  );
  const [forceReload, setForceReload] = useState(false);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <QuestionContext.Provider
      value={{
        questionList,
        setQuestionList: setquestionList,
        changedQuestionList: changedquestionList,
        setChangedQuestionList: setChangedquestionList,
        forceReload: forceReload,
        setForceReload: setForceReload,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

import React, { useState, useContext, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { ShopOrderEntry } from "./ShopOrderEntry";

import { ShopOrderContext } from "./ShopOrderContext";
import { Box, Button, Typography } from "@mui/material";
import { getStoredCart } from "./cart";

interface ShopOrderTablePropsI {}

export const ShopOrderTable = (props: ShopOrderTablePropsI) => {
  const { orderList, setOrderList } = useContext(ShopOrderContext);
  const [cartNotEmpty, setCartNotEmpty] = useState<boolean>(
    getStoredCart().length > 0
  );
  const [openOrderIds, setOpenOrderIds] = useState<number[]>([
    orderList[0]?.orderId,
  ]);

  const mappedOrderList = [];
  let lastOrderId = 0;
  let priceTotal = 0;
  let priceShipping = 0;
  for (let i = 0; i < orderList.length; i++) {
    priceTotal +=
      orderList[i].pricePremium * 1 +
      orderList[i].priceAdd * 1 +
      orderList[i].priceBooks * 1 +
      orderList[i].priceSolutions * 1 +
      orderList[i].priceBranding * 1 +
      orderList[i].priceLicense * 1;

    if ((orderList[i].priceShipping || 0) > 0)
      priceShipping = orderList[i].priceShipping || 0; /* no sum, only once */

    mappedOrderList.push({
      ...orderList[i],
      isFirst: orderList[i].orderId !== lastOrderId,
      isLast: orderList[i + 1]
        ? orderList[i + 1].orderId !== orderList[i].orderId
        : true,
      priceTotal,
      priceShipping,
    });

    if (orderList[i + 1] && orderList[i + 1].orderId !== orderList[i].orderId) {
      priceTotal = 0;
      priceShipping = 0;
    }

    lastOrderId = orderList[i].orderId;
  }

  return (
    <>
      <Box className="component-shop-order-table">
        <Typography variant="h1" component="h1">
          Meine Bestellungen
        </Typography>
        <TableContainer
          sx={{
            mt: 2,
            height: "calc(100vh - 335px)",
            width: "auto",
          }}
        >
          <Table
            sx={{
              minWidth: 750,
            }}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            className="enhanced-table enhanced-table-collapse"
          >
            <TableBody className="enhanced-table-body pr">
              {mappedOrderList.map((order, index) => {
                return (
                  <ShopOrderEntry
                    key={order.id}
                    order={order}
                    setCartNotEmpty={setCartNotEmpty}
                    isOpen={openOrderIds.includes(order.orderId)}
                    setIsOpen={(isOpen: boolean) => {
                      if (isOpen) {
                        setOpenOrderIds([...openOrderIds, order.orderId]);
                      } else {
                        setOpenOrderIds(
                          openOrderIds.filter((id) => id !== order.orderId)
                        );
                      }
                    }}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mr: 2 }}>
          <Button
            variant="contained"
            disabled={!cartNotEmpty}
            onClick={() => {
              window.location.href = "/customer/cart";
            }}
          >
            Nachbestellen
          </Button>
        </Box>
      </Box>
    </>
  );
};

import { Box, Tooltip } from "@mui/material";
import { RatingT } from "./api";

const ratingIcon = (rating: RatingT) => {
  switch (rating) {
    case "easy":
      return (
        <Box sx={{ width: 40 }}>
          🟢<span className="rating-caption">E</span>
        </Box>
      );
    case "medium":
      return (
        <Box sx={{ width: 40 }}>
          🟡<span className="rating-caption yellow">M</span>
        </Box>
      );
    case "hard":
      return (
        <Box sx={{ width: 40 }}>
          🔴<span className="rating-caption">S</span>
        </Box>
      );
    case "none":
      return (
        <Box sx={{ width: 40 }}>
          ⚪<span className="rating-caption">-</span>
        </Box>
      );
  }
};

const ratingLabel = (rating: RatingT) => {
  switch (rating) {
    case "easy":
      return "einfach";
    case "medium":
      return "mittel";
    case "hard":
      return "schwer";
    case "none":
      return "ohne LMS";
  }
};

interface RatingFlagPropI {
  rating: RatingT;
}

export const RatingFlag = ({ rating }: RatingFlagPropI) => {
  return (
    <Tooltip title={<div className="tooltip-text">{ratingLabel(rating)}</div>}>
      <div>{ratingIcon(rating)}</div>
    </Tooltip>
  );
};

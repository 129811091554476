import { useContext, useState } from "react";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { SubCategoryT } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";

import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { isEmpty } from "lodash";
import { SubCategoryContext } from "../subCategory/SubCategoryContext";

interface SubCategoryFormPropsI {
  category?: SubCategoryT;
  onClick: (data: SubCategoryT) => void;
}

const EMPTYCATEGORY: SubCategoryT = {
  id: 0,
  name: "",
  view: 0,
  introText: "",
  memTime: 0,
  memText: "",
  active: 0,
  order: 0,
};

export const SubCategoryForm = (props: SubCategoryFormPropsI) => {
  const { categoryList } = useContext(SubCategoryContext);

  const [categoryState, setCategoryState] = useState<SubCategoryT>(
    props.category ? props.category : EMPTYCATEGORY
  );
  const { showProgress } = useContext(FeedbackContext);
  const fields2check = ["name"];
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f) => {
      const error = categoryState[f as keyof SubCategoryT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });
    if (oneError) return;
    console.log("teststt");
    if (!isEmpty(categoryState.name)) {
      const error = categoryList.some(
        (u) =>
          u.name === categoryState.name &&
          (!categoryState.id || categoryState.id !== u.id)
      );

      setErrorState((p) =>
        buildErrorState(p, "name", error, "Dieser Name ist bereits vorhanden!")
      );
    }

    showProgress();

    props.onClick({
      ...categoryState,
      name: categoryState.name,
      active: categoryState.active,
      id: 0,
    });
  };

  return (
    <div>
      <StyledFormControl required={true}>
        <TextField
          id="name"
          label="Name"
          value={categoryState.name}
          error={errorState.name?.error}
          helperText={
            errorState.name?.error
              ? errorState.name?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setCategoryState({
              ...categoryState,
              name: e.target.value,
            })
          }
        />
      </StyledFormControl>

      <StyledFormControl>
        <TextField
          id="view"
          label="Anzeige"
          value={categoryState.view}
          type="number"
          onChange={(e) =>
            setCategoryState({
              ...categoryState,
              view: e.target.value as unknown as number,
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id="introText"
          label="Intro Text"
          value={categoryState.introText}
          onChange={(e) =>
            setCategoryState({
              ...categoryState,
              introText: e.target.value,
            })
          }
        />
      </StyledFormControl>
      <div>
        <StyledFormControl>
          <Button variant="contained" color="primary" onClick={onClick}>
            Add
          </Button>
        </StyledFormControl>
      </div>
    </div>
  );
};

import * as React from "react";

import { SxProps, Theme, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { Copyright } from "../Copyright";
import { Sidebar } from "./Sidebar";
import { saveUserSettings } from "../../auth/api";
import { AuthContext } from "../../auth/AuthContext";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface CustomerContentI {
  children: React.ReactNode;
  hidePaper?: boolean;
  paperSx?: SxProps<Theme>;
}

export const CustomerContent = ({
  children,
  hidePaper,
  paperSx,
}: CustomerContentI) => {
  useEffect(() => {
    console.log(
      "%csrc/components/layout/customer/CustomerContent.tsx:86 import.meta.env.VITE_ENVIRONMEN",
      "color: #007acc;",
      import.meta.env.VITE_ENVIRONMENT
    );
    if (import.meta.env.VITE_ENVIRONMENT === "local") return;
    const blockContextMenu = (evt: MouseEvent) => {
      evt.preventDefault();
    };

    window.addEventListener("contextmenu", blockContextMenu);

    return () => {
      window.removeEventListener("contextmenu", blockContextMenu);
    };
  });

  const { authState, setAuthState } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(
    authState && authState.settings && authState.settings.drawerState
      ? authState.settings.drawerState === "true"
        ? true
        : false
      : localStorage.getItem("drawerState") === "false"
      ? false
      : true
  );
  const toggleDrawer = () => {
    setOpen(!open);
    setAuthState((p) => ({
      ...p,
      settings: { ...p.settings, drawerState: (!open).toString() },
    }));
    localStorage.setItem("drawerState", open ? "false" : "true");
    saveUserSettings("drawerState", open ? "false" : "true");
  };

  return (
    <>
      <Helmet>
        <title>degeba - Testverwaltung</title>
      </Helmet>
      <Box sx={{ display: "flex" }} className="component-customer-content">
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              degeba Testverwaltung
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Sidebar />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {hidePaper ? (
            <>{children}</>
          ) : (
            <Paper
              sx={{
                m: 2,
                p: 2,
                display: "flex",
                flexDirection: "column",
                ...(window.location.href.indexOf("/candidate") > -1
                  ? {
                      // height: "calc(100vh - 140px)",
                      // overflowY: "auto",
                      // width: "calc(100vw - " + (open ? "275px" : "105px") + ")",
                    }
                  : {}),
                ...paperSx,
              }}
            >
              {children}
            </Paper>
          )}
          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </>
  );
};

import { useContext, useState } from "react";
import { RelationT, updateRelationBulk } from "./api";
import { RelationContext } from "./RelationContext";
import { FilteredMainCategoryT } from "./RelationTable";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ellipsisSx } from "../shared/styles/sx";
import { EditableEntry } from "../shared/list/EditableEntry";
import { MoveButton } from "./MoveButton";
import { RELATIONREDUCERACTIONS } from "./relationReducer";
import Typography from "@mui/material/Typography";
import { SelTreeT, SetSelTreeT } from "./useSelTree";
import { Tooltip } from "../shared/forms/Tooltip";

interface MainCategoryPropsI {
  mainCategory: FilteredMainCategoryT;
  selTree: SelTreeT;
  setSelTree: SetSelTreeT;
  moveMainCategory: (direction: string, id: number) => void;
  onClick: () => void;
  isMainCategorySelected: (i: number) => boolean;
}
export const MainCategory = ({
  mainCategory,
  selTree,
  setSelTree,
  moveMainCategory,
  onClick,
  isMainCategorySelected,
}: MainCategoryPropsI) => {
  const { relationList, setRelationList } = useContext(RelationContext);

  const relOwn = relationList.find(
    (r: RelationT) =>
      r.professionId === selTree.professionId &&
      r.mainCategoryId === mainCategory.id
  );
  const [mainName, setMainName] = useState(relOwn?.mainCategoryName);

  const countQuestions =
    relOwn?.subRelations.reduce((p, a) => p + a.questionRelations.length, 0) ||
    0;

  const hasEmptySubRelation = relOwn?.subRelations.some(
    (s) => s.questionRelations.length === 0
  );

  const saveMainName = () => {
    console.log("saveMainNam");
    updateRelationBulk([
      {
        type: "main",
        id: relOwn?.id || 0,
        mainCategoryName: mainName,
      },
    ]);

    setRelationList({
      action: RELATIONREDUCERACTIONS.SAVEMAIN,
      data: {
        professionId: selTree.professionId,
        mainCategoryId: mainCategory.id,
        mainName: mainName || "",
      },
    });
  };

  /** ************************************************************************
   *
   * inserts one MainCat in the List
   * cat-name is editable if mainCategory is selected and owned ( = relation exists)
   * alt. name will be save onClickAway
   *
   */

  return (
    <Box
      key={mainCategory.id}
      sx={{
        ...(mainCategory.id === selTree.mainCategoryId
          ? { background: "lightgray" }
          : {}),
        fontWeight: relOwn ? "bold" : "",
        mb: 1,
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={8} sx={{ ...ellipsisSx }}>
          <Tooltip title={"id:" + mainCategory.id + " o:" + mainCategory.order}>
            <Typography
              variant="body2"
              key={mainCategory.id}
              onClick={onClick}
              sx={{
                ...(isMainCategorySelected(mainCategory.id)
                  ? { fontWeight: "bold" }
                  : {}),
                mb: 1,
                ...ellipsisSx,
              }}
            >
              {mainCategory.name}
            </Typography>
          </Tooltip>

          {countQuestions >= 0 && isMainCategorySelected(mainCategory.id) && (
            <Typography
              variant="body2"
              sx={{
                fontWeight: "bold",
                color: countQuestions === 0 || hasEmptySubRelation ? "red" : "",
              }}
            >
              ({countQuestions})
            </Typography>
          )}
          {mainCategory.id === selTree.mainCategoryId && relOwn && (
            <EditableEntry
              id="mainNameEditable"
              value={mainName || ""}
              setValue={(v) => setMainName(v as string)}
              showIcon={true}
              onClickAway={() => saveMainName()}
              label="Alternat. Name"
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {mainCategory.order > mainCategory.minOrder &&
          mainCategory.order < 10000 ? (
            <MoveButton
              direction="up"
              onClick={() => moveMainCategory("up", mainCategory.id)}
            />
          ) : (
            <MoveButton direction="up" disabled={true} />
          )}
        </Grid>
        <Grid item xs={2}>
          {mainCategory.order < mainCategory.maxOrder ? (
            <MoveButton
              direction="down"
              onClick={() => moveMainCategory("down", mainCategory.id)}
            />
          ) : (
            <MoveButton direction="down" disabled={true} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

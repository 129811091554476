import { addTransaction, deleteTransaction, TransactionT } from "../user/api";
import { useContext, useState, Fragment } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { TooltipIcon } from "../shared/forms/TooltipIcon";

import { Dialog } from "../shared/Dialog";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ProfessionSelect } from "../profession/ProfessionSelect";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { ProfessionContext } from "../profession/ProfessionContext";
import { UserContext } from "../user/UserContext";
import TextField from "@mui/material/TextField";
import { deleteStateFromArrayById } from "../shared/helper/state";
import { DeleteButton } from "../shared/forms/DeleteButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import TypeSelect from "../profession/TypeSelect";
import {
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import { ShopOrderContext } from "../order/ShopOrderContext";
import { date_hr } from "../shared/helper/datetime";
interface TransactionsPropsI {
  userId: number;
  professionIds: number[];
}

export const Transactions = ({
  userId,
  professionIds = [],
}: TransactionsPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress, hideProgress } =
    useContext(FeedbackContext);
  const { professionList } = useContext(ProfessionContext);
  const { transactionList, setTransactionList } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [professionId, setProfessionId] = useState(0);
  const [number, setNumber] = useState(1);

  const [professions] = useState<number[]>(professionIds);
  const [typeFilter, setTypeFilter] = useState<string>("basic");
  const [version, setVersion] = useState("0");

  const { orderList } = useContext(ShopOrderContext);
  const [selectedOrderItem, setSelectedOrderItem] = useState<number>(0);

  const filtererdOrderList = orderList.filter(
    (o) =>
      o.customer &&
      o.customer.id === userId &&
      o.type == "premium_online" &&
      !(
        userId in transactionList &&
        transactionList[userId].find((t) => t.orderItemId === o.id)
      )
  );

  const onClickAdd = () => {
    if (!professions || !number) return;
    showProgress();
    addTransaction({
      id: 0,
      userId: userId,
      professionId: professionId,
      number: number,
      orderItemId: selectedOrderItem,
    })
      .then((res) => {
        if (res.success && res.data) {
          setTransactionList((p) => ({
            ...p,
            [userId]: p[userId]
              ? p[userId].concat(res.data as TransactionT)
              : [res.data as TransactionT],
          }));
          setSelectedOrderItem(0);
          openSnackbar("success", "Eintrag gespeichert");
        } else {
          if (res.error && res.error.indexOf("no transaction found") > -1)
            setError("Keine Lizenz zum Reduzieren gefunden");
          else if (res.error && res.error.indexOf("not enough") > -1)
            setError(
              "Nicht genügend nutzbare Lizenzen  zum Reduzieren vorhanden"
            );
          else setError(res.error);
        }
        hideProgress();
      })
      .catch((error) => {
        setError(error);
        hideProgress();
      });
  };

  return (
    <>
      <TooltipIcon
        type="profession"
        onClick={(e) => setOpen(true)}
        title="Tests zuweisen"
      />

      {open && (
        <Dialog
          open={open}
          setOpen={setOpen}
          title="Tests zuweisen"
          showClose={true}
          showCancel={false}
          fullWidth={true}
          maxWidth="md"
        >
          <>
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Beruf</TableCell>
                    <TableCell>Anzahl</TableCell>
                    <TableCell>Genutzt</TableCell>
                    <TableCell>Datum</TableCell>
                    <TableCell>Optionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionList[userId] &&
                    transactionList[userId].map((t) => {
                      const pro = professionList.find(
                        (p) => t.professionId === p.id
                      );

                      return (
                        <TableRow
                          key={"t" + t.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {pro ? pro.name + " " + pro.testId : ""}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {t.number}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {t.numberUsed}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {t.createdAt &&
                              t.createdAt.toLocaleDateString("de-DE") +
                                " " +
                                t.createdAt
                                  .toLocaleTimeString("de-DE")
                                  .substr(0, 5)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DeleteButton
                              id={t.id}
                              deleteFunc={deleteTransaction}
                              {...((t.numberUsed || 0) > 0
                                ? {
                                    disabled: true,
                                    tooltipTitle:
                                      "Der Kunde hat die Lizenz bereits genutzt",
                                  }
                                : {})}
                              successFunc={() =>
                                setTransactionList((p) => {
                                  return {
                                    ...p,
                                    [userId]: deleteStateFromArrayById(
                                      p[userId],
                                      t.id
                                    ),
                                  };
                                })
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography component="h2" variant="h2" sx={{ mt: 5, mb: 1 }}>
              Neue Transaktion hinzufügen
            </Typography>
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{ minWidth: 400 }}
              >
                <Grid item xs="auto">
                  <StyledFormControl
                    sx={{
                      width: "auto",
                      ml: 2,
                      mr: 0,
                      pr: 0,
                    }}
                  >
                    <TypeSelect
                      id="type"
                      labelId="type-label"
                      label="Typ"
                      value={typeFilter}
                      setValue={(n: string) => setTypeFilter(n)}
                      showAll={false}
                      radio={true}
                    />
                  </StyledFormControl>
                </Grid>
                <Grid item xs="auto">
                  <StyledFormControl
                    sx={{
                      ml: 2,
                      pl: 0,
                      mr: 0,
                      pr: 0,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={version === "1"}
                          onChange={(e) =>
                            setVersion(e.target.checked ? "1" : "0")
                          }
                          name="version"
                          color="primary"
                          sx={{
                            ml: 0,
                            pl: 0,
                            pr: 0,
                          }}
                        />
                      }
                      label="Alte Versionen zeigen"
                      style={{
                        width: "auto",
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    />
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs="auto">
                  <StyledFormControl>
                    <InputLabel htmlFor="professionSelect" shrink>
                      Beruf
                    </InputLabel>
                    <ProfessionSelect
                      id="profSelect"
                      value={professionId}
                      setValue={setProfessionId}
                      label="Beruf"
                      labelId="professionSelect"
                      active={true}
                      filterByType={
                        typeFilter && typeFilter !== "all"
                          ? typeFilter
                          : undefined
                      }
                      filterByNoParent={version === "0"}
                    />
                  </StyledFormControl>
                </Grid>
                {typeFilter === "premium" && (
                  <Grid item sx={{ ml: 2 }}>
                    {filtererdOrderList.length > 0 ? (
                      <Select
                        value={selectedOrderItem}
                        onChange={(e) =>
                          setSelectedOrderItem(e.target.value as number)
                        }
                      >
                        <MenuItem value={0}>Keine Bestellung zuweisen</MenuItem>
                        {filtererdOrderList.map((o) => {
                          const c = o.content.split("|");

                          return (
                            <MenuItem key={o.id} value={o.id}>
                              {o.id}: {c[1]} ({date_hr(o.createdAt)})
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      <>Keine offenen Premium-Bestellungen</>
                    )}
                  </Grid>
                )}
                <Grid item sx={{ ml: 2 }}>
                  <TextField
                    size="medium"
                    value={number}
                    label="Anzahl"
                    onChange={(e) => {
                      setNumber(e.target.value as unknown as number);
                    }}
                    type="number"
                    sx={{ width: 100 }}
                    inputProps={{
                      max: 1000,
                    }}
                  />
                </Grid>
                <Grid item sx={{ ml: 1 }}>
                  <Button
                    variant="contained"
                    onClick={() => onClickAdd()}
                    startIcon={<AddBoxIcon />}
                    sx={{ height: 50 }}
                    data-testid="addTransactionIcon"
                  ></Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Dialog>
      )}
    </>
  );
};

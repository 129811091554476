import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Grid,
  Button,
  TablePagination,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { SearchField } from "../shared/forms/SearchField";
import { cachebreaker } from "../shared/helper/url";
import { usePagination } from "../shared/hooks/usePagination";
import { useSearch } from "../shared/hooks/useSearch";
import { fetchCSVQuestion } from "./api";
import { StatsContext } from "./StatsContext";

export const QuestionStatsTable = () => {
  const { statsQuestion } = useContext(StatsContext);

  const { setError } = useContext(ErrorContext);

  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const [filter, setFilter] = useSearch("questionstatsearch");
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("question");

  const onClick = () => {
    showProgress();
    fetchCSVQuestion()
      .then((res) => {
        if (res.success && res.data) {
          const url =
            import.meta.env.VITE_IMGURL +
            "/csv/" +
            res.data?.filename +
            "?" +
            cachebreaker();

          window.location.href = url;
          openSnackbar("success", "CSV generiert");
        } else {
          setError(res.error);
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const filteredQuestionList = statsQuestion.filter(
    (q) =>
      q.id.toString().indexOf(filter.toLowerCase()) >= 0 ||
      (q.question || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
      q.type.toLowerCase().indexOf(filter.toLowerCase()) >= 0
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredQuestionList.length - page * rowsPerPage);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <SearchField
            value={filter}
            setValue={setFilter}
            id="questionsearch"
            handleChangePage={handleChangePage}
          />
        </Grid>
        <Grid item>
          <Button onClick={onClick} variant="contained">
            Download CSV
          </Button>
        </Grid>
      </Grid>
      {isEmpty(statsQuestion) ? (
        <>Keine Daten vorhanden</>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Typ</TableCell>
                  <TableCell>Frage</TableCell>
                  <TableCell>Schwierigkeit</TableCell>
                  <TableCell># richtig</TableCell>
                  <TableCell># falsch</TableCell>
                  <TableCell>Prozent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredQuestionList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((q) => (
                    <TableRow key={"Q" + q.id}>
                      <TableCell>{q.id}</TableCell>
                      <TableCell>{q.type}</TableCell>
                      <TableCell>{q.question}</TableCell>
                      <TableCell>{q.difficulty}</TableCell>
                      <TableCell>{q.countResultsCorrect}</TableCell>
                      <TableCell>{q.countResultsWrong}</TableCell>
                      <TableCell>{q.percentTotalCorrect}</TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 40 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredQuestionList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => handleChangePage(newPage)}
            onRowsPerPageChange={(event) =>
              handleChangeRowsPerPage(
                event as React.ChangeEvent<HTMLInputElement>
              )
            }
          />
        </>
      )}
    </Box>
  );
};

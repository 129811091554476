import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import { fetchQuestions, QuestionT, uploadQuestion } from "./api";
import { useContext } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { QuestionContext } from "./QuestionContext";
import { NonOptionalKeys, OrderDirT } from "../shared/table/EnhancedTable";

const FileInput = styled("input")({
  display: "none",
});

interface QuestionUploadPropsI {
  setOrderBy?: React.Dispatch<React.SetStateAction<NonOptionalKeys<QuestionT>>>;
  setOrderDir?: React.Dispatch<React.SetStateAction<OrderDirT>>;
}

export const QuestionUpload = ({
  setOrderBy,
  setOrderDir,
}: QuestionUploadPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const { setForceReload, setQuestionList, setLoadingDone } =
    useContext(QuestionContext);

  const onQuestionChange = (event: any) => {
    showProgress();
    const formData = new FormData();

    formData.append("file", event.target.files[0]);
    console.log("vor uploadQuestion");
    uploadQuestion(formData)
      .then((result) => {
        if (result.success) {
          setForceReload(true);

          fetchQuestions({})
            .then((result: any) => {
              if (result.success) {
                setQuestionList(result.data);
                setLoadingDone(true);
                openSnackbar("success", "Datei hochgeladen");
                if (setOrderDir) setOrderDir("desc");
                if (setOrderBy) setOrderBy("id");
              } else setError(result.error);
            })
            .catch((error) => {
              setError(error);
            });

          // openSnackbar("success", "Datei hochgeladen ");
        } else setError(result.error);
      })
      .catch((error) => {
        // console.log("onClickDelete catch", error);
        setError(error);
      });
  };

  return (
    <label htmlFor="contained-button-question">
      <FileInput
        accept="application/JSON"
        id="contained-button-question"
        multiple
        type="file"
        name="question"
        onChange={(e) => onQuestionChange(e)}
      />
      <Button variant="contained" component="span">
        Upload
      </Button>
    </label>
  );
};

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ShopOrderItemT, downloadPremium } from "./api";
import { Typography } from "@mui/material";
import { datetime_hr } from "../shared/helper/datetime";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import FileSaver from "file-saver";

interface AdminShopOrderEntryPropsI {
  order: ShopOrderItemT;
}

type TranslateT = { [key in ShopOrderItemT["type"]]: string };

const TRANSLATE: TranslateT = {
  basic: "Basis Papier",
  online: "Basis Online",
  premium: "Premium Papier",
  premium_online: "Premium Online",
};

export const AdminShopOrderEntry = ({ order }: AdminShopOrderEntryPropsI) => {
  const cutContent = order.content.split("|").slice(0, -1).join("|");

  const onClickDownload = (id: number) => {
    const filename = order.content.split("|")[1];
    downloadPremium(id)
      .then((res) => {
        console.log(
          "%cAdminShopOrderEntry.tsx line:33 res",
          "color: #007acc;",
          res.data
        );
        if (res.data) {
          // save to file using FileSaver.js
          console.log("%cAdminShopOrderEntry.tsx line:35 ", "color: #007acc;");
          FileSaver.saveAs(
            new Blob([res.data], { type: "text/html" }),
            filename
          );
        }
      })
      .catch((err) => {
        console.log(
          "%cAdminShopOrderEntry.tsx line:42 err",
          "color: #007acc;",
          err
        );
      });
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={order.id}>
        <TableCell align="left">
          <Typography variant="body2">{order.id}</Typography>
        </TableCell>
        <TableCell padding="none" align="left" sx={{ width: 120 }}>
          <Typography variant="body2">
            {datetime_hr(order.createdAt)}
          </Typography>
        </TableCell>
        <TableCell padding="none" align="left">
          <Typography variant="body2">
            {order.customer && order.customer.email}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">{TRANSLATE[order.type]}</Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2">{cutContent}</Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2">
            {(order.type === "premium" || order.type === "premium_online") &&
              !order.content.includes("Nachbestellung") && (
                <TooltipIcon
                  type="download"
                  title="Download Premiumdata"
                  onClick={() => onClickDownload(order.id)}
                />
              )}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

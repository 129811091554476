import { Button } from "@mui/material";
import { useContext } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { buttonLikeSearchBox } from "../shared/styles/sx";
import { fetchProfessions, ProfessionT } from "./api";
import { ProfessionContext } from "./ProfessionContext";
import CachedIcon from "@mui/icons-material/Cached";
import { SelTreeT, SetSelTreeT } from "../relation/useSelTree";
import { Tooltip } from "../shared/forms/Tooltip";

interface RefreshButtonPropsI {
  selTree?: SelTreeT;
  setSelTree?: SetSelTreeT;
}
export const RefreshButton = ({ selTree, setSelTree }: RefreshButtonPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { showProgress, hideProgress } = useContext(FeedbackContext);
  const { setProfessionList } = useContext(ProfessionContext);

  const onClickRefresh = () => {
    showProgress();
    fetchProfessions({ onlyActive: false, check: true })
      .then((result: any) => {
        if (result.success) {
          setProfessionList(result.data);

          if (selTree && selTree.professionId) {
            const prof = (result.data as ProfessionT[]).find(
              (p) => p.id === selTree.professionId
            );
            console.log("prof:", prof);
            if (prof)
              setSelTree &&
                setSelTree({
                  professionIsActive: prof.active,
                });
          }

          hideProgress();
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Tooltip title="Daten neu laden">
      <Button
        variant="outlined"
        sx={buttonLikeSearchBox}
        startIcon={<CachedIcon />}
        onClick={onClickRefresh}
      />
    </Tooltip>
  );
};

import React, { createContext, useReducer, useState } from "react";
import { RelationT, SubRelationT } from "./api";
import { ContextPropsT } from "../shared/types/Context";
import {
  relationReducer,
  RelationReducerPropsPayloadT,
} from "./relationReducer";

export type SubRelationListT = {
  [key: string]: SubRelationT[];
};

interface RelationContextPropsType extends ContextPropsT {
  relationList: RelationT[];
  setRelationList: React.Dispatch<RelationReducerPropsPayloadT>;
}

export const RelationContext = createContext<RelationContextPropsType>({
  relationList: [],
  setRelationList: () => [] as RelationT[],
  //setRelationList: (s: RelationT[], action: any) => [] as RelationT[],

  loadingDone: false,
  setLoadingDone: () => {},
});
export const RelationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  //const [relationList, setRelationList] = useState<RelationT[]>([]);
  const [relationList, setRelationList] = useReducer(
    relationReducer,
    [] as RelationT[]
  );

  //<RelationT[]>
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <RelationContext.Provider
      value={{
        relationList,
        setRelationList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </RelationContext.Provider>
  );
};

import { useContext, useState } from "react";

import { Box, SxProps, Theme, Typography } from "@mui/material";
import { CallApiResultT } from "../shared/lib/api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ConfirmDialog } from "../shared/ConfirmDialog";

interface TestAccountButtonPropsI {
  testAccount: boolean;
  confirmTitle?: string;
  confirmContent?: string;
  id?: number;
  setFunc: (testAccount: boolean) => void;
  successFunc?: (id: number) => void;
  successMsg?: string;
  className?: string;
  textYes?: string;
  textNo?: string;
  sx?: SxProps<Theme>;
  button?: boolean;
}

export const TestAccountButton = ({
  setFunc,
  testAccount,
  ...props
}: TestAccountButtonPropsI) => {
  const { showProgress } = useContext(FeedbackContext);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleYes = () => {
    showProgress();
    setFunc(!testAccount);
    setDialogOpen(false);
  };

  return (
    <>
      <Typography variant="body2" onClick={(e) => setDialogOpen(true)}>
        <Box sx={testAccount ? { color: "red" } : {}}>
          {testAccount ? "Test" : "Aktiv"}
        </Box>
      </Typography>
      <ConfirmDialog
        open={dialogOpen}
        handleYes={() => handleYes()}
        handleNo={() => setDialogOpen(false)}
        title={props.confirmTitle || "Kontotyp wechseln?"}
        content={
          props.confirmContent || "Möchtest du wirklich den Kontotyp wechseln?"
        }
        textYes={props.textYes}
        textNo={props.textNo}
      />{" "}
    </>
  );
};

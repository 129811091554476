import { Box, Card, Grid } from "@mui/material";
import React from "react";
import { QuestionResultsT } from "../../api";
import { ResultIcon } from "../ResultIcon";

interface AnswerPropsI {
  value: string;
  answer: string;
  correctAnswer: any;
  candidateAnswer: any;
}

const Answer = (props: AnswerPropsI) => {
  const thisIsCorrect = props.correctAnswer === props.value;
  const thisGiven = props.value === props.candidateAnswer;

  return (
    <label
      className={
        "result " +
        (thisGiven ? "cy-given " : "") + // class-name for cypress testing
        (thisIsCorrect ? "green-correct" : "")
      }
    >
      <div className="cb-container">
        {thisGiven && thisIsCorrect && <ResultIcon correct />}
        {thisGiven && !thisIsCorrect && <ResultIcon incorrect />}

        <div className="cb-outline">
          <input
            type="radio"
            checked={false} // {thisGiven || thisIsCorrect}
            // value={props.value}
            readOnly
            id={props.value}
          />
        </div>
      </div>
      <div
        className="result-question-text"
        dangerouslySetInnerHTML={{
          __html: props.answer,
        }}
      />
    </label>
  );
};

interface AnswersPropsI {
  // answerOptions: string;
  // setGivenAnswers: (s: string) => void;
  question: QuestionResultsT;
}

export const ResultMultipleChoice = React.memo(
  ({ question }: AnswersPropsI) => {
    console.log("question:", question);

    const options = question.answerOptionsDecoded || {};
    const answer = question.answerDecoded;
    console.log("options:", options, answer);

    return (
      <Box className="question-container">
        {Object.entries(options).map(
          ([aKey, aStr]: [aKey: string, aStr: any]) => (
            <Answer
              key={aKey}
              value={aKey}
              answer={aStr as string}
              correctAnswer={answer}
              candidateAnswer={question.candidateAnswer}
            />
          )
        )}
      </Box>
    );
  }
);

import { Box, Button, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { checkPassword } from "../auth/api";
import { AuthContext } from "../auth/AuthContext";
import { deleteCandidatesByProfession } from "../candidate/api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { Dialog } from "../shared/Dialog";
import { HiddenInputAutoCompleteOff } from "../shared/forms/HiddenInputAutoCompleteOff";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { updatePartOfStateArrayById } from "../shared/helper/state";
import { ProfessionT } from "./api";

interface CandidateCountPropsI {
  count: number;
  professionId: number;
  setProfessionList: React.Dispatch<React.SetStateAction<ProfessionT[]>>;
}
export const CandidateCount = ({
  count,
  professionId,
  setProfessionList,
}: CandidateCountPropsI) => {
  const [openDeleteCandidates, setOpenDeleteCandidates] = useState(false);
  const [openDeleteCandidatesConfirm, setOpenDeleteCandidatesConfirm] =
    useState(false);
  const [password, setPassword] = useState("");
  const { authState } = useContext(AuthContext);
  const [error, setError] = useState("");
  const { openSnackbar } = useContext(FeedbackContext);

  const handleYes = () => {
    setOpenDeleteCandidatesConfirm(false);
    if (!password) return setError("Bitte ausfüllen");
    checkPassword({ email: authState.user.email, password: password })
      .then((res) => {
        if (res.success) {
          deleteCandidatesByProfession({
            professionId,
            email: authState.user.email,
            password: password,
          }).then((res) => {
            if (res.success) {
              setProfessionList((prev) =>
                updatePartOfStateArrayById(prev, professionId, {
                  candidateCount: 0,
                  resultCount: 0,
                })
              );
              openSnackbar("success", "Kandidaten gelöscht");
              setOpenDeleteCandidates(false);
            } else {
              setError("Fehler beim Löschen");
            }
          });
        } else {
          console.log("ERROR 406");
          setError("Das Passwort ist nicht korrekt!");
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setError(error);
      });
  };

  return (
    <>
      <span onClick={() => count > 0 && setOpenDeleteCandidates(true)}>
        {count}
      </span>
      {openDeleteCandidates && (
        <Dialog
          open={openDeleteCandidates}
          setOpen={setOpenDeleteCandidates}
          title="Testergebnisse löschen"
        >
          <Box>
            <Typography color="error" sx={{ fontWeight: "bold" }}>
              Achtung! Es gibt {count} Kandidaten in diesem Beruf!
            </Typography>
            <br />
            Wollen Sie wirklich alle Testergebnisse zurücksetzen und alle
            Kandidaten dieses Berufes zurücksetzen?
            <br />
            <br />
            <Typography color="error" sx={{ fontWeight: "bold" }}>
              Diese Aktion kann nicht rückgängig gemacht werden!
            </Typography>
            <br />
            Zur Sicherheit muss das Admin-Passwort eingegeben werden: <br />
            <br />
            <StyledFormControl sx={{ ml: 0 }}>
              <HiddenInputAutoCompleteOff
                type="password"
                name="fake-password"
                autoComplete="your-password"
              />

              <TextField
                id="password"
                label="Passwort"
                name="password"
                value={password}
                onChange={(e) => {
                  if (!e.target.value) setError("Bitte ausüllen");
                  else setError("");
                  setPassword(e.target.value);
                }}
                type="password"
                required={true}
                error={error ? true : false}
                autoComplete="your-password"
              />
            </StyledFormControl>
            <br />
            <StyledFormControl sx={{ ml: 0 }}>
              <Button
                variant="contained"
                onClick={() => setOpenDeleteCandidatesConfirm(true)}
              >
                {" "}
                Löschen
              </Button>
            </StyledFormControl>
          </Box>
        </Dialog>
      )}
      <ConfirmDialog
        open={openDeleteCandidatesConfirm}
        handleYes={() => handleYes()}
        handleNo={() => setOpenDeleteCandidatesConfirm(false)}
        title={"Sind Sie sicher?"}
        content={"Wirklich löschen?"}
      />{" "}
    </>
  );
};

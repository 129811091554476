import { useContext, useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MailTemplateT, sendMail } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { Editor } from "@tinymce/tinymce-react";
import { Box } from "@mui/system";
import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ErrorContext } from "../error/ErrorContext";
import { CandidateT } from "../candidate/api";
import { MailTemplateContext } from "./MailTemplateContext";
import { sortBy } from "lodash";
import { getCurrentDomain } from "../shared/helper/url";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { date_hr } from "../shared/helper/datetime";

interface SendMailCandidatePropsI {
  candidate: CandidateT;
  setDialogOpen?: (value: boolean) => void;
  onSend?: (i: number) => void;
}

export const SendMailCandidate = ({
  candidate,
  setDialogOpen,
  onSend,
}: SendMailCandidatePropsI) => {
  const { mailTemplateList } = useContext(MailTemplateContext);
  const { setError } = useContext(ErrorContext);

  const editorRef = useRef(null as any);

  const { showProgress, hideProgress, openSnackbar } =
    useContext(FeedbackContext);
  const fields2check = ["subject", "content"];
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const [dialogSendMailOpen, setDialogSendMailOpen] = useState(false);

  const [mailTemplateState, setMailTemplateState] = useState(
    {} as MailTemplateT
  );

  const domain = import.meta.env.VITE_TESTURL || getCurrentDomain();

  const [select, setSelect] = useState(0);
  useEffect(() => {
    if (select > 0) {
      const mailTemplate = mailTemplateList.find((e) => e.id === select);
      if (mailTemplate) {
        const salutation =
          candidate.gender && candidate.gender === "m"
            ? mailTemplate.salutationM
            : candidate.gender && candidate.gender === "f"
            ? mailTemplate.salutationF
            : mailTemplate.salutationD;
        setMailTemplateState({
          ...mailTemplate,
          content: mailTemplate.content
            .replace("##ANREDE##", salutation || "")
            .replace("##VORNAME##", candidate.firstname || "")
            .replace("##NACHNAME##", candidate.lastname || "")
            .replace(
              "##EINLADUNGSLINK##",
              '<a href="' +
                domain +
                "/test/" +
                candidate.uuid +
                '">' +
                domain +
                "/test/" +
                candidate.uuid +
                "</a>"
            )
            .replace(
              "##ABLAUFDATUM##",
              candidate.professionExpiredAt
                ? date_hr(candidate.professionExpiredAt)
                : "##ABLAUFDATUM##"
            ),
        });
      }
    }
  }, [select]);

  const onClickSendMail = () => {
    console.log("%cSendMailCandidate.tsx line:98 onClickS", "color: red;");
    let oneError = false;

    setMailTemplateState((p) => ({
      ...p,
      content: editorRef.current.getContent(),
    }));

    fields2check.forEach((f) => {
      const error = mailTemplateState[f as keyof MailTemplateT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });
    if (oneError) return;

    showProgress();

    const content = editorRef.current.getContent();

    sendMail(
      mailTemplateState.id,
      candidate.id,
      mailTemplateState.subject,
      content
    )
      .then((res) => {
        hideProgress();
        if (res.success) {
          openSnackbar("success", "Mail gesendet");

          candidate.mailLogs = candidate.mailLogs
            ? candidate.mailLogs.concat([
                {
                  candidateId: candidate.id,
                  mailTemplateId: mailTemplateState.id,
                  createdAt: new Date(),
                },
              ])
            : [
                {
                  candidateId: candidate.id,
                  mailTemplateId: mailTemplateState.id,
                  createdAt: new Date(),
                },
              ];
          if (onSend)
            onSend(content.indexOf("/test/" + candidate.uuid) > -1 ? 1 : 0);
        } else setError("Fehler beim Mail versenden");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <div>
      <Typography variant="h1" component="h1" sx={{ pl: 1, pb: 2 }}>
        Mail verschicken
      </Typography>
      <StyledFormControl>
        <TextField
          id="candidateEmail"
          label="Empfänger"
          value={candidate.email}
          disabled={true}
        />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel htmlFor="mail-template-select">Vorlage wählen</InputLabel>

        <Select
          labelId="mail-template-select-label"
          id="mail-template-select"
          value={select}
          onChange={(e: SelectChangeEvent<number>) => {
            setSelect(e.target.value as number);
          }}
          label="Vorlage wählen"
        >
          <MenuItem value={0}>
            <Typography variant="body2">-</Typography>
          </MenuItem>
          {sortBy(
            mailTemplateList
              .filter((m) => m.id !== -1)
              .map(
                (m: MailTemplateT) =>
                  ({
                    ...m,
                    sentAt:
                      candidate.mailLogs?.find((l) => l.mailTemplateId === m.id)
                        ?.createdAt || 0,
                  } as MailTemplateT)
              ),
            [(e) => e.pos]
          ).map((e) => (
            <MenuItem
              key={"menuitem" + e.id}
              value={e.id}
              sx={{
                width: "100%",

                // p: 0,
                // p: "16px",
              }}
              className="menuitemx"
            >
              <Box
                component="span"
                sx={{
                  display: "inline-flex",
                  // border: "1px solid green",
                  justifyContent: "flex-start",
                  // justifyContent: "space-between",
                  wrap: "nowrap",
                  width: "100%",
                  // m: 0,
                  // p: 0,
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    maxWidth: "440px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {e.description}
                </Box>
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    maxWidth: "160px",
                    fontSize: "0.8rem",
                    // border: "1px solid red",
                    ml: 1,
                  }}
                >
                  {e.sentAt ? (
                    <>&ndash; verschickt am {date_hr(e.sentAt)}</>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <StyledFormControl>
        <TextField
          id="subject"
          label="Betreffzeile"
          key={"subject" + mailTemplateState.id}
          value={mailTemplateState.subject}
          error={errorState.subject?.error}
          helperText={
            errorState.subject?.error
              ? errorState.subject?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setMailTemplateState({
              ...mailTemplateState,
              subject: e.target.value,
            })
          }
          inputProps={{
            maxLength: 255,
          }}
        />
      </StyledFormControl>

      <StyledFormControl>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={
                errorState.content?.error
                  ? {
                      border: "1px solid red",
                    }
                  : {}
              }
            >
              <Editor
                apiKey="ot8ti4mrmg21ckfbqldpnaa759khpd8ntv385nw1s7mxfuia"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={mailTemplateState.content}
                init={{
                  height: 500,
                  language: "de",
                  menubar: false,
                  // word_wrap: true,
                  // plugins: "wordwrap",
                  plugins: ["lists"],
                  paste_as_text: true,
                  resize: true,
                  //   " autolink lists link image charmap print preview anchor",
                  //   "searchreplace visualblocks code fullscreen",
                  //   "insertdatetime media table paste code help wordcount",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic underline | alignleft aligncenter " +
                    "alignright alignjustify | bullist outdent indent | " +
                    "removeformat",
                  content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; moverflow: hidden;  white-space: pre-wrap;   word-wrap: break-word; }                   `,
                  autoformat_remove: true,
                  text_patterns: false,
                  convert_urls: false, // Prevents TinyMCE from making absolute links relative
                }}
              />
            </Box>

            {errorState.content?.error && (
              <Box sx={{ pl: 2, pt: "3px" }}>
                <Typography color="error" variant="caption">
                  Bitte ausfüllen
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </StyledFormControl>

      <Button
        disabled={select === 0}
        variant="contained"
        color="primary"
        onClick={() => {
          if (editorRef.current.getContent().indexOf("##ABLAUFDATUM##") > -1) {
            setError(
              'Ihre Mail enthält einen Platzhalter für das Ablaufdatum. Sie haben für diesen Test allerdings kein Ablaufdatum festgelegt (Dashboard > Übersicht "Meine Online-Tests" > Spalte "Läuft bis"). Bitte holen Sie dies nach oder entfernen Sie den Platzhalter, ehe Sie die Mail verschicken.'
            );
          } else setDialogSendMailOpen(true);
        }}
        sx={{ ml: 1, width: "250px" }}
      >
        Mail versenden
      </Button>

      <ConfirmDialog
        open={dialogSendMailOpen}
        handleYes={() => {
          onClickSendMail();
          setDialogSendMailOpen(false);
          setDialogOpen && setDialogOpen(false);
        }}
        handleNo={() => setDialogSendMailOpen(false)}
        title="Mail verschicken"
        content="Soll die Mail versendet werden?"
      />
    </div>
  );
};

import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { updatePartOfStateArrayById } from "../../shared/helper/state";
import { TestModusQuestionPropsI } from "../QuestionSwitch";
import { Checkbox, Grid } from "@mui/material";

const Answer = (props: {
  candidateAnswer: string;
  value: string;
  answer: string;
  layout: string;
  height?: number;
  width?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const htmlAnswer = (
    <div
    // style={{
    //   display: "flex",
    //   flexDirection: "column",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   ...(props.height ? { height: props.height } : {}),
    //   ...(props.width ? { width: props.width } : {}),
    // }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: props.answer,
        }}
      />
    </div>
  );

  // return (
  //   <FormControlLabel
  //     value={props.value}
  //     control={radioDiv}
  //     label={htmlAnswer}
  //     className="noselect"
  //     {...(props.layout === "horizontal" ? { labelPlacement: "top" } : {})}
  //   />
  // );
  return (
    <label htmlFor={props.value} className="noselect">
      <div className="cb-container">
        <div className="cb-outline">
          <input
            type="radio"
            checked={props.candidateAnswer === props.value}
            onChange={props.onChange}
            value={props.value}
            id={props.value}
          />
        </div>
      </div>
      {htmlAnswer}
    </label>

    //   {...(props.layout === "horizontal" ? { labelPlacement: "top" } : {})}
  );
};

export const AnswersMultipleChoice = React.memo(
  ({ question, setQuestionList }: TestModusQuestionPropsI) => {
    const options = question.answerOptionsDecoded;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setQuestionList)
        setQuestionList((prevState) =>
          updatePartOfStateArrayById(prevState, question.id, {
            candidateAnswer: (event.target as HTMLInputElement).value as string,
          })
        );
    };

    return (
      <fieldset
        data-testid="questionmultiplechoice"
        className="question-answers"
      >
        <Grid
          container
          direction={question.layout === "horizontal" ? "row" : "column"}
          className={"layout-" + question.layout}
        >
          {Object.entries(options).map(([aKey, aStr]) => (
            <Grid item key={aKey}>
              <Answer
                key={aKey + question.id}
                value={aKey}
                answer={aStr as string}
                layout={question.layout}
                candidateAnswer={(question.candidateAnswer as string) || ""}
                onChange={handleChange}
                {...(question.answerOptionsMaxHeight
                  ? { height: question.answerOptionsMaxHeight }
                  : {})}
                {...(question.answerOptionsMaxWidth
                  ? { width: question.answerOptionsMaxWidth }
                  : {})}
              />
            </Grid>
          ))}
        </Grid>
      </fieldset>
    );
  }
);

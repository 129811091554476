import { UserT } from "../user/api";
import { CustomerForm } from "./CustomerForm";
import { Dialog } from "../shared/Dialog";

interface EditCustomerPropsI {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserT;
  setUserState: React.Dispatch<React.SetStateAction<UserT>>;
}
export const EditCustomer = ({
  user,
  open,
  setOpen,
  setUserState,
}: EditCustomerPropsI) => {
  const onClick = (data: UserT) => {
    if (!data.email) return;

    setUserState(data);
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} setOpen={setOpen} title="Kunde bearbeiten">
        <CustomerForm onClick={onClick} button="Schließen" user={user} />
      </Dialog>
    </>
  );
};

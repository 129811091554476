import { useContext } from "react";
import * as Sentry from "@sentry/react";
import { Login } from "./auth/Login";
import { AdminContent } from "./layout/admin/AdminContent";
import { AuthContext } from "./auth/AuthContext";

import { ErrorProvider } from "./error/ErrorContext";
import { Error } from "./error/Error";
import { FeedbackProvider } from "./feedback/FeedbackContext";
import { Snackbar } from "./feedback/Snackbar";
import { BackdropProgress } from "./feedback/BackdropProgress";
import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { CustomerContent } from "./layout/customer/CustomerContent";
import { ForgotPassword } from "./auth/ForgotPassword";
import { CandidateContent } from "./layout/candidate/CandidateContent";
import { NotFound404 } from "./error/NotFound404";
import { AutoLogin } from "./auth/AutoLogin";
import { ChangePasswordForce } from "./user/ChangePasswordForce";
import { CandidateLoggedOut } from "./auth/CandidateLoggedOut";
import { AdminDashboardLoader } from "./dashboard/admin/AdminDashboardLoader";
import { CandidateProvider } from "./candidate/CandidateContext";
import { CandidateStart } from "./dashboard/candidate/CandidateStart";
import { CustomerDashboardLoader } from "./dashboard/customer/CustomerDashboardLoader";
import { ProfessionProvider } from "./profession/ProfessionContext";
import { AdminLoader } from "./admin/AdminLoader";
import { CandidateLoader } from "./candidate/CandidateLoader";
import { ResultsLoader } from "./candidate/results/ResultsLoader";
import { CategoryModus } from "./CategoryModus/CategoryModus";
import { CustomerLoader } from "./customer/CustomerLoader";
import { CustomerDataLoader } from "./settings/CustomerDataLoader";
import { FileProvider } from "./file/FileContext";
import { FileLoader } from "./file/FileLoader";
import { MailTemplateProvider } from "./mailTemplate/MailTemplateContext";
import { MailTemplateLoader } from "./mailTemplate/MailTemplateLoader";
import { MainCategoryProvider } from "./mainCategory/MainCategoryContext";
import { MainCategoryLoader } from "./mainCategory/MainCategoryLoader";
import { ProfessionLoader } from "./profession/ProfessionLoader";
import { EditorLoader } from "./question/editor/EditorLoader";
import { QuestionProvider } from "./question/QuestionContext";
import { QuestionLoader } from "./question/QuestionLoader";
import { RelationProvider } from "./relation/RelationContext";
import { RelationLoader } from "./relation/RelationLoader";
import { TableProvider } from "./shared/table/TableContext";
import { SubCategoryProvider } from "./subCategory/SubCategoryContext";
import { SubCategoryLoader } from "./subCategory/SubCategoryLoader";
import { TestModusProvider } from "./testModus/TestModusContext";
import { TestModusLoader } from "./testModus/TestModusLoader";
import { ChangePassword } from "./user/ChangePassword";
import { UserProvider } from "./user/UserContext";
import { Logout } from "./auth/Logout";
import { ResetPassword } from "./auth/ResetPassword";
import { AdminTestModusLoader } from "./profession/AdminTestModusLoader";
import { CustomerTestModusLoader } from "./profession/CustomerTestModusLoader";
import { TestResultsLoader } from "./profession/TestResultsLoader";
import { SystemHealthCheckProvider } from "./systemHealthCheck/SystemHealthCheckContext";
import { StatsLoader } from "./stats/StatsLoader";
import { StatsProvider } from "./stats/StatsContext";
import { CustomerLayout } from "./settings/CustomerLayout";
import { CountryProvider } from "./country/CountryContext";
import { LayoutProvider } from "./testModus/LayoutContext";
import { ShopOrderLoader } from "./order/ShopOrderLoader";
import { ShopOrderProvider } from "./order/ShopOrderContext";
import { useAutoLogin } from "./auth/useAutoLogin";
import { AutoLoginError } from "./auth/AutoLoginError";
import { HelpLoader } from "./help/HelpLoader";
import { HelpContext, HelpProvider } from "./help/HelpContext";
import { HelpContentLoader } from "./help/HelpContentLoader";
import { Box, CssBaseline } from "@mui/material";

export const Routes = () => {
  const { authState } = useContext(AuthContext);

  const { autoLoginChecked, alreadyFinished } = useAutoLogin();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="candidate/categories"
          element={
            <CandidateContent>
              <MainCategoryProvider>
                <SubCategoryProvider>
                  <QuestionProvider>
                    <TestModusProvider>
                      <CategoryModus />
                    </TestModusProvider>
                  </QuestionProvider>
                </SubCategoryProvider>
              </MainCategoryProvider>
            </CandidateContent>
          }
        />
        <Route
          path="candidate/testmodus"
          element={
            <LayoutProvider>
              <CandidateContent>
                <TestModusProvider>
                  <TestModusLoader />
                </TestModusProvider>
              </CandidateContent>
            </LayoutProvider>
          }
        />
        <Route path="candidate/logout" element={<Logout />} />
        <Route path="candidate/*" element={<NotFound404 />} />
        <Route path="reset/:token" element={<ResetPassword />} />
        <Route
          path="testaccount-pw/:token"
          element={
            <ResetPassword
              buttonText="Passwort setzen"
              successText="Passwort erfolgreich gesetzt."
            />
          }
        />
        <Route
          path="admin/forgot-password"
          element={<ForgotPassword type="admin" />}
        />
        <Route
          path="customer/forgot-password"
          element={<ForgotPassword type="customer" />}
        />
        <Route
          path="candidate/forgot-password"
          element={<ForgotPassword type="candidate" />}
        />
        <Route
          path="admin"
          element={
            authState.loggedIn && authState.role === "admin" ? (
              authState.user && authState.user.force_change_password ? (
                <ChangePasswordForce />
              ) : (
                <Navigate
                  to={{
                    pathname: "/admin/dashboard",
                  }}
                  replace={true}
                />
              )
            ) : (
              <Login type="admin" />
            )
          }
        />
        <Route
          path="candidate"
          element={
            authState.loggedIn && authState.role === "candidate" ? (
              <LayoutProvider>
                <CandidateContent>
                  <CandidateStart />
                </CandidateContent>
              </LayoutProvider>
            ) : (
              <CandidateLoggedOut />
            )
          }
        />
        <Route path="test/:uuid" element={<AutoLogin />} />
        <Route path="demo" element={<AutoLogin demo={true} />} />
        <Route
          path="customer"
          element={
            authState.loggedIn && authState.role === "customer" ? (
              authState.user && authState.user.force_change_password ? (
                <ChangePasswordForce />
              ) : (
                <Navigate
                  to={{
                    pathname: authState.user.hasTransaction
                      ? "/customer/dashboard"
                      : "/customer/orders",
                  }}
                  replace={true}
                />
              )
            ) : (
              <Login type="customer" />
            )
          }
        />
        {/* key is needed to force reload of candidates */}
        <Route
          path="customer/dashboard"
          element={
            <CustomerContent key="dashboard">
              <CandidateProvider>
                <ProfessionProvider>
                  <ShopOrderProvider>
                    <CustomerDashboardLoader />
                  </ShopOrderProvider>
                </ProfessionProvider>
              </CandidateProvider>
            </CustomerContent>
          }
        />
        {/* key is needed to force reload of candidates */}
        <Route
          path="customer/candidate"
          element={
            <CustomerContent key="candidate">
              <CandidateProvider>
                <ProfessionProvider>
                  <CountryProvider>
                    <CandidateLoader />
                  </CountryProvider>
                </ProfessionProvider>
              </CandidateProvider>
            </CustomerContent>
          }
        />
        <Route
          path="customer/print/:candidateId"
          element={
            <CandidateProvider>
              <ProfessionProvider>
                <ResultsLoader />
              </ProfessionProvider>
            </CandidateProvider>
          }
        />
        <Route
          path="customer/mailtemplates"
          element={
            <CustomerContent hidePaper={true}>
              <MailTemplateProvider>
                <MailTemplateLoader type="customer" />
              </MailTemplateProvider>
            </CustomerContent>
          }
        />
        <Route
          path="customer/settings"
          element={
            <CustomerContent hidePaper={true}>
              <CustomerLayout />
            </CustomerContent>
          }
        />
        <Route
          path="customer/orders"
          element={
            <CustomerContent
              paperSx={{
                display: "inline-block",
                minWidth: 860,
                minHeight: "calc(100vh - 150px)",
              }}
            >
              <ShopOrderProvider>
                <ShopOrderLoader />
              </ShopOrderProvider>
            </CustomerContent>
          }
        />
        <Route
          path="customer/cart"
          element={
            <CustomerContent
              paperSx={{
                display: "inline-block",
                minWidth: 860,
                minHeight: "calc(100vh - 150px)",
              }}
            >
              <ShopOrderProvider>
                <ShopOrderLoader cart={true} />
              </ShopOrderProvider>
            </CustomerContent>
          }
        />
        <Route
          path="customer/data"
          element={
            <CustomerContent paperSx={{ maxWidth: "1100px" }}>
              <CustomerDataLoader />
            </CustomerContent>
          }
        />
        <Route
          path="customer/help"
          element={
            <CustomerContent paperSx={{ maxWidth: "900px" }}>
              <HelpProvider>
                <HelpContentLoader />
              </HelpProvider>
            </CustomerContent>
          }
        />
        <Route
          path="customer/testmodus/:professionId/:professionName"
          element={
            <LayoutProvider>
              <CustomerContent hidePaper={true}>
                <TestModusProvider>
                  <CustomerTestModusLoader />
                </TestModusProvider>
              </CustomerContent>
            </LayoutProvider>
          }
        />
        <Route path="customer/logout" element={<Logout />} />
        <Route path="customer/*" element={<NotFound404 />} />

        <Route
          path="admin/dashboard"
          element={
            <AdminContent>
              <ProfessionProvider>
                <SystemHealthCheckProvider>
                  <AdminDashboardLoader />
                </SystemHealthCheckProvider>
              </ProfessionProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/admin"
          element={
            <AdminContent>
              <UserProvider>
                <AdminLoader />
              </UserProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/customer"
          element={
            <AdminContent>
              <ProfessionProvider>
                <CountryProvider>
                  <TableProvider>
                    <UserProvider>
                      <ShopOrderProvider>
                        <CustomerLoader />
                      </ShopOrderProvider>
                    </UserProvider>
                  </TableProvider>
                </CountryProvider>
              </ProfessionProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/professions"
          element={
            <AdminContent>
              <ProfessionProvider>
                <ProfessionLoader />
              </ProfessionProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/testmodus/:professionId/:professionName"
          element={
            <TestModusProvider>
              <AdminTestModusLoader />
            </TestModusProvider>
          }
        />
        <Route
          path="admin/results/:professionId"
          element={
            <AdminContent>
              <CandidateProvider>
                <ProfessionProvider>
                  <TestResultsLoader />
                </ProfessionProvider>
              </CandidateProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/relations"
          element={
            <AdminContent>
              <ProfessionProvider>
                <MainCategoryProvider>
                  <SubCategoryProvider>
                    <QuestionProvider>
                      <RelationProvider>
                        <RelationLoader />
                      </RelationProvider>
                    </QuestionProvider>
                  </SubCategoryProvider>
                </MainCategoryProvider>
              </ProfessionProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/maincategories"
          element={
            <AdminContent>
              <MainCategoryProvider>
                <MainCategoryLoader />
              </MainCategoryProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/subcategories"
          element={
            <AdminContent>
              <SubCategoryProvider>
                <SubCategoryLoader />
              </SubCategoryProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/media"
          element={
            <AdminContent>
              <FileProvider>
                <FileLoader />
              </FileProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/question"
          element={
            <AdminContent>
              <TableProvider>
                <QuestionProvider>
                  <QuestionLoader />
                </QuestionProvider>
              </TableProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/question/:questionId"
          element={
            <AdminContent>
              <QuestionProvider>
                <EditorLoader />
              </QuestionProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/mailtemplates"
          element={
            <AdminContent>
              <MailTemplateProvider>
                <MailTemplateLoader type="admin" />
              </MailTemplateProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/orders"
          element={
            <AdminContent>
              <ProfessionProvider>
                <CountryProvider>
                  <TableProvider>
                    <ShopOrderProvider>
                      <ShopOrderLoader />
                    </ShopOrderProvider>
                  </TableProvider>
                </CountryProvider>
              </ProfessionProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/stats"
          element={
            <AdminContent>
              <ProfessionProvider>
                <StatsProvider>
                  <StatsLoader />
                </StatsProvider>
              </ProfessionProvider>
            </AdminContent>
          }
        />
        <Route
          path="admin/settings"
          element={
            <AdminContent>
              <ChangePassword />
            </AdminContent>
          }
        />
        <Route
          path="admin/help"
          element={
            <AdminContent>
              <HelpProvider>
                <HelpLoader />
              </HelpProvider>
            </AdminContent>
          }
        />
        <Route path="admin/logout" element={<Logout />} />
        <Route path="admin/*" element={<NotFound404 />} />

        <Route path="*" element={<NotFound404 />}></Route>
      </>
    )
  );

  if (!autoLoginChecked) return <></>;
  if (alreadyFinished) return <AutoLoginError />;

  if (authState.loggedIn && authState.user)
    Sentry.setUser({ email: authState.user.email });

  return (
    <FeedbackProvider>
      <Snackbar />
      <BackdropProgress />
      <ErrorProvider>
        <Error noSnackbar={authState.role === "candidate"} />
        <RouterProvider router={router} />
      </ErrorProvider>
    </FeedbackProvider>
  );
};

const adminRoutes = <></>;

const customerRoutes = <></>;

type ConfT = {
  premium: {
    price: number;
  };
  premium_online: {
    price: number;
  };
  basic: {
    scale: (number | string)[];
    price: number[];
    solution: number;
    branding: number;
    shipping: number;
  };
  online: {
    scale: (number | string)[];
    price: number[];
  };
};

export const conf: ConfT = {
  premium: {
    price: 299.0,
  },
  premium_online: {
    price: 299.0,
  },

  basic: {
    scale: [5, 10, 50, 100, "*"],
    price: [9.95, 7.95, 5.95, 4.95, 3.95],
    solution: 9.95,
    branding: 99.0,
    shipping: 5.0,
  },
  online: {
    scale: [5, 10, 50, 100, "*"],
    price: [9.95, 7.95, 5.95, 4.95, 3.95],
  },
};
export const calcPrice = (type: string, count: number) => {
  if (type === "premium" || type === "premium_online") {
    type = "basic";
  }
  const { scale, price } = conf[type as "basic" | "online"];
  const idx = scale.findIndex(
    (s: number | string) => (s as number) >= count || s === "*"
  );
  return price[idx];
};

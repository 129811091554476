import { isEmpty } from "lodash";
import { useContext, useEffect } from "react";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ErrorContext } from "./ErrorContext";

interface ErrorI {
  noSnackbar?: boolean;
}
export const Error = ({ noSnackbar = false }: ErrorI) => {
  const { error, setError } = useContext(ErrorContext);
  const { openSnackbar } = useContext(FeedbackContext);

  useEffect(() => {
    if (!error) return;

    let errorStr;
    if (typeof error === "string") errorStr = error;
    else if (typeof error === "object" && error.message)
      errorStr = error.message;
    else if (typeof error === "object" && !isEmpty(error))
      errorStr = JSON.stringify(error, Object.getOwnPropertyNames(error));

    if (errorStr && !noSnackbar) {
      openSnackbar("error", errorStr);
      setError("");
    }
  }, [error, setError, openSnackbar, noSnackbar]);

  return <></>;
};

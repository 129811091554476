import { Box, Button, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { buildEmptyErrorState } from "../shared/helper/formError";
import { UserT, updateUserSelf } from "../user/api";
import { EditableEntryWithPen } from "../shared/forms/EditableEntryWithPen";
import { isEqual, debounce } from "lodash";
import { ErrorContext } from "../error/ErrorContext";

const fields2check = ["email", "firstname", "lastname"];

interface CustomerDataPropsI {
  user: UserT;
}

const initTmpPassword = { old: "", new: "", rep: "" };

export const CustomerData = ({ user }: CustomerDataPropsI) => {
  const [editedEntry, setEditedEntry] = useState<string | null>(null);
  const { showProgress } = useContext(FeedbackContext);
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );
  const [userState, setUserState] = useState(user);
  const [userInit, setUserInit] = useState(user);
  const [hasChanges, setHasChanges] = useState(false);
  const [error, setError] = useState("");
  const [tmpPassword, setTmpPassword] = useState(initTmpPassword);
  const { setError: setErrorC } = useContext(ErrorContext);
  const { openSnackbar } = useContext(FeedbackContext);

  const onClickSave = () => {
    console.log("%cCustomerData.tsx line:47 onClickSave", "color: #007acc;");

    const mandatory = [
      "company",
      "lastname",
      "firstname",
      "street",
      "streetno",
      "zipcode",
      "city",
      "email",
    ];
    if (userState.shippingAddress)
      mandatory.push(
        "shippingCompany",
        "shippingLastname",
        "shippingFirstname",
        "shippingStreet",
        "shippingStreetno",
        "shippingZipcode",
        "shippingCity"
      );
    const missing = mandatory.filter((m) => !userState[m as keyof UserT]);
    if (missing.length > 0) {
      setError("mandatory");
      setEditedEntry(missing[0]);
      return;
    }

    setEditedEntry(null);

    if (!userState.shippingAddress)
      setUserState((p) => {
        delete p.shippingCompany;
        delete p.shippingGender;
        delete p.shippingFirstname;
        delete p.shippingLastname;
        delete p.shippingStreet;
        delete p.shippingStreetno;
        delete p.shippingCity;
        delete p.shippingZipcode;
        delete p.shippingPhone;
        delete p.shippingFax;
        return p;
      });

    updateUserSelf(userState).then((result) => {
      if (result.success) {
        setUserInit(userState);
        setTmpPassword(initTmpPassword);
        setHasChanges(false);
        openSnackbar("success", "Eintrag gespeichert");
      } else {
        setErrorC("Änderungen konnten nicht gespeichert werden");
      }
    });
  };

  const onClickEdit = (id: string) => {
    console.log(
      "%cCustomerData.tsx line:93 onClickEdit, id, error",
      "color: #007acc;",
      id,
      error
    );
    if (
      error === "mandatory" ||
      error === "password_equal" ||
      error === "password"
    )
      return;
    setEditedEntry(id);
  };

  /** in/active submit button */
  useEffect(() => {
    console.log(
      "%cCustomerData.tsx line:92 userState",
      "color: red;",
      userState,
      userInit
    );
    setHasChanges(!isEqual(userState, userInit));
  }, [userState, userInit, setHasChanges]);

  /** check if passwords match */
  useEffect(() => {
    if (tmpPassword.new && tmpPassword.rep) {
      setError("");
      setUserState({ ...userState, password: tmpPassword.new });
      if (tmpPassword.new !== tmpPassword.rep) {
        setError("password_equal");
        setUserState({ ...userState, password: "" });
      }
    }
  }, [tmpPassword]);

  /* check if all mandatory fields are filled */
  useEffect(() => {
    if (
      !editedEntry ||
      [
        "password",
        "phone",
        "fax",
        "newsletter",
        "gender",
        "shippingGender",
        "shippingFax",
        "shippingPhone",
      ].includes(editedEntry)
    )
      return;

    if (!userState[editedEntry as keyof UserT]) {
      setError("mandatory");
    } else setError("");
  }, [userState, editedEntry, tmpPassword]);

  useEffect(() => {
    if (!userState.shippingAddress) setError("");
  }, [userState.shippingAddress]);

  const closeShowEdit = () => {
    console.log(
      "%cCustomerData.tsx line:143 editedEntry, error",
      "color: #007acc;",
      editedEntry,
      error
    );
    if (editedEntry === "password") {
      if (!tmpPassword.new || !tmpPassword.rep) {
        setError("password");
        return;
      } else {
        setUserState({
          ...userState,
          password: tmpPassword.new,
        }); /* might be overwritten by passwordVerify or password equal check */
      }
    }
    if (!error) setEditedEntry(null);
  };

  return (
    <div onMouseDown={closeShowEdit}>
      <Box display="flex" sx={{ gap: 5 }}>
        <Box>
          <Typography variant="h1" component="h1" sx={{ mb: 2 }}>
            Stammdaten
          </Typography>
          <Typography variant="h2" component="h2" sx={{ mb: 2 }}>
            Rechnungsanschrift
          </Typography>
          <div className="customer-data-table-container">
            <Box className="label">
              Firma<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="company"
              value={userState.company || ""}
              setValue={(v) =>
                setUserState({ ...userState, company: v as string })
              }
              showEdit={editedEntry === "company"}
              setShowEdit={(b) => onClickEdit("company")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">Anrede:</Box>
            <EditableEntryWithPen
              id="gender"
              value={userState.gender || ""}
              type="gender-select"
              setValue={(v) =>
                setUserState({ ...userState, gender: v as string })
              }
              showEdit={editedEntry === "gender"}
              setShowEdit={(b) => onClickEdit("gender")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">
              Name<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="lastname"
              value={userState.lastname}
              setValue={(v) =>
                setUserState({ ...userState, lastname: v as string })
              }
              showEdit={editedEntry === "lastname"}
              setShowEdit={(b) => onClickEdit("lastname")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">
              Vorname<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="firstname"
              value={userState.firstname}
              setValue={(v) =>
                setUserState({ ...userState, firstname: v as string })
              }
              showEdit={editedEntry === "firstname"}
              setShowEdit={(b) => onClickEdit("firstname")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">
              Straße<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="street"
              value={userState.street || ""}
              setValue={(v) =>
                setUserState({ ...userState, street: v as string })
              }
              showEdit={editedEntry === "street"}
              setShowEdit={(b) => onClickEdit("street")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">
              Hausnummer<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="streetno"
              value={userState.streetno || ""}
              setValue={(v) =>
                setUserState({ ...userState, streetno: v as string })
              }
              showEdit={editedEntry === "streetno"}
              setShowEdit={(b) => onClickEdit("streetno")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">
              PLZ<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="zipcode"
              value={userState.zipcode || ""}
              setValue={(v) =>
                setUserState({ ...userState, zipcode: v as string })
              }
              showEdit={editedEntry === "zipcode"}
              setShowEdit={(b) => onClickEdit("zipcode")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">
              Ort<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="city"
              value={userState.city || ""}
              setValue={(v) =>
                setUserState({ ...userState, city: v as string })
              }
              showEdit={editedEntry === "city"}
              setShowEdit={(b) => onClickEdit("city")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">
              E-Mail-Adresse<sup>*</sup>:
            </Box>
            <EditableEntryWithPen
              id="email"
              value={userState.email}
              setValue={(v) =>
                setUserState({ ...userState, email: v as string })
              }
              showEdit={editedEntry === "email"}
              setShowEdit={(b) => onClickEdit("email")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">Rechnungs-E-Mail:</Box>
            <EditableEntryWithPen
              id="email"
              value={userState.emailInvoice || ""}
              setValue={(v) =>
                setUserState({ ...userState, emailInvoice: v as string })
              }
              showEdit={editedEntry === "emailInvoice"}
              setShowEdit={(b) => onClickEdit("emailInvoice")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">Telefon:</Box>
            <EditableEntryWithPen
              id="phone"
              value={userState.phone || ""}
              setValue={(v) =>
                setUserState({ ...userState, phone: v as string })
              }
              showEdit={editedEntry === "phone"}
              setShowEdit={(b) => onClickEdit("phone")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box className="label">Fax:</Box>
            <EditableEntryWithPen
              id="fax"
              value={userState.fax || ""}
              setValue={(v) => setUserState({ ...userState, fax: v as string })}
              showEdit={editedEntry === "fax"}
              setShowEdit={(b) => onClickEdit("fax")}
              closeShowEdit={closeShowEdit}
              closeAction={(e) => {
                setEditedEntry(null);
              }}
            />
            <Box
              className={
                "password-wrapper " + (editedEntry === "password" ? "open" : "")
              }
            >
              <Box className="label">Passwort ändern:</Box>
              <EditableEntryWithPen
                id="password"
                type="password"
                value={userState.password || false}
                setValue={(v, id) =>
                  setTmpPassword((p) => ({ ...p, [id as string]: v as string }))
                }
                showEdit={editedEntry === "password"}
                setShowEdit={(b) => onClickEdit("password")}
                closeAction={(e) => {
                  setEditedEntry(null);
                  setTmpPassword(initTmpPassword);
                  setError("");
                  e.stopPropagation();
                }}
                closeShowEdit={closeShowEdit}
              />
            </Box>
            <Box sx={{ w: "100%", ml: "2px", mt: 1 }}>
              <input
                type="checkbox"
                id="checkbox"
                value="1"
                checked={Boolean(userState.shippingAddress)}
                onChange={(e) =>
                  setUserState((p) => ({
                    ...p,
                    shippingAddress: e.target.checked,
                  }))
                }
              />{" "}
              <Typography variant="body2" component="span" sx={{ ml: 1 }}>
                Abweichende Lieferanschrift angeben
              </Typography>
            </Box>
            <Box sx={{ w: "100%", ml: "2px", mt: 1 }}>
              <input
                type="checkbox"
                value="1"
                checked={Boolean(userState.newsletter)}
                onChange={(e) =>
                  setUserState({
                    ...userState,
                    newsletter: e.target.checked,
                  })
                }
              />
              <Typography variant="body2" component="span" sx={{ ml: 1 }}>
                Ja, ich möchte per Mail über aktuelle Angebote der degeba
                informiert werden.
              </Typography>
            </Box>
          </div>
        </Box>
        <Box>
          {userState.shippingAddress && (
            <>
              <Typography
                variant="h2"
                component="div"
                sx={{ mt: "50px", mb: 2 }}
              >
                Lieferanschrift
              </Typography>
              <div className="customer-data-table-container">
                <Box className="label">
                  Firma<sup>*</sup>:
                </Box>
                <EditableEntryWithPen
                  id="shippingCompany"
                  value={userState.shippingCompany || ""}
                  setValue={(v) =>
                    setUserState({ ...userState, shippingCompany: v as string })
                  }
                  showEdit={editedEntry === "shippingCompany"}
                  setShowEdit={(b) => onClickEdit("shippingCompany")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">Anrede:</Box>
                <EditableEntryWithPen
                  id="
              shippingGender"
                  value={userState.shippingGender || ""}
                  type="gender-select"
                  setValue={(v) =>
                    setUserState({ ...userState, shippingGender: v as string })
                  }
                  showEdit={editedEntry === "shippingGender"}
                  setShowEdit={(b) => onClickEdit("shippingGender")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">
                  Name<sup>*</sup>:
                </Box>
                <EditableEntryWithPen
                  id="shippingLastname"
                  value={userState.shippingLastname || ""}
                  setValue={(v) =>
                    setUserState({
                      ...userState,
                      shippingLastname: v as string,
                    })
                  }
                  showEdit={editedEntry === "shippingLastname"}
                  setShowEdit={(b) => onClickEdit("shippingLastname")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">
                  Vorname<sup>*</sup>:
                </Box>
                <EditableEntryWithPen
                  id="shippingFirstname"
                  value={userState.shippingFirstname || ""}
                  setValue={(v) =>
                    setUserState({
                      ...userState,
                      shippingFirstname: v as string,
                    })
                  }
                  showEdit={editedEntry === "shippingFirstname"}
                  setShowEdit={(b) => onClickEdit("shippingFirstname")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />

                <Box className="label">
                  Straße<sup>*</sup>:
                </Box>
                <EditableEntryWithPen
                  id="shippingStreet"
                  value={userState.shippingStreet || ""}
                  setValue={(v) =>
                    setUserState({ ...userState, shippingStreet: v as string })
                  }
                  showEdit={editedEntry === "shippingStreet"}
                  setShowEdit={(b) => onClickEdit("shippingStreet")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">
                  Hausnummer<sup>*</sup>:
                </Box>
                <EditableEntryWithPen
                  id="shippingStreetno"
                  value={userState.shippingStreetno || ""}
                  setValue={(v) =>
                    setUserState({
                      ...userState,
                      shippingStreetno: v as string,
                    })
                  }
                  showEdit={editedEntry === "shippingStreetno"}
                  setShowEdit={(b) => onClickEdit("shippingStreetno")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">
                  PLZ<sup>*</sup>:
                </Box>
                <EditableEntryWithPen
                  id="shippingZipcode"
                  value={userState.shippingZipcode || ""}
                  setValue={(v) =>
                    setUserState({ ...userState, shippingZipcode: v as string })
                  }
                  showEdit={editedEntry === "shippingZipcode"}
                  setShowEdit={(b) => onClickEdit("shippingZipcode")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">
                  Ort<sup>*</sup>:
                </Box>
                <EditableEntryWithPen
                  id="shippingCity"
                  value={userState.shippingCity || ""}
                  setValue={(v) =>
                    setUserState({ ...userState, shippingCity: v as string })
                  }
                  showEdit={editedEntry === "shippingCity"}
                  setShowEdit={(b) => onClickEdit("shippingCity")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">Telefon:</Box>
                <EditableEntryWithPen
                  id="shippingPhone"
                  value={userState.shippingPhone || ""}
                  setValue={(v) =>
                    setUserState({ ...userState, shippingPhone: v as string })
                  }
                  showEdit={editedEntry === "shippingPhone"}
                  setShowEdit={(b) => onClickEdit("shippingPhone")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
                <Box className="label">Fax:</Box>
                <EditableEntryWithPen
                  id="shippingFax"
                  value={userState.shippingFax || ""}
                  setValue={(v) =>
                    setUserState({ ...userState, shippingFax: v as string })
                  }
                  showEdit={editedEntry === "shippingFax"}
                  setShowEdit={(b) => onClickEdit("shippingFax")}
                  closeShowEdit={closeShowEdit}
                  closeAction={(e) => {
                    setEditedEntry(null);
                  }}
                />
              </div>
            </>
          )}
        </Box>
      </Box>

      {error && (
        <Box sx={{ color: "red", mt: 2 }}>
          {error === "mandatory" && (
            <>Bitte füllen Sie alle mit * markierten Pflichtfelder.</>
          )}
          {error === "password" && <>Bitte geben Sie Ihr neues Passwort ein.</>}
          {error === "password_equal" && <>Passwörter nicht übereinstimmend.</>}
        </Box>
      )}

      <Box className="button-left-container">
        <Button
          variant="contained"
          color="primary"
          onClick={onClickSave}
          disabled={!hasChanges || error != ""}
          sx={{ fontSize: "12px", width: "300px", color: "white", mt: 2 }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          Änderungen speichern
        </Button>
      </Box>
    </div>
  );
};

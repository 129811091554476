import { get, post, put, del, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";
import { checkApiResult } from "../shared/lib/checkApi";

export type TargetT = {
  id: number;
  customerId: number;
  professionId: number;
  mainCategoryId: number;
  name: string;
  target: number;
  standard: number;
};

type TargetDataT = {
  id: number;
  customerId: number;
  professionId: number;
  mainCategoryId: number;
  name: string;
  target: number;
  standard: number;
};

const data2target = (data: TargetDataT): TargetT => {
  checkApiResult("data2target", data, {
    id: "number",
    customerId: "number",
    professionId: "number",
    // mainCategoryId: "number",
    name: "string",
    target: "number",
  });

  return {
    ...data,
  };
};

const data2targets = (data: TargetDataT[]): TargetT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2target(d));
};

const target2data = (target: TargetT): TargetDataT => {
  return {
    ...target,
  };
};

const targets2data = (targets: TargetT[]): TargetDataT[] => {
  return targets.map((target) => target2data(target));
};

const APIPATH = "/targets";

/** ************************************************************************
 *
 *
 */
export const fetchTargets = async (
  professionId: number
): Promise<CallApiResultT<TargetT[]>> => {
  const res = await get(APIPATH, { professionId });

  if (!(res.data && isArray(res.data))) {
    throw Error("[ffetchTargets] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2targets(res.data),
  };
};

/** ************************************************************************
 *
 * @returns addedTarget
 */
export const updateTargets = async (
  targets: TargetT[]
): Promise<CallApiResultT<number>> => {
  // console.log("addMedia", target);

  const res = await post<number>(APIPATH, targets2data(targets));

  if (!res.data) {
    throw Error("[addtarget] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: res.data,
  };
};

export const deleteTargets = async (
  professionId: number
): Promise<CallApiResultT<TargetT[]>> => {
  const res = await del<TargetT[]>(APIPATH, { professionId });

  if (!res.data) {
    throw Error("[deleteTargets] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: res.data,
  };
};

import { useContext, useState, useEffect, useRef } from "react";

import { debouncedSetUpdatedState } from "../shared/helper/state";
import { EditableCell } from "../shared/list/InsertCell";
import { checkChanged } from "../shared/helper/object";
import { HelpContext } from "./HelpContext";
import { HelpCategoryT, HelpEntryT } from "./api";
import { TableCell, TableRow } from "@mui/material";
import { MoveButton } from "../relation/MoveButton";
import { AddHelpEntry } from "./AddHelpEntry";
import { HelpEntry } from "./HelpEntry";

interface QuestionPropsI {
  helpCategory: HelpCategoryT;
  setShowSave: (showSave: boolean) => void;
  moveCategory: (helpCategory: HelpCategoryT, direction: string) => void;
  moveEntry: (helpEntry: HelpEntryT, direction: string) => void;
}

export const HelpCategory = ({
  helpCategory,
  setShowSave,
  moveCategory,
  moveEntry,
}: QuestionPropsI) => {
  const { setChangedHelpCategoryList, helpCategoryList, helpEntryList } =
    useContext(HelpContext);

  const [hasChanged, setHasChanged] = useState(false);
  const [helpCategoryState, setHelpCategoryState] =
    useState<HelpCategoryT>(helpCategory);
  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(helpCategory, helpCategoryState, hasChanged, setHasChanged);
  }, [setHasChanged, hasChanged, helpCategory, helpCategoryState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(helpCategoryState, setChangedHelpCategoryList);
  }, [helpCategoryState, setChangedHelpCategoryList]);

  const actHelpEntryList = helpEntryList.filter(
    (entry) => entry.helpCategoryId === helpCategory.id
  );

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={helpCategory.id}
        selected={hasChanged}
        sx={{ bgcolor: "lightgray" }}
      >
        <EditableCell
          id="title"
          stateObject={helpCategoryState}
          setStateObject={setHelpCategoryState}
          isObligatory={true}
        />
        <TableCell align="right">
          {helpCategoryState.sortOrder > 1 && (
            <MoveButton
              direction="up"
              onClick={() => moveCategory(helpCategory, "up")}
            />
          )}
          {helpCategoryState.sortOrder < helpCategoryList.length && (
            <MoveButton
              direction="down"
              onClick={() => moveCategory(helpCategory, "down")}
            />
          )}
        </TableCell>
        <TableCell align="right">
          <AddHelpEntry categoryId={helpCategoryState.id} />
        </TableCell>
      </TableRow>
      {actHelpEntryList
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((helpEntry) => (
          <HelpEntry
            key={helpEntry.id}
            helpEntry={helpEntry}
            setShowSave={setShowSave}
            moveEntry={moveEntry}
            actHelpEntryList={actHelpEntryList}
          />
        ))}
    </>
  );
};

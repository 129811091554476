import { Typography } from "@mui/material";
import { CandidateT } from "../api";

interface CandidateDataPropsI {
  candidate: CandidateT;
}

export const CandidateData = ({ candidate }: CandidateDataPropsI) => {
  return (
    <Typography variant="body2" component="div">
      <span className="semibold">
        {candidate.firstname} {candidate.lastname}
      </span>
      <br />
      <br />
      {candidate.street && (
        <>
          {candidate.street} {candidate.streetno}
          <br />
        </>
      )}
      {(candidate.zipcode || candidate.city) && (
        <>
          {candidate.zipcode} {candidate.city}
          <br />
        </>
      )}
      {candidate.email && (
        <>
          {candidate.email}
          <br />
        </>
      )}
      {candidate.phone && (
        <>
          {candidate.phone}
          <br />
        </>
      )}
      {(candidate.email || candidate.phone) && <br />}
      {candidate.dateBirth && (
        <>
          Geburtsdatum:{" "}
          {typeof candidate.dateBirth === "object"
            ? candidate.dateBirth.toLocaleDateString("de-DE")
            : candidate.dateBirth}
          <br />
        </>
      )}
      {candidate.nationality && (
        <>
          <br />
          Staatsangehörigkeit: {candidate.nationality}
          <br />
        </>
      )}
      {candidate.schoolGraduation && (
        <>
          <br />
          Schulabschluss: {candidate.schoolGraduation}
          <br />
        </>
      )}
    </Typography>
  );
};

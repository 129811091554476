import { ReactElement } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";
import { Modal } from "@mui/material";

export const Modal2ndWindow = ({ open }: { open: boolean }): ReactElement => {
  return (
    <Modal open={open} className="modal-backdrop">
      <Box className="modal">
        <Box
          sx={{
            marginTop: "12px !important",
            marginBottom: "8px !important",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="login-box"
        >
          <Box className="logo degeba-logo"></Box>
          <Typography component="h1" variant="h5">
            Zweites Browser-Fenster
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <br />
            <br />
            Unser System hat festgestellt, dass der Test parallel in einem
            zweiten Browser-Fenster geöffnet wurde. Die Bearbeitung in mehreren
            Fenstern gleichzeitig ist nicht möglich.
            <br />
            <br />
            <b>
              Bitte schließen Sie dieses Fenster und setzen Sie den Test im
              anderen Fenster fort.
            </b>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

// * src: "{Bild|KV-37-a30R.svg|w:;h:}

import { Key } from "react";
import { jsonDecode } from "../../../shared/helper/json";

/**
 * inhalte ist ein array mit objekten
 * jedes objekt ist eine zeile in der Clickmatrix
 */

type ResultT = {
  content: { [key: string | number]: any };
  titlecolumn: boolean;
  titlerow: any[];
};

// * tar: {"img": "KV-37-a30R.svg","w": "", "h": "" },
export const decodeAnswerOptionsClickMatrix = (options: string): ResultT => {
  const answerParts = jsonDecode(options);
  const result: ResultT = {
    content: {},
    titlecolumn: false,
    titlerow: [],
  };
  if (!answerParts) return result;

  if (answerParts.inhalte) {
    // inhalte ist ein array mit objecten, jedes Objekt hat nur einen Key => Array

    result.content = answerParts.inhalte
      .map((obj: any) =>
        Object.entries(obj).map(
          ([key, array]: [key: string | number, array: any]) => ({
            [key]: array.map((content: any) => {
              let m = content.match(/^\{Bild\|([^|]+)\|w:(\d*);h:(\d*)\}$/);
              if (m) {
                return {
                  img: (m[1] as string).toLowerCase(),
                  w: m[2],
                  h: m[3],
                };
              }
              return content;
            }),
          })
        )
      )
      .flat();
  }
  if (answerParts.titelzeile) {
    const decoded2 = answerParts.titelzeile.map((content: any) => {
      let m = content.match(/^\{Bild\|([^|]+)\|w:(\d*);h:(\d*)\}$/);
      if (m) {
        return { img: (m[1] as string).toLowerCase(), w: m[2], h: m[3] };
      }
      return content;
    });

    result.titlerow = decoded2;
  }

  result.titlecolumn =
    answerParts.titelspalte === "true" || answerParts.titelspalte === true
      ? true
      : false;

  return result;
};

export const decodeAnswerClickMatrix = (options: string): any => {
  const answerParts = jsonDecode(options);
  if (!answerParts) throw new Error("json not parseable");
  const decoded = Object.fromEntries(
    Object.entries(answerParts).map(([a, b]: [a: string | number, b: any]) => {
      return [[parseInt(a as string) - 1], b.map((c: number) => c - 1)];
    })
  );
  return decoded;
};

export const checkAnswerClickMatrix = (options: string): string => {
  try {
    decodeAnswerClickMatrix(options);
  } catch (e: any) {
    return e.message;
  }
  return "ok";
};

import { Card, Grid, Typography } from "@mui/material";
import { ResultsMainList } from "./ResultsMainList";
import { ResultSummary } from "./ResultSummary";
import { CandidateData } from "./CandidateData";
import { CandidateLogs } from "./CandidateLogs";
import { ResultSummaryChart } from "./ResultSummaryChart";
import { datetime_hr } from "../../shared/helper/datetime";
import { CandidateT } from "../api";

interface ResultsPropsI {
  candidate: CandidateT;
}
export const ResultsData = ({ candidate }: ResultsPropsI) => {
  return (
    <div id="results">
      <Typography variant="h2" component="h2">
        Auswertung {candidate?.firstname + " " + candidate?.lastname}
      </Typography>
      <Typography variant="h4" component="div" sx={{ mt: 1, mb: 2 }}>
        {candidate?.professionName} | {candidate?.testId} |{" "}
        {candidate?.testDate ? datetime_hr(new Date(candidate?.testDate)) : ""}{" "}
      </Typography>

      <Card sx={{ pt: 3, pb: 3, pl: 1, pr: 1, m: 0 }}>
        <div className="results-overview-container">
          {candidate?.results?.countTotal &&
          candidate?.results?.countTotal > 0 ? (
            <div className="card-grid line-right results-chart-container">
              {candidate && candidate.results && (
                <div className="result-summary result-summary-1">
                  <Typography
                    variant="h2"
                    component="div"
                    className="result-summary-title"
                  >
                    Gesamtergebnis:
                  </Typography>
                  <Typography
                    variant="h2"
                    component="div"
                    sx={{ mb: 4 }}
                    className="result-summary-percent"
                  >
                    {candidate.results.sumPoints > 0
                      ? (
                          (candidate.results.sumPointsCorrect /
                            candidate.results.sumPoints) *
                          100
                        ).toFixed(0)
                      : 0.0}{" "}
                    %
                  </Typography>
                </div>
              )}
              {candidate?.results && (
                <ResultSummary results={candidate.results} />
              )}
              {candidate?.results &&
                candidate?.results.mainCategories.length > 2 && (
                  <ResultSummaryChart results={candidate?.results} />
                )}
            </div>
          ) : (
            <></>
          )}
          <div className="results-candidate-data-container card-grid">
            {candidate && <CandidateData candidate={candidate} />}
            <br />
            <Typography variant="body2" component="div">
              <span className="semibold">Bemerkungen</span>
              <br />
              <br />
              {candidate?.comment}
            </Typography>
          </div>
        </div>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {candidate?.results && (
            <ResultsMainList results={candidate.results} />
          )}
        </Grid>
        {candidate?.logs && (
          <Grid item xs={12} md={12}>
            <div className="results-candidate-logs-container card-grid">
              {<CandidateLogs logs={candidate.logs} />}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

import {
  Grid,
  styled,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useFiles } from "../file/useFiles";
import { ProfessionT } from "./api";

const IMGSIZE = 150;
const StyledImg = styled("img")({
  maxWidth: IMGSIZE,
  maxHeight: IMGSIZE,
});

interface ProfessionImageSelectPropsI {
  openImgDialog: boolean;
  setOpenImgDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setProfessionState: React.Dispatch<React.SetStateAction<ProfessionT>>;
}

export const ProfessionImageSelect = (props: ProfessionImageSelectPropsI) => {
  const { fileList: imgList } = useFiles({ type: "profession" });

  const handleCloseImgDialog = (reason: string) => {
    if (reason === "cancel") props.setOpenImgDialog(false);
  };

  return (
    <Dialog
      open={props.openImgDialog}
      onClose={(e: object, reason: string) => handleCloseImgDialog(reason)}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">Bild zuordnen</DialogTitle>
      <DialogContent>
        <Grid container>
          {imgList.map((img) => (
            <Grid
              item
              key={img.id}
              sx={{
                width: IMGSIZE + 4,
                height: IMGSIZE + 4,
                m: 1,
                p: "1px",
                border: "1px solid lightgray",
                textAlign: "center",
              }}
            >
              <StyledImg
                src={import.meta.env.VITE_IMGURL + "/" + img.filePath}
                alt={img.name}
                onClick={(e) => {
                  props.setProfessionState((prevState) => {
                    return {
                      ...prevState,
                      imgId: img.id,
                      imgName: img.name,
                      imgPath: img.filePath,
                    };
                  });
                  props.setOpenImgDialog(false);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCloseImgDialog("cancel")}
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

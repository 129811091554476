import { updateUser, UserT } from "../user/api";
import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { AdminForm } from "./AdminForm";

import { Dialog } from "../shared/Dialog";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { UserContext } from "../user/UserContext";
import { updateStateArrayById } from "../shared/helper/state";
import { TooltipIcon } from "../shared/forms/TooltipIcon";

interface EditAdminPropsI {
  user: UserT;
}

export const EditAdmin = ({ user }: EditAdminPropsI) => {
  const { setUserList, setAllUserEmails } = useContext(UserContext);
  const { setError } = useContext(ErrorContext);

  const [open, setOpen] = useState(false);
  const { openSnackbar } = useContext(FeedbackContext);

  const onClick = (userData: UserT) => {
    if (!userData.email) return;
    userData.role = "admin";
    updateUser(userData)
      .then((res) => {
        if (res.success) {
          setUserList((p) => updateStateArrayById<UserT>(p, userData));

          setAllUserEmails((p) =>
            updateStateArrayById(p, { email: userData.email, id: userData.id })
          );

          openSnackbar("success", "Eintrag gespeichert");
          setOpen(false);
        } else {
          if (res.errors) {
            if (
              "email" in res.errors &&
              (res.errors as any).email[0].indexOf("already") > 0
            ) {
              setError("Diese E-Mail ist bereits vorhanden!");
            } else setError(res.error);
          }
        }
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <>
      <TooltipIcon
        type="edit"
        title="Bearbeiten"
        onClick={(e) => setOpen(true)}
      />
      <Dialog open={open} setOpen={setOpen} title="Admin bearbeiten">
        <AdminForm onClick={onClick} user={user} button="Speichern" />
      </Dialog>
    </>
  );
};

import { Box, Checkbox, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import GenderSelect from "./GenderSelect";
import CloseIcon from "@mui/icons-material/Close";

type PasswordT = {
  new: string;
  rep: string;
};
interface EditableEntryWithPenPropsI {
  id: string;
  value: number | string | boolean | PasswordT;
  setValue: (i: number | string | boolean, id?: string) => void;
  showEdit: boolean;
  setShowEdit: (b: boolean) => void;
  closeShowEdit: () => void;
  type?: "text" | "number" | "gender-select" | "newsletter" | "password";
  closeAction: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const EditableEntryWithPen = ({
  id,
  value,
  setValue,
  showEdit,
  setShowEdit,
  closeShowEdit,
  type = "text",
  closeAction,
}: EditableEntryWithPenPropsI) => {
  useEffect(() => {
    if (showEdit) {
      setTimeout(() => document.getElementById(id)?.focus(), 100);
    }
  }, [showEdit]);

  const [initValue, setInitValue] = useState<number | string | boolean>(
    value as number | string | boolean
  );

  return showEdit && type === "newsletter" ? (
    <Box
      className="input-container"
      style={{ justifyContent: "flex-start", gap: "5px" }}
    >
      <>
        <span>
          <input
            type="checkbox"
            id="newsletter"
            value="1"
            checked={value as boolean}
            onChange={(e) => setValue(e.target.checked)}
            onMouseDown={(e) => e.stopPropagation()}
            style={{
              position: "relative",
              top: "2px",
            }}
          />
        </span>
        <span
          className="value-container"
          style={{
            width: "320px",
            overflow: "hidden",
            maxHeight: "30px",
            textOverflow: "ellipsis",
            fontSize: "9px",
            whiteSpace: "normal",
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          Ja, ich möchte per Mail über aktuelle Angebote der Degeba informiert
          werden.
        </span>
      </>
    </Box>
  ) : (
    <Box className="input-container">
      {showEdit ? (
        type === "gender-select" ? (
          <>
            <GenderSelect
              id="gender"
              value={value as string}
              setValue={(v: string) => setValue(v)}
              size="medium"
              onMouseDown={(e) => e.stopPropagation()}
              sx={{ backgroundColor: "#fff", height: "30px" }}
            />
            <Box
              className="close-icon"
              onClick={(e) => {
                setValue(initValue);
                closeShowEdit();
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <CloseIcon className="close-icon" />
            </Box>
          </>
        ) : type === "newsletter" ? (
          <></>
        ) : type === "password" ? (
          <>
            <div className="password-container">
              <Box
                className="close-icon"
                onClick={(e) => closeAction(e)}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <CloseIcon className="close-icon" />
              </Box>
              <TextField
                value={(value as PasswordT).new}
                onChange={(e) => {
                  setValue(e.target.value, "new");
                }}
                onMouseDown={(e) => e.stopPropagation()}
                type="password"
                placeholder="neues Passwort"
                sx={{ width: "100%" }}
              />
              <TextField
                value={(value as PasswordT).rep}
                onChange={(e) => {
                  setValue(e.target.value, "rep");
                }}
                onMouseDown={(e) => e.stopPropagation()}
                type="password"
                placeholder="Passwort wiederholen"
                sx={{ width: "100%" }}
              />
            </div>
          </>
        ) : setValue ? (
          <>
            <Box
              className="close-icon"
              onClick={(e) => {
                setValue(initValue);
                closeAction(e);
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <CloseIcon className="close-icon" />
            </Box>
            <input
              id={id}
              className="textfield"
              value={value as string}
              onChange={(e) => setValue(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </>
        ) : (
          <></>
        )
      ) : (
        /**
         * non edit mode
         */
        <>
          <div
            className={"value-container" + value ? "" : "newsletter-inctive"}
          >
            {type === "newsletter" ? (
              <span
                style={{
                  width: "320px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "9px",
                }}
              >
                Ja, ich möchte per Mail über aktuelle Angebote der Degeba
                informiert werden.
              </span>
            ) : type === "password" ? (
              "********"
            ) : type === "gender-select" ? (
              (value as string) === "m" ? (
                "Herr"
              ) : (value as string) === "f" ? (
                "Frau"
              ) : (value as string) === "d" ? (
                "Divers"
              ) : (
                ""
              )
            ) : value ? (
              (value as string)
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          <div className="edit-icon">
            <EditIcon
              id={id}
              onMouseDown={(e) => {
                e.stopPropagation();
                setInitValue(value as number | string | boolean);
                setShowEdit(true);
              }}
              sx={{ fontSize: "medium", color: "#818181" }}
            />
          </div>
        </>
      )}
    </Box>
  );
};

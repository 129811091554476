import { Loader } from "../../shared/Loader";
import { Results } from "./Results";
import { useCandidates } from "../useCandidates";
import {
  CandidateResultsT,
  CandidateT,
  QuestionResultsT,
  SubResultT,
  fetchCandidate,
} from "../api";
import { useContext, useEffect, useRef, useState } from "react";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { useParams } from "react-router-dom";

export const getMainIdx = (
  results: CandidateResultsT,
  mainCategoryId: number
) =>
  results.mainCategories.findIndex((r) => r.mainCategoryId === mainCategoryId);

export const getSubIdx = (subs: SubResultT[], subCategoryId: number) =>
  subs.findIndex((r) => r.subCategoryId === subCategoryId);

export const getQuestionIdx = (subs: QuestionResultsT[], questionId: number) =>
  subs.findIndex((r) => r.id === questionId);

interface ResultsLoaderPropsI {
  candidateId?: number;
}
export const ResultsLoader = (props: ResultsLoaderPropsI) => {
  const params = useParams<{ candidateId: string }>();

  let candidateId = params.candidateId
    ? parseInt(params.candidateId)
    : props.candidateId;

  const { loadingDone, candidateList, setCandidateList } = useCandidates({
    reloadResultsForCandidateId: candidateId,
  });
  const { setError } = useContext(ErrorContext);
  const { hideProgress } = useContext(FeedbackContext);

  const [loadingDoneSingleCandidate, setLoadingDoneSingleCandidate] =
    useState(false);

  const [singleCandidateList, setSingleCandidateList] = useState<CandidateT[]>(
    []
  );

  const done = useRef(false);
  useEffect(() => {
    if (!candidateList.length || !candidateId) return;
    if (loadingDoneSingleCandidate) return;
    if (done.current) return;
    done.current = true;
    const candidateInList = candidateList.find((c) => c.id === candidateId);
    if (
      candidateInList &&
      candidateInList.results &&
      candidateInList.results.mainCategories &&
      candidateInList.results.mainCategories.length &&
      candidateInList.results.mainCategories[0].subCategories.length &&
      candidateInList.results.mainCategories[0].subCategories[0].questions &&
      candidateInList.results.mainCategories[0].subCategories[0].questions
        .length
    ) {
      setLoadingDoneSingleCandidate(true);
    } else {
      fetchCandidate(candidateId || 0)
        .then((result: any) => {
          if (result.success) {
            setCandidateList((p) =>
              p.map((c) => (c.id === candidateId ? result.data : c))
            );
            setLoadingDoneSingleCandidate(true);
          } else setError(result.error);
          hideProgress();
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [candidateList, candidateId, loadingDoneSingleCandidate]);

  if (loadingDone && loadingDoneSingleCandidate)
    return <Results candidateId={candidateId || 0} />;

  return <Loader />;
};

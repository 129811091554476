import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  ComparatorT,
  EnhancedTableHead,
  HeadCellT,
  NonOptionalKeys,
  OrderDirT,
  getComparator,
  handleRequestSort,
  stableSort,
} from "../shared/table/EnhancedTable";
import {
  HelpCategoryT,
  HelpEntryT,
  updateHelpCategoryBulk,
  updateHelpEntryBulk,
} from "./api";
import { useContext, useEffect, useState } from "react";
import { TableContext } from "../shared/table/TableContext";
import { HelpContext } from "./HelpContext";
import { usePagination } from "../shared/hooks/usePagination";
import { useShowSave } from "../shared/hooks/useShowSave";
import { HelpCategory } from "./HelpCategory";
import SaveChanges from "../shared/list/SaveChanges";
import { AddHelpCategory } from "./AddHelpCategory";
import { useSearch } from "../shared/hooks/useSearch";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ErrorContext } from "../error/ErrorContext";
import {
  updateOrInsertStateArrayById,
  updateStateArrayById,
} from "../shared/helper/state";

export const HelpCategoryTable = () => {
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const { setError } = useContext(ErrorContext);
  const [saveCatDone, setSaveCatDone] = useState(false);
  const [saveEntryDone, setSaveEntryDone] = useState(false);

  const {
    headCells,
  }: {
    headCells: HeadCellT<HelpCategoryT>[];
  } = useContext(TableContext);
  const [orderDir, setOrderDir] = useState<OrderDirT>("asc");
  const [orderBy, setOrderBy] =
    useState<NonOptionalKeys<HelpCategoryT>>("sortOrder");
  const {
    helpCategoryList,
    setHelpCategoryList,
    changedHelpCategoryList,
    setChangedHelpCategoryList,
    helpEntryList,
    setHelpEntryList,
    changedHelpEntryList,
    setChangedHelpEntryList,
  } = useContext(HelpContext);
  const comparator: ComparatorT<HelpCategoryT> = getComparator(
    orderDir,
    orderBy
  );
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("help-category");
  const [showSave, setShowSave] = useShowSave();
  const [filter, setFilter] = useSearch("help-category-search");
  const [forceShowSave, setForceShowSave] = useState(false);

  const moveCategory = (helpCategory: HelpCategoryT, direction: string) => {
    const actSortOrder = helpCategory.sortOrder;
    console.clear();
    console.log(
      "%c START",
      "color: gold;",
      JSON.stringify(helpCategoryList, null, 2)
    );

    if (actSortOrder === 1 && direction === "up") return;
    if (actSortOrder === helpCategoryList.length && direction === "down")
      return;

    const cat2swap = helpCategoryList.find(
      (e) =>
        e.sortOrder ===
        (direction === "down" ? actSortOrder + 1 : actSortOrder - 1)
    );
    if (!cat2swap) return;

    console.log(
      "%cHelpCategoryTable.tsx line:63 actPos",
      "color: gold;",
      "act sortOrder",
      JSON.stringify(helpCategory, null, 2),
      direction,
      JSON.stringify(cat2swap, null, 2)
    );

    const helpCategoryList2 = helpCategoryList.map((e) => {
      if (e.id === helpCategory.id) {
        return {
          ...e,
          sortOrder: cat2swap.sortOrder,
        };
      }
      if (e.id === cat2swap.id) {
        return {
          ...e,
          sortOrder: helpCategory.sortOrder,
        };
      }
      return e;
    });
    setHelpCategoryList(helpCategoryList2);
    /** we store also in changed to save changes */
    setChangedHelpCategoryList((prevState) => {
      let res = updateOrInsertStateArrayById(prevState, {
        ...helpCategory,
        sortOrder: cat2swap.sortOrder,
      });
      console.log(
        "%cHelpCategoryTable.tsx line:106 res",
        "color: orange;",
        res
      );
      res = updateOrInsertStateArrayById(res, {
        ...cat2swap,
        sortOrder: helpCategory.sortOrder,
      });
      console.log(
        "%cHelpCategoryTable.tsx line:106 res",
        "color: orange;",
        res
      );
      return res;
    });
  };

  const moveEntry = (helpEntry: HelpEntryT, direction: string) => {
    const actSortOrder = helpEntry.sortOrder;
    console.clear();
    console.log(
      "%c moveEntry",
      "color: gold;",
      JSON.stringify(helpEntryList, null, 2)
    );

    const actHelpEntryList = helpEntryList.filter(
      (entry) => entry.helpCategoryId === helpEntry.helpCategoryId
    );

    if (actSortOrder === 1 && direction === "up") return;
    if (actSortOrder === actHelpEntryList.length && direction === "down")
      return;

    const entry2swap = actHelpEntryList.find(
      (e) =>
        e.sortOrder ===
        (direction === "down" ? actSortOrder + 1 : actSortOrder - 1)
    );
    if (!entry2swap) return;

    console.log(
      "%cHelpEntryTable.tsx line:63 actPos",
      "color: gold;",
      "act sortOrder",
      JSON.stringify(helpEntry, null, 2),
      direction,
      JSON.stringify(entry2swap, null, 2)
    );

    const helpEntryList2 = helpEntryList.map((e) => {
      if (e.id === helpEntry.id) {
        return {
          ...e,
          sortOrder: entry2swap.sortOrder,
        };
      }
      if (e.id === entry2swap.id) {
        return {
          ...e,
          sortOrder: helpEntry.sortOrder,
        };
      }
      return e;
    });
    setHelpEntryList(helpEntryList2);
    /** we store also in changed to save changes */
    setChangedHelpEntryList((prevState) => {
      let res = updateOrInsertStateArrayById(prevState, {
        ...helpEntry,
        sortOrder: entry2swap.sortOrder,
      });
      console.log("%cHelpEntryTable.tsx line:106 res", "color: orange;", res);
      res = updateOrInsertStateArrayById(res, {
        ...entry2swap,
        sortOrder: helpEntry.sortOrder,
      });
      console.log("%cHelpEntryTable.tsx line:106 res", "color: orange;", res);
      return res;
    });
  };

  useEffect(() => {
    console.log(
      "%cHelpCategoryTable.tsx line:118 🍀 helpCategoryList",
      "color: #007acc;",
      JSON.stringify(helpCategoryList.sort(comparator))
    );
  }, [helpCategoryList]);

  useEffect(() => {
    console.log(
      "%cHelpCategoryTable.ts CHANGE CHANGE :122 🎸 changedHelpCategoryList",
      "color: #007acc;",
      JSON.stringify(changedHelpCategoryList)
    );
    if (changedHelpCategoryList.length > 0) {
      setShowSave(true);
      setForceShowSave(true);
    } else setForceShowSave(false);
  }, [changedHelpCategoryList]);

  useEffect(() => {
    console.log(
      "%cHelpCategoryTable.ts CHANGE CHANGE :122 🎸🎸 changedHelpEntryList",
      "color: #007acc;",
      JSON.stringify(changedHelpEntryList)
    );
    if (changedHelpEntryList.length > 0) {
      setShowSave(true);
      setForceShowSave(true);
    } else setForceShowSave(false);
  }, [changedHelpEntryList]);

  const saveHelpCategories = () => {
    if (changedHelpCategoryList.length > 0) {
      setSaveCatDone(false);
      updateHelpCategoryBulk(changedHelpCategoryList)
        .then((result) => {
          if (result.success) {
            setHelpCategoryList((prevState) =>
              prevState.map((oldEntry) => {
                const replEntry = changedHelpCategoryList.find(
                  (e) => e.id === oldEntry.id
                );

                if (replEntry) {
                  return {
                    ...oldEntry,
                    ...replEntry,
                  };
                }
                return oldEntry;
              })
            );

            setChangedHelpEntryList([]);
            setSaveCatDone(true);
          } else {
            if (result.errors) {
              setError(JSON.stringify(result.errors));
            }
          }
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setSaveCatDone(true);
    }
    if (changedHelpEntryList.length > 0) {
      setSaveEntryDone(false);
      updateHelpEntryBulk(changedHelpEntryList)
        .then((result) => {
          if (result.success) {
            setHelpEntryList((prevState) =>
              prevState.map((oldEntry) => {
                const replCat = changedHelpCategoryList.find(
                  (e) => e.id === oldEntry.id
                );

                if (replCat) {
                  return {
                    ...oldEntry,
                    ...replCat,
                  };
                }
                return oldEntry;
              })
            );

            setChangedHelpCategoryList([]);
            setSaveEntryDone(true);
          } else {
            if (result.errors) {
              setError(JSON.stringify(result.errors));
            }
          }
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setSaveEntryDone(true);
    }
  };

  useEffect(() => {
    if (saveCatDone && saveEntryDone) {
      openSnackbar("success", "Daten gespeichert");
      setShowSave(false);
      setSaveCatDone(false);
      setSaveEntryDone(false);
    }
  }, [saveCatDone, saveEntryDone]);

  return (
    <Typography variant="body1" component="span">
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item sx={{ marginRight: "auto" }}>
          {(showSave || forceShowSave) && (
            <SaveChanges onClick={saveHelpCategories} />
          )}
        </Grid>
        <Grid item>
          <AddHelpCategory setFilter={setFilter} />
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={headCells}
            orderDir={orderDir}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(
                event,
                property,
                orderDir,
                setOrderDir,
                orderBy,
                setOrderBy
              )
            }
            rowCount={helpCategoryList.length}
          />
          <TableBody>
            {stableSort<HelpCategoryT>(helpCategoryList, comparator)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((helpCategory, index) => {
                return (
                  <HelpCategory
                    key={helpCategory.id + "" + helpCategory.sortOrder}
                    helpCategory={helpCategory}
                    setShowSave={setShowSave}
                    moveCategory={moveCategory}
                    moveEntry={moveEntry}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={helpCategoryList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
      />
    </Typography>
  );
};

import { CallApiResultT, get } from "../shared/lib/api";

export type CountryT = {
  id: number;
  name: string;
};
export interface CountryDataT extends CountryT {}

/** ************************************************************************
 *
 */
export const fetchCountries = async (
  id: number
): Promise<CallApiResultT<CountryT[]>> => {
  const res = await get<CountryDataT[]>("/countries/");

  return res;
};

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { updatePartOfStateArrayById } from "../../shared/helper/state";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TestModusQuestionPropsI } from "../QuestionSwitch";
import { uniqueId } from "lodash";
import React, { Fragment } from "react";
import { TestModusQuestionT } from "../api";
import { Loader } from "../../shared/Loader";

export type ClozeTextMappingT = {
  [key: string | number]: string;
};

export const QuestionClozeText = ({
  question: q,
  setQuestionList,
}: TestModusQuestionPropsI) => {
  if (!q) return <Loader />;

  const handleChangeText = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (setQuestionList)
      setQuestionList((prevState) =>
        updatePartOfStateArrayById(prevState, q.id, {
          candidateAnswer: {
            ...(q.candidateAnswer as ClozeTextMappingT),
            [idx]: (event.target as HTMLInputElement).value as string,
          },
        })
      );
  };

  const handleChangeDropdown = (
    event: SelectChangeEvent<string>,
    idx: number
  ) => {
    // setDropdownValue(event.target.value as string);

    if (setQuestionList)
      setQuestionList((prevState) =>
        updatePartOfStateArrayById(prevState, q.id, {
          candidateAnswer: {
            ...(q.candidateAnswer as ClozeTextMappingT),
            [idx]: event.target.value as string,
          },
        })
      );
  };

  if (!q.answerOptionsDecoded) return <></>;
  if (q.answerOptionsDecoded.format === "table") {
    const header =
      q.answerOptionsDecoded.data[1][0].type === "separator"
        ? q.answerOptionsDecoded.data.slice(0, 1)[0]
        : undefined;
    const data = header
      ? q.answerOptionsDecoded.data.slice(2)
      : q.answerOptionsDecoded.data;

    return (
      <TableContainer className="question-answers">
        <Table data-testid="questionclozetext" className="cloze-table">
          {header && (
            <TableHead>
              <TableRow>
                {header.map((o: any, idx2: number) => {
                  return (
                    <TableCell
                      sx={{ fontWeight: "bold", backgroundColor: "#e0e0e0" }}
                      key={"header" + idx2}
                    >
                      {clozeTextPart({
                        o,
                        q,
                        handleChangeText,
                        handleChangeDropdown,
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.map((line: any, idx: number) => {
              return (
                <TableRow key={"TableRow" + idx}>
                  {line.map((o: any, idx2: number) => (
                    <TableCell
                      sx={{ backgroundColor: "#f3f3f3" }}
                      key={"TableCell" + idx + "|" + idx2}
                      className="cloze-table-cell"
                    >
                      {clozeTextPart({
                        o,
                        q,
                        handleChangeText,
                        handleChangeDropdown,
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <Box className="question-answers">
      {(q.answerOptionsDecoded.data as any[]).map((o) =>
        clozeTextPart({
          o,
          q,
          handleChangeText,
          handleChangeDropdown,
        })
      )}
    </Box>
  );
};

interface ClozeTextPartPropsI {
  o: any;
  q: TestModusQuestionT;
  handleChangeText: (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => void;
  handleChangeDropdown: (event: SelectChangeEvent<string>, idx: number) => void;
}

const clozeTextPart = ({
  o,
  q,
  handleChangeText,
  handleChangeDropdown,
}: ClozeTextPartPropsI) => {
  if (!o || !("type" in o)) {
    return <>Fehler</>;
  }

  return o.type === "text" ? (
    <TextField
      key={"textfieldText" + o.index}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeText(e, o.index)
      }
      value={
        q.candidateAnswer && q.candidateAnswer[o.index]
          ? q.candidateAnswer[o.index]
          : ""
      }
    />
  ) : o.type === "dropdown" && o.options ? (
    <Fragment key={"fragment" + o.index}>
      <Select
        key={"select" + o.index}
        size="small"
        value={
          q.candidateAnswer && q.candidateAnswer[o.index]
            ? q.candidateAnswer[o.index]
            : 0
        }
        onChange={(e) =>
          handleChangeDropdown(e as SelectChangeEvent<string>, o.index)
        }
        className="cloze-dropdown"
      >
        <MenuItem value={0} className="cloze-dropdown">
          <Typography variant="body2"></Typography>
        </MenuItem>
        {o.options.map((e: string, idx: number) => (
          <MenuItem key={"menuitem" + idx} value={e} className="cloze-dropdown">
            <Typography variant="body2">{e}</Typography>
          </MenuItem>
        ))}
      </Select>
      {o.answer && (
        <span data-testid="answer" key={uniqueId()}>
          ({o.answer})
        </span>
      )}
    </Fragment>
  ) : (
    <span key={uniqueId()} className="cloze-label">
      {o.content}
    </span>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { MoveButton } from "./MoveButton";
import { addRelation, deleteRelation, QuestionRelationT } from "./api";
import { RelationContext } from "./RelationContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import { QuestionDetails } from "../question/QuestionDetails";
import { ellipsisSx } from "../shared/styles/sx";
import { FilteredQuestionT } from "./QuestionRelationTable";
import { RELATIONREDUCERACTIONS } from "./relationReducer";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { SelTreeT } from "./useSelTree";
import { Theme } from "@mui/system";
import { Preview } from "../question/editor/Preview";
import { AddRelationIcon } from "./Icons/AddRelationIcon";
import { DelRelationIcon } from "./Icons/DelRelationIcon";
import { RatingFlag } from "../question/RatingFlag";

interface QuestionPropsI {
  question: FilteredQuestionT;
  selTree: SelTreeT;
  isQuestionSelected: (questionId: number) => QuestionRelationT | undefined;
  isQuestionSelectedByOther: (questionId: number) => boolean | undefined;
  isSubCategorySelected: boolean;
  showDetails: boolean;
  moveQuestion: (direction: string, id: number) => void;
  showPreview?: boolean;
}
export const Question = React.memo(
  ({
    question,
    isQuestionSelected,
    isQuestionSelectedByOther,
    isSubCategorySelected,
    selTree,
    showDetails,
    moveQuestion,
    showPreview: showPreviewProps = false,
  }: QuestionPropsI) => {
    const { setRelationList } = useContext(RelationContext);
    const { setError } = useContext(ErrorContext);
    const { openSnackbar, showProgress } = useContext(FeedbackContext);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
      setShowPreview(showPreviewProps);
    }, [showPreviewProps]);

    const relOwn = isQuestionSelected(question.id);
    const relOther = isQuestionSelectedByOther(question.id);

    const onClickAddRelation = (questionId: number) => {
      showProgress();
      addRelation({
        type: "question",
        professionId: selTree.professionId,
        mainCategoryId: selTree.mainCategoryId,
        subCategoryId: selTree.subCategoryId,
        questionId: questionId,
      })
        .then((result) => {
          if (result.success) {
            setRelationList({
              action: RELATIONREDUCERACTIONS.ADDQUESTION,
              data: {
                professionId: selTree.professionId,
                mainCategoryId: selTree.mainCategoryId,
                subCategoryId: selTree.subCategoryId,
                add: result.data as QuestionRelationT,
              },
            });

            openSnackbar("success", "Frage zugeordnet");
          } else setError(result.error);
        })
        .catch((error) => {
          setError(error);
        });
    };

    const onClickDelRelation = (relationId: number) => {
      showProgress();
      deleteRelation("question", relationId)
        .then(() => {
          setRelationList({
            action: RELATIONREDUCERACTIONS.DELQUESTION,
            data: {
              professionId: selTree.professionId,
              mainCategoryId: selTree.mainCategoryId,
              subCategoryId: selTree.subCategoryId,
              relationId: relationId,
            },
          });
          openSnackbar("success", "Frage entfernt");
        })
        .catch((error) => {
          setError(error);
        });
    };

    return (
      <Card
        variant="outlined"
        sx={{
          display: "inline-block",
          p: 1,
          background: (theme) => theme.palette.grey[100],
          m: 1,
          minWidth: 400,
          width: "100%",
        }}
        key={question.id}
      >
        <CardContent>
          <Grid container spacing={0} key={question.id}>
            <Grid item xs={8}>
              <Typography variant="body2" sx={{ ...ellipsisSx }}>
                {question.id} {question.question}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {question.example && (
                <Typography color="error">BEISPIEL</Typography>
              )}
            </Grid>
            <Grid item xs={1}>
              {question.difficulty}
            </Grid>
            <Grid item xs={1}>
              <RatingFlag rating={question.rating} />
            </Grid>
            {isSubCategorySelected && (
              <>
                <Grid item xs={12}></Grid>
                <Grid item xs={1}>
                  {relOther ? (
                    <NotInterestedOutlinedIcon />
                  ) : relOwn ? (
                    <DelRelationIcon
                      selTree={selTree}
                      onClick={() => onClickDelRelation(relOwn.id)}
                    />
                  ) : (
                    <AddRelationIcon
                      selTree={selTree}
                      onClick={() => onClickAddRelation(question.id)}
                    />
                  )}
                </Grid>

                <Grid item xs={1}>
                  {question.order > question.minOrder &&
                  question.order < 10000 ? (
                    <MoveButton
                      direction="up"
                      onClick={() => moveQuestion("up", question.id)}
                    />
                  ) : (
                    <MoveButton direction="up" disabled={true} />
                  )}
                </Grid>
                <Grid item xs={1}>
                  {question.order < question.maxOrder ? (
                    <MoveButton
                      direction="down"
                      onClick={() => moveQuestion("down", question.id)}
                    />
                  ) : (
                    <MoveButton direction="down" disabled={true} />
                  )}
                </Grid>
                <Grid item xs={1}>
                  <Link to={"/admin/question/" + question.id}>
                    <TooltipIcon
                      type="edit"
                      sx={{
                        color: (theme: Theme) => theme.palette.text.primary,
                      }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={1}>
                  {showPreview ? (
                    <TooltipIcon
                      type="hidePreview"
                      title="Vorschau der Frage ausblenden"
                      onClick={() => setShowPreview(false)}
                    />
                  ) : (
                    <TooltipIcon
                      type="showPreview"
                      title="Vorschau der Frage einblenden"
                      onClick={() => setShowPreview(true)}
                    />
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {showDetails && <QuestionDetails question={question} />}
            </Grid>
            <Grid item xs={12}>
              {showPreview && <Preview question={question} />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

import { default as MuiDialog } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breakpoint, SxProps, Theme } from "@mui/material/styles";
import { ReactElement } from "react";

interface DialogPropsI {
  children: ReactElement;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string | ReactElement;
  showCancel?: boolean;
  showClose?: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  sx?: SxProps<Theme>;
  width?: string;
}

export const Dialog = ({
  children,
  open,
  setOpen,
  title,
  showCancel = true,
  showClose = false,
  fullWidth = false,
  maxWidth = "lg",
  sx,
  width,
}: DialogPropsI) => {
  const handleClose = (reason: string) => {
    if (reason === "cancel") setOpen(false);
  };
  return (
    <MuiDialog
      open={open}
      onClose={(e: object, reason: string) => handleClose(reason)}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={sx}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent sx={width ? { width: width } : {}}>
        {children}
      </DialogContent>
      <DialogActions>
        {showClose && (
          <Button
            onClick={() => handleClose("cancel")}
            color="primary"
            variant="contained"
          >
            Schließen
          </Button>
        )}
        {showCancel && (
          <Button
            onClick={() => handleClose("cancel")}
            color="primary"
            variant="outlined"
          >
            Abbrechen
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

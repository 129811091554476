import { ConfirmDialog } from "../ConfirmDialog";
import { useContext, useState } from "react";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { TooltipIcon } from "./TooltipIcon";
import { CallApiResultT } from "../lib/api";
import { Box, Button, Modal, SxProps, Theme } from "@mui/material";
import { MailTemplateProvider } from "../../mailTemplate/MailTemplateContext";
import { MailTemplateLoader } from "../../mailTemplate/MailTemplateLoader";
import { CloseModalButton } from "../modal/CloseModalButton";
import { CandidateT } from "../../candidate/api";
import { SetCandidateProfession } from "../../candidate/SetCandidateProfession";
import { CandidateContext } from "../../candidate/CandidateContext";

interface ProfessionButtonPropsI {
  id?: number;
  candidates?: CandidateT[];
  successFunc?: () => void;
  successMsg?: string;
  className?: string;
  textYes?: string;
  textNo?: string;
  sx?: SxProps<Theme>;
  button?: boolean;
}

export const ProfessionButton = ({
  candidates = [],
  ...props
}: ProfessionButtonPropsI) => {
  console.log(
    "%csrc/components/shared/forms/ProfessionButton.tsx:34 candidates",
    "color: #007acc;",
    candidates
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const { setCandidateList } = useContext(CandidateContext);

  return (
    <>
      {Boolean(dialogOpen) && (
        <SetCandidateProfession
          candidates={candidates}
          setCandidateList={setCandidateList}
          onChange={() => {
            if (props.successFunc) {
              props.successFunc();
            }
          }}
          open={dialogOpen}
          setOpen={setDialogOpen}
        />
      )}
      <Button
        variant="contained"
        onClick={(e) => setDialogOpen(true)}
        sx={props.sx}
        data-testid="ProfessionButton"
      >
        Test zuordnen
      </Button>
    </>
  );
};

import { CustomerMailTemplate } from "./CustomerMailTemplate";
import { MailTemplate } from "./MailTemplate";
import { useMailTemplates } from "./useMailTemplates";
import { Loader } from "../shared/Loader";
import { CandidateT } from "../candidate/api";
import { SendMailCandidate } from "./SendMailCandidate";
import { UserT } from "../user/api";
import { SendWelcomeMailCustomer } from "./SendWelcomeMailCustomer";
import { SendMailCandidates } from "./SendMailCandidates";

interface MailTemplateLoaderPropsI {
  type: "customer" | "admin" | "candidate" | "candidates";
  candidate?: CandidateT;
  candidates?: CandidateT[];
  customer?: UserT;
  setDialogOpen?: (value: boolean) => void;
  onSend?: (i: number) => void;
}
export const MailTemplateLoader = (props: MailTemplateLoaderPropsI) => {
  const { loadingDone } = useMailTemplates({});

  if (!loadingDone) return <Loader />;

  return props.type === "customer" ? (
    props.customer ? (
      <SendWelcomeMailCustomer
        customer={props.customer}
        setDialogOpen={props.setDialogOpen}
        onSend={props.onSend}
      />
    ) : (
      <CustomerMailTemplate />
    )
  ) : props.type === "candidate" && props.candidate ? (
    <SendMailCandidate
      candidate={props.candidate}
      setDialogOpen={props.setDialogOpen}
      onSend={props.onSend}
    />
  ) : props.type === "candidates" && props.candidates ? (
    <SendMailCandidates
      candidates={props.candidates}
      setDialogOpen={props.setDialogOpen}
      onSend={props.onSend}
    />
  ) : (
    <MailTemplate />
  );
};

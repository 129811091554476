import { useContext, useEffect, useState, useRef } from "react";
import {
  fetchTransactions,
  fetchUserSelf,
  TransactionT,
  UserT,
  UserTransactionT,
} from "../user/api";
import { EMPTYUSER, UserContext } from "../user/UserContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseCustomerResultT extends UseResultT {
  user: UserT;
}

interface UseUsersPropsI extends UsePropsI {
  userId?: number;
}

export const useCustomer = ({
  dontHideProgress = false,
}: UseUsersPropsI): UseCustomerResultT => {
  const [userList, setUserList] = useState<UserT>(EMPTYUSER);

  const [temp, setTemp] = useState<UserT>();
  const [loadingDone, setLoadingDone] = useState(false);
  const [allDone, setAlldone] = useState(false);

  useLoadData({
    fetchFunc: () => fetchUserSelf(),
    list: temp,
    setList: setTemp,
    setLoadingDone,
    dontHideProgress,
  });

  const setDone = useRef(false);
  useEffect(() => {
    if (setDone.current) return;
    if (loadingDone) {
      setDone.current = true;
      if (temp) setUserList(temp);
      setAlldone(true);
    }
  }, [loadingDone, temp, setUserList]);

  return { user: userList, loadingDone: allDone };
};

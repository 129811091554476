import Button from "@mui/material/Button";

import { FileT, uploadFile } from "./api";
import { useCallback, useContext, useEffect, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { humanFileSize } from "../shared/helper/file";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { uniqBy } from "lodash";

interface FileUploadPropsI {
  type: string;
  setFileList: React.Dispatch<React.SetStateAction<FileT[]>>;
  fileList?: FileT[];
  maxNoFiles?: number;
  onUpload?: (files: FileT[]) => void;
}

const filesize = parseInt(import.meta.env.VITE_FILESIZE || "4");

export const FileUpload = ({
  type,
  setFileList,
  fileList = [],
  maxNoFiles = 200,
  onUpload,
}: FileUploadPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const [files, setFiles] = useState<File[]>([]);
  const [doubles, setDoubles] = useState<File[]>([]);
  const [dialogConfirmDouble, setDialogConfirmDouble] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newfiles = acceptedFiles.filter((f: File) => {
        if (f.size > filesize * 1024000) {
          // TODO: [DEGEBA-118] central config
          openSnackbar(
            "info",
            "Die Dateigröße darf maximal " + filesize + " MB betragen"
          );
          return false;
        }
        return true;
      });

      setDoubles((p) =>
        p.concat(
          newfiles.filter((f: File) =>
            fileList.some((f2: FileT) => f2.name === f.name)
          )
        )
      );

      setFiles((p) => uniqBy(p.concat(newfiles), "name"));
    },
    [openSnackbar, fileList]
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, multiple: maxNoFiles === 1 ? false : true });

  const onClickUpload = (checkDoubles = 0) => {
    if (files.length === 0) return;

    console.log("onClickUpload:", checkDoubles);
    if (!checkDoubles && doubles.length > 0) {
      setDialogConfirmDouble(true);
      return;
    }

    const formData = new FormData();
    const tmpFiles =
      checkDoubles === 2
        ? files.filter(
            (f: File) => !fileList.some((f2: FileT) => f2.name === f.name)
          )
        : files;
    setFiles(tmpFiles);
    if (tmpFiles.length === 0) return;
    showProgress();

    console.log("onClickUpload files", tmpFiles);
    formData.append("type", type);
    for (let i = 0; i < tmpFiles.length; i++) {
      formData.append("files[]", tmpFiles[i]);
    }
    console.log("formData", formData);
    uploadFile(formData)
      .then((result) => {
        if (result && result.data) {
          setFileList((p) => uniqBy(p.concat(result.data || []), "name"));

          openSnackbar("success", result.data.length + " Dateien hochgeladen ");
          setFiles([]);
          setDoubles([]);
          onUpload && onUpload(result.data);
        } else setError(result.error);
      })
      .catch((error) => {
        console.log("uploadFile catch", error);
        setError(error);
      });
  };

  useEffect(() => {
    console.log("files:", files);
  }, [files]);

  let totalSize = 0;
  let countFiles = 0;
  files.forEach((file, idx) => {
    totalSize += file.size;
    countFiles++;
  });
  if (countFiles > maxNoFiles) {
    openSnackbar("warning", "max. " + maxNoFiles + " Dateien!");
    setFiles(files.slice(0, 200));
  }

  return (
    <>
      <ConfirmDialog
        open={dialogConfirmDouble}
        handleYes={() => {
          setDialogConfirmDouble(false);
          onClickUpload(1);
        }}
        handleNo={() => {
          setDialogConfirmDouble(false);
          onClickUpload(2);
        }}
        title="Doppelte Dateien gefunden"
        content="Sollen die doppelten Dateien überschrieben werden?"
        textYes="Überschreiben"
        textNo="Doppelte Dateien entfernen"
        showCancel={true}
        handleCancel={() => setDialogConfirmDouble(false)}
      />
      <Grid container spacing="2">
        <Grid item>
          {acceptedFiles.length > 0 && files.length > 0 && (
            <Button variant="contained" onClick={() => onClickUpload()}>
              Upload
            </Button>
          )}
        </Grid>
        <Grid item {...getRootProps()}>
          {files.length === 0 ? (
            <Button
              sx={{
                background: isDragActive ? "darkgrey" : "#f5f5f5",
                border: "1px solid gray",
                height: 38,
                verticalAlign: "middle",
                p: "2px",
                //display: "inline-block",
                width: 400,
              }}
            >
              <input {...getInputProps()} />
              {/* <Typography variant="body2" sx={{ display: "inline" }}> */}
              {isDragActive
                ? "Datei hier Droppen"
                : "DragNDrop Dateien oder Click"}
              {/* </Typography> */}
            </Button>
          ) : (
            <Box
              sx={{
                border: "1px solid gray",
                background: isDragActive ? "gray" : "#f5f5f5",
                minHeight: 40,
                p: "2px",
                paddingRight: "20px",
                position: "relative",
                width: 400,
              }}
            >
              <Typography variant="body2" component="h5">
                {countFiles} Dateien ({humanFileSize(totalSize)})
              </Typography>
              {files.map((file, idx) => {
                let isDouble = doubles.some((f) => file.name === f.name);
                return (
                  <Typography
                    variant="body2"
                    component="li"
                    key={"file" + idx}
                    sx={{ ...(isDouble ? { color: "red" } : {}) }}
                  >
                    {file.name} - {humanFileSize(file.size)}{" "}
                  </Typography>
                );
              })}
              <DeleteForeverIcon
                onClick={(e) => {
                  setFiles([]);
                  setDoubles([]);
                  e.stopPropagation();
                }}
                fontSize="small"
                color="info"
                sx={{ position: "absolute", top: "0px", right: "0px" }}
              />
              <input {...getInputProps()} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

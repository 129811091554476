import { addCategory, MainCategoryT } from "./api";
import { useContext, useState } from "react";
import { MainCategoryContext } from "./MainCategoryContext";
import { ErrorContext } from "../error/ErrorContext";
import { MainCategoryForm } from "./MainCategoryForm";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { addStateToArray } from "../shared/helper/state";
import { AddButton } from "../shared/forms/AddButton";

export const AddMainCategory = () => {
  const { setCategoryList } = useContext(MainCategoryContext);
  const { setError } = useContext(ErrorContext);

  const [open, setOpen] = useState(false);
  const { openSnackbar } = useContext(FeedbackContext);

  const onClick = (data: MainCategoryT) => {
    if (!data.name) return;

    addCategory(data)
      .then((result) => {
        if (result.success && result.data) {
          setCategoryList((p) =>
            addStateToArray(p, result.data as MainCategoryT)
          );
          setOpen(false);
          openSnackbar("success", "Eintrag gespeichert");
        } else setError(result.error);
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };
  const handleClose = (reason: string) => {
    if (reason === "cancel") setOpen(false);
  };

  return (
    <>
      <AddButton title=" Hauptkategorie" onClick={() => setOpen(true)} />

      <Dialog
        open={open}
        onClose={(e: object, reason: string) => handleClose(reason)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neue Kategorie</DialogTitle>
        <DialogContent>
          <MainCategoryForm onClick={onClick} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose("cancel")}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface LayoutSelectPropI {
  id: string;
  value?: string;
  setValue: (value: string) => void;
  label?: string;
  labelId?: string;
}

export const LayoutSelect = (props: LayoutSelectPropI) => {
  return (
    <Select
      labelId={props.labelId}
      id={props.id}
      value={props.value ? props.value : ""}
      onChange={(e: SelectChangeEvent<string>) => {
        props.setValue(e.target.value);
      }}
      label="{props.label}"
      size="small"
      sx={{ fontSize: (theme) => theme.typography.body2.fontSize }}
    >
      <MenuItem value="vertical">Vertikal</MenuItem>
      <MenuItem value="horizontal">Horizontal</MenuItem>
    </Select>
  );
};

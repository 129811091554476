import * as React from "react";

import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { Sidebar } from "./Sidebar";
import { Copyright } from "../Copyright";
import { AuthContext } from "../../auth/AuthContext";
import { Navigate } from "react-router-dom";
import { DebugToolBox } from "../../DebugToolBox";
import { Helmet } from "react-helmet";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface AdminContentI {
  children: React.ReactNode;
}

export const AdminContent = ({ children }: AdminContentI) => {
  const { authState, setAuthState } = React.useContext(AuthContext);

  const [open, setOpen] = React.useState(
    authState && authState.settings && authState.settings.drawerState
      ? authState.settings.drawerState === "true"
        ? true
        : false
      : localStorage.getItem("drawerState") === "false"
      ? false
      : true
  );

  if (!authState.loggedIn) {
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
        replace={true}
      />
    );
  }

  const toggleDrawer = () => {
    setOpen(!open);
    setAuthState((p) => ({
      ...p,
      settings: { ...p.settings, drawerState: (!open).toString() },
    }));
    localStorage.setItem("drawerState", open ? "false" : "true");
  };

  return (
    <>
      <Helmet>
        <title>degeba - Adminbereich</title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
              px: [1],
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: "primary.main",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <MenuIcon sx={{}} />
            </IconButton>
            {open && (
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Toolbar>
          <Divider />
          <Sidebar />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            width: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "top",
          }}
        >
          <Paper
            sx={{
              mt: 0,
              mb: 2,
              p: 2,
              display: "flex",
              width: "100%",
              overflowX:
                "auto" /* important, so the paper is not smaller than the space it needs */,
              flexGrow: 1,
              alignItems: "flex-start",
              justifyContent: "top",
            }}
          >
            {children}
          </Paper>
          <Copyright sx={{ pt: 4, display: "flex" }} />
          <DebugToolBox />
        </Box>
      </Box>
    </>
  );
};

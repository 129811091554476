import { CandidateResultsT } from "../api";
import { Navigation } from "./Navigation";
import { ResultClozeText } from "./questionTypes/ResultClozeText";
import { ResultDnD } from "./questionTypes/ResultDnD";
import { ResultMultipleChoice } from "./questionTypes/ResultMultipleChoice";
import { ResultClickMatrix } from "./questionTypes/ResultClickMatrix";
import { ResultIcon } from "./ResultIcon";
import { getMainIdx, getQuestionIdx, getSubIdx } from "./ResultsLoader";
import { Box, Grid, Typography } from "@mui/material";
import { ResultNumbers } from "./ResultNumbers";

interface ResultsSubPropsI {
  questionId: number;
  mainCategoryId: number;
  subCategoryId: number;
  setShowQuestionId: (n: number) => void;
  setShowMainCategoryId: (n: number) => void;
  setShowSubCategoryId: (n: number) => void;
  results: CandidateResultsT;
}

export const ResultsQuestion = ({
  questionId,
  mainCategoryId,
  subCategoryId,
  setShowQuestionId,
  setShowMainCategoryId,
  setShowSubCategoryId,
  results,
}: ResultsSubPropsI) => {
  const mainIdx = getMainIdx(results, mainCategoryId);
  const subs =
    mainIdx >= 0 ? results.mainCategories[mainIdx].subCategories : null;
  if (!subs) return <>Error rs - s</>;
  const subIdx = getSubIdx(subs, subCategoryId);
  const questions = subs && subIdx >= 0 ? subs[subIdx].questions : null;
  if (!questions) return <>Error rs - qs</>;
  const questionIdx = getQuestionIdx(questions, questionId);
  const question = questions[questionIdx] || null;
  if (!question) return <>Error rs - q</>;

  return (
    <>
      {question ? (
        <>
          <Typography variant="body2" component="div" data-testid="question">
            <Box sx={{ color: "#878686" }}>Aufgaben-ID: {question.id}</Box>
            <br />
            <Box
              dangerouslySetInnerHTML={{
                __html: question.questionDecoded
                  ? question.questionDecoded
                  : question.question || " - keine Frage - ",
              }}
              sx={{ mb: 1, color: "#000" }}
            />
          </Typography>

          {
            {
              "multiple-choice": <ResultMultipleChoice question={question} />,
              lueckentext: <ResultClozeText question={question} />,
              klickmatrix: <ResultClickMatrix question={question} />,
              dnd_1u1_zuordnungen: <ResultDnD question={question} />,
            }[question.type]
          }
        </>
      ) : (
        <></>
      )}
    </>
  );
};

import React, { useState, useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { SearchField } from "../shared/forms/SearchField";
import Grid from "@mui/material/Grid";
import SaveChanges from "../shared/list/SaveChanges";

import { AddSubCategory } from "./AddSubCategory";
import { SubCategory } from "./SubCategory";
import { SubCategoryT } from "./api";
import {
  EnhancedTableHead,
  HeadCellT,
  stableSort,
  getComparator,
  OrderDirT,
  handleRequestSort,
  NonOptionalKeys,
  ComparatorT,
} from "../shared/table/EnhancedTable";
import { usePagination } from "../shared/hooks/usePagination";
import { SubCategoryContext } from "./SubCategoryContext";
import { ErrorContext } from "../error/ErrorContext";
import { updateCategoryBulk } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";

import Box from "@mui/material/Box";
import { useSearch } from "../shared/hooks/useSearch";
import { useShowSave } from "../shared/hooks/useShowSave";

const headCells: HeadCellT<SubCategoryT>[] = [
  {
    id: "name",
    disablePadding: true,
    label: "Name",
  },
  { id: "view", label: "Anzeige" },
  {
    id: "introText",
    label: "Text Einleitung",
    disablePadding: true,
  },
  { id: "memTime", label: "Merkzeit" },
  {
    id: "memText",
    label: "Merktext",
    disablePadding: true,
  },

  { id: "active", label: "Aktionen", colSpan: 2 },
];

/* ************************************ */
/*
 *
 *
 */
export const SubCategoryTable = () => {
  const [orderDir, setOrderDir] = useState<OrderDirT>("asc");
  const [orderBy, setOrderBy] = useState<NonOptionalKeys<SubCategoryT>>("name");
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("subcat");
  const [showSave, setShowSave] = useShowSave();
  const [filter, setFilter] = useSearch("subcatsearch");
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const { categoryList, setCategoryList } = useContext(SubCategoryContext);

  const {
    changedCategoryList,
    setChangedCategoryList,
  }: {
    changedCategoryList: SubCategoryT[];
    setChangedCategoryList: React.Dispatch<
      React.SetStateAction<SubCategoryT[]>
    >;
  } = useContext(SubCategoryContext);
  const { setError } = useContext(ErrorContext);

  if (categoryList.length === 0) return <></>;

  /** ************************************************************************
   *
   *
   */
  const saveCategories = () => {
    console.log("saveCa");
    showProgress();

    updateCategoryBulk(changedCategoryList)
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setCategoryList((prevState) =>
            prevState.map((oldCat) => {
              const replCat = changedCategoryList.find(
                (e) => e.id === oldCat.id
              );

              if (replCat) {
                return {
                  ...oldCat,
                  ...replCat,
                };
              }
              return oldCat;
            })
          );
          setChangedCategoryList([]);
          setShowSave(false);
        } else setError(result.error);
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  const comparator: ComparatorT<SubCategoryT> = getComparator(
    orderDir,
    orderBy
  );

  const filteredCategoryList = categoryList.filter(
    (e) => e.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredCategoryList.length - page * rowsPerPage);

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          {showSave && <SaveChanges onClick={saveCategories} />}
          <SearchField
            value={filter}
            setValue={setFilter}
            id="subcatsearch"
            handleChangePage={handleChangePage}
          />
        </Grid>
        <Grid item>
          <AddSubCategory />
        </Grid>
      </Grid>

      <TableContainer sx={{ width: "100%" }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={headCells}
            orderDir={orderDir}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(
                event,
                property,
                orderDir,
                setOrderDir,
                orderBy,
                setOrderBy
              )
            }
            rowCount={filteredCategoryList.length}
          />
          <TableBody>
            {stableSort<SubCategoryT>(filteredCategoryList, comparator)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((category, index) => {
                return (
                  <SubCategory
                    key={category.id}
                    category={category}
                    setShowSave={setShowSave}
                  />
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 61 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCategoryList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
      />
    </Box>
  );
};

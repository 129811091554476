import React, { createContext, useEffect, useState } from "react";
import { ContextPropsT } from "../shared/types/Context";
import { CandidateT, SchoolGraduationT } from "./api";

type CandidateTLenT = {
  [key: string | number]: number;
};

export const CandidateTLen: CandidateTLenT = {
  gender: 1,
  zipcode: 10,
  country: 2,
  phone: 20,
};

interface CandidateContextPropsType extends ContextPropsT {
  candidateList: CandidateT[];
  setCandidateList: React.Dispatch<React.SetStateAction<CandidateT[]>>;
  changedCandidateList: CandidateT[];
  setChangedCandidateList: React.Dispatch<React.SetStateAction<CandidateT[]>>;
  schoolGraduationList: SchoolGraduationT[];
  setSchoolGraduationList: React.Dispatch<
    React.SetStateAction<SchoolGraduationT[]>
  >;
  candidatesToDelete: number[];
  setCandidatesToDelete: React.Dispatch<React.SetStateAction<number[]>>;
  candidatesToMail: number[];
  setCandidatesToMail: React.Dispatch<React.SetStateAction<number[]>>;
  candidatesToProfession: number[];
  setCandidatesToProfession: React.Dispatch<React.SetStateAction<number[]>>;
}

export const candidateContextDefaults: CandidateContextPropsType = {
  candidateList: [],
  setCandidateList: () => {},
  changedCandidateList: [],
  setChangedCandidateList: () => {},
  candidatesToDelete: [],
  setCandidatesToDelete: () => {},
  candidatesToMail: [],
  setCandidatesToMail: () => {},
  candidatesToProfession: [],
  setCandidatesToProfession: () => {},
  schoolGraduationList: [],
  setSchoolGraduationList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
};

export const CandidateContext = createContext<CandidateContextPropsType>(
  candidateContextDefaults
);

export interface CandidateChangedT extends CandidateT {
  error?: boolean;
}

export const CandidateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [candidateList, setCandidateList] = useState<CandidateT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const [changedCandidateList, setChangedCandidateList] = useState<
    CandidateT[]
  >([]);
  const [schoolGraduationList, setSchoolGraduationList] = useState<
    SchoolGraduationT[]
  >([]);
  const [candidatesToDelete, setCandidatesToDelete] = useState<number[]>([]);
  const [candidatesToMail, setCandidatesToMail] = useState<number[]>([]);
  const [candidatesToProfession, setCandidatesToProfession] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT == "local") return;
    const blockContextMenu = (evt: MouseEvent) => {
      evt.preventDefault();
    };
    window.addEventListener("contextmenu", blockContextMenu);
    return () => {
      window.removeEventListener("contextmenu", blockContextMenu);
    };
  });

  return (
    <CandidateContext.Provider
      value={{
        candidateList,
        setCandidateList,
        changedCandidateList,
        setChangedCandidateList,
        schoolGraduationList,
        setSchoolGraduationList,
        loadingDone,
        setLoadingDone,
        candidatesToDelete,
        setCandidatesToDelete,
        candidatesToMail,
        setCandidatesToMail,
        candidatesToProfession,
        setCandidatesToProfession,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

import { TestModusQuestionT } from "../../testModus/api";
import { QuestionSwitch } from "../../testModus/QuestionSwitch";
import { QuestionT } from "../api";
import { isJSON } from "../../shared/helper/json";
import { Box, Typography } from "@mui/material";
import {
  decodeAnswerOptionsDragNDrop,
  decodeAnswerDragNDrop,
  checkAnswerDragNDrop,
  checkAnswerOptionsDragNDrop,
} from "./decode/dragNDrop";
import { decodeAnswerOptionsClozeText } from "./decode/clozeText";
import {
  checkAnswerClickMatrix,
  decodeAnswerClickMatrix,
  decodeAnswerOptionsClickMatrix,
} from "./decode/clickMatrix";
import {
  decodeAnswerOptionsMultipleChoice,
  decodeQuestionOptionsMultipleChoice,
} from "./decode/multipleChoice";
import { replaceImages } from "../../testModus/helper/img";

interface PreviewPropsI {
  question: QuestionT;
}
export const Preview = ({ question }: PreviewPropsI) => {
  if (question.type === "klickmatrix") {
    question.answerOptionsDecoded = decodeAnswerOptionsClickMatrix(
      question.answerOptions
    );
    try {
      question.answerDecoded = decodeAnswerClickMatrix(question.answer);
    } catch (e) {
      console.error(e);
      question.answerDecoded = [];
    }
  }
  if (question.type === "multiple-choice") {
    const res = decodeAnswerOptionsMultipleChoice(question.answerOptions);
    question.answerOptionsDecoded = res.answerOptionsDecoded;
    question.answerOptionsMaxHeight = res.answerOptionsMaxHeight;
    question.answerOptionsMaxWidth = res.answerOptionsMaxWidth;

    question.questionDecoded = decodeQuestionOptionsMultipleChoice(
      question.question
    );
  }
  if (question.type === "lueckentext") {
    question.answerOptionsDecoded = decodeAnswerOptionsClozeText(
      question.answerOptions
    );
  }
  if (question.type === "dnd_1u1_zuordnungen") {
    try {
      question.answerOptionsDecoded = decodeAnswerOptionsDragNDrop(
        question.answerOptions
      );
      question.answerDecoded = decodeAnswerDragNDrop(question.answer);
      if (question.answer.match(/\{Bild/)) {
        question.isImage = true;
      }
    } catch (e) {
      console.error(e);
      question.answerDecoded = [];
      question.answerOptionsDecoded = [];
    }
  }
  question.questionDecoded = replaceImages(
    question.question.replace(/(?:\r\n|\r|\n)/g, "<br />")
  );

  const emulateTestModusQuestion: TestModusQuestionT = {
    ...question,
    questionDecoded: question.questionDecoded ? question.questionDecoded : "",
    noInSubCategory: 0,
    mainCategoryId: 0,
    mainCategoryName: "",
    mainCategoryDesc: "",
    mainCategoryImg: "",
    subCategoryId: 0,
    subCategoryName: "",
    subCategoryDesc: "",
    subCategoryMemText: "",
    subCategoryMemTime: 0,
  };
  if (
    question.type === "dnd_1u1_zuordnungen" ||
    question.type === "klickmatrix" ||
    question.type === "multiple-choice"
  ) {
    if (!isJSON(question.answerOptions)) {
      return (
        <Typography variant="h2" color="error">
          Fehler in JSON
        </Typography>
      );
    }
  }
  if (question.type === "dnd_1u1_zuordnungen") {
    const checkResult = checkAnswerOptionsDragNDrop(question.answerOptions);
    if (checkResult === "json not parseable") {
      return (
        <Typography variant="h2" color="error">
          &quot;Optionen&quot; in falschem Format, JSON nicht parsebar
        </Typography>
      );
    } else if (checkResult !== "ok") {
      return (
        <Typography variant="h2" color="error">
          &quot;Optionen&quot; in falschem Format - Fehler: {checkResult}
        </Typography>
      );
    }
  }

  if (
    question.type === "klickmatrix" ||
    question.type === "dnd_1u1_zuordnungen"
  ) {
    const checkResult =
      question.type === "klickmatrix"
        ? checkAnswerClickMatrix(question.answer)
        : checkAnswerDragNDrop(question.answer);
    if (checkResult === "json not parseable") {
      return (
        <Typography variant="h2" color="error">
          &quot;Antworten&quot; in falschem Format, JSON nicht parsebar
        </Typography>
      );
    } else if (checkResult !== "ok") {
      return (
        <Typography variant="h2" color="error">
          &quot;Antworten&quot; in falschem Format - Fehler: {checkResult}
        </Typography>
      );
    }
  }

  return (
    <Box sx={{ maxHeight: "80vh", overflow: "scroll", pb: 1 }}>
      <QuestionSwitch question={emulateTestModusQuestion} />
    </Box>
  );
};

import React, { createContext, useCallback, useState } from "react";
import { HeadCellT, NonOptionalKeys } from "./EnhancedTable";

interface TableContextPropsT<T> {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  headCells: HeadCellT<T>[];
  setHeadCells: React.Dispatch<React.SetStateAction<HeadCellT<T>[]>>;
  cellIsHidden: (i: NonOptionalKeys<T>) => boolean;
}

export const TableContext = createContext<TableContextPropsT<any>>({
  name: "",
  setName: () => {},
  headCells: [],
  setHeadCells: () => {},
  cellIsHidden: () => false,
});
export const TableProvider = <T extends any>({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [headCells, setHeadCells] = useState<HeadCellT<T>[]>([]);
  const [name, setName] = useState("");

  const cellIsHidden = useCallback(
    (id: any) =>
      headCells
        .filter((c) => c.hide)
        .map((c) => c.id)
        .some((c) => c === id),
    [headCells]
  );

  return (
    <TableContext.Provider
      value={{
        headCells,
        setHeadCells,
        name,
        setName,
        cellIsHidden,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

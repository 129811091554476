import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { Tooltip } from "../forms/Tooltip";

interface SaveChangesPropsI {
  onClick: () => void;
}

export const SaveChanges = ({ onClick }: SaveChangesPropsI) => {
  const { setCloseOpenModal } = useContext(FeedbackContext);

  return (
    <Tooltip title="Änderungen übernehmen">
      <Button
        onClick={() => {
          setCloseOpenModal(true);
          onClick();
        }}
        variant="contained"
        sx={{
          position: "absolute",
          zIndex: 1600, // modal = 1200
          // left: "50%",
          // bottom: 50,
          top: 100,
          right: 50,
          width: 100,
          backgroundColor: "error.main",
        }}
      >
        <SaveIcon fontSize="large" sx={{ color: "white" }} />
      </Button>
    </Tooltip>
  );
};

export default SaveChanges;

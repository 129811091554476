import {
  useMainCategories,
  UseMainCategoriesResultT,
} from "../mainCategory/useMainCategories";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";

export const CategoryModus = () => {
  const { categoryList }: UseMainCategoriesResultT = useMainCategories({});

  return (
    <>
      <Typography variant="h1" component="h1">
        CategoryModus
      </Typography>
      <List>
        {categoryList.map((c) => (
          <ListItem key={c.id}>
            <ListItemText primary={c.name} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

import React from "react";
import { AuthProvider } from "./components/auth/AuthContext";
import { Routes } from "./components/Routes";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useTheme } from "./components/layout/useTheme";

function App() {
  const theme = useTheme();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

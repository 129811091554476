import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useContext } from "react";
import { SystemHealthCheckContext } from "./SystemHealthCheckContext";

export const SystemHealthCheckTable = () => {
  const { systemHealthCheckList } = useContext(SystemHealthCheckContext);

  if (!systemHealthCheckList.length) return <></>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Mehr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {systemHealthCheckList &&
            systemHealthCheckList.map((shc) => {
              return (
                <TableRow
                  key={shc.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {shc.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {shc.details}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {shc.data}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import addrs from "email-addresses";
import { isEmpty } from "lodash";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { EMPTYUSER, UserContext } from "../user/UserContext";
import { UserT } from "../user/api";

interface UserFormPropsI {
  user?: UserT;
  onClick: (data: UserT) => void;
  button: string;
}

const fields2check = ["email", "firstname", "lastname"];

export const AdminForm = (props: UserFormPropsI) => {
  const { allUserEmails } = useContext(UserContext);
  const { showProgress } = useContext(FeedbackContext);
  const [userState, setUserState] = useState(
    props.user ? props.user : EMPTYUSER
  );
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f) => {
      const error = userState[f as keyof UserT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });

    if (!isEmpty(userState.email)) {
      const error =
        userState.email.indexOf(".") > 0 && // apparently foo@bar is a valid email-adress
        addrs.parseOneAddress(userState.email)
          ? false
          : true;

      setErrorState((p) => buildErrorState(p, "email", error));
      oneError = oneError || error;
    }

    if (oneError) return;

    if (!isEmpty(userState.email)) {
      const error = allUserEmails.some(
        (u) =>
          u.email === userState.email &&
          (!userState.id || userState.id !== u.id)
      );

      setErrorState((p) =>
        buildErrorState(
          p,
          "email",
          error,
          "Diese E-Mail ist bereits vorhanden!"
        )
      );
      oneError = oneError || error;
    }
    if (oneError) return;

    showProgress();
    props.onClick(userState);
  };

  return (
    <div>
      <StyledFormControl required={true}>
        <TextField
          id="firstname"
          label="Vorname"
          value={userState.firstname}
          error={errorState.firstname?.error}
          helperText={
            errorState.firstname?.error
              ? errorState.firstname?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, firstname: e.target.value };
            })
          }
        />
      </StyledFormControl>

      <StyledFormControl>
        <TextField
          id="lastname"
          label="Nachname"
          value={userState.lastname}
          error={errorState.lastname?.error}
          helperText={
            errorState.lastname?.error
              ? errorState.lastname?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, lastname: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id="email"
          label="E-Mail"
          type="email"
          error={errorState.email?.error}
          helperText={
            errorState.email?.error
              ? errorState.email?.helperText || "Ungültige E-Mail"
              : ""
          }
          value={userState.email}
          onChange={(e) =>
            setUserState((prevState) => {
              return { ...prevState, email: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <div>
        <StyledFormControl>
          <Button variant="contained" color="primary" onClick={onClick}>
            {props.button}
          </Button>
        </StyledFormControl>
      </div>
    </div>
  );
};

import React, { useState, useContext, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { TableRowSpaceLine } from "./ShopOrderEntry";

import { ShopOrderContext } from "./ShopOrderContext";
import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";
import { getStoredCart, removeStoredCart } from "./cart";
import { ShopCartEntry } from "./ShopCartEntry";
import { CartItemT, createOrder } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ErrorContext } from "../error/ErrorContext";
import { formatAmount } from "../shared/helper/string";
import { conf } from "./shop";
import { Link } from "react-router-dom";

export const ShopCartTable = () => {
  const { articleList } = useContext(ShopOrderContext);

  const cart = getStoredCart().map((item) => {
    if (item.type === "online" && !item.numberLicenses) {
      item.numberLicenses = 0;
    } else if (!item.numberBooks) {
      item.numberBooks = 0;
    }
    return item;
  });
  console.log("%cShopCartTable.tsx line:36 cart", "color: gold;", cart);
  const [cartChanged, setCartChanged] = useState(0);
  const [priceTotal, setPriceTotal] = useState(0);
  const [isCheckout, setIsCheckout] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasPrint, setHasPrint] = useState(false);
  const { hideProgress, showProgress } = useContext(FeedbackContext);
  const { setError } = useContext(ErrorContext);

  const cartList: CartItemT[] = [];
  for (let i = 0; i < cart.length; i++) {
    const article = articleList.find((a) => a.id === cart[i].articleId);
    if (article) {
      if (cart[i].professionId && cart[i].professionId !== article.professionId)
        continue;
      cartList.push({
        ...cart[i],
        article,
      });
    }
  }

  const onClick = () => {
    if (isCheckout) {
      showProgress();

      createOrder({ orders: cart })
        .then((res) => {
          if (res.success) {
            removeStoredCart();
            hideProgress();
            setIsSuccess(true);
            setCartChanged((prev) => prev + 1);
          }
        })
        .catch((error) => {
          setError(error);
        });
      return;
    }
    setIsCheckout(true);
  };

  useEffect(() => {
    const hasPrint = getStoredCart().some(
      (item) => item.type !== "online" && item.type !== "premium_online"
    );
    setPriceTotal(
      getStoredCart().reduce((acc, item) => acc + (item.price || 0), 0)
    );
    setHasPrint(hasPrint);
  }, [cartChanged]);

  const tax =
    Math.round(
      (Number(priceTotal) + (hasPrint ? Number(conf.basic.shipping) : 0)) * 19
    ) / 100;

  console.log(
    "%cShopCartTable.tsx line:91 tax, priceTotal, hasPrint",
    "color: #007acc;",
    tax,
    priceTotal,
    hasPrint
  );

  const priceBrutto =
    tax +
    Number(priceTotal || 0) +
    (hasPrint ? (hasPrint ? Number(conf.basic.shipping) : 0) : 0);

  if (isSuccess) {
    return (
      <Box className="component-shop-cart-table">
        <Typography variant="h1" component="h1">
          Bestellung abgeschlossen.
        </Typography>
        <Typography variant="body1" component="p">
          <br />
          Vielen Dank für Ihre Bestellung.
          <br />
          <br />
          Wir werden Ihren Auftrag schnellstmöglich bearbeiten.
          <br />
          <br />
        </Typography>
        <Link to="/customer/orders" reloadDocument>
          <Button variant="contained">Zurück zur Bestellübersicht</Button>
        </Link>
      </Box>
    );
  }

  if (cartList.length === 0) {
    return (
      <Box className="component-shop-cart-table">
        <Typography variant="h1" component="h1">
          Warenkorb
        </Typography>
        <Typography variant="body1" component="p">
          Der Warenkorb ist leer.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="component-shop-cart-table">
      <Typography variant="h1" component="h1">
        Warenkorb
      </Typography>
      <TableContainer
        sx={{
          mt: 2,
          height: "calc(100vh - 335px)",
        }}
      >
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          className="enhanced-table enhanced-table-collapse"
        >
          <TableBody className="enhanced-table-body pr">
            <TableRow
              hover
              tabIndex={-1}
              className={"table-row-header"}
              sx={{}}
            >
              <TableCell
                align="left"
                padding="normal"
                component="th"
              ></TableCell>
              {["Titel", "Anzahl", "Einzelpreis", "Summe"].map((label) => (
                <TableCell
                  component="th"
                  scope="row"
                  padding="normal"
                  className="article-thumbnail-cell"
                  key={label}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
            {cartList.map((cartItem) => {
              return (
                <ShopCartEntry
                  key={cartItem.orderId}
                  cartItem={cartItem}
                  setCartChanged={setCartChanged}
                  isCheckout={isCheckout}
                />
              );
            })}
            <TableRowSpaceLine />
            <TableRow hover tabIndex={-1} className="sum">
              <TableCell align="left" padding="normal"></TableCell>
              <TableCell align="left" padding="normal" colSpan={3}>
                <Box>
                  <b>Gesamt (netto</b>)
                </Box>
              </TableCell>
              <TableCell align="left" padding="normal" className="sum">
                <Box>
                  <b>{priceTotal && formatAmount(priceTotal)} &euro;</b>
                </Box>
              </TableCell>
            </TableRow>
            {hasPrint && (
              <>
                <TableRow hover tabIndex={-1} className="sum">
                  <TableCell align="left" padding="normal"></TableCell>
                  <TableCell
                    align="left"
                    padding="normal"
                    colSpan={3}
                    className="sum"
                  >
                    <Box>
                      <b>Versandkosten</b>
                    </Box>
                  </TableCell>
                  <TableCell align="left" padding="normal" className="sum">
                    <Box>
                      <b>{formatAmount(conf.basic.shipping)} &euro;</b>
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow hover tabIndex={-1}>
              <TableCell align="left" padding="normal"></TableCell>
              <TableCell align="left" padding="normal" colSpan={3}>
                <Box>
                  <b>19% MwSt.</b>
                </Box>
              </TableCell>
              <TableCell align="left" padding="normal" className="sum">
                <Box>
                  <b>{priceTotal && formatAmount(tax)} &euro;</b>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow hover tabIndex={-1} className="sum">
              <TableCell align="left" padding="normal"></TableCell>
              <TableCell
                align="left"
                padding="normal"
                colSpan={3}
                className="sum"
              >
                <Box>
                  <b>Zahlbetrag</b>
                </Box>
              </TableCell>
              <TableCell align="left" padding="normal" className="sum">
                <Box>
                  <b>{formatAmount(priceBrutto)} &euro;</b>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mt: 3, mr: 2 }}
      >
        {isCheckout ? (
          <Button variant="contained" onClick={() => setIsCheckout(false)}>
            Warenkorb bearbeiten
          </Button>
        ) : (
          <Link to={"/customer/orders"}>
            <Button variant="contained" onClick={() => setIsCheckout(false)}>
              Zurück zur Bestellübersicht
            </Button>
          </Link>
        )}
        <Button
          variant="contained"
          onClick={onClick}
          disabled={priceTotal <= 0}
        >
          {isCheckout
            ? "Jetzt zahlungspflichtig bestellen"
            : "Weiter zur Kasse"}
        </Button>
      </Box>
    </Box>
  );
};

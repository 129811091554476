import { QuestionUpload } from "./QuestionUpload";
import { QuestionTable } from "./QuestionTable";
import { useQuestions } from "./useQuestions";
import { Loader } from "../shared/Loader";
import { QuestionT } from "./api";
import { HeadCellT } from "../shared/table/EnhancedTable";
import { useTable } from "../shared/table/useTable";

const headCellsDefault: HeadCellT<QuestionT>[] = [
  { id: "id", label: "ID" },
  { id: "example", label: "Beispiel" },
  {
    id: "type",
    disablePadding: true,
    label: "Typ",
  },
  {
    id: "rating",
    label: "Einstufung",
  },
  {
    id: "difficulty",
    label: "Schwierigkeit",
  },
  { id: "duration", label: "Dauer" },
  { id: "question", label: "Frage" },
  {
    id: "answerOptions",
    label: "Optionen",
  },
  { id: "answer", label: "Antwort" },
  { id: "points", label: "Punkte" },
  {
    id: "evaluation",
    label: "Wertung",
  },
  { id: "layout", label: "Layout" },
  { id: "questionDecoded", label: "Aktionen", colSpan: 3 },
];

export const QuestionLoader = () => {
  const { questionList, loadingDone } = useQuestions({});

  const { headCells } = useTable<QuestionT>("question", headCellsDefault, 1.2);

  if (questionList.length === 0) {
    if (loadingDone && headCells.length > 0) return <QuestionUpload />;
    else return <Loader />;
  }

  return <QuestionTable />;
};

/* eslint-disable  */
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  LineElement,
  PointElement,
  LinearScale,
  Filler,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { CandidateResultsT } from "../api";
import { Box, Typography } from "@mui/material";
import { splitLongLabel } from "../../shared/helper/string";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

interface ResultSummaryChartPropsI {
  results: CandidateResultsT;
}

export const ResultSummaryChart = ({ results }: ResultSummaryChartPropsI) => {
  // Data for the chart
  const data = {
    labels: results.mainCategories
      // .filter((n) => n.mainCategoryId != 11 && n.mainCategoryId != 13)
      .map((r) => splitLongLabel(r.mainCategoryName)),
    // .concat(["Fremdsprachenkenntnisse"]),

    datasets: [
      {
        order: 1,
        data: results.mainCategories.map((r) =>
          r.sumPoints > 0
            ? ((r.sumPointsCorrect / r.sumPoints) * 100).toFixed(2)
            : 0.0
        ),
        fill: false,
        borderWidth: 1.5,
        borderColor: "rgb(0, 141, 211)",
        pointBackgroundColor: "rgb(0, 141, 211)",
        pointBorderColor: "#fff",
        label: "Erzieltes Ergebnis",
      },

      {
        order: 2,
        data: results.mainCategories.map((r) =>
          parseFloat(r.mainCategoryTarget + "").toFixed(2)
        ),
        fill: false,
        borderWidth: 1.5,
        borderColor: "#2e7d32",
        pointBackgroundColor: "#2e7d32",
        label: "Sollprofil",
      },
    ],
  };

  // Options for the chart
  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom" as "bottom",
        align: "center" as "center",
        labels: {
          textAlign: "left" as "left",
          boxWidth: 20,
        },
      },
    },
    layout: {
      padding: 20,
      margin: 20,
    },
    scales: {
      r: {
        ticks: {
          display: false,
        },
        pointLabels: {
          font: {
            size: 11,
          },
        },
        display: true,
        angleLines: {
          //color: "rgba(0, 0, 255, 0.5)", // Color of the radial grid lines
          // color: "red",
        },
        grid: {
          display: true,
          color: "lightgray",
          drawOnChartArea: true,
          drawTicks: true,
          z: 0,
        },
        min: 0, // Minimum value for the scale
        max: 100, // Maximum value for the scale
        stepSize: 20, // Defines how many labels to display
        filled: true,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <Box className="result-summary-chart-container">
      <Radar data={data} options={options} />
      <Box className="result-summary-chart-legend">
        <Box className="result-summary-chart-legend-item-color blue-standard"></Box>
        <Box className="result-summary-chart-legend-item-text">
          <Typography variant="body2" component="div">
            Erzieltes Ergebnis
          </Typography>
        </Box>
        <Box className="result-summary-chart-legend-item-color correct"></Box>
        <Box className="result-summary-chart-legend-item-text">
          <Typography variant="body2" component="div">
            Sollprofil
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

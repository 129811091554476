export type replaceImagesResultT = {
  strDecoded: string;
  maxHeight: number;
  maxWidth: number;
};

export const replaceImages = (str: string, quote = '"'): string =>
  _replaceImages(str, quote) as string;

export const replaceImagesAsObject = (
  str: string,
  quote = '"'
): replaceImagesResultT =>
  _replaceImages(str, quote, true) as replaceImagesResultT;

const _replaceImages = (
  str: string,
  quote = '"',
  determinMaxWidthHeight = false
): string | replaceImagesResultT => {
  if (!str) return "";
  const regex = /\{Bild\|([^|]+)\|w:(\d*);h:(\d*)\}/;

  let maxWidth = 0;
  let maxHeight = 0;

  let check = true;
  let i = 0;
  while (check && i++ < 20) {
    check = false;
    const m = str.match(regex);
    if (m) {
      check = true;
      const [all, imgsrc, w, h] = m;

      const sub =
        "<img src=" +
        quote +
        import.meta.env.VITE_IMGURL +
        "/content/" +
        imgsrc.toLowerCase() +
        quote +
        (w ? " width=" + quote + w + quote : "") +
        (h ? " height=" + quote + h + quote : "") +
        " />";
      str = str.replace(all, sub);

      if (determinMaxWidthHeight) {
        if (w && parseInt(w) > maxWidth) maxWidth = parseInt(w);
        if (h && parseInt(h) > maxHeight) maxHeight = parseInt(h);
      }
    }
  }

  if (determinMaxWidthHeight) {
    return {
      strDecoded: str,
      maxHeight: maxHeight,
      maxWidth: maxWidth,
    };
  }

  return str;
};

import React, { createContext, Key, useState } from "react";

import { ContextPropsT } from "../shared/types/Context";
import { ShopOrderItemT, ShopArticleT } from "./api";

interface OrderContextPropsType extends ContextPropsT {
  orderList: ShopOrderItemT[];
  setOrderList: React.Dispatch<React.SetStateAction<ShopOrderItemT[]>>;
  articleList: ShopArticleT[];
  setArticleList: React.Dispatch<React.SetStateAction<ShopArticleT[]>>;
}

export const shopOrderContextDefaults: OrderContextPropsType = {
  orderList: [],
  setOrderList: () => {},
  articleList: [],
  setArticleList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
};

export const ShopOrderContext = createContext<OrderContextPropsType>(
  shopOrderContextDefaults
);

export interface OrderChangedT extends ShopOrderItemT {
  error?: boolean;
}

export const ShopOrderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [orderList, setOrderList] = useState<ShopOrderItemT[]>([]);
  const [articleList, setArticleList] = useState<ShopArticleT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <ShopOrderContext.Provider
      value={{
        orderList,
        setOrderList,
        articleList,
        setArticleList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </ShopOrderContext.Provider>
  );
};

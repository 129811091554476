import { now, toNumber, toString } from "lodash";
import { redirectAfterLoggedout } from "../shared/helper/logout";

const VALIDTOKENTIME = 1000 * 60 * 60 * 3; // 3 hours

export const getStoredAuthToken = (): string | null => {
  if (checkValidTokenTime()) {
    localStorage.setItem("authTokenTime", toString(now()));
    return localStorage.getItem("authToken");
  }

  return null;
};

export const checkValidTokenTime = () => {
  const time = getStoredAuthTokenTime();
  if (time) {
    // console.log(
    //   "%clocalStore.ts line:19 time, now() - VALIDTOKENTIME",
    //   "color: #007acc;",
    //   now() - time,
    //   VALIDTOKENTIME
    // );
    if (time > now() - VALIDTOKENTIME) {
      return true;
    } else {
      removeStoredAuthToken();
      redirectAfterLoggedout();
    }
  }
  return false;
};

export const storeAuthToken = (token: string) => {
  localStorage.setItem("authToken", token);
  localStorage.setItem("authTokenTime", toString(now()));
};

export const removeStoredAuthToken = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("authTokenTime");
  localStorage.removeItem("cart");
};

const getStoredAuthTokenTime = (): number | null => {
  const str = localStorage.getItem("authTokenTime");
  if (str) return toNumber(str);
  return null;
};

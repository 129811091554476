import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface ConfirmDialogPropsI {
  title: string;
  content: string;
  open: boolean;
  handleNo: () => void;
  handleYes: () => void;
  textYes?: string;
  textNo?: string;
  showCancel?: boolean;
  textCancel?: string;
  handleCancel?: () => void;
}

export function ConfirmDialog(props: ConfirmDialogPropsI) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={() => props.handleNo()}
        data-testid="ConfirmDialog"
        className="confirm-dialog"
      >
        <DialogTitle id="responsive-dialog-title" className="title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="candidate-dialog-content">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={() => props.handleNo()}
            color="primary"
            sx={{ fontSize: "12px" }}
          >
            {props.textNo ? props.textNo : "Nein"}
          </Button>
          <Button
            variant="contained"
            onClick={() => props.handleYes()}
            color="primary"
            autoFocus
            sx={{ fontSize: "12px" }}
          >
            {props.textYes ? props.textYes : "Ja"}
          </Button>
          {props.showCancel && (
            <Button
              variant="outlined"
              onClick={() =>
                props.handleCancel !== undefined && props.handleCancel()
              }
              color="primary"
              autoFocus
              sx={{ fontSize: "12px" }}
            >
              {props.textCancel ? props.textCancel : "Abbrechen"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

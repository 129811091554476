import { get, put, del, post, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";

export type HelpCategoryT = {
  id: number;
  title: string;
  sortOrder: number;
};

type HelpCategoryDataT = HelpCategoryT;

export type HelpT = {
  id: number;
  title: string;
  sortOrder: number;
  entries: HelpEntryT[];
};

type HelpDataT = HelpT;

export type HelpEntryT = {
  id: number;
  title: string;
  content: string;
  sortOrder: number;
  helpCategoryId: number;
};

type HelpEntryDataT = HelpEntryT;

const data2help = (data: HelpDataT[]): HelpT[] => {
  console.log("%capi.ts line:70 ", "color: #007acc;", data);

  if (data.length === 0) return [];
  return data.map((d) => {
    return {
      id: d.id,
      title: d.title,
      sortOrder: d.sortOrder,
      entries: data2entries(d.entries),
    };
  });
};

const data2category = (data: HelpCategoryDataT): HelpCategoryT => {
  return {
    id: data.id,
    title: data.title,
    sortOrder: data.sortOrder,
  };
};

const data2categories = (data: HelpCategoryDataT[]): HelpCategoryT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2category(d));
};

const category2data = (category: HelpCategoryT): HelpCategoryDataT => {
  return {
    id: category.id,
    title: category.title,
    sortOrder: category.sortOrder,
  };
};

const categories2data = (categories: HelpCategoryT[]): HelpCategoryDataT[] => {
  if (categories.length === 0) return [];
  return categories.map((c) => category2data(c));
};

const data2entry = (data: HelpEntryDataT): HelpEntryT => {
  return {
    id: data.id,
    title: data.title,
    content: data.content,
    sortOrder: data.sortOrder,
    helpCategoryId: data.helpCategoryId,
  };
};

const data2entries = (data: HelpEntryDataT[]): HelpEntryT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2entry(d));
};

const entry2data = (entry: HelpEntryT): HelpEntryDataT => {
  return {
    id: entry.id,
    title: entry.title,
    content: entry.content,
    sortOrder: entry.sortOrder,
    helpCategoryId: entry.helpCategoryId,
  };
};

const entries2data = (entries: HelpEntryT[]): HelpEntryDataT[] => {
  if (entries.length === 0) return [];
  return entries.map((e) => entry2data(e));
};

/** ************************************************************************
 *
 *
 */
export const fetchHelp = async (): Promise<CallApiResultT<HelpCategoryT[]>> => {
  const res = await get("/help");

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchHelp] " + res.error);
  }

  if (!res.success)
    return {
      success: false,
      error: res.error,
      status: res.status,
      data: [],
    };

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2help(res.data),
  };
};
/** ************************************************************************
 *
 *
 */
export const fetchHelpCategories = async (): Promise<
  CallApiResultT<HelpCategoryT[]>
> => {
  const res = await get("/help-category");

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchHelps(main)] " + res.error);
  }

  if (!res.success)
    return {
      success: false,
      error: res.error,
      status: res.status,
      data: [],
    };

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2categories(res.data),
  };
};

export const fetchHelpEntries = async (): Promise<
  CallApiResultT<HelpEntryT[]>
> => {
  const res = await get("/help-entry");

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchHelps(main)] " + res.error);
  }

  if (!res.success)
    return {
      success: false,
      error: res.error,
      status: res.status,
      data: [],
    };

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2entries(res.data),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const addHelpCategory = async (
  category: HelpCategoryT
): Promise<CallApiResultT<HelpCategoryT>> => {
  // console.log("updateHelp", category, category2data(category));

  const res = await post<HelpCategoryT>(
    "/help-category",
    category2data(category)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const addHelpEntry = async (
  entry: HelpEntryT
): Promise<CallApiResultT<HelpEntryT>> => {
  // console.log("updateHelp", category, category2data(category));

  const res = await post<HelpEntryT>("/help-entry", entry2data(entry));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateHelpCategory = async (
  category: HelpCategoryT
): Promise<CallApiResultT<number>> => {
  // console.log("updateHelp", category, category2data(category));

  const res = await put<number>(
    `/help-category/${category.id}`,
    category2data(category)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateHelpEntry = async (
  entry: HelpEntryT
): Promise<CallApiResultT<number>> => {
  // console.log("updateHelp", category, category2data(category));

  const res = await put<number>(`/help-entry/${entry.id}`, entry2data(entry));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateHelpCategoryBulk = async (
  cats: HelpCategoryT[]
): Promise<CallApiResultT<HelpCategoryT>> => {
  const res = await put<HelpCategoryT>("/help-category", categories2data(cats));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateHelpEntryBulk = async (
  entries: HelpEntryT[]
): Promise<CallApiResultT<HelpEntryT>> => {
  const res = await put<HelpEntryT>("/help-entry", entries2data(entries));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteHelp = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`help-category/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteHelpEntry = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`help-entry/${id}`);

  return res;
};

import { isArray } from "lodash";
import { get, post, put, del, CallApiResultT } from "../shared/lib/api";

export type QuestionRelationT = {
  id: number;
  relId: number;
  questionId: number;
  questionOrder: number;
};

export type SubRelationT = {
  id: number;
  relId: number;
  subCategoryId: number;
  subCategoryOrder: number;
  subCategoryName?: string;
  questionRelations: QuestionRelationT[];
  mainAddedOrder?: number;
};
export type RelationT = {
  id: number;
  professionId: number;
  mainCategoryId: number;
  mainCategoryOrder: number;
  mainCategoryName?: string;
  subRelations: SubRelationT[];
};

export type QuestionRelationDataT = {
  id: number;
  relId: number;
  questionId: number;
  questionOrder: number;
};

export type SubRelationDataT = {
  id: number;
  relId: number;
  subCategoryId: number;
  subCategoryOrder: number;
  subCategoryName?: string;
  questionRelations: QuestionRelationDataT[];
  mainAddedOrder?: number;
};

export type RelationDataT = {
  id: number;
  professionId: number;
  mainCategoryId: number;
  mainCategoryOrder: number;
  mainCategoryName?: string;
  subRelations: SubRelationDataT[];
};

interface AddRelationPropsI {
  type: string;
  professionId?: number;
  mainCategoryId?: number;
  subCategoryId?: number;
  questionId?: number;
}

// interface UpdateRelationPropsI {
//   professionId: number;
//   mainCategoryId: number;
//   mainCategoryName?: string;
//   subCategoryId?: number;
//   subCategoryName?: string;
// }

interface AddRelationBulkPropsI {
  professionId: number;
  mainCategoryId: number;
  subCategoryId: number;
  questionId: number;
}

interface DeleteRelationBulkPropsI {
  professionId: number;
  mainCategoryId: number;
  subCategoryId: number;
}

const APIPATH = "/rel";

const data2relations = (data: RelationDataT[]): RelationT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2relation(d));
};

const data2relation = (data: RelationDataT): RelationT => {
  //console.log("dat2re", data);
  return {
    ...data,

    subRelations: data.subRelations
      ? data.subRelations.map(
          (sr: SubRelationDataT): SubRelationT => data2subRelation(sr)
        )
      : [],
  };
};

const dataArray2questionRelations = (
  data: QuestionRelationDataT[]
): QuestionRelationT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2questionRelation(d));
};

const data2questionRelation = (
  qr: QuestionRelationDataT
): QuestionRelationT => ({
  id: qr.id,
  relId: qr.relId,
  questionId: qr.questionId,
  questionOrder: qr.questionOrder,
});

const data2subRelation = (sr: SubRelationDataT): SubRelationT => ({
  ...sr,
  ...(sr["mainAddedOrder"] ? { mainAddedOrder: sr["mainAddedOrder"] } : {}),
  questionRelations: sr.questionRelations
    ? sr.questionRelations.map(
        (qr: QuestionRelationDataT): QuestionRelationT =>
          data2questionRelation(qr)
      )
    : [],
});

// // /** ************************************************************************
// //  *
// //  * @returns addedRelation
// //  */
// // export const updateRelation = async (
// //   data: UpdateRelationPropsI
// // ): Promise<CallApiResultT<number>> => {
// //   console.log("updateRelation", data);

// //   const apipath = APIPATH["prof_main_sub"];

// //   const queryData: {
// //     profession_id: number;
// //     main_category_id: number;
// //     sub_category_id?: number;
// //     main_category_name?: string;
// //     sub_category_name?: string;
// //   } = {
// //     profession_id: data.professionId,
// //     main_category_id: data.mainCategoryId,
// //   };
// //   if (data.subCategoryId) queryData["sub_category_id"] = data.subCategoryId;

// //   if (data.mainCategoryName)
// //     queryData["main_category_name"] = data.mainCategoryName;
// //   if (data.subCategoryName)
// //     queryData["sub_category_name"] = data.subCategoryName;

// //   const res = await put(apipath, queryData);

// //   return {
// //     success: true,
// //     status: res.status,
// //     error: "",
// //   };
// // };

interface UpdateRelationI
  extends Partial<RelationT>,
    Partial<SubRelationT>,
    Partial<QuestionRelationT> {
  type: string;
  relId?: number;
}

interface UpdateRelationDataT
  extends Partial<RelationDataT>,
    Partial<SubRelationDataT>,
    Partial<QuestionRelationDataT> {
  type: string;
  rel_id?: number;
}

const relation2data = (data: UpdateRelationI): UpdateRelationDataT => {
  const result: UpdateRelationDataT = { type: data.type };

  if (data.id) result["id"] = data.id;
  if (data.professionId) result["professionId"] = data.professionId;
  if (data.mainCategoryId) result["mainCategoryId"] = data.mainCategoryId;
  if (data.mainCategoryOrder)
    result["mainCategoryOrder"] = data.mainCategoryOrder;
  if (data.mainCategoryName) result["mainCategoryName"] = data.mainCategoryName;
  if (data.relId) result["relId"] = data.relId;
  if (data.subCategoryId) result["subCategoryId"] = data.subCategoryId;
  if (data.subCategoryOrder) result["subCategoryOrder"] = data.subCategoryOrder;
  if (data.subCategoryName) result["subCategoryName"] = data.subCategoryName;
  if (data.questionOrder) result["questionOrder"] = data.questionOrder;

  return result;
};
const relations2dataArray = (
  data: UpdateRelationDataT[]
): UpdateRelationDataT[] => {
  if (data.length === 0) return [];
  return data.map((d) => relation2data(d));
};

/** ************************************************************************
 *
 * @returns addedRelation
 */
export const addRelation = async (
  data: AddRelationPropsI
): Promise<CallApiResultT<RelationT | SubRelationT | QuestionRelationT>> => {
  const queryData = {
    ...data,
    ...(data.questionId ? { question_id: data.questionId } : {}),
  };

  const res = await post(APIPATH, queryData);

  if (!res.success)
    return {
      success: false,
      status: res.status,
      error: res.error,
    };

  return {
    success: res.success,
    status: res.status,
    error: "",
    data:
      data.type === "sub"
        ? data2subRelation(res.data as SubRelationDataT)
        : data.type === "question"
        ? data2questionRelation(res.data as QuestionRelationDataT)
        : data2relation(res.data as RelationDataT),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateRelationBulk = async (
  relationationParts: UpdateRelationI[]
): Promise<CallApiResultT<number>> => {
  console.log("updateRelation", relationationParts);

  const res = await put<number>(
    APIPATH,
    relations2dataArray(relationationParts)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns CallApiResultT
 */
export const addRelationBulk = async (
  dataArray: AddRelationBulkPropsI[]
): Promise<CallApiResultT<QuestionRelationT[]>> => {
  const queryData = dataArray.map((data) => ({
    type: "question",
    ...data,
  }));

  const res = await post(APIPATH, queryData);

  if (!res.success)
    return {
      success: false,
      status: res.status,
      error: res.error,
    };

  return {
    success: true,
    status: res.status,
    error: "",
    data: dataArray2questionRelations(res.data as QuestionRelationDataT[]),
  };
};

// /** ************************************************************************
//  *
//  *
//  */
export const fetchRelations = async (): Promise<
  CallApiResultT<RelationT[]>
> => {
  const res = await get(APIPATH);

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchRelations] " + res.error);
  }

  return {
    success: true,
    status: res.status,
    error: "",
    data: data2relations(res.data),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteRelationBulk = async (
  data: DeleteRelationBulkPropsI
): Promise<CallApiResultT<number>> => {
  const queryData = {
    type: "question_by_sub_category_id",
    professionId: data.professionId,
    mainCategoryId: data.mainCategoryId,
  };

  const res = await del<number>(`${APIPATH}/${data.subCategoryId}`, queryData);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteRelation = async (
  type: string,
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`, { type });

  return res;
};

export const ButtonLeftSvg = () => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
  >
    <polygon points="29.25,39.44 14.82,25 29.41,10.41 32.59,13.59 21.18,25 32.44,36.25 " />
  </svg>
);

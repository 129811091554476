import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CartItemT, ShopOrderItemT } from "./api";
import { Box, Checkbox, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { ShopOrderContext } from "./ShopOrderContext";
import { date_hr } from "../shared/helper/datetime";
import { addToCart, getStoredCart, isInCart, removeFromCart } from "./cart";
import { calcPrice, conf } from "./shop";
import { formatAmount } from "../shared/helper/string";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

interface ShopOrderPropsI {
  order: ShopOrderItemT;
  setCartNotEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}

export const TableRowRest = () => (
  <TableRow
    hover
    tabIndex={-1}
    className={"table-row-details table-row-rest"}
    sx={{ height: "100%" }}
  >
    <TableCell align="left" padding="normal" colSpan={7} sx={{}}></TableCell>
  </TableRow>
);
export const TableRowSpace = () => (
  <TableRow hover tabIndex={-1} className={"table-row-details"}>
    <TableCell
      align="left"
      padding="normal"
      colSpan={8}
      className="table-row-space"
    ></TableCell>
  </TableRow>
);
export const TableRowSpaceLine = () => (
  <TableRow hover tabIndex={-1} className={"table-row-details"}>
    <TableCell
      align="left"
      padding="normal"
      colSpan={8}
      className="table-row-space"
    >
      <Box sx={{ borderBottom: "1px solid #ccc", ml: 2 }}></Box>
    </TableCell>
  </TableRow>
);
export const TableRowWhiteSpace = () => (
  <TableRow hover tabIndex={-1} className={"table-row-details"}>
    <TableCell
      align="left"
      padding="normal"
      colSpan={8}
      className="table-row-space white"
    ></TableCell>
  </TableRow>
);

export const ShopOrderEntry = ({
  order,
  setCartNotEmpty,
  isOpen,
  setIsOpen,
}: ShopOrderPropsI) => {
  const isPremium = order.type === "premium" || order.type === "premium_online";
  const isOnline = order.type === "online";
  const isBasic = order.type === "basic";

  const { articleList } = useContext(ShopOrderContext);

  const [checked, setChecked] = useState<boolean>(isInCart(order.id));

  /*  Formate:
   *  Basistest: (ID Beruf) | (Artikelnr)-(B)(Auflage) | (Stk) | (Schablone) | (Branding)
   *  (ID Beruf) | (Artikelnr) | (Name) | (Niveau) | (Stk Prüfungshefte) | (Stk Schablone) | (Aufpreis) | (Aufgaben nach Kundenwunsch) |
   */

  let id = "0";
  let artnr: string;
  let count, level, solutions, branding, add;
  let name = "";
  if (isPremium) {
    [id, artnr, name, level, count, solutions, add] = order.content.split("|");
  } else {
    [id, artnr, count, solutions, branding] = order.content.split("|");
  }

  const addStr = add
    ?.split("#")
    .map((a) => a.split(";")[0])
    .join(", ");

  const hasBrandding = branding === "ja";

  const article = articleList.find((a) => a.id === order.articleId);

  console.log(
    "%cShopOrderEntry.tsx line:102 isPremium, name",
    "color: #007acc;",
    isPremium,
    name,
    name?.indexOf("Nachbestellung"),
    !name?.indexOf("Nachbestellung")
  );
  if (
    article &&
    order.type === "premium_online" &&
    name &&
    name?.indexOf("Nachbestellung") < 0
  ) {
    name = name.replace("Online-Einstellungstest ", "");
  }

  console.log(
    "%cShopOrderEntry.tsx line:101 article",
    "color: #007acc;",
    order,
    article
  );

  const newEdition =
    !isPremium && article && article.artNo + "-B" + article.edition != artnr;

  let imgRowSpan = 2;
  if (isPremium) {
    if (parseInt(solutions) > 0) imgRowSpan++;
    if (addStr) imgRowSpan++;
    if (parseInt(count) > 0) imgRowSpan++;
  } else {
    imgRowSpan = 2;
    if (parseInt(count) > 0) imgRowSpan++;
    if (parseInt(solutions) > 0) imgRowSpan++;
    if (hasBrandding) imgRowSpan++;
  }

  const addItemToCart = (e: React.ChangeEvent<HTMLInputElement>) => {
    const article = articleList.find((a) => a.id === order.articleId);
    if (article) {
      const cartItem: CartItemT = {
        articleId: order.articleId,
        orderId: order.id,
        orderName: name,
        type: order.type,
        artnr,
        numberLicenses:
          order.type === "online" || order.type === "premium_online" ? 1 : 0,
        numberBooks:
          order.type === "online" || order.type === "premium_online" ? 0 : 1,
        price: calcPrice(order.type, 1),
        numberSolutions: 0,
        branding: false,
        article,
      };
      if (e.target.checked) addToCart(cartItem);
      else removeFromCart(cartItem);
      setChecked(e.target.checked);
      setCartNotEmpty(getStoredCart().length > 0);
    }
  };

  const tax =
    Math.round(
      (Number(order.priceTotal || 0) + Number(order.priceShipping || 0)) * 19
    ) / 100;

  const priceBrutto =
    tax + Number(order.priceTotal || 0) + Number(order.priceShipping || 0);

  return (
    <>
      {order.isFirst && (
        <>
          <TableRow hover tabIndex={-1} className={"table-row-details"} sx={{}}>
            <TableCell component="td" scope="row" padding="normal" colSpan={8}>
              <Box
                className="bold"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                sx={{ ml: 1 }}
              >
                <Typography variant="h2" component="h2">
                  {isOpen ? (
                    <ArrowDropDownIcon sx={{ position: "relative", top: 5 }} />
                  ) : (
                    <ArrowRightIcon sx={{ position: "relative", top: 5 }} />
                  )}
                  Bestellung vom {date_hr(order.createdAt)}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRowSpace />
          {!isOpen && <TableRowWhiteSpace />}
        </>
      )}
      {isOpen && (
        <>
          {order.isFirst && (
            <TableRow
              hover
              tabIndex={-1}
              className={"table-row-header"}
              sx={{}}
            >
              <TableCell
                align="left"
                padding="normal"
                component="th"
              ></TableCell>
              {["Titel", "Anzahl", "Einzelpreis", "Summe"].map((label) => (
                <TableCell
                  component="th"
                  scope="row"
                  padding="normal"
                  className="article-thumbnail-cell"
                  key={label}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          )}
          <TableRow hover tabIndex={-1} className={"table-row-details"} sx={{}}>
            <TableCell
              component="td"
              scope="row"
              padding="normal"
              rowSpan={imgRowSpan}
              className="article-thumbnail-cell"
            >
              <Box sx={{ ml: 1 }}>
                <img
                  src={
                    order.type === "premium"
                      ? article?.imagePathPremium
                      : order.type === "premium_online"
                      ? article?.imagePathPremiumOnline
                      : article?.imagePath
                  }
                  alt=""
                  className="article-thumbnail"
                />
              </Box>
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="normal"
              className="article-name-cell"
            >
              <Box sx={{ width: 500 }}>
                <b>
                  {isPremium ? name : article?.name} | {artnr}{" "}
                </b>
                {isPremium && (
                  <>
                    <br />
                    Testniveau: {level}
                  </>
                )}
              </Box>
            </TableCell>
            <TableCell component="td" scope="row" padding="normal"></TableCell>
            <TableCell align="left" padding="normal"></TableCell>
            <TableCell
              align="left"
              padding="normal"
              className={
                "table-cell-order" + (newEdition ? " new-edition" : "")
              }
            >
              <Box>
                {newEdition ? (
                  <>
                    Neuauflage vorhanden
                    <TooltipIcon
                      type="info"
                      title="Dieser Test wurde
                überarbeitet und steht Ihnen unter einer neuen Artikelnummer in
                unserem Shop zur Verfügung. Um die Vergleichbarkeit der
                Testergebnisse zu gewährleisten, sollten verschiedene Auflagen
                nicht parallel eingesetzt werden."
                    />
                  </>
                ) : (
                  <>
                    Nachbestellen&nbsp;
                    <Checkbox
                      checked={checked}
                      onChange={addItemToCart}
                      sx={{
                        padding: 0,
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                        position: "relative",
                        top: -2,
                      }}
                    />
                  </>
                )}
              </Box>
            </TableCell>
          </TableRow>
          {isPremium && name && !(name?.indexOf("Nachbestellung") >= 0) && (
            <TableRow hover tabIndex={-1} className={"table-row-details"}>
              <TableCell align="left" padding="normal">
                <Box>
                  {order.type === "premium_online" ? (
                    <>
                      Grundpreis inkl. Testverwaltungs-Backend und
                      Corporate-Design-Funktion
                    </>
                  ) : (
                    <>
                      Grundpreis inkl. Prüferexemplar des Tests,
                      Lösungsschablone, Begleitheft, Branding mit Logo
                    </>
                  )}
                </Box>
              </TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-quantity"
              ></TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-price-single"
              ></TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-price-sum"
              >
                <Box>{formatAmount(order.pricePremium)} &euro;</Box>
              </TableCell>
            </TableRow>
          )}
          {isPremium && order.priceAdd > 0 && (
            <TableRow hover tabIndex={-1} className={"table-row-details"}>
              <TableCell align="left" padding="normal">
                <Box>Aufpreis: {addStr}</Box>
              </TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-quantity"
              ></TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-price-single"
              ></TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-price-sum"
              >
                <Box>{formatAmount(order.priceAdd)} &euro;</Box>
              </TableCell>
            </TableRow>
          )}
          {parseInt(count) > 0 && (
            <TableRow hover tabIndex={-1} className={"table-row-details"}>
              <TableCell align="left" padding="normal">
                <Box>
                  {isOnline || order.type === "premium_online"
                    ? "Lizenzen"
                    : "Prüfungshefte"}
                  :
                </Box>
              </TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-quantity"
              >
                <Box>{count}</Box>
              </TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-price-single"
              >
                <Box>
                  {formatAmount(calcPrice(order.type, parseInt(count)))} &euro;{" "}
                </Box>
              </TableCell>
              <TableCell
                align="left"
                padding="normal"
                className="article-price-sum"
              >
                <Box>
                  {formatAmount(
                    isOnline || order.type === "premium_online"
                      ? order.priceLicense
                      : order.priceBooks
                  )}{" "}
                  &euro;
                </Box>
              </TableCell>
            </TableRow>
          )}

          {((isBasic && parseInt(solutions) > 0) ||
            (isPremium && parseInt(solutions) > 0)) && (
            <TableRow hover tabIndex={-1} className={"table-row-details"}>
              <TableCell align="left" padding="normal">
                <Box>
                  {isPremium ? (
                    <>Weitere Lösungsschablonen:</>
                  ) : isBasic ? (
                    <>Lösungsschablonen:</>
                  ) : (
                    <> </>
                  )}
                </Box>
              </TableCell>
              <TableCell align="left" padding="normal">
                <Box>{solutions}</Box>
              </TableCell>
              <TableCell align="left" padding="normal">
                <Box>{formatAmount(conf.basic.solution)} &euro; </Box>
              </TableCell>
              <TableCell align="left" padding="normal">
                <Box>{formatAmount(order.priceSolutions)} &euro; </Box>
              </TableCell>
            </TableRow>
          )}

          {isBasic && hasBrandding && (
            <TableRow hover tabIndex={-1} className={"table-row-details"}>
              <TableCell align="left" padding="normal">
                <Box>Branding mit Firmenlogo</Box>
              </TableCell>
              <TableCell align="left" padding="normal"></TableCell>
              <TableCell align="left" padding="normal"></TableCell>
              <TableCell align="left" padding="normal">
                <Box>{formatAmount(order.priceBranding)} &euro;</Box>
              </TableCell>
            </TableRow>
          )}
          <TableRowRest />
          {!order.isLast && <TableRowSpaceLine />}

          {order.isLast && (
            <>
              <TableRowSpaceLine />

              <TableRow hover tabIndex={-1} className={"table-row-details"}>
                <TableCell align="left" padding="normal"></TableCell>
                <TableCell align="left" padding="normal" colSpan={3}>
                  <Box>Gesamt (netto)</Box>
                </TableCell>
                <TableCell align="left" padding="normal">
                  <Box>
                    {order.priceTotal && formatAmount(order.priceTotal)} &euro;
                  </Box>
                </TableCell>
              </TableRow>
              {Boolean(order.priceShipping) && (
                <TableRow hover tabIndex={-1} className={"table-row-details"}>
                  <TableCell align="left" padding="normal"></TableCell>
                  <TableCell align="left" padding="normal" colSpan={3}>
                    <Box>Versandkosten</Box>
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    <Box>
                      {order.priceShipping && formatAmount(order.priceShipping)}{" "}
                      &euro;
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              <TableRow hover tabIndex={-1} className={"table-row-details"}>
                <TableCell align="left" padding="normal"></TableCell>
                <TableCell align="left" padding="normal" colSpan={3}>
                  <Box>19% MwSt.</Box>
                </TableCell>
                <TableCell align="left" padding="normal">
                  <Box>{order.priceTotal && formatAmount(tax)} &euro;</Box>
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} className={"table-row-details"}>
                <TableCell align="left" padding="normal"></TableCell>
                <TableCell align="left" padding="normal" colSpan={3}>
                  <Box sx={{ mb: 2 }}>
                    <b>Zahlbetrag</b>
                  </Box>
                </TableCell>
                <TableCell align="left" padding="normal">
                  <Box>
                    <b>{formatAmount(priceBrutto)} &euro;</b>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRowWhiteSpace />
            </>
          )}
        </>
      )}
    </>
  );
};

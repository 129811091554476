import { AddMainCategory } from "./AddMainCategory";
import { MainCategoryTable } from "./MainCategoryTable";
import { useMainCategories } from "./useMainCategories";
import { Loader } from "../shared/Loader";

export const MainCategoryLoader = () => {
  const { categoryList, loadingDone } = useMainCategories({});

  if (!loadingDone) return <Loader />;

  if (categoryList.length === 0) {
    if (loadingDone) return <AddMainCategory />;
    else return <Loader />;
  }

  return <MainCategoryTable />;
};

import { Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  CandidateResultsT,
  SubResultT,
  QuestionResultsT,
} from "../candidate/api";
import { Loader } from "../shared/Loader";
import { TestResults } from "./TestResults";
import { useTestResults } from "./useTestResults";

export const getMainIdx = (
  results: CandidateResultsT,
  mainCategoryId: number
) =>
  results.mainCategories.findIndex((r) => r.mainCategoryId === mainCategoryId);

export const getSubIdx = (subs: SubResultT[], subCategoryId: number) =>
  subs.findIndex((r) => r.subCategoryId === subCategoryId);

export const getQuestionIdx = (subs: QuestionResultsT[], questionId: number) =>
  subs.findIndex((r) => r.id === questionId);

export const TestResultsLoader = () => {
  const params = useParams();
  const { loadingDone } = useTestResults({
    professionId: parseInt(params.professionId || "0"),
  });

  if (loadingDone)
    return (
      <Paper
        sx={{
          m: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {" "}
        <TestResults />
      </Paper>
    );

  return <Loader />;
};

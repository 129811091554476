import {
  checkValidTokenTime,
  removeStoredAuthToken,
} from "../../auth/localStore";

export const redirectAfterLoggedout = () => {
  if (window.location.href.indexOf("/admin") >= 0)
    window.location.href = "/admin";
  else if (window.location.href.indexOf("/customer") >= 0)
    window.location.href = "/customer";
  else window.location.href = "/candidate";
};

const autoLogout = () => {
  if (checkValidTokenTime()) return;

  removeStoredAuthToken();
  redirectAfterLoggedout();
};

export const setAutoLogout = () => setInterval(autoLogout, 1000);

import dayjs from "dayjs";

export const mm_ss = (seconds: number): string => {
  const mm = Math.floor(seconds / 60);
  const ss = seconds % 60;
  return `${mm < 10 ? "0" : ""}${mm}:${ss < 10 ? "0" : ""}${ss}`;
};

export const date_hr = (d: Date): string => dayjs(d).format("DD.MM.YYYY");

export const datetime_hr = (d: Date): string =>
  dayjs(d).format("DD.MM.YYYY HH:mm");

export const time_hr = (d: Date): string => dayjs(d).format("HH:mm:ss");

export const actYear = () => parseInt(dayjs(new Date()).format("YYYY"));

export const START_YEAR: number = parseInt(
  import.meta.env.VITE_START_YEAR || "2021"
);

export const getYearArray = () => {
  const result = [];
  for (let y = START_YEAR; y <= actYear(); y++) {
    result.push(y);
  }
  return result;
};

export const getUnixTimestamp = () => {
  // print human readable date
  return Math.floor(Date.now() / 1000);
};

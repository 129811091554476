import { useContext, useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MailTemplateT } from "./api";
import { FeedbackContext } from "../feedback/FeedbackContext";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { Editor } from "@tinymce/tinymce-react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { ErrorContext } from "../error/ErrorContext";
import { MailTemplateContext } from "./MailTemplateContext";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { UserT, sendWelcomeUser } from "../user/api";

interface SendMailCustomerPropsI {
  customer: UserT;
  setDialogOpen?: (value: boolean) => void;
  onSend?: (i: number) => void;
}

export const SendWelcomeMailCustomer = ({
  customer,
  setDialogOpen,
  onSend,
}: SendMailCustomerPropsI) => {
  const { mailTemplateList } = useContext(MailTemplateContext);
  const { setError } = useContext(ErrorContext);

  const editorRef = useRef(null as any);

  const { showProgress, hideProgress, openSnackbar } =
    useContext(FeedbackContext);
  const fields2check = ["subject", "content"];
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const [dialogSendMailOpen, setDialogSendMailOpen] = useState(false);

  const [mailTemplateState, setMailTemplateState] = useState(
    {} as MailTemplateT
  );

  useEffect(() => {
    console.log(
      "%cSendWelcomeMailCustomer.tsx line:58 mailTemplateState",
      "color: red;",
      mailTemplateState
    );
  }, [mailTemplateState]);

  useEffect(() => {
    const mailTemplate = mailTemplateList.find(
      (e) => e.type === "welcome_customer"
    );
    if (mailTemplate) {
      const salutation =
        customer.gender && customer.gender === "m"
          ? mailTemplate.salutationM
          : customer.gender && customer.gender === "f"
          ? mailTemplate.salutationF
          : mailTemplate.salutationD;
      setMailTemplateState({
        ...mailTemplate,
        content: mailTemplate.content
          .replace("{salutation}", salutation || "")
          .replace("{firstname}", customer.firstname || "")
          .replace("{lastname}", customer.lastname || ""),
        // .replace(
        //   "##ABLAUFDATUM##",
        //   candidate.professionExpiredAt
        //     ? date_hr(candidate.professionExpiredAt)
        //     : "##ABLAUFDATUM##"
        // ),
      });
    }
  }, []);

  const onClickSendMail = () => {
    let oneError = false;

    setMailTemplateState((p) => ({
      ...p,
      content: editorRef.current.getContent(),
    }));

    fields2check.forEach((f) => {
      const error = mailTemplateState[f as keyof MailTemplateT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });
    if (oneError) return;

    showProgress();

    sendWelcomeUser(
      mailTemplateState.id,
      customer.id,
      mailTemplateState.subject,
      editorRef.current.getContent()
    )
      .then((res) => {
        hideProgress();
        if (res.success) {
          openSnackbar("success", "Mail gesendet");
          onSend && onSend(mailTemplateState.id);
        } else setError("Fehler beim Mail versenden");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <div>
      <Typography variant="h1" component="h1" sx={{ pl: 1, pb: 2 }}>
        Mail verschicken
      </Typography>
      <StyledFormControl>
        <TextField
          id="candidateEmail"
          label="Empfänger"
          value={customer.email}
          disabled={true}
        />
      </StyledFormControl>

      <StyledFormControl>
        <TextField
          id="subject"
          label="Betreffzeile"
          key={"subject" + mailTemplateState.id}
          value={mailTemplateState.subject}
          error={errorState.subject?.error}
          helperText={
            errorState.subject?.error
              ? errorState.subject?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) => {
            console.log(
              "%cSendWelcomeMailCustomer.tsx line:162 e.target.value",
              "color: #007acc;",
              e.target.value
            );
            setMailTemplateState({
              ...mailTemplateState,
              subject: e.target.value,
            });
          }}
          inputProps={{
            maxLength: 255,
          }}
        />
      </StyledFormControl>

      <StyledFormControl>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={
                errorState.content?.error
                  ? {
                      border: "1px solid red",
                    }
                  : {}
              }
            >
              <Editor
                apiKey="ot8ti4mrmg21ckfbqldpnaa759khpd8ntv385nw1s7mxfuia"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={mailTemplateState.content}
                init={{
                  height: 500,
                  language: "de",
                  menubar: false,
                  // word_wrap: true,
                  // plugins: "wordwrap",
                  plugins: ["lists"],
                  paste_as_text: true,
                  resize: true,
                  //   " autolink lists link image charmap print preview anchor",
                  //   "searchreplace visualblocks code fullscreen",
                  //   "insertdatetime media table paste code help wordcount",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic underline | alignleft aligncenter " +
                    "alignright alignjustify | bullist outdent indent | " +
                    "removeformat",
                  content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; moverflow: hidden;  white-space: pre-wrap;   word-wrap: break-word; }                   `,
                  autoformat_remove: true,
                  text_patterns: false,
                }}
              />
            </Box>

            {errorState.content?.error && (
              <Box sx={{ pl: 2, pt: "3px" }}>
                <Typography color="error" variant="caption">
                  Bitte ausfüllen
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </StyledFormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setDialogSendMailOpen(true)}
        sx={{ ml: 1, width: "250px" }}
      >
        Mail versenden
      </Button>

      <ConfirmDialog
        open={dialogSendMailOpen}
        handleYes={() => {
          onClickSendMail();
          setDialogSendMailOpen(false);
          setDialogOpen && setDialogOpen(false);
        }}
        handleNo={() => setDialogSendMailOpen(false)}
        title="Mail verschicken"
        content="Soll die Mail versendet werden?"
      />
    </div>
  );
};

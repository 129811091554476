import { stringToSlug } from "../../../shared/helper/string";
import { jsonDecode } from "../../../shared/helper/json";
import { replaceImages } from "../../../testModus/helper/img";

/**
 * put something like
 * "given": [
 *   "{Bild|VD-14-a020-1.svg|w:;h:}",
 *   "{Bild|VD-14-a020-2.svg|w:;h:}",
 * ... ]
 * to
 *  'given' =>
 * [
 *    'bildvd-14-a020-1svgwh' => '<img src="/storage/content/VD-14-a020-1.svg" />',
 *    'bildvd-14-a020-2svgwh' => '<img src="/storage/content/VD-14-a020-2.svg" />',
 *   ...]
 */
export const decodeAnswerOptionsDragNDrop = (options: string): any => {
  const decoded = jsonDecode(options);
  if (!decoded) return [];
  if (!("given" in decoded)) throw new Error("given missing");
  if (!("options" in decoded)) throw new Error("options missing");

  return {
    given: Object.fromEntries(
      decoded.given.map((e: string) => [stringToSlug(e), replaceImages(e)])
    ),
    options: Object.fromEntries(
      decoded.options.map((e: string) => [stringToSlug(e), replaceImages(e)])
    ),
  };
};

/**
 * decoded the correct answer
 *
 * input: array of entries like
 * {
 * "gegeben": "{Bild|VD-14-a020-5.svg|w:;h:}",
 *  "erwartet": "{Bild|VD-14-a020-C.svg|w:;h:}"
 *  }
 *
 * returns:
 *
 * given (as slug) => expteded (as slug)
 * 'bildvd-14-a020-5svgwh' => 'bildvd-14-a020-csvgwh',
 */
export const decodeAnswerDragNDrop = (answer: string): any => {
  const decoded = jsonDecode(answer);
  if (!decoded) return [];

  return Object.fromEntries(
    decoded.map((d: any) => [stringToSlug(d.gegeben), stringToSlug(d.erwartet)])
  );
};

export const checkAnswerDragNDrop = (options: string): string => {
  try {
    decodeAnswerDragNDrop(options);
  } catch (e: any) {
    return e.message;
  }
  return "ok";
};

export const checkAnswerOptionsDragNDrop = (options: string): string => {
  try {
    decodeAnswerOptionsDragNDrop(options);
  } catch (e: any) {
    return e.message;
  }
  return "ok";
};

import Box from "@mui/material/Box";
import { TestModusCategoryInfoT, TestModusQuestionT } from "./api";
import { TestTimer } from "./TestTimer";
import { SxProps, Theme } from "@mui/material";
import { LayoutContext } from "./LayoutContext";
import { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";

interface InfoBarPropsI {
  question: TestModusQuestionT;
  timeTotal: number;
  timeAct: number;
  timeMem?: number;
  subCategoryInfo: TestModusCategoryInfoT;
  testmodusHeadSx?: SxProps<Theme>;
  testmodusHeadInnerSx?: SxProps<Theme>;
  testTimerSx?: SxProps<Theme>;
  isSubCategoryStart?: boolean;
}
export const TestModusHeader = ({
  question,
  timeMem = 0,
  timeAct,
  subCategoryInfo,
  testmodusHeadSx,
  testmodusHeadInnerSx,
  testTimerSx,
  isSubCategoryStart = false,
}: InfoBarPropsI) => {
  const { layout } = useContext(LayoutContext);
  const { authState } = useContext(AuthContext);

  const counterStr = question.example
    ? "Beispiel"
    : ((question.evaluation === "single" || question.type === "klickmatrix") &&
      question.countTotal
        ? question.noInSubCategory -
          question.countTotal +
          1 +
          "-" +
          question.noInSubCategory
        : question.noInSubCategory) +
      "/" +
      subCategoryInfo[question.subCategoryId].count;

  return (
    <Box
      className="testmodus-head"
      sx={{
        ...(layout && layout.primaryColor
          ? { backgroundColor: layout.primaryColor + " !important" }
          : {}),
        ...testmodusHeadSx,
      }}
    >
      {/* <AppBar position="static"> */}
      <Box className="head-inner">
        <Box
          className="title"
          sx={{
            ...testmodusHeadInnerSx,
            ...(layout && layout.secondaryColor
              ? { color: layout.secondaryColor + " !important" }
              : {}),
          }}
        >
          {question.mainCategoryName} |{" " + question.subCategoryName}
        </Box>
        <Box
          className="counter"
          sx={{
            ...testmodusHeadInnerSx,
            ...(layout && layout.secondaryColor
              ? { color: layout.secondaryColor + " !important" }
              : {}),
          }}
        >
          {counterStr}
        </Box>

        <Box
          className="timer"
          sx={{
            ...testTimerSx,
            ...(layout && layout.secondaryColor
              ? { backgroundColor: layout.secondaryColor + " !important" }
              : {}),
            ...(layout && layout.primaryColor
              ? { color: layout.primaryColor + " !important" }
              : {}),
          }}
        >
          {isSubCategoryStart && question.subCategoryMemTime > 0 ? (
            <TestTimer
              time={timeMem}
              duration={question.subCategoryMemTime}
              direction="down"
            />
          ) : (
            <TestTimer
              time={timeAct}
              duration={subCategoryInfo[question.subCategoryId].duration}
              direction="down"
            />
          )}
          <Box className="profession profession-font">
            {authState.user.professionName}
          </Box>
        </Box>
      </Box>
      {/* </AppBar> */}
    </Box>
  );
};

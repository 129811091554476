import React, { createContext, useState } from "react";
import { ContextPropsT } from "../shared/types/Context";
import { SubCategoryT } from "./api";

export interface SubCategoryChangedT extends SubCategoryT {
  error?: boolean;
}

interface SubCategoryContextPropsType extends ContextPropsT {
  categoryList: SubCategoryT[];
  setCategoryList: React.Dispatch<React.SetStateAction<SubCategoryT[]>>;
  changedCategoryList: SubCategoryChangedT[];
  setChangedCategoryList: React.Dispatch<
    React.SetStateAction<SubCategoryChangedT[]>
  >;
}

export const SubCategoryContext = createContext<SubCategoryContextPropsType>({
  categoryList: [],
  setCategoryList: () => {},
  changedCategoryList: [],
  setChangedCategoryList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
});
export const SubCategoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [categoryList, setCategoryList] = useState<SubCategoryT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const [changedCategoryList, setChangedCategoryList] = useState<
    SubCategoryT[]
  >([]);

  return (
    <SubCategoryContext.Provider
      value={{
        categoryList,
        setCategoryList,
        changedCategoryList,
        setChangedCategoryList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </SubCategoryContext.Provider>
  );
};

import React, { ReactElement } from "react";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";
import { Button } from "@mui/material";
import { getCurrentDomain } from "../shared/helper/url";
import { CandidateLoggedOut } from "../auth/CandidateLoggedOut";

export const NotFound404 = (): ReactElement => {
  console.log(
    "%cNotFound404.tsx line:16 object",
    "color: #007acc;",
    getCurrentDomain(),
    import.meta.env.VITE_TESTURL,
    getCurrentDomain() === import.meta.env.VITE_TESTURL
  );
  if (getCurrentDomain() === import.meta.env.VITE_TESTURL)
    return <CandidateLoggedOut />;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="login-box"
      >
        <Box className="logo degeba-logo"></Box>
        <Typography component="h1" variant="h5">
          Seite nicht gefunden (Error 404)
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          <br />
          <br />
          Leider gibt es die gesuchte Seite nicht oder nicht mehr. Haben Sie
          sich vielleicht vertippt? Schauen Sie sich gerne auf unserer Webseite
          um oder melden Sie uns einen möglichen Fehler über{" "}
          <a href="mailto:kontakt@degeba.de">kontakt@degeba.de</a>.<br />
          <br />
          <Button
            variant="contained"
            color="primary"
            href="https://www.degeba.de"
          >
            Zur Startseite
          </Button>
        </Typography>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

import {
  useContext,
  useState,
  useCallback,
  useLayoutEffect,
  useRef,
  useEffect,
} from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  TextField,
} from "@mui/material";
import { RelationContext } from "./RelationContext";
import {
  addRelationBulk,
  deleteRelationBulk,
  QuestionRelationT,
  RelationT,
  SubRelationT,
  updateRelationBulk,
} from "./api";
import { QuestionContext } from "../question/QuestionContext";
import { orderSort } from "./RelationTable";
import { Question } from "./Question";
import { QuestionT } from "../question/api";
import { SubCategoryContext } from "../subCategory/SubCategoryContext";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { RELATIONREDUCERACTIONS } from "./relationReducer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { SelTreeT } from "./useSelTree";
import { SearchField } from "../shared/forms/SearchField";
import { useSearch } from "../shared/hooks/useSearch";
import { indexOf, noop } from "lodash";
import WebIcon from "@mui/icons-material/Web";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { TooltipIcon } from "../shared/forms/TooltipIcon";

export interface FilteredQuestionT extends QuestionT {
  minOrder: number;
  maxOrder: number;
  relationId: number;
}

interface QuestionRelationTablePropsI {
  selTree: SelTreeT;
  isSubCategorySelected: boolean;
  small?: boolean;
  checkedProfessionList: number[];
}

export const QuestionRelationTable = ({
  selTree,
  isSubCategorySelected,
  small = false,
  checkedProfessionList = [],
}: QuestionRelationTablePropsI) => {
  const { relationList, setRelationList } = useContext(RelationContext);

  const { questionList } = useContext(QuestionContext);
  const { categoryList: subCategoryList } = useContext(SubCategoryContext);

  const [filteredQuestionList, setFilteredQuestionList] = useState<
    FilteredQuestionT[]
  >([]);
  const [selectValue, setSelectValue] = useState(selTree.subCategoryId);
  const [showDetails, setShowDetails] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const [exampleSelect, setExampleSelect] = useState("all");
  const [orderSelect, setOrderSelect] = useState("id");
  const [filter, setFilter] = useSearch("questrelsearch");
  const [
    filterQuestionsCheckedProfessions,
    setFilterQuestionsCheckedProfessions,
  ] = useState(false);
  const [
    filterQuestionsCheckedProfessionsInvertion,
    setFilterQuestionsCheckedProfessionsInvertion,
  ] = useState(false);

  /* ÄNDERT SICH NICHT MEHR ! */
  const relationEntry = relationList
    .find(
      (r: RelationT) =>
        r.professionId === selTree.professionId &&
        r.mainCategoryId === selTree.mainCategoryId
    )
    ?.subRelations.find(
      (s: SubRelationT) => s.subCategoryId === selTree.subCategoryId
    );

  const filterFunc = useCallback(
    (q: QuestionT) => {
      return (
        ((exampleSelect === "question" && !q.example) ||
          (exampleSelect === "example" && q.example) ||
          exampleSelect === "all") &&
        (filter === "" ||
          q.id.toString().indexOf(filter.toLowerCase()) >= 0 ||
          (q.question || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          q.type.toLowerCase().indexOf(filter.toLowerCase()) >= 0) &&
        ((exampleSelect === "example" && q.example) ||
          (exampleSelect === "question" && !q.example) ||
          exampleSelect === "all")
      );
    },
    [exampleSelect, filter]
  );

  const updateFilteredQuestionList = useCallback(
    (subCategoryId: number) => {
      const minOrder = relationEntry
        ? Math.min(
            ...relationEntry.questionRelations.map(
              (s: QuestionRelationT) => s.questionOrder
            )
          )
        : 0;
      const maxOrder = relationEntry
        ? Math.max(
            ...relationEntry.questionRelations.map(
              (s: QuestionRelationT) => s.questionOrder
            )
          )
        : 0;

      /** all entries with the checked professions */
      const relationEntriesCheckedProfessions =
        filterQuestionsCheckedProfessions
          ? relationList.filter(
              (r: RelationT) =>
                checkedProfessionList.includes(r.professionId) &&
                r.mainCategoryId === selTree.mainCategoryId
            )
          : [];

      /** filter those with correct subcat and flat to questions only */
      const questionsCheckedProfessions = relationEntriesCheckedProfessions
        .map((r) =>
          r.subRelations.map((s) =>
            s.subCategoryId === selTree.subCategoryId
              ? s.questionRelations.map((q) => q.questionId)
              : []
          )
        )
        .flat()
        .flat();

      const tmpList =
        subCategoryId === -2 // all
          ? questionList
          : subCategoryId === -1 // no relation
          ? questionList.filter(
              (q) =>
                !relationList.some((r) =>
                  r.subRelations.some((sr) =>
                    sr.questionRelations.some((qr) => qr.questionId === q.id)
                  )
                )
            )
          : questionList.filter((q) =>
              relationList.some((r) =>
                r.subRelations.some(
                  (sr) =>
                    sr.subCategoryId === subCategoryId &&
                    sr.questionRelations.some((qr) => qr.questionId === q.id)
                )
              )
            );

      /** now filter again if checkedProf-Filter is activer */
      const tmpListFiltered = filterQuestionsCheckedProfessions
        ? filterQuestionsCheckedProfessionsInvertion
          ? tmpList.filter((q) => !questionsCheckedProfessions.includes(q.id))
          : tmpList.filter((q) => questionsCheckedProfessions.includes(q.id))
        : tmpList;

      const tmpListSorted = tmpListFiltered
        .map((q) => q[orderSelect as keyof QuestionT])
        .sort();
      console.log(
        "%cQuestionRelationTable.tsx line:197 tmpListSorted",
        "color: #007acc;",
        tmpListSorted
      );
      setFilteredQuestionList(
        tmpListFiltered
          .map((q) => {
            const questionSortValue =
              indexOf(tmpListSorted, q[orderSelect as keyof QuestionT]) +
              100000;

            return {
              ...q,
              /**
               * if we have a relation, the order comes from that
               * if not the order comes from orderSelect
               */
              order: relationEntry
                ? relationEntry?.questionRelations.find(
                    (r) => r.questionId === q.id
                  )?.questionOrder || questionSortValue
                : questionSortValue,
              minOrder: minOrder,
              maxOrder: maxOrder,
              relationId:
                relationEntry?.questionRelations.find(
                  (r) => r.questionId === q.id
                )?.id || 0,
            };
          })
          .sort(orderSort)
          .filter((q) => filterFunc(q))
      );
    },
    [
      questionList,
      relationEntry,
      relationList,
      selTree,
      filterFunc,
      orderSelect,
      checkedProfessionList,
      filterQuestionsCheckedProfessions,
      filterQuestionsCheckedProfessionsInvertion,
    ]
  );

  /* only on change of selTree */
  const firstUpdateFilterEffect = useRef(true);
  useLayoutEffect(() => {
    firstUpdateFilterEffect.current = false;
    // sets the list back to "selTree", not to the selected category in dropdown!
    if (selTree.subCategoryId === selectValue)
      updateFilteredQuestionList(selTree.subCategoryId);
  }, [updateFilteredQuestionList, selTree.subCategoryId, selectValue]);

  /* change of select */
  useEffect(() => {
    setSelectValue(selTree.subCategoryId);
  }, [selTree.subCategoryId]);

  /* change of search-filter */
  useLayoutEffect(() => {
    noop(filter); // to have it in depency-list
    updateFilteredQuestionList(selectValue);
  }, [filter, selectValue, updateFilteredQuestionList]);

  const isQuestionSelected = (questionId: number) =>
    relationList
      .find(
        (r: RelationT) =>
          r.professionId === selTree.professionId &&
          r.mainCategoryId === selTree.mainCategoryId
      )
      ?.subRelations.find(
        (sr: SubRelationT) => sr.subCategoryId === selTree.subCategoryId
      )
      ?.questionRelations.find(
        (qr: QuestionRelationT) => qr.questionId === questionId
      );

  const isQuestionSelectedByOther = (questionId: number) =>
    relationList
      .find((r: RelationT) => r.professionId === selTree.professionId)
      ?.subRelations.some(
        (sr: SubRelationT) =>
          sr.subCategoryId !== selTree.subCategoryId &&
          sr.questionRelations.some(
            (qr: QuestionRelationT) => qr.questionId === questionId
          )
      );

  /** ************************************************************************
   *
   * ,--,--,--. ,---.,--.  ,--.,---.
   * |        || .-. |\  `'  /| .-. :
   * |  |  |  |' '-' ' \    / \   --.
   * `--`--`--' `---'   `--'   `----'
   *
   */
  const moveQuestion = (direction: string, questionId: number) => {
    const actQuestion: FilteredQuestionT | undefined = filteredQuestionList
      ? filteredQuestionList.find((c) => c.id === questionId)
      : undefined;

    const actOrder = actQuestion ? (actQuestion as FilteredQuestionT).order : 0;
    if (direction === "up" && actOrder === 1) return;

    if (filteredQuestionList) {
      const changeQuestion =
        direction === "down"
          ? filteredQuestionList.find((c) => (c.order || 0) > actOrder)
          : filteredQuestionList
              .reverse()
              .find((c) => (c.order || 0) < actOrder);

      if (changeQuestion && actQuestion)
        setRelationList({
          action: RELATIONREDUCERACTIONS.MOVEQUESTION,
          data: {
            professionId: selTree.professionId,
            mainCategoryId: selTree.mainCategoryId,
            subCategoryId: selTree.subCategoryId,
            questions: [changeQuestion, actQuestion],
          },
        });

      if (actQuestion && changeQuestion)
        updateRelationBulk([
          {
            type: "question",
            id: (actQuestion as FilteredQuestionT).relationId || 0,
            questionOrder: changeQuestion.order,
          },
          {
            type: "question",
            id: changeQuestion.relationId || 0,
            questionOrder: actOrder,
          },
        ])
          .then(() => {
            openSnackbar("success", "Reihenfolge gespeichert");
          })
          .catch((e) => setError(e));
    }
  };

  /** ************************************************************************
   *          _    _    _         _ _
   *  __ _ __| |__| |  | |__ _  _| | |__
   * / _` / _` / _` |  | '_ \ || | | / /
   * \__,_\__,_\__,_|  |_.__/\_,_|_|_\_\
   *
   */
  const onClickAddRelationBulk = (subCategoryId: number) => {
    showProgress();

    const data = filteredQuestionList
      .filter((r) => !isQuestionSelectedByOther(r.id))
      .map((r) => ({
        professionId: selTree.professionId,
        mainCategoryId: selTree.mainCategoryId,
        subCategoryId: selTree.subCategoryId,
        questionId: r.id,
      }));

    addRelationBulk(data)
      .then((result) => {
        if (result.success) {
          setRelationList({
            action: RELATIONREDUCERACTIONS.ADDQUESTIONBULK,
            data: {
              professionId: selTree.professionId,
              mainCategoryId: selTree.mainCategoryId,
              subCategoryId: selTree.subCategoryId,
              add: result.data as QuestionRelationT[],
            },
          });
          openSnackbar("success", "Fragen zugeordnet");
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  /**
   *    ,--.       ,--.    ,--.           ,--.,--.
   *  ,-|  | ,---. |  |    |  |-. ,--.,--.|  ||  |,-.
   * ' .-. || .-. :|  |    | .-. '|  ||  ||  ||     /
   * \ `-' |\   --.|  |    | `-' |'  ''  '|  ||  \  \
   *  `---'  `----'`--'     `---'  `----' `--'`--'`--'
   */
  const onClickDeleteRelationBulk = () => {
    showProgress();

    const data = {
      professionId: selTree.professionId,
      mainCategoryId: selTree.mainCategoryId,
      subCategoryId: selTree.subCategoryId,
    };

    deleteRelationBulk(data)
      .then((result) => {
        if (result.success) {
          setRelationList({
            action: RELATIONREDUCERACTIONS.DELQUESTIONBULK,
            data: {
              professionId: selTree.professionId,
              mainCategoryId: selTree.mainCategoryId,
              subCategoryId: selTree.subCategoryId,
            },
          });

          openSnackbar("success", "Fragen entfernt");
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {
          <TooltipIcon
            type="filter"
            iconProps={{
              fontSize: "small",
              ...(filterQuestionsCheckedProfessions
                ? { color: "warning" }
                : {}),
            }}
            onClick={() => {
              setFilterQuestionsCheckedProfessions((p) => !p);
            }}
            title={
              filterQuestionsCheckedProfessions
                ? "Filter nach HK + Berufauswahl ist aktiv - Click zum abschalten"
                : "Filter nach HK + Berufauswahl"
            }
          />
        }
        <TooltipIcon
          type="swap"
          off={true}
          iconProps={{
            fontSize: "small",
            ...(filterQuestionsCheckedProfessionsInvertion
              ? { color: "warning" }
              : {}),
          }}
          onClick={() => {
            if (!filterQuestionsCheckedProfessionsInvertion)
              setFilterQuestionsCheckedProfessions(true);
            setFilterQuestionsCheckedProfessionsInvertion((p) => !p);
          }}
          title={
            filterQuestionsCheckedProfessionsInvertion
              ? "Filter nach HK invertierter Berufsauswahl ist aktiv - Click zum abschalten"
              : "Filter nach HK invertierter Berufsauswahl"
          }
        />

        {!small && (
          <>
            <Button
              variant="contained"
              startIcon={
                showDetails ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              onClick={() => setShowDetails(!showDetails)}
              sx={{ mr: 2 }}
            >
              Details
            </Button>
            {!selTree.professionIsActive && (
              <>
                <Button
                  variant="contained"
                  startIcon={showPreview ? <WebIcon /> : <AssignmentIcon />}
                  onClick={() => setShowPreview(!showPreview)}
                  sx={{ mr: 2 }}
                >
                  Vorschau
                </Button>

                <Button
                  variant="contained"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => onClickAddRelationBulk(selectValue)}
                  sx={{ mr: 2 }}
                >
                  Alle
                </Button>
                <Button
                  variant="contained"
                  startIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => onClickDeleteRelationBulk()}
                  sx={{ mr: 2 }}
                >
                  Alle
                </Button>
              </>
            )}
          </>
        )}
        <TextField
          label="Anzeige"
          select
          value={exampleSelect}
          onChange={(e) => setExampleSelect(e.target.value as string)}
          aria-label="text alignment"
          sx={{ mr: 2, height: 40, mb: 2 }}
        >
          <MenuItem value="example">Bsp.</MenuItem>
          <MenuItem value="question">Fragen</MenuItem>
          <MenuItem value="all">Alle</MenuItem>
        </TextField>
        <TextField
          label="Sortierung"
          select
          value={orderSelect}
          onChange={(e) => setOrderSelect(e.target.value as string)}
          aria-label="text alignment"
          sx={{ mr: 2, height: 40 }}
        >
          <MenuItem value="id">ID</MenuItem>
          <MenuItem value="question">Frage</MenuItem>
          <MenuItem value="difficulty">Schwierigkeit</MenuItem>
        </TextField>
        <Select
          labelId="kategorien"
          id="questionSelectId"
          value={selectValue}
          onChange={(e: SelectChangeEvent<number>) => {
            updateFilteredQuestionList(e.target.value as number);
            setSelectValue(e.target.value as number);
          }}
          label="Kategorien"
          size="small"
          sx={{ mr: 2, mb: 1 }}
        >
          <MenuItem key={selTree.subCategoryId} value={selTree.subCategoryId}>
            {subCategoryList.find((c) => c.id === selTree.subCategoryId)?.name}
          </MenuItem>
          <Divider />
          <MenuItem value={-1}>ohne Zuordnung</MenuItem>
          <MenuItem value={-2}>alle (!langsam!)</MenuItem>
          <Divider />
          {subCategoryList
            .filter((c) => c.id !== selTree.subCategoryId)
            .map((e) => (
              <MenuItem key={e.id} value={e.id}>
                {e.name}
              </MenuItem>
            ))}
        </Select>
        <SearchField value={filter} setValue={setFilter} id="questrelsearch" />
      </Grid>

      {filteredQuestionList.map((q) => (
        <Grid item xs={12} key={"GridQ" + q.id}>
          <Question
            key={q.id}
            question={q}
            isQuestionSelected={isQuestionSelected}
            isQuestionSelectedByOther={isQuestionSelectedByOther}
            isSubCategorySelected={isSubCategorySelected}
            selTree={selTree}
            showDetails={showDetails}
            moveQuestion={moveQuestion}
            showPreview={showPreview}
          />
        </Grid>
      ))}
    </Grid>
  );
};

import { updateProfession, ProfessionT } from "./api";
import { useContext } from "react";
import { ProfessionContext } from "./ProfessionContext";
import { ErrorContext } from "../error/ErrorContext";
import { ProfessionForm } from "./ProfessionForm";

import { FeedbackContext } from "../feedback/FeedbackContext";
import { updateStateArrayById } from "../shared/helper/state";

export const EditProfession = ({
  profession,
  setProfession,
  setShowEdit,
}: {
  profession: ProfessionT;
  setProfession: (p: ProfessionT) => void;
  setShowEdit: (b: boolean) => void;
}) => {
  const { setProfessionList } = useContext(ProfessionContext);
  const { setError } = useContext(ErrorContext);

  const { openSnackbar } = useContext(FeedbackContext);

  const onClickUpdate = (data: ProfessionT) => {
    if (!data.name) return;

    updateProfession(data)
      .then((result) => {
        if (result.success) {
          setProfessionList((p) => updateStateArrayById(p, data));
          setProfession(data);
          setShowEdit(false);
          openSnackbar("success", "Eintrag gespeichert");
        } else setError(result.error);
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <ProfessionForm
      onClick={onClickUpdate}
      profession={profession}
      buttonLabel="Update"
    />
  );
};

import React, { createContext, useState } from "react";
import { FileProvider } from "../file/FileContext";
import { ContextPropsT } from "../shared/types/Context";
import { TargetT } from "./api";

interface TargetContextPropsType extends ContextPropsT {
  targetList: TargetT[];
  setTargetList: React.Dispatch<React.SetStateAction<TargetT[]>>;
  changedTargetList: TargetChangedT[];
  setChangedTargetList: React.Dispatch<React.SetStateAction<TargetChangedT[]>>;
}

export interface TargetChangedT extends TargetT {
  error?: boolean;
}

export const TargetContext = createContext<TargetContextPropsType>({
  targetList: [],
  setTargetList: () => {},
  changedTargetList: [],
  setChangedTargetList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
});
export const TargetProvider = ({ children }: { children: React.ReactNode }) => {
  const [targetList, setTargetList] = useState<TargetT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const [changedTargetList, setChangedTargetList] = useState<TargetT[]>([]);

  return (
    <TargetContext.Provider
      value={{
        targetList,
        setTargetList,
        changedTargetList,
        setChangedTargetList,
        loadingDone,
        setLoadingDone,
      }}
    >
      <FileProvider>{children}</FileProvider>
    </TargetContext.Provider>
  );
};

export const ButtonRightSvg = () => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
  >
    <polygon points="20.1,39.4 34.6,25 20,10.4 16.8,13.6 28.2,25 17,36.2 " />
  </svg>
);

import { useContext, useState, useEffect, useRef } from "react";

import { debouncedSetUpdatedState } from "../shared/helper/state";
import { EditableCell } from "../shared/list/InsertCell";
import { checkChanged } from "../shared/helper/object";
import { HelpContext } from "./HelpContext";
import { HelpEntryT } from "./api";
import { TableCell, TableRow } from "@mui/material";
import { MoveButton } from "../relation/MoveButton";

interface QuestionPropsI {
  helpEntry: HelpEntryT;
  setShowSave: (showSave: boolean) => void;
  moveEntry: (helpEntry: HelpEntryT, direction: string) => void;
  actHelpEntryList: HelpEntryT[];
}

export const HelpEntry = ({
  helpEntry,
  setShowSave,
  moveEntry,
  actHelpEntryList,
}: QuestionPropsI) => {
  const { setChangedHelpEntryList } = useContext(HelpContext);

  console.log(
    "%cHelpEntry.tsx line:26 actHElpEntryList",
    "color: #007acc;",
    JSON.stringify(actHelpEntryList, null, 2)
  );

  const [hasChanged, setHasChanged] = useState(false);
  const [helpEntryState, setHelpEntryState] = useState<HelpEntryT>(helpEntry);
  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(helpEntry, helpEntryState, hasChanged, setHasChanged);
  }, [setHasChanged, hasChanged, helpEntry, helpEntryState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(helpEntryState, setChangedHelpEntryList);
  }, [helpEntryState, setChangedHelpEntryList]);

  return (
    <>
      <TableRow hover tabIndex={-1} key={helpEntry.id} selected={hasChanged}>
        <EditableCell
          id="title"
          stateObject={helpEntryState}
          setStateObject={setHelpEntryState}
          isObligatory={true}
        />
        <EditableCell
          id="content"
          stateObject={helpEntryState}
          setStateObject={setHelpEntryState}
          isObligatory={true}
          maxLength={10000}
        />
        <TableCell align="right">
          {helpEntry.sortOrder > 1 && (
            <MoveButton
              direction="up"
              onClick={() => moveEntry(helpEntry, "up")}
            />
          )}
          {helpEntry.sortOrder < actHelpEntryList.length && (
            <MoveButton
              direction="down"
              onClick={() => moveEntry(helpEntry, "down")}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

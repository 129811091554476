import { isArray } from "lodash";
import { get, CallApiResultT, post, put } from "../shared/lib/api";
import { checkApiResult } from "../shared/lib/checkApi";
import { UserDataT, UserT, data2user } from "../user/api";

export type ShopOrderItemDataT = {
  id: number;
  orderId: number;
  articleId: number;
  professionId: number;
  type: string;
  content: string;
  pricePremium: number;
  priceAdd: number;
  priceBooks: number;
  priceSolutions: number;
  priceBranding: number;
  priceLicense: number;
  createdAt: string;
  customer?: UserDataT;
};

export type ShopOrderItemT = {
  id: number;
  orderId: number;
  articleId: number;
  professionId: number;
  type: string;
  content: string;
  createdAt: Date;
  isFirst?: boolean;
  isLast?: boolean;
  pricePremium: number;
  priceAdd: number;
  priceBooks: number;
  priceSolutions: number;
  priceBranding: number;
  priceLicense: number;
  priceTotal?: number;
  priceShipping?: number;
  customer?: UserT;
};

export type ShopArticleDataT = {
  id: number;
  professionId?: number;
  artNo: string;
  name: string;
  edition: number;
  imagePath: string;
  imagePathPremium: string;
  imagePathPremiumOnline: string;
  basic: number;
  premium: number;
  online: number;
};

export type ShopArticleT = {
  id: number;
  professionId?: number;
  artNo: string;
  name: string;
  edition: number;
  imagePath: string;
  imagePathPremium: string;
  imagePathPremiumOnline: string;
  basic: boolean;
  premium: boolean;
  online: boolean;
};

export type ShopCartT = {
  articleId: number;
  type: string;
  professionId: number;
  number?: number;
};

export type ShopCartDataT = {
  articleId: number;
  type: string;
  professionId: number;
  number?: number;
  content: string;
};

export type CartItemT = {
  articleId: number;
  orderId?: number;
  orderName?: string;
  type: string;
  artnr: string;
  numberLicenses?: number;
  numberBooks?: number;
  numberSolutions?: number;
  branding: boolean;
  price?: number;
  professionId?: number;

  article: ShopArticleT;
};

export type ShopCreateOrderDataT = {
  orders: CartItemT[];
};

const data2orderItem = (data: ShopOrderItemDataT): ShopOrderItemT => {
  checkApiResult("data2orderItem", data, {
    id: "number",
    orderId: "number",
    articleId: "number",
    type: "string",
    content: "string",
  });

  const order: any = {};
  ["id", "orderId", "articleId", "type", "content"].forEach(
    (f) => (order[f] = data[f as keyof ShopOrderItemDataT] || "")
  );
  [
    "pricePremium",
    "priceAdd",
    "priceBooks",
    "priceSolutions",
    "priceBranding",
    "priceLicense",
    "priceShipping",
  ].forEach((f) => (order[f] = data[f as keyof ShopOrderItemDataT] || 0));

  order.createdAt = new Date(data.createdAt);

  if (data.customer) {
    order.customer = data2user(data.customer);
  }

  return order as ShopOrderItemT;
};

const data2orderItems = (data: ShopOrderItemDataT[]): ShopOrderItemT[] => {
  return data.map((d) => data2orderItem(d));
};

const data2articles = (data: ShopArticleDataT[]): ShopArticleT[] => {
  return data.map((d) => data2article(d));
};

const data2article = (data: ShopArticleDataT): ShopArticleT => {
  checkApiResult("data2article", data, {
    id: "number",
    artNo: "string",
    name: "string",
    edition: "number",
    imagePath: "string",
    imagePathPremium: "string",
    basic: "number",
    premium: "number",
    online: "number",
  });

  const article: any = {};
  [
    "id",
    "artNo",
    "name",
    "imagePath",
    "imagePathPremium",
    "imagePathPremiumOnline",
    "edition",
  ].forEach((f) => (article[f] = data[f as keyof ShopArticleDataT] || ""));
  article.professionId = data.professionId || null;

  article.basic = data.basic === 1;
  article.premium = data.premium === 1;
  article.online = data.online === 1;

  return article as ShopArticleT;
};

/** ************************************************************************
 *
 *
 */
export const fetchOrderItems = async (): Promise<
  CallApiResultT<ShopOrderItemT[]>
> => {
  console.log("%capi.ts line:185 shop-orders 🥎", "color: #007acc;");
  const res = await get("/shop-orders");
  if (!res.success || !(res.data && isArray(res.data))) {
    throw Error("[fetchOrderItems] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: data2orderItems(res.data),
  };
};

export const fetchArticles = async (): Promise<
  CallApiResultT<ShopArticleT[]>
> => {
  const res = await get("/shop-articles");
  if (!res.success || !(res.data && isArray(res.data))) {
    throw Error("[fetchArticles] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: data2articles(res.data),
  };
};

export const createOrder = async (orders: ShopCreateOrderDataT) => {
  const res = await post("/shop-order", orders);
  if (!res.success) {
    throw Error("[createOrder] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
  };
};

export const downloadPremium = async (id: number) => {
  const res = await get<string>("/download-premium?orderItemId=" + id);
  if (!res.success) {
    throw Error("[downloadPremium] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: res.data,
  };
};

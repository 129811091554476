import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { DeleteButton } from "../shared/forms/DeleteButton";

import {
  debouncedSetUpdatedState,
  deleteStateFromArrayById,
} from "../shared/helper/state";
import { deleteFile, FileT } from "./api";
import { useContext, useEffect, useRef, useState } from "react";
import { FileChangedT, FileContext } from "./FileContext";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import { EditableCell } from "../shared/list/InsertCell";
import { checkChanged } from "../shared/helper/object";
import { containsSuffix, mustBeSanitized } from "../shared/helper/string";
import { FileLink } from "./FileLink";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { cachebreaker } from "../shared/helper/url";

const StyledImg = styled("img")({
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -20%)",
  width: 400,
  background: "lightgray",
});

interface FilePropsI {
  file: FileT;
  setShowSave: (showSave: boolean) => void;
  setChangedFileList: React.Dispatch<React.SetStateAction<FileChangedT[]>>;
}

export const File = ({ file, setShowSave, setChangedFileList }: FilePropsI) => {
  const [showImage, setShowImage] = useState(false);
  const { setFileList } = useContext(FileContext);
  const [fileState, setFileState] = useState(file);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(file, fileState, hasChanged, setHasChanged);
  }, [setHasChanged, hasChanged, file, fileState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(fileState, setChangedFileList);
  }, [fileState, setChangedFileList]);

  return (
    <>
      <TableRow hover tabIndex={-1} key={file.id}>
        <EditableCell
          id="name"
          isObligatory={true}
          stateObject={fileState}
          setStateObject={setFileState}
          checkContent={(str: string) => {
            if (mustBeSanitized(str)) return "Invalides Zeichen";
            if (!containsSuffix(str)) return "Fehlende Dateiendung";
            return "";
          }}
          onClickAway={() =>
            setFileState((p) => ({ ...p, name: p.name.toLowerCase() }))
          }
        />
        {["size"].map((id) => (
          <TableCell component="th" scope="row" padding="none" key={id}>
            {(file as any)[id]}
          </TableCell>
        ))}
        <TableCell component="th" scope="row" padding="none">
          {file.updatedAt.toLocaleDateString("de-DE")}
        </TableCell>

        <TableCell
          padding="none"
          sx={{ width: file.type === "media" ? 50 : 100 }}
          align="center"
        >
          <Grid container spacing={0} justifyContent="space-between">
            {file.type !== "media" && (
              <Grid item>
                <RemoveRedEyeIcon
                  onMouseEnter={() => setShowImage(true)}
                  onMouseLeave={() => setShowImage(false)}
                />
              </Grid>
            )}
            {showImage && (
              <StyledImg
                src={
                  import.meta.env.VITE_IMGURL +
                  "/" +
                  file.type +
                  "/" +
                  file.name +
                  "?" +
                  cachebreaker()
                }
                alt={file.name}
              />
            )}
            <Grid item>
              <a
                href={"/storage/" + file.filePath + "?" + cachebreaker()}
                target="_blank"
                rel="noreferrer"
              >
                <TooltipIcon type="newTab" title="In neuem Tab öffnen" />
              </a>
            </Grid>
            <Grid item>
              <FileLink file={file} />
            </Grid>
            <Grid>
              <DeleteButton
                id={file.id}
                deleteFunc={deleteFile}
                successFunc={() =>
                  setFileList((p) => deleteStateFromArrayById(p, file.id))
                }
                {...(file.countMainCategories > 0
                  ? {
                      disabled: true,
                      tooltipTitle:
                        "Diese Datei wird bereits in einer Hauptkategorie verwendet",
                    }
                  : {})}
                {...(file.countProfessions > 0
                  ? {
                      disabled: true,
                      tooltipTitle:
                        "Diese Datei wird bereits in einem Beruf verwendet",
                    }
                  : {})}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
};

import { ConfirmDialog } from "../ConfirmDialog";
import { useContext, useState } from "react";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { TooltipIcon } from "./TooltipIcon";
import { CallApiResultT } from "../lib/api";
import { Button, SxProps, Theme } from "@mui/material";

interface DeleteButtonPropsI {
  onClick?: () => void; // deperecated
  tooltipTitle?: string;
  confirmTitle?: string;
  confirmContent?: string;
  id?: number;
  deleteFunc?: (id: number) => Promise<CallApiResultT<number> | number>;
  successFunc?: (id: number) => void;
  successMsg?: string;
  disabled?: boolean;
  className?: string;
  textYes?: string;
  textNo?: string;
  sx?: SxProps<Theme>;
  button?: boolean;
}

export const DeleteButton = ({
  onClick,
  tooltipTitle,
  disabled = false,
  ...props
}: DeleteButtonPropsI) => {
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const { setError } = useContext(ErrorContext);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleYes = () => {
    showProgress();
    if (onClick) onClick();
    else if (props.deleteFunc && props.id) {
      console.log("%cDeleteButton.tsx line:36 ", "color: #007acc;");
      props
        .deleteFunc(props.id)
        .then(() => {
          if (props.successFunc) props.successFunc(props.id as number);
          openSnackbar(
            "success",
            props.successMsg ? props.successMsg : "Der Eintrag wurde gelöscht!"
          );
        })
        .catch((error) => {
          setError(error);
        });
    }
    setDialogOpen(false);
  };

  return disabled ? (
    <TooltipIcon
      type="delete"
      title={tooltipTitle || "Löschen"}
      iconProps={{ color: "disabled", "data-testid": "DeleteIconDisabled" }}
      sx={props.sx}
    />
  ) : (
    <>
      {" "}
      {props.button ? (
        <Button
          variant="contained"
          onClick={(e) => setDialogOpen(true)}
          sx={props.sx}
          data-testid="DeleteButton"
        >
          Löschen
        </Button>
      ) : (
        <TooltipIcon
          type="delete"
          title={tooltipTitle || "Löschen"}
          onClick={(e) => setDialogOpen(true)}
          iconProps={{ "data-testid": "DeleteIcon" }}
          className={props.className}
          sx={props.sx}
        />
      )}
      <ConfirmDialog
        open={dialogOpen}
        handleYes={() => handleYes()}
        handleNo={() => setDialogOpen(false)}
        title={props.confirmTitle || "Sind Sie sicher?"}
        content={
          props.confirmContent || "Soll der Eintrag wirklich gelöscht werden?"
        }
        textYes={props.textYes}
        textNo={props.textNo}
      />{" "}
    </>
  );
};

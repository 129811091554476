import { CleaningServices } from "@mui/icons-material";

// https://stackoverflow.com/questions/1293147/example-javascript-code-to-parse-csv-data
export const parseCSV = (str: string) => {
  const arr: string[] = [];
  let quote = false; // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let col = 0, c = 0; c < str.length; c++) {
    const cc = str[c],
      nc = str[c + 1]; // Current character, next character
    arr[col] = arr[col] || ""; // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc === '"' && quote && nc === '"') {
      arr[col] += cc;
      ++c;
      continue;
    }

    // If it's just one quotation mark, begin/end quoted field
    if (cc === "'" || cc === '"') {
      quote = !quote;
      continue;
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc === ";" && !quote) {
      ++col;
      continue;
    }

    // Otherwise, append the current character to the current column
    arr[col] += cc;
  }
  return arr;
};

export const stringToSlug = (str: string, separator = "-"): string => {
  return str
    .toString()
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/-/g, separator) //
    .replace(/[^a-z0-9- ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator)
    .replace(/[-]+/g, separator);
};

export const sanitizeFilename = (str: string): string => {
  return str.replace(/[^a-z0-9-_+.]/gi, "-").toLowerCase();
};

export const mustBeSanitized = (str: string): boolean => {
  return str.match(/[^a-z0-9-_+.]/i) ? true : false;
};

export const containsSuffix = (str: string): boolean => {
  return str.match(/\.[a-z]{1,4}$/i) ? true : false;
};

export const removeNonAscii = (str: string): string => {
  /* eslint-disable no-control-regex */
  return str.replace(/[^\x00-\x7F]/g, "");
};

export const replaceUmlaute = (str: string): string => {
  return str
    .replace(/\u00dc/g, "Ue")
    .replace(/\u00fc/g, "ue")
    .replace(/\u00c4/g, "Ae")
    .replace(/\u00e4/g, "ae")
    .replace(/\u00d6/g, "Oe")
    .replace(/\u00f6/g, "oe")
    .replace(/\u00df/g, "ss");
};

export const padString = (str: string, count: number, pad = " "): string => {
  return (str + Array(count).join(pad)).substring(0, count);
};

export const uppercaseFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const splitLongLabel = (label: string) => {
  if (label.match(/\s/)) {
    return label.split(/\s/);
  }
  const check = ["beherrschung", "vermögen", "kenntnisse"];
  for (let i = 0; i < check.length; i++) {
    if (label.match(check[i])) {
      return [label.replace(check[i], "") + "-", check[i]];
    }
  }

  return label;
};

export const formatAmount = (amount: number | string): string => {
  if (typeof amount === "string") {
    amount = parseFloat(amount);
  }
  return amount.toFixed(2).replace(".", ",");
};

export const randomString = (length: number): string => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const randomNumberString = (length: number): string => {
  const chars = "0123456789";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

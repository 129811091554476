import { Box, Modal } from "@mui/material";
import { ProfessionProvider } from "../profession/ProfessionContext";
import { ResultsLoader } from "./results/ResultsLoader";
import { CloseModalButton } from "../shared/modal/CloseModalButton";
import PrintIcon from "@mui/icons-material/Print";
import { removeNavigation } from "./results/useNavigation";

interface CandidateResultsModalPropsI {
  showResults: number;
  setShowResults: (value: React.SetStateAction<number>) => void;
}

export const CandidateResultsModal = ({
  showResults,
  setShowResults,
}: CandidateResultsModalPropsI) => {
  const closeModal = () => {
    removeNavigation();
    setShowResults(0);
  };

  return (
    <Modal open={true} onClose={closeModal} className="modal-backdrop">
      <Box className="modal modal-full modal-a4">
        <ProfessionProvider>
          <ResultsLoader candidateId={showResults} />
        </ProfessionProvider>
        <CloseModalButton close={closeModal} />
        <PrintIcon
          className="print-button"
          onClick={() =>
            window.open("/customer/print/" + showResults, "_blank")
          }
        />
      </Box>
    </Modal>
  );
};

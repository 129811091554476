import { csvToArray } from "../../../shared/helper/csv";
import { flatten, uniqueId } from "lodash";
import { Key } from "react";

let dropDownIdx = 0;
const possibleTypes = ["text", "dropdown"];

export const convertClozeTextFormat = (str: string) => {
  while (str.indexOf("||") >= 0) str = str.replace(/\|\|/, "|EMPTYLABEL|");
  str = str.replaceAll("{", "|{");
  str = str.replaceAll("}", "}|");
  str = str.replace(/\|+{/g, "|{");
  str = str.replace(/}\|+/g, "}|");
  str = str.replace(/(^\|)|(\|$)/g, "");
  return str;
};

export const decodeAnswerOptionsClozeText = (options: string): any => {
  dropDownIdx = 0;
  options = options.replace(/^"/, "");
  options = options.replace(/"$/, "");
  if (options.startsWith("|"))
    return {
      format: "table",
      data: _decodeAnswerOptionsClozeTextTable(options),
    };
  else
    return { format: "single", data: _decodeAnswerOptionsClozeText(options) };
};

export const _decodeAnswerOptionsClozeTextTable = (options: string): any[] => {
  const x = options
    .split(/\r\n|\r|\n|\\\n/)
    .filter((o) => o !== "")
    .map((o) => _decodeAnswerOptionsClozeText(o));
  return x;
};

export const _decodeAnswerOptionsClozeText = (options: string): any[] => {
  options = convertClozeTextFormat(options);

  /* 1st we take all inputs in {} to an array and replace them with ___INPUT:{number}___ */
  const inputs: { [key: string | number]: string } = {};
  options = options.replace(/\{([^}]*)\}/g, (m) => {
    const key = uniqueId();
    inputs[key] = m;
    return "____INPUT:" + key + "____";
  });

  const parts = options.split(/\|/);

  let index = 0;
  const lineResult: any[] = parts.map((part) => {
    const m = part.match(/____INPUT:(\d+)____/);
    if (m && m[1]) {
      if (m[1] in inputs) {
        const idx: string | number = m[1];
        const input: string = inputs[idx];
        const foundType = possibleTypes.find((t) => input.indexOf(t) >= 0);

        if (foundType === "dropdown")
          return { index: index++, ...parseDropdown(input) };
        if (foundType === "text")
          return { index: index++, ...parseText(input) };
        return foundType;
      }
    }
    return part.split(/\|/).map((p) =>
      p.match(/^-+$/)
        ? { type: "separator" }
        : {
            type: "label",
            content: p === "EMPTYLABEL" ? "" : p === "-^-" ? "" : p,
          }
    );
  });
  return flatten(lineResult);
};

export const parseDropdown = (str: string) => {
  const regex = "^{?dropdown\\|([^|]*)\\|([^|]*)\\|'?([^']*)'?}?$";

  const m = str.match(regex);
  if (!m) return { error: "---UNPARSEABLE---" };

  const options = csvToArray(m[1], ";", "'")[0];
  return {
    type: "dropdown",
    options: options,
    size: m[2],
    index: dropDownIdx++,
    answer: m[3],
  };
};

export const parseText = (str: string) => {
  const regex = "^{?text\\|([^|]*)\\|'?([^']*)'?}?$";
  const m = str.match(regex);
  if (!m) return { error: "---UNPARSEABLE---" };

  return { type: "text", size: m[1], answer: m[2] };
};

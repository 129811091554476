import { useContext, useEffect } from "react";
import { LayoutContext, LayoutT } from "./LayoutContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import React from "react";

export interface UseLayoutsResultT extends UseResultT {
  layout: LayoutT;
  setLayout: React.Dispatch<React.SetStateAction<LayoutT>>;
}

interface UseLayoutsPropsI extends UsePropsI {
  layout: LayoutT;
}

export const useLayout = ({ layout }: UseLayoutsPropsI): UseLayoutsResultT => {
  const { setLayout, loadingDone, setLoadingDone } = useContext(LayoutContext);
  useEffect(() => {
    setLayout(layout);
  }, []);

  useEffect(() => {
    if (!layout) return;
    setLoadingDone(true);
  }, [layout]);

  return { layout, setLayout, loadingDone };
};

import Button from "@mui/material/Button";

import { StyledFormControl } from "../shared/forms/StyledFormControl";
import TextField from "@mui/material/TextField";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { useState } from "react";

interface ChangePasswordFormPropsI {
  onSave: (password: string) => void;
  buttonText?: string;
}

export const ChangePasswordForm = ({
  onSave,
  ...props
}: ChangePasswordFormPropsI) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(["password", "passwordConfirm"])
  );

  const onClick = () => {
    const error1 = !password ? true : false;
    setErrorState((p) => buildErrorState(p, "password", error1));

    const error2 = !passwordConfirm ? true : false;
    setErrorState((p) => buildErrorState(p, "passwordConfirm", error2));
    if (error1 || error2) return;

    if (password !== passwordConfirm) {
      setErrorState((p) =>
        buildErrorState(
          p,
          "passwordConfirm",
          true,
          "Die beiden Passwörter sind nicht gleich"
        )
      );
      return;
    }

    onSave(password);
  };

  return (
    <>
      <StyledFormControl required={true}>
        <TextField
          id="password"
          label="Passwort"
          value={password}
          type="password"
          error={errorState.password?.error}
          helperText={
            errorState.password?.error
              ? errorState.password?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) => setPassword(e.target.value)}
        />
      </StyledFormControl>
      <StyledFormControl required={true}>
        <TextField
          id="passwordConfirm"
          label="Wiederholen"
          type="password"
          value={passwordConfirm}
          error={errorState.passwordConfirm?.error}
          helperText={
            errorState.passwordConfirm?.error
              ? errorState.passwordConfirm?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
      </StyledFormControl>
      <div>
        <StyledFormControl>
          <Button variant="contained" color="primary" onClick={onClick}>
            {props.buttonText || "Passwort ändern"}
          </Button>
        </StyledFormControl>
      </div>
    </>
  );
};

import { useState, useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { SearchField } from "../shared/forms/SearchField";
import Box from "@mui/material/Box";

import { ErrorContext } from "../error/ErrorContext";

import { AddCandidate } from "./AddCandidate";
import { FeedbackContext } from "../feedback/FeedbackContext";

import {
  deleteAllCandidateSettings,
  updateCandidateBulk,
} from "../candidate/api";

import { CandidateContext } from "../candidate/CandidateContext";
import { usePagination } from "../shared/hooks/usePagination";
import { useNavigation } from "./results/useNavigation";
import { useSearch } from "../shared/hooks/useSearch";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEqual, unionWith } from "lodash";
import { CandidateTable } from "./CandidateTable";
import { CandidateResultTable } from "./CandidateResultTable";
import { useShowSave } from "../shared/hooks/useShowSave";
import SaveChanges from "../shared/list/SaveChanges";
import { deleteCandidates as deleteCandidatesApi } from "../candidate/api";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { DeleteButton } from "../shared/forms/DeleteButton";
import { WarnDialog } from "../shared/WarnDialog";
import { MailButton } from "../shared/forms/MailButton";
import { ProfessionButton } from "../shared/forms/ProfessionButton";
import { updateSettings } from "../user/api";
import { AuthContext } from "../auth/AuthContext";

export const CandidateSwitch = () => {
  const {
    candidateList,
    setCandidateList,
    changedCandidateList,
    setChangedCandidateList,
    candidatesToDelete,
    setCandidatesToDelete,
    candidatesToMail,
    setCandidatesToMail,
    candidatesToProfession,
    setCandidatesToProfession,
  } = useContext(CandidateContext);
  console.log("⛱️ ⛱️ ⛱️");
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const [selectedProfession, setSelectedProfession] = useState(0);

  const { handleChangePage } = usePagination("candidate");
  const [filter, setFilter] = useSearch("candsearch");
  console.log("setFilter", setFilter);
  const [exportClicked, setExportClicked] = useState(false);
  const [exportType, setExportType] = useState(0); // 0: xls, 1: csv
  useNavigation("remove");
  const [showSave, setShowSave] = useShowSave();
  const [saveFirst, setSaveFirst] = useState(false);
  const [masterCheckboxDeleteSelected, setMasterCheckboxDeleteSelected] =
    useState(false);
  const [masterCheckboxMailSelected, setMasterCheckboxMailSelected] =
    useState(false);
  const [
    masterCheckboxProfessionSelected,
    setMasterCheckboxProfessionSelected,
  ] = useState(false);
  const [uploadedCandidateIds, setUploadedCandidateIds] = useState<number[]>(
    []
  );

  const professionList = unionWith(
    candidateList
      .filter((c) => c.professionId > 0)
      .map((p) => ({
        id: p.professionId,
        name: p.professionName,
      })),
    isEqual
  ).sort((a, b) => a.name?.localeCompare(b.name?.toString() || "") || 0);

  const [view, setView] = useState("candidates");
  const { authState, setAuthState } = useContext(AuthContext);

  console.log("authState.customerSettings", authState.customerSettings);
  const [disability, setDisability] = useState(
    authState.customerSettings?.disability ? true : false
  );
  /** in result-view as default select 1st prof in list */
  useEffect(() => {
    if (view === "results") {
      if (selectedProfession === 0) {
        setSelectedProfession(professionList[0].id);
      }
    }
  }, [view]);

  /** ************************************************************************
   *
   *
   */
  const saveCandidates = () => {
    showProgress();

    updateCandidateBulk(changedCandidateList)
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Änderungen erfolgreich übernommen.");
          setCandidateList((prevState) =>
            prevState.map((oldCat) => {
              const replCat = changedCandidateList.find(
                (e) => e.id === oldCat.id
              );

              if (replCat) {
                return {
                  ...oldCat,
                  ...replCat,
                };
              }
              return oldCat;
            })
          );
          setChangedCandidateList([]);
          setShowSave(false);
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  /** ************************************************************************
   *
   *  P R O F E S S I O N
   *
   */
  const addToProfession = (id: number) => {
    if (showSave) {
      setSaveFirst(true);
      return;
    }
    if (candidatesToProfession.includes(id)) {
      setMasterCheckboxProfessionSelected(false); // one is not selected
      setCandidatesToProfession((prevState) =>
        prevState.filter((e) => e !== id)
      );
    } else {
      const candidate = candidateList.find((c) => c.id === id);
      if (
        candidate &&
        (!candidate.professionId ||
          (!candidate.testPending && !candidate.testDate))
      ) {
        setCandidatesToProfession((prevState) => [...prevState, id]);
      }
    }
    console.log(
      "%csrc/components/candidate/setCandidatesToProfession.tsx:145 setCand",
      "color: #007acc;",
      candidatesToProfession
    );
    setCandidatesToDelete([]);
    setCandidatesToMail([]);
    setMasterCheckboxDeleteSelected(false);
    setMasterCheckboxMailSelected(false);
  };

  /** ************************************************************************
   *
   *  B U L K M A I L
   *
   */
  const addToMail = (id: number) => {
    if (showSave) {
      setSaveFirst(true);
      return;
    }
    if (candidatesToMail.includes(id)) {
      setMasterCheckboxMailSelected(false); // one is not selected
      setCandidatesToMail((prevState) => prevState.filter((e) => e !== id));
    } else setCandidatesToMail((prevState) => [...prevState, id]);
    setCandidatesToDelete([]);
    setMasterCheckboxDeleteSelected(false);
    setCandidatesToProfession([]);
    setMasterCheckboxProfessionSelected(false);
  };

  const setCandidatesMailed = (addComment = false) => {
    console.log(
      "%csrc/components/candidate/CandidateSwitch.tsx:132 setCandidatesMAils addCOmment",
      "color: #007acc;",
      addComment
    );
    if (addComment) {
      setCandidateList((cands) =>
        cands.map((c) => {
          if (candidatesToMail.includes(c.id))
            return {
              ...c,
              comment: "Einladung verschickt. " + (c.comment || ""),
              mailLogs: c.mailLogs,
            };
          return c;
        })
      );
    }
    setCandidatesToMail([]);
    setMasterCheckboxMailSelected(false);
  };

  const [
    showConfirmDeleteCandidateSettings,
    setShowConfirmDeleteCandidateSettings,
  ] = useState(false);

  const [
    showDisableDisabilityNotPossible,
    setShowDisableDisabilityNotPossible,
  ] = useState(false);

  /** ************************************************************************
   *
   *  D E L E T E
   *
   */
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const addToDelete = (id: number) => {
    if (showSave) {
      setSaveFirst(true);
      return;
    }
    if (candidatesToDelete.includes(id)) {
      setMasterCheckboxDeleteSelected(false); // one is not selected
      setCandidatesToDelete((prevState) => prevState.filter((e) => e !== id));
    } else setCandidatesToDelete((prevState) => [...prevState, id]);
    setCandidatesToMail([]);
    setMasterCheckboxMailSelected(false);
    setCandidatesToProfession([]);
    setMasterCheckboxProfessionSelected(false);
  };

  const deleteCandidates = () => {
    showProgress();

    deleteCandidatesApi(candidatesToDelete).then((result) => {
      if (result.success) {
        openSnackbar(
          "success",
          "Die ausgewählten Teilnehmer wurden erfolgreich gelöscht."
        );
        setCandidateList((prevState) =>
          prevState.filter((e) => !candidatesToDelete.includes(e.id))
        );
        setCandidatesToDelete([]);
        setShowConfirmDelete(false);
      } else {
        console.log(
          "%cCandidateSwitch.tsx line:149 result.error",
          "color: #007acc;",
          result.error,
          typeof result.error
        );
        // if (typeof result.error === "string" && result.error.match(/bereits/i))
        //   setError("Keine Änderung möglich: Der Test läuft bereits!");
        if (typeof result.error === "object" && "error" in result.error) {
          setError(result.error["error"]);
        }
        //   if (
        //     typeof result.error["error"] === "string" &&
        //     result.error["error"].match(/bereits/i)
        //   )
        //     setError("Keine Änderung möglich: Der Test läuft bereits!");
        //   else setError(result.error);
        // }
        else setError(result.error);
      }
    });
  };

  const candidateWithStartedTestExists = candidateList.find(
    (c) => c.candidateSettings && (c.testPending || c.testDate)
  );

  return (
    <>
      <Box sx={{ width: "100%" }} className="candidate-table">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Typography
            variant="h1"
            sx={{
              pt: "5px",
              color: view === "candidates" ? "primary.main" : "primary.dark",
            }}
          >
            Teilnehmer/innen
          </Typography>
          <Tooltip
            title={
              candidateList.find((c) => c.testDate)
                ? ""
                : "Auswertung nicht verfügbar - keine absolvierten Tests"
            }
          >
            <Box>
              <Switch
                className="uni-switch"
                checked={view !== "candidates"}
                onChange={() =>
                  setView((p) =>
                    p === "candidates" ? "results" : "candidates"
                  )
                }
                value="checked"
                disabled={!candidateList.find((c) => c.testDate)}
              />
            </Box>
          </Tooltip>
          <Typography
            variant="h1"
            sx={{
              pt: "5px",
              color: view === "candidates" ? "primary.dark" : "primary.main",
            }}
          >
            Auswertung
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ maxWidth: "800px", gap: "10px" }}
          >
            <FormControl sx={{ m: 0, minWidth: 240 }}>
              <Select
                value={
                  selectedProfession > 0 || selectedProfession === -1
                    ? selectedProfession
                    : ""
                }
                onChange={(e: SelectChangeEvent<number>) => {
                  if (candidatesToDelete.length > 0) {
                    setShowConfirmDelete(true);
                    return;
                  }
                  console.log(
                    "%csrc/components/candidate/CandidateSwitch.tsx:235 e.target.value",
                    "color: #007acc;",
                    e.target.value
                  );
                  setSelectedProfession(e.target.value as number);
                }}
                displayEmpty={true}
                renderValue={(value) => {
                  console.log(
                    "%csrc/components/candidate/CandidateSwitch.tsx:239 value",
                    "color: #007acc;",
                    value
                  );
                  if (!value) return "Test wählen";
                  if (value === -1) return "Kein Test zugeordnet";
                  const p = professionList.find((p) => p.id === value);
                  return p ? p.name : "Test wählen";
                }}
                sx={{ height: "36px" }}
                key={"profession" + view}
              >
                {view === "candidates" && (
                  <MenuItem value={0}>
                    <Typography variant="body2">Alle</Typography>
                  </MenuItem>
                )}

                {professionList.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    <Typography variant="body2">{e.name}</Typography>
                  </MenuItem>
                ))}
                {view === "candidates" && (
                  <MenuItem value={-1}>
                    <Typography variant="body2">
                      Kein Test zugeordnet
                    </Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <SearchField
              value={filter}
              setValue={setFilter}
              id="candsearch"
              handleChangePage={handleChangePage}
              className="search-field-big"
            />
            {uploadedCandidateIds.length > 0 && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setUploadedCandidateIds([])}
                  sx={{
                    width: "244px",
                    height: "37px",
                    pl: "0 !important",
                    pr: "10px",
                  }}
                >
                  <Typography variant="body2">
                    Importierte Teilnehmer zeigen
                  </Typography>
                </Button>
                <Box
                  sx={{
                    position: "relative",
                    left: "-30px",
                    top: "-7px",
                    // border: "1px solid red",
                    cursor: "pointer",
                  }}
                  onClick={() => setUploadedCandidateIds([])}
                >
                  <CloseIcon
                    sx={{
                      fontSize: 12,
                      color: "#535353",
                      position: "absolute",
                    }}
                  ></CloseIcon>
                </Box>
              </>
            )}
            {/* NACHTEILSAUSGLEICH */}
            <Box>
              <FormControlLabel
                control={<Switch />}
                label="Nachteilsausgleich"
                checked={disability}
                onChange={() => {
                  if (disability) {
                    /* if any candidate has settings and already began the test, it's not allowed to delete the settings */
                    if (candidateWithStartedTestExists) {
                      setShowDisableDisabilityNotPossible(true);
                      return;
                    }
                    if (candidateList.find((c) => c.candidateSettings)) {
                      setShowConfirmDeleteCandidateSettings(true);
                      return;
                    }
                  }
                  setDisability((p) => !p);
                  updateSettings({ disability: !disability });
                  setAuthState((p) => ({
                    ...p,
                    customerSettings: {
                      ...p.customerSettings,
                      disability: !disability,
                    },
                  }));
                }}
              />
            </Box>{" "}
          </Box>
          <Box>
            {view === "candidates" && (
              <AddCandidate
                setFilter={setFilter}
                setUploadedCandidateIds={setUploadedCandidateIds}
              />
            )}
            {view !== "candidates" && (
              <>
                <Select
                  value={exportType}
                  onChange={(e: SelectChangeEvent<number>) => {
                    setExportType(e.target.value as number);
                  }}
                  sx={{ height: "36px", marginRight: "10px" }}
                >
                  <MenuItem value={0}>
                    <Typography variant="body2">Excel</Typography>
                  </MenuItem>
                  <MenuItem value={1}>
                    <Typography variant="body2">CSV</Typography>
                  </MenuItem>
                </Select>
                <Button
                  variant="contained"
                  onClick={() => setExportClicked(true)}
                >
                  Export
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
          className="jhojjjj"
        >
          {showSave && <SaveChanges onClick={saveCandidates} />}
          {candidatesToDelete.length > 0 && (
            <DeleteButton
              button={true}
              id={1}
              deleteFunc={() =>
                new Promise((resolve) => {
                  deleteCandidates();
                  resolve(1);
                })
              }
              confirmTitle="Teilnehmer löschen?"
              confirmContent="Sollen die ausgewählten Teilnehmer gelöscht werden?"
              sx={{
                position: "absolute",
                zIndex: 100, // modal = 1200
                top: 100,
                right: 32,
                width: 220,
                backgroundColor: "error.main",
              }}
            />
          )}
          <Box>
            {candidatesToMail.length > 0 && (
              <>
                <MailButton
                  button={true}
                  id={1}
                  candidates={
                    candidatesToMail.map((id) =>
                      candidateList.find((c) => c.id === id)
                    ) as any
                  }
                  successFunc={setCandidatesMailed}
                  sx={{
                    position: "absolute",
                    zIndex: 100, // modal = 1200
                    top: 100,
                    right: 32,
                    width: 220,
                    backgroundColor: "error.main",
                  }}
                />
              </>
            )}
          </Box>
          <Box>
            {candidatesToProfession.length > 0 && (
              <>
                <ProfessionButton
                  button={true}
                  id={1}
                  candidates={
                    candidatesToProfession.map((id) =>
                      candidateList.find((c) => c.id === id)
                    ) as any
                  }
                  successFunc={() => {
                    console.log(
                      "%csrc/components/🌶️/CandidateSwitch.tsx:470 ",
                      "color: #007acc;"
                    );
                    setCandidatesToProfession([]);
                    setMasterCheckboxProfessionSelected(false);
                  }}
                  sx={{
                    position: "absolute",
                    zIndex: 100, // modal = 1200
                    top: 100,
                    right: 32,
                    width: 220,
                    backgroundColor: "error.main",
                  }}
                />
              </>
            )}
          </Box>
        </Box>
        {view === "candidates" ? (
          <CandidateTable
            selectedProfession={selectedProfession}
            filter={filter}
            saveCandidates={saveCandidates}
            setShowSave={setShowSave}
            addToDelete={addToDelete}
            masterCheckboxDeleteSelected={masterCheckboxDeleteSelected}
            setMasterCheckboxDeleteSelected={setMasterCheckboxDeleteSelected}
            addToMail={addToMail}
            masterCheckboxMailSelected={masterCheckboxMailSelected}
            setMasterCheckboxMailSelected={setMasterCheckboxMailSelected}
            addToProfession={addToProfession}
            masterCheckboxProfessionSelected={masterCheckboxProfessionSelected}
            setMasterCheckboxProfessionSelected={
              setMasterCheckboxProfessionSelected
            }
            forceRerender={candidatesToMail.length}
            uploadedCandidateIds={uploadedCandidateIds}
          />
        ) : (
          <CandidateResultTable
            selectedProfession={selectedProfession}
            filter={filter}
            saveCandidates={saveCandidates}
            setShowSave={setShowSave}
            exportClicked={exportClicked}
            setExportClicked={setExportClicked}
            exportType={exportType}
          />
        )}
      </Box>
      {showConfirmDelete && (
        <ConfirmDialog
          open={showConfirmDelete}
          handleYes={() => {
            deleteCandidates();
          }}
          handleNo={() => setShowConfirmDelete(false)}
          title="Teilnehmer löschen?"
          content="Sollen die ausgewählten Teilnehmer gelöscht werden?"
          textYes="Löschen"
          textNo="Abbrechen"
        />
      )}
      {showConfirmDeleteCandidateSettings && (
        <ConfirmDialog
          open={showConfirmDeleteCandidateSettings}
          handleYes={() => {
            deleteAllCandidateSettings().then(() => {
              setShowConfirmDeleteCandidateSettings(false);
              setDisability(false);
              updateSettings({ disability: !disability });
              setAuthState((p) => ({
                ...p,
                customerSettings: {
                  ...p.customerSettings,
                  disability: !disability,
                },
              }));
              setCandidateList((p) => {
                return p.map((c) => {
                  if (c.candidateSettings) {
                    return {
                      ...c,
                      candidateSettings: null,
                    };
                  }
                  return c;
                });
              });
            });
          }}
          handleNo={() => setShowConfirmDeleteCandidateSettings(false)}
          title="Nachteilsausgleich entfernen?"
          content="Wollen Sie die eingegebenen Werte löschen und den Nachteilsausgleich für alle betroffenen Teilnehmer entfernen?"
          textYes="Ja, löschen"
          textNo="Abbrechen"
        />
      )}
      {showDisableDisabilityNotPossible && (
        <WarnDialog
          open={showDisableDisabilityNotPossible}
          handleOK={() => {
            setShowDisableDisabilityNotPossible(false);
          }}
          title="Nachteilsausgleich aktiv"
          content="Es wurden bereits Tests mit aktivem Nachteilsaugleich gestartet. Die Einstellungen für die betreffenden Teilnehmer können nachträglich nicht mehr geändert werden."
          contentSx={{ fontSize: "1rem" }}
        />
      )}
      {saveFirst && (
        <WarnDialog
          open={saveFirst}
          handleOK={() => setSaveFirst(false)}
          title="Ungespeicherte Änderungen"
          content="Bitte speichern oder entfernen Sie zunächst Ihre Änderungen, um fortzufahren."
          textOK="OK"
        />
      )}
    </>
  );
};

import PermDeviceInformationIcon from "@mui/icons-material/PermDeviceInformation";
import { Box, Popover } from "@mui/material";
import { useState } from "react";

interface InfoPopOverPropsI {
  id: number;
  list?: {
    id: number;
    name: string;
  }[];
}
export const InfoPopOver = ({ id, list = [] }: InfoPopOverPropsI) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  return (
    <>
      <PermDeviceInformationIcon
        onMouseEnter={(event: React.MouseEvent<SVGSVGElement>) =>
          setAnchorEl(event.currentTarget)
        }
        onMouseLeave={() => setAnchorEl(null)}
        id={"openInfo" + id}
        key={"openInfo" + id}
      />
      <Popover
        id={"info" + id}
        key={"info" + id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: "none",
        }}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        <Box sx={{ background: "lightgray", p: 5 }}>
          {list.length > 0
            ? list.map((s) => <ul key={s.id}>{s.name}</ul>)
            : " keine Kategorien zugeordnet"}
        </Box>
      </Popover>
    </>
  );
};

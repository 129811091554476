import { useEffect, useState } from "react";
import { jsonDecode } from "../shared/helper/json";

export type SelTreeT = {
  professionId: number;
  mainCategoryId: number;
  subCategoryId: number;
  professionIsActive: boolean;
};
export type SetSelTreeT = (props: Partial<SelTreeT>) => void;

type useSelTreeResultT = {
  selTree: SelTreeT;
  setSelTree: SetSelTreeT;
};

/* which profession/category was clicked on to choose this path in tree */
export const useSelTree = (): useSelTreeResultT => {
  const last = jsonDecode(localStorage.getItem("selTree") || "");

  const [selTree, setSelTree] = useState<SelTreeT>({
    professionId: last ? last.professionId : 0,
    mainCategoryId: last ? last.mainCategoryId : 0,
    subCategoryId: last ? last.subCategoryId : 0,
    professionIsActive: last ? last.professionIsActive : false,
  });

  const modSetSelTree = (props: Partial<SelTreeT>) => {
    setSelTree((p) => ({
      ...p,
      ...props,
    }));
  };

  useEffect(() => {
    localStorage.setItem("selTree", JSON.stringify(selTree));
  }, [selTree]);

  return { selTree, setSelTree: modSetSelTree };
};

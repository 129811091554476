import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BuildIcon from "@mui/icons-material/Build";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import { FileTable } from "./FileTable";
import { TabPanel, tabProps } from "../shared/TabPanel";

export const FileTabs = () => {
  const [actTab, setActTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={actTab} onChange={handleChange}>
          <Tab icon={<BuildIcon />} label="Berufe" {...tabProps(0)} />
          <Tab
            icon={<ViewAgendaIcon />}
            label="Hauptkategorien"
            {...tabProps(1)}
          />
          <Tab
            icon={<AssignmentIcon />}
            label="Hauptinhalte"
            {...tabProps(2)}
          />
          <Tab
            icon={<MusicVideoIcon />}
            label="Mediendateien"
            {...tabProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={actTab} index={0}>
        <FileTable type="profession" />
      </TabPanel>
      <TabPanel value={actTab} index={1}>
        <FileTable type="maincategory" />
      </TabPanel>
      <TabPanel value={actTab} index={2}>
        <FileTable type="content" />
      </TabPanel>
      <TabPanel value={actTab} index={3}>
        <FileTable type="media" />
      </TabPanel>
    </Box>
  );
};

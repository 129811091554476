import React, { useState, useContext, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { CandidateEntry } from "./CandidateEntry";
import { CandidateT } from "../candidate/api";
import { CandidateContext } from "../candidate/CandidateContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccessibleIcon from "@mui/icons-material/Accessible";

import {
  EnhancedTableHead,
  HeadCellT,
  stableSort,
  getComparator,
  OrderDirT,
  handleRequestSort,
  NonOptionalKeys,
  ComparatorT,
} from "../shared/table/EnhancedTable";
import { usePagination } from "../shared/hooks/usePagination";
import { useNavigation } from "./results/useNavigation";
import Box from "@mui/material/Box";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { AuthContext } from "../auth/AuthContext";

interface MasterCheckBoxPropsI {
  onSelect: (checked: boolean) => void;
  selected?: boolean;
}
const MasterCheckbox = ({ onSelect, selected }: MasterCheckBoxPropsI) => {
  return (
    <input
      type="checkbox"
      onChange={(e) => onSelect(e.target.checked)}
      checked={selected}
    />
  );
};

interface CandidateTablePropsI {
  selectedProfession: number;
  filter: string;
  saveCandidates: () => void;
  setShowSave: (show: boolean) => void;
  addToDelete: (id: number) => void;
  masterCheckboxDeleteSelected: boolean;
  setMasterCheckboxDeleteSelected: (val: boolean) => void;
  addToMail: (id: number) => void;
  masterCheckboxMailSelected: boolean;
  setMasterCheckboxMailSelected: (val: boolean) => void;
  addToProfession: (id: number) => void;
  masterCheckboxProfessionSelected: boolean;
  setMasterCheckboxProfessionSelected: (val: boolean) => void;
  forceRerender: number;
  uploadedCandidateIds: number[];
}

export const CandidateTable = ({
  selectedProfession,
  filter,
  setShowSave,
  addToDelete,
  masterCheckboxDeleteSelected,
  setMasterCheckboxDeleteSelected,
  addToMail,
  masterCheckboxMailSelected,
  setMasterCheckboxMailSelected,
  addToProfession,
  masterCheckboxProfessionSelected,
  setMasterCheckboxProfessionSelected,
  forceRerender,
  uploadedCandidateIds,
}: CandidateTablePropsI) => {
  const {
    candidateList,
    setCandidatesToMail,
    setCandidatesToDelete,
    setCandidatesToProfession,
  } = useContext(CandidateContext);

  const [orderDir, setOrderDir] = useState<OrderDirT>("asc");
  const [orderBy, setOrderBy] = useState<NonOptionalKeys<CandidateT>>("email");
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination("candidate");
  useNavigation("remove");

  useEffect(() => {
    setPage(0);
  }, [filter, selectedProfession]);

  const comparator: ComparatorT<CandidateT> = getComparator(orderDir, orderBy);

  const { authState } = useContext(AuthContext);
  const disability = authState.customerSettings?.disability || false;

  console.log("uploadedCandidateIds", uploadedCandidateIds);

  const filteredCandidateList = candidateList
    .filter(
      (c) =>
        (uploadedCandidateIds.length === 0 ||
          uploadedCandidateIds.indexOf(c.id) >= 0) &&
        ((c.firstname || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          (c.lastname || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          (c.email || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0) &&
        (selectedProfession === 0 ||
          c.professionId === selectedProfession ||
          (selectedProfession === -1 && !c.professionId))
    )
    .map((c) => {
      return {
        ...c,
        resultSumPercent: c.results
          ? c.results.sumPoints > 0
            ? (c.results.sumPointsCorrect / c.results.sumPoints) * 100
            : 0
          : -1,
      };
    });

  const onSelectMasterCheckboxMail = (val: boolean) => {
    console.log(
      "%csrc/components/candidate/Candid💌ateTable.tsx:106 val",
      "color: #007acc;",
      val
    );
    setMasterCheckboxMailSelected(val);
    setMasterCheckboxDeleteSelected(false);
    setMasterCheckboxProfessionSelected(false);
    filteredCandidateList.forEach((c) => {
      if (val) setCandidatesToMail((prevState) => [...prevState, c.id]);
      else
        setCandidatesToMail((prevState) => prevState.filter((e) => e !== c.id));
    });
    setCandidatesToDelete([]);
    setCandidatesToProfession([]);
  };
  const onSelectMasterCheckboxProfession = (val: boolean) => {
    console.log(
      "%csrc/components/candidate/Candid💌ateTable.tsx:106 val",
      "color: #007acc;",
      val
    );
    setMasterCheckboxProfessionSelected(val);
    setMasterCheckboxMailSelected(false);
    setMasterCheckboxDeleteSelected(false);
    filteredCandidateList.forEach((c) => {
      if (c.testDate || c.testPending) return;
      if (val) setCandidatesToProfession((prevState) => [...prevState, c.id]);
      else
        setCandidatesToProfession((prevState) =>
          prevState.filter((e) => e !== c.id)
        );
    });
    setCandidatesToMail([]);
    setCandidatesToDelete([]);
  };
  const onSelectMasterCheckboxDelete = (val: boolean) => {
    setMasterCheckboxDeleteSelected(val);
    setMasterCheckboxMailSelected(false);
    setMasterCheckboxProfessionSelected(false);
    filteredCandidateList.forEach((c) => {
      if (val) setCandidatesToDelete((prevState) => [...prevState, c.id]);
      else
        setCandidatesToDelete((prevState) =>
          prevState.filter((e) => e !== c.id)
        );
    });
    setCandidatesToMail([]);
    setCandidatesToProfession([]);
  };

  const headCells: HeadCellT<CandidateT>[] = [
    {
      id: "lastname",
      disablePadding: false,
      label: "Nachname",
    },
    {
      id: "firstname",
      disablePadding: true,
      label: "Vorname",
    },
    { id: "dateBirth", disablePadding: true, label: "Geburtsdatum" },
    { id: "gender", disablePadding: true, label: "Anrede", sortable: false },
    { id: "email", disablePadding: true, label: "E-Mail" },
    {
      id: "comment",
      disablePadding: true,
      label: "Bemerkungen",
      sortable: false,
    },
    {
      id: "mailId",
      disablePadding: true,
      sortable: false,
      label: (
        <>
          Mailversand
          <br />
          <Box sx={{ display: "flex", gap: "3px" }}>
            <Box sx={{ fontSize: "small" }}>alle</Box>{" "}
            <MasterCheckbox
              onSelect={onSelectMasterCheckboxMail}
              selected={masterCheckboxMailSelected}
            />
          </Box>
        </>
      ),
    },
    {
      id: "professionName",
      disablePadding: true,
      sortable: false,
      label: (
        <>
          Test
          <br />
          <Box sx={{ display: "flex", gap: "3px" }}>
            <Box sx={{ fontSize: "small" }}>alle</Box>{" "}
            <MasterCheckbox
              onSelect={onSelectMasterCheckboxProfession}
              selected={masterCheckboxProfessionSelected}
            />
          </Box>
        </>
      ),
    },
    { id: "resultSumPercent", disablePadding: true, label: "Ergebnis" },
    { id: "testDate", disablePadding: true, label: "Testdatum" },
    { id: "uuid", disablePadding: true, label: "Einladungslink" },
    {
      id: "id",
      disablePadding: true,
      label: (
        <>
          <DeleteOutlineIcon />
          <br />
          <Box sx={{ display: "flex", gap: "3px" }}>
            <Box sx={{ fontSize: "small" }}>alle</Box>{" "}
            <MasterCheckbox
              onSelect={onSelectMasterCheckboxDelete}
              selected={masterCheckboxDeleteSelected}
            />
          </Box>
        </>
      ),
      sortable: false,
    },
  ];

  if (disability) {
    const emailIndex = headCells.findIndex((cell) => cell.id === "email");
    headCells.splice(emailIndex + 1, 0, {
      id: "candidateSettingsId",
      disablePadding: true,
      label: <AccessibleIcon />,
    });
  }

  return (
    <>
      <TableContainer
        sx={{
          height: "calc(100vh - 335px)",
        }}
        data-testid="scrollableBox"
      >
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          className="enhanced-table"
        >
          <EnhancedTableHead
            headCells={headCells.filter(
              (c) => selectedProfession <= 0 || c.id !== "professionName"
            )}
            orderDir={orderDir}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(
                event,
                property,
                orderDir,
                setOrderDir,
                orderBy,
                setOrderBy
              )
            }
            rowCount={filteredCandidateList.length}
          />
          <TableBody className="enhanced-table-body pr">
            {stableSort<CandidateT>(filteredCandidateList, comparator)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((candidate) => {
                return (
                  <CandidateEntry
                    candidate={candidate}
                    key={candidate.id}
                    setShowSave={setShowSave}
                    showProfession={selectedProfession <= 0}
                    addToDelete={addToDelete}
                    addToMail={addToMail}
                    addToProfession={addToProfession}
                    forceRerender={forceRerender}
                    disability={disability}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCandidateList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
        labelRowsPerPage="Einträge anzeigen:"
      />
    </>
  );
};

import React, { createContext, useState, ReactElement } from "react";
import { UserSettingsT, CustomerSettingsT } from "./api";

export type AuthStateT = {
  loggedIn: boolean;
  token: string;
  role: string;
  user?: any;
  settings?: UserSettingsT;
  customerSettings?: CustomerSettingsT;
};

export const buildAuthState = (
  loggedIn = false,
  token = "",
  role = "",
  user: any = {},
  settings: UserSettingsT = {},
  customerSettings: CustomerSettingsT = { disability: false }
): AuthStateT => {
  return { loggedIn, token, role, user, settings, customerSettings };
};

export type SetAuthStateI = React.Dispatch<React.SetStateAction<AuthStateT>>;

type AuthContextT = {
  authState: AuthStateT;
  setAuthState: SetAuthStateI;
  user?: any;
};

export const initialAuthState: AuthStateT = {
  loggedIn: false,
  role: "",
  token: "",
};

export const AuthContext = createContext<AuthContextT>({
  authState: initialAuthState,
  setAuthState: () => {},
});

interface AuthProviderPropsI {
  children: ReactElement;
}

export const AuthProvider = ({
  children,
}: AuthProviderPropsI): ReactElement => {
  const [authState, setAuthState] = useState<AuthStateT>(initialAuthState);

  return (
    <AuthContext.Provider
      value={{
        authState: authState,
        setAuthState: setAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { useFiles } from "./useFiles";
import { FileTabs } from "./FileTabs";
import { Loader } from "../shared/Loader";

export const FileLoader = () => {
  const { loadingDone } = useFiles({ type: "profession" });

  if (!loadingDone) return <Loader />;

  return <FileTabs />;
};

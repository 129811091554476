import { Loader } from "../../shared/Loader";
import { AdminDashboard } from "./AdminDashboard";
import { useProfessions } from "../../profession/useProfessions";
import { useSystemHealthCheck } from "../../systemHealthCheck/useSystemHealthCheck";

export const AdminDashboardLoader = () => {
  const { professionList, loadingDone } = useProfessions({ check: false });
  const { loadingDone: loadingDoneSHC } = useSystemHealthCheck({});

  if (!loadingDone || !loadingDoneSHC) return <Loader />;
  return <AdminDashboard professionList={professionList} />;
};

import { isEmpty } from "lodash";
import React, { createContext, useEffect, useState } from "react";

type ContextPropsT = {
  error: any;
  setError: React.Dispatch<React.SetStateAction<string>>;
};

export const ErrorContext = createContext<ContextPropsT>({
  error: {},
  setError: () => {},
});

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<any>({});

  useEffect(() => {
    if (!isEmpty(error)) console.error(error);
  }, [error]);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

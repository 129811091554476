import React, { createContext, useState } from "react";
import { CountryT } from "./api";

type ContextPropsType = {
  countryList: CountryT[];
  setCountryList: React.Dispatch<React.SetStateAction<CountryT[]>>;
  loadingDone: boolean;
  setLoadingDone: React.Dispatch<React.SetStateAction<boolean>>;
};

export const countryContextDefaults: ContextPropsType = {
  countryList: [],
  setCountryList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
};

export const CountryContext = createContext<ContextPropsType>(
  countryContextDefaults
);
export const CountryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [countryList, setCountryList] = useState<CountryT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <CountryContext.Provider
      value={{
        countryList,
        setCountryList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

if (import.meta.env.VITE_SENTRY === "1") {
  Sentry.init({
    dsn: "https://83da5d51ac9649e2a04e82df19228a2f@o1075143.ingest.sentry.io/6075392",
    integrations: [new Integrations.BrowserTracing()],
    environment: import.meta.env.VITE_ENVIRONMENT || "local",
    release:
      "degeba@" +
        import.meta.env.VITE_GITDATE +
        "-" +
        import.meta.env.VITE_GITVERSION || "",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}

const container = document.getElementById("root");
/**
 * ! after container
 * That's the non-null assertion operator. It is a way to tell the compiler
 * "this expression cannot be null or undefined here,
 * so don't complain about the possibility of it being null or undefined."
 */
/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary showDialog>
    <App />
  </Sentry.ErrorBoundary>
);

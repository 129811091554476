import { useState, useContext, useEffect } from "react";
import { FeedbackContext } from "../../feedback/FeedbackContext";

export const useLoadDataTimeout = (duration: number, endCondition: boolean) => {
  const [timedOut, setTimedOut] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);
  const { hideProgress } = useContext(FeedbackContext);

  useEffect(() => {
    const id = window.setTimeout(() => {
      setTimedOut(true);
      hideProgress();
    }, duration * 1000);
    setTimeoutId(id);
  }, [setTimedOut, hideProgress, duration]);

  useEffect(() => {
    if (!endCondition) return;
    window.clearTimeout(timeoutId);
    setTimeoutId(0);
  }, [endCondition, timeoutId]);

  return { timedOut };
};

import { Button } from "@mui/material";
import { buttonLikeSearchBox } from "../shared/styles/sx";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import { Tooltip } from "../shared/forms/Tooltip";

interface VersionsButtonI {
  showVersions: boolean;
  setShowVersions: React.Dispatch<React.SetStateAction<boolean>>;
  showText?: boolean;
}

export const VersionsButton = ({
  showVersions,
  setShowVersions,
  showText = true,
}: VersionsButtonI) => {
  return (
    <Tooltip
      title={showVersions ? "Versionen ausblenden" : "Versionen anzeigen"}
    >
      <Button
        variant="outlined"
        sx={buttonLikeSearchBox}
        startIcon={<SyncLockIcon />}
        onClick={() => setShowVersions((p) => !p)}
      >
        {showText &&
          (showVersions ? "Versionen ausblenden" : "Versionen anzeigen")}
      </Button>
    </Tooltip>
  );
};

import React, { useContext, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { SearchField } from "../shared/forms/SearchField";
import Grid from "@mui/material/Grid";

import { useFiles } from "./useFiles";
import { File } from "./File";
import {
  EnhancedTableHead,
  HeadCellT,
  stableSort,
  getComparator,
  OrderDirT,
  handleRequestSort,
  NonOptionalKeys,
  ComparatorT,
} from "../shared/table/EnhancedTable";
import { usePagination } from "../shared/hooks/usePagination";
import { FileUpload } from "./FileUpload";

import Box from "@mui/material/Box";
import SaveChanges from "../shared/list/SaveChanges";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { updateFileBulk, FileT } from "./api";
import { ErrorContext } from "../error/ErrorContext";
import { FileContext } from "./FileContext";
import { useSearch } from "../shared/hooks/useSearch";
import { useShowSave } from "../shared/hooks/useShowSave";

const headCells: HeadCellT<FileT>[] = [
  {
    id: "name",
    disablePadding: true,
    label: "Name",
  },
  {
    id: "size",
    disablePadding: true,
    label: "Größe",
  },
  {
    id: "updatedAt",
    disablePadding: true,
    label: "Letzte Änderung",
  },
  { id: "id", label: "Optionen", disablePadding: true },
];

interface FileTablePropsI {
  type: string;
}

export const FileTable = ({ type }: FileTablePropsI) => {
  const [orderDir, setOrderDir] = useState<OrderDirT>("asc");
  const [orderBy, setOrderBy] = useState<NonOptionalKeys<FileT>>("name");
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("file");
  const [filter, setFilter] = useSearch("filesearch");
  const [showSave, setShowSave] = useShowSave();
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const { changedFileList, setChangedFileList } = useContext(FileContext);
  const { setError } = useContext(ErrorContext);

  const { fileList, setFileList } = useFiles({ type });
  if (fileList.length === 0)
    return (
      <FileUpload type={type} fileList={fileList} setFileList={setFileList} />
    );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, fileList.length - page * rowsPerPage);

  const comparator: ComparatorT<FileT> = getComparator(orderDir, orderBy);

  const filteredFileList = fileList.filter(
    (e) => e.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
  );

  /** ************************************************************************
   *
   *
   */
  const saveUsers = () => {
    showProgress();

    updateFileBulk(changedFileList)
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setFileList((prevState) =>
            prevState.map((oldFile) => {
              const replFile = changedFileList.find((e) => e.id === oldFile.id);
              if (replFile)
                replFile.filePath = replFile.type + "/" + replFile.name;

              if (replFile) {
                return {
                  ...oldFile,
                  ...replFile,
                };
              }
              return oldFile;
            })
          );

          setChangedFileList([]);
          setShowSave(false);
        } else {
          if (result.errors) {
            setError(JSON.stringify(result.errors));
          }
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Box>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid item>
          <SearchField
            value={filter}
            setValue={setFilter}
            id="filesearch"
            handleChangePage={handleChangePage}
          />
          {showSave && <SaveChanges onClick={saveUsers} />}
        </Grid>
        <Grid item>
          <FileUpload
            type={type}
            fileList={fileList}
            setFileList={setFileList}
          />
        </Grid>
      </Grid>

      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={headCells}
            orderDir={orderDir}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(
                event,
                property,
                orderDir,
                setOrderDir,
                orderBy,
                setOrderBy
              )
            }
            rowCount={filteredFileList.length}
          />
          <TableBody>
            {stableSort<FileT>(filteredFileList, comparator)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((file, index) => {
                return (
                  <File
                    key={file.id}
                    file={file}
                    setShowSave={setShowSave}
                    setChangedFileList={setChangedFileList}
                  />
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredFileList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
      />
    </Box>
  );
};

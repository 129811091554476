import { useContext, useState } from "react";
import {
  fetchCandidates,
  CandidateT,
  fetchSchoolGraduations,
  fetchCandidate,
} from "./api";
import { CandidateContext } from "./CandidateContext";

import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

interface UseCandidatesResultT extends UseResultT {
  candidateList: CandidateT[];
  setCandidateList: React.Dispatch<React.SetStateAction<CandidateT[]>>;
}

interface UseCandidatesPropsI extends UsePropsI {
  reloadResultsForCandidateId?: number;
}

export const useCandidates = ({
  dontHideProgress = false,
  reloadResultsForCandidateId = 0,
}: UseCandidatesPropsI): UseCandidatesResultT => {
  const {
    candidateList,
    setCandidateList,
    loadingDone,
    setLoadingDone,
    schoolGraduationList,
    setSchoolGraduationList,
  } = useContext(CandidateContext);

  useLoadData({
    fetchFunc: fetchCandidates,
    list: candidateList,
    setList: setCandidateList,
    setLoadingDone,
    dontHideProgress,
    omitLoadIfDefined: true,
    name: "fetchCandidates",
  });

  const [loadingDoneSchoolGraduations, setLoadingDoneSchoolGraduations] =
    useState(false);

  useLoadData({
    fetchFunc: fetchSchoolGraduations,
    list: schoolGraduationList,
    setList: setSchoolGraduationList,
    setLoadingDone: setLoadingDoneSchoolGraduations,
    dontHideProgress,
    omitLoadIfDefined: true,
    name: "fetchSchoolGraduations",
  });

  return {
    candidateList,
    setCandidateList,
    loadingDone: loadingDone && loadingDoneSchoolGraduations,
  };
};

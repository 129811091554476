import React, { createContext, useState } from "react";
import { CandidateResultsT } from "../candidate/api";
import { ProfessionT } from "./api";

type ContextPropsType = {
  professionList: ProfessionT[];
  setProfessionList: React.Dispatch<React.SetStateAction<ProfessionT[]>>;
  loadingDone: boolean;
  setLoadingDone: React.Dispatch<React.SetStateAction<boolean>>;
  testResultList: CandidateResultsT;
  setTestResultList: React.Dispatch<React.SetStateAction<CandidateResultsT>>;
};

export interface ProfessionChangedT extends ProfessionT {
  error?: boolean;
}

const emptyResult = {
  mainCategories: [],
  countCorrect: 0,
  countTotal: 0,
  sumPoints: 0,
  sumPointsCorrect: 0,
  concentrationCorrect: 0,
  concentrationTotal: 0,
  concentrationPoints: 0,
  concentrationPointsCorrect: 0,
  professionTarget: 0,
};

export const ProfessionContext = createContext<ContextPropsType>({
  professionList: [],
  setProfessionList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
  testResultList: emptyResult,
  setTestResultList: () => {},
});
export const ProfessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [professionList, setProfessionList] = useState<ProfessionT[]>([]);
  const [testResultList, setTestResultList] =
    useState<CandidateResultsT>(emptyResult);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <ProfessionContext.Provider
      value={{
        professionList,
        setProfessionList,
        loadingDone,
        setLoadingDone,
        testResultList,
        setTestResultList,
      }}
    >
      {children}
    </ProfessionContext.Provider>
  );
};

import { Card, Grid, Typography } from "@mui/material";
import { mm_ss } from "../../shared/helper/datetime";
import { ShowButton } from "../../shared/ShowButton";
import { CandidateResultsT } from "../api";
import { Navigation } from "./Navigation";
import { ResultNumbers } from "./ResultNumbers";
import { getMainIdx } from "./ResultsLoader";
import { ResultsQuestionList } from "./ResultsQuestionList";
import { useNavigation } from "./useNavigation";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ResultPercent } from "./ResultPercent";

interface ResultsSubPropsI {
  mainCategoryId: number;
  results: CandidateResultsT;
  setShowMainCategoryId: React.Dispatch<React.SetStateAction<number>>;
}

/* Results on level of sub-categories */
export const ResultsSubList = ({
  mainCategoryId,
  setShowMainCategoryId,
  results,
}: ResultsSubPropsI) => {
  const { getNavigation, setNavigation } = useNavigation("sub");

  const mainIdx = getMainIdx(results, mainCategoryId);
  const subs =
    mainIdx >= 0 ? results.mainCategories[mainIdx].subCategories : null;

  return (
    <>
      {subs?.map((sub) => (
        <Grid container key={sub.subCategoryId} className="results-sub-list">
          <Grid item xs={4} className="results-arrow-name-container">
            <div
              className="drop-arrow"
              onClick={() => setNavigation(sub.subCategoryId)}
            >
              {getNavigation(sub.subCategoryId) ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowRightIcon />
              )}
            </div>
            <Typography
              variant="body2"
              component="div"
              className="sub-category-name"
            >
              {sub.subCategoryName}
            </Typography>
          </Grid>
          <Grid item xs={8} className="result-numbers">
            <ResultNumbers
              correct={sub.countCorrect}
              incorrect={sub.countTotal - sub.countCorrect}
            />
            <ResultPercent
              sumPoints={sub.sumPoints}
              sumPointsCorrect={sub.sumPointsCorrect}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          {getNavigation(sub.subCategoryId) && (
            <ResultsQuestionList
              mainCategoryId={mainCategoryId}
              subCategoryId={sub.subCategoryId}
              setShowMainCategoryId={setShowMainCategoryId}
              setShowSubCategoryId={(id) => setNavigation(sub.subCategoryId)}
              results={results}
            />
          )}
        </Grid>
      ))}
    </>
    // )}
    // </>
  );
};

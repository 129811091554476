import { useContext, Fragment, useEffect, useState } from "react";
import { LSKEYS, TestModusContext } from "./TestModusContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Typography, Box } from "@mui/material";
import { logCandidate } from "./api";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { CustomerHead } from "../dashboard/candidate/CustomerHead";
import { addBodyClass, removeBodyClass } from "../shared/lib/useBodyClass";
import { DegebaLogo } from "../dashboard/candidate/DegebaLogo";
import { LayoutContext } from "./LayoutContext";
import { AuthContext } from "../auth/AuthContext";
import { getUnixTimestamp, mm_ss } from "../shared/helper/datetime";
import { TestTimer } from "./TestTimer";
import { unUglifyTimestamp } from "../shared/helper/uglify";

export const TestModusDashboard = () => {
  const {
    started,
    setStarted,
    finished,
    questionList,
    questionNo,
    setActMainCategory,
    profession,
    mainCategoryInfo,
    timeTotal,
    setTimeTotal,
    startTimerTotal,
    timeoutTotal,
    candidateSettings,
    timePause,
    startTimerPause,
    stopTimerPause,
    stopTimerTotal,
  } = useContext(TestModusContext);

  const { layout } = useContext(LayoutContext);
  const { authState } = useContext(AuthContext);

  const [isPaused, setIsPaused] = useState(
    localStorage.getItem(LSKEYS.IS_PAUSED) ? true : false
  );

  addBodyClass("start");

  const dup: { [n: number]: boolean } = {};

  const stopTimerPauseResumeTimerTotal = () => {
    setIsPaused(false);
    stopTimerPause();
    startTimerTotal(timeTotal, timePause);
    // must be here not in effect to take place immediately
    localStorage.removeItem(LSKEYS.IS_PAUSED);
  };

  const onClickStart = () => {
    if (!started) {
      if (!authState.user.demo) {
        logCandidate("test-begin");
      }
      startTimerTotal();
      setStarted(true);
    }

    if (isPaused) {
      stopTimerPauseResumeTimerTotal();
    }

    setActMainCategory(questionList[questionNo].mainCategoryId);

    removeBodyClass("start");
  };

  /**
   * TIME_ACT stores time of actual subcat
   * so it will be removed on Dashboard
   */
  useEffect(() => {
    localStorage.removeItem(LSKEYS.TIME_ACT);
    /** every 10 seconds we check for rounding differences / problems with milliseconds
     * and correct them regarding the time in local storage
     */

    const timeAct = localStorage.getItem(LSKEYS.TIME_START);
    if (timeAct) {
      let newTime = getUnixTimestamp() - unUglifyTimestamp(timeAct);
      console.log(
        "correction 🍺🍺",
        name,
        "timeAct:",
        new Date(unUglifyTimestamp(timeAct)).toLocaleTimeString(),
        "timeNOW:",
        new Date(Date.now()).toLocaleTimeString(),
        "noround:",
        getUnixTimestamp() - unUglifyTimestamp(timeAct),
        ", timeTotal:",
        timeTotal,
        "newTime:",
        newTime,
        "pauseTime:",
        timePause,
        "set to",
        newTime - timePause
      );
      if (newTime - timePause != timeTotal) setTimeTotal(newTime - timePause);
    }
  }, []);

  useEffect(() => {
    if (candidateSettings.compensationPause && isPaused) {
      if (timePause > candidateSettings.compensationPause * 60) {
        stopTimerPauseResumeTimerTotal();
      }
    }
  }, [timePause]);

  useEffect(() => {
    /* mobile */
    const candidate = document.getElementsByClassName("candidate")[0];
    if (candidate && typeof candidate.scrollTo === "function")
      candidate.scrollTo(0, 0);
    /* desktop */
    const main = document.getElementById("main");
    if (main && typeof main.scrollTo === "function") main.scrollTo(0, 0);
  }, [questionNo]);

  return (
    <>
      <Box className="candidate-category-overview" sx={{}}>
        <CustomerHead />
        <Box
          id="main"
          className="category-overview-container"
          data-testid="category-overview-container"
          sx={{ mt: 3 }}
        >
          <Box className="category-overview-box-left-container">
            {!finished && (
              <>
                {(candidateSettings.compensationPause > 0 ||
                  candidateSettings.compensationPercent > 0) && (
                  <Box
                    className="category-overview-box-left"
                    sx={{ fontWeight: 600 }}
                  >
                    Sie erhalten einen Nachteilsausgleich:&nbsp;
                    {candidateSettings.compensationPercent > 0 && (
                      <>
                        verlängerte Bearbeitungszeit
                        {!candidateSettings.compensationPause && "."}
                      </>
                    )}
                    {candidateSettings.compensationPause > 0 &&
                      candidateSettings.compensationPercent > 0 &&
                      ", "}
                    {candidateSettings.compensationPause > 0 && (
                      <>
                        {candidateSettings.compensationPause} Minute
                        {candidateSettings.compensationPause > 1 ? "n" : ""}
                        &nbsp;Pause. Die Pausenzeit können Sie frei einteilen
                        und nach dem Abschluss jeder Hauptkategorie über den
                        „Pause“-Button abrufen.
                      </>
                    )}
                  </Box>
                )}
                <Box
                  className="category-overview-box-left"
                  sx={{ fontWeight: 600 }}
                >
                  {authState.user.demo ? (
                    <>
                      <b>Willkommen bei degeba online!</b>
                      <br />
                      <br />
                      {!started && (
                        <>
                          Klicken Sie auf „Test starten“, um einen Demo-Test zu
                          beginnen.
                          <br />
                          <br />
                        </>
                      )}
                      Sie haben Fragen zu unserem Angebot? Sprechen Sie uns
                      gerne an:
                      <br />
                      <br />
                      <a href="mailto:kundenbetreuung@degeba.de">
                        kundenbetreuung@degeba.de
                      </a>
                      <br />
                      Tel.: +49 (69) 94 94 64 70 (Mo–Fr, 9–18 Uhr)
                    </>
                  ) : (
                    <>
                      Bitte arbeiten Sie den Test zügig durch. Nach dem Klick
                      auf „Test starten“ kann die Prüfung nicht noch einmal
                      begonnen werden.
                    </>
                  )}
                  <br />
                </Box>
              </>
            )}
            {!finished && (
              <Box
                className="category-overview-box-left"
                sx={{ fontSize: "1.25rem" }}
              >
                {profession.duration && (
                  <>
                    <AccessTimeIcon sx={{ fontSize: 50, mt: 4, mb: "5px" }} />
                    <br />
                    Bearbeitungszeit
                    <br />
                    <Box sx={{ fontSize: "4rem" }}>
                      {mm_ss(
                        Math.ceil(Math.round(profession.duration - timeTotal))
                      )}
                    </Box>
                    Minuten
                    <br />
                  </>
                )}
                <br />
              </Box>
            )}
            {finished ? (
              <Box className="category-overview-box-left bold finished">
                {authState.user.demo ? (
                  <>
                    <b>Demo-Test abgeschlossen!</b>
                    <br />
                    <br />
                    Sie haben Fragen zu unserem Angebot? Sprechen Sie uns gerne
                    an:
                    <br />
                    <br />
                    <a href="mailto:kundenbetreuung@degeba.de">
                      kundenbetreuung@degeba.de
                    </a>
                    <br />
                    Tel.: +49 (69) 94 94 64 70 (Mo–Fr, 9–18 Uhr)
                  </>
                ) : timeoutTotal ? (
                  <>
                    Die Bearbeitungszeit ist abgelaufen und der Test wurde
                    automatisch beendet. Ihre Ergebnisse wurden erfolgreich
                    gespeichert. Vielen Dank!
                  </>
                ) : (
                  <>
                    Vielen Dank!
                    <br />
                    Sie haben den Test abgeschlossen und Ihre Ergebnisse wurden
                    erfolgreich gespeichert. Sie können dieses Fenster nun
                    schließen.
                  </>
                )}
              </Box>
            ) : (
              <>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1,

                      border: "1px solid green",
                    }}
                    onClick={() => onClickStart()}
                    data-testid="next-step-button"
                  >
                    {started ? "weiter" : "Test starten"}
                  </Button>
                </Box>
                {candidateSettings.compensationPause > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    {started &&
                    timePause < candidateSettings.compensationPause * 60 ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (isPaused) {
                            stopTimerPauseResumeTimerTotal();
                          } else {
                            setIsPaused(true);
                            startTimerPause(timePause);
                            stopTimerTotal();
                          }
                        }}
                        color={isPaused ? "error" : "primary"}
                      >
                        {isPaused || timePause > 0 ? (
                          <>
                            Pause&nbsp;
                            <TestTimer
                              time={timePause}
                              duration={
                                candidateSettings.compensationPause * 60
                              }
                              direction="down"
                            />
                          </>
                        ) : (
                          "Pause machen"
                        )}
                      </Button>
                    ) : (
                      <Button variant="contained" disabled>
                        Pause machen
                      </Button>
                    )}
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box className="category-overview-box-right">
            {questionList.map((q, idx) => {
              if (dup[q.mainCategoryId]) return null;
              dup[q.mainCategoryId] = true;
              const imgData = mainCategoryInfo[q.mainCategoryId].imgData;

              return (
                <Box
                  key={q.mainCategoryId + q.id}
                  sx={{}}
                  className={
                    "category-overview-box " +
                    (idx < questionNo || (finished && !timeoutTotal)
                      ? "done"
                      : idx > questionNo
                      ? "std"
                      : "next")
                  }
                >
                  {/* raw data of svgs, so styles can be overwritten by css */}
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: imgData,
                    }}
                  />

                  <Box sx={{ flexGrow: 1, height: 50, pl: 2, pt: "12px" }}>
                    <Typography variant="h2">{q.mainCategoryName}</Typography>
                  </Box>
                  <Box sx={{ mt: 0.4, mr: 2 }}>
                    {(idx < questionNo || (finished && !timeoutTotal)) && (
                      <CheckCircleIcon sx={{ fontSize: 30 }} />
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      {layout && layout.logo && (
        <DegebaLogo className="start degeba-logo-trans" />
      )}
    </>
  );
};

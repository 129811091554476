import { getCurrentDomain } from "../shared/helper/url";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CandidateT } from "./api";
import { useContext, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextField from "@mui/material/TextField";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { Grid } from "@mui/material";
import { FeedbackContext } from "../feedback/FeedbackContext";

interface CandidateLinkPropsI {
  candidate: CandidateT;
}

export const CandidateLink = ({ candidate }: CandidateLinkPropsI) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { openSnackbar } = useContext(FeedbackContext);

  const textAreaRef = useRef<HTMLInputElement>(null);

  const handleClose = (reason: string) => {
    setOpen(false);
  };

  const domain = import.meta.env.VITE_TESTURL || getCurrentDomain();

  return (
    <>
      <TooltipIcon
        type="link"
        title={
          candidate.professionId > 0
            ? "Einladungslink anzeigen"
            : "kein Link verfügbar - kein Test ausgewählt"
        }
        onClick={() => setOpen(candidate.professionId > 0)}
        iconProps={{
          ...(candidate.professionId > 0
            ? { "data-testid": "CandidateLink" }
            : { color: "disabled", "data-testid": "CandidateLinkDisabled" }),
        }}
      />

      <Dialog
        open={open}
        onClose={(e: object, reason: string) => handleClose(reason)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Einladungslink {candidate.firstname} {candidate.lastname}
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <TextField
                sx={{ width: "100%" }}
                ref={textAreaRef}
                value={domain + "/test/" + candidate.uuid}
              />
            </Grid>
            <Grid item>
              <CopyToClipboard
                text={domain + "/test/" + candidate.uuid}
                onCopy={() => {
                  openSnackbar(
                    "success",
                    "Einladungslink wurde in die Zwischenablage kopiert"
                  );
                  setCopied(true);
                }}
              >
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  startIcon={<ContentCopyIcon />}
                  color="primary"
                >
                  Kopieren
                </Button>
              </CopyToClipboard>
            </Grid>
            <Grid item>
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                color="primary"
                onClick={() => setOpen(false)}
              >
                Schließen
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

import { useContext, useEffect, useRef } from "react";
import { getUserSetting, saveUserSettings } from "../../auth/api";
import { ErrorContext } from "../../error/ErrorContext";
import { HeadCellT } from "./EnhancedTable";
import { TableContext } from "./TableContext";

/* elist:disable:@typescript-eslint/no-unnecessary-type-constraint */
export const useTable = <Type extends any>(
  name: string,
  defaultValue: HeadCellT<Type>[],
  version = 1 // version is used to force a reset of the table settings
): { headCells: HeadCellT<Type>[] } => {
  const { setError } = useContext(ErrorContext);

  const {
    headCells,
    setHeadCells,
    setName,
  }: {
    headCells: HeadCellT<Type>[];
    setHeadCells: React.Dispatch<React.SetStateAction<HeadCellT<Type>[]>>;
    setName: React.Dispatch<React.SetStateAction<string>>;
  } = useContext(TableContext);

  const firstTime = useRef(false);
  useEffect(() => {
    if (firstTime.current) return;
    firstTime.current = true;
    console.log("%cuseTable.ts line:29 version", "color: #007acc;", version);
    getUserSetting("table:" + name + ":" + version)
      .then((res) => {
        console.log("%cuseTable.ts line:32 res", "color: #007acc;", res);
        if (res && res.success && res.data && res.data.length > 0) {
          setHeadCells(res.data);
        } else {
          setHeadCells(defaultValue);
        }
      })
      .catch((error) => {
        setError(error);
      });
  }, [firstTime, name, setHeadCells, setError, defaultValue]);

  useEffect(() => {
    setName(name);
  }, [name, setName]);

  useEffect(() => {
    saveUserSettings(
      "table:" + name + ":" + version,
      JSON.stringify(headCells)
    );
  }, [name, headCells]);

  return { headCells };
};

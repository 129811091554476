import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import React, { ReactElement, ReactNode } from "react";
import { TooltipIcon } from "../forms/TooltipIcon";

const descendingComparator = <T extends { [key: string | number]: any }>(
  a: T,
  b: T,
  orderBy: string | number
) => {
  let x = a[orderBy];
  let y = b[orderBy];
  if (x === undefined) return 1;
  if (y === undefined) return -1;
  if (typeof x === "string" && typeof y === "string") {
    x = x.replace(
      /!(\d+)\s/g,
      (match, p1) => "!" + String(p1).padStart(12, "0").slice(-10) + "xxx"
    );
    y = y.replace(
      /!(\d+)\s/g,
      (match, p1) => "!" + String(p1).padStart(12, "0").slice(-10) + "xxx"
    );

    if ((y as string).toLowerCase() < (x as string).toLowerCase()) {
      return -1;
    }
    if ((y as string).toLowerCase() > (x as string).toLowerCase()) {
      return 1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export type OrderDirT = "asc" | "desc";

export type ComparatorT<T extends any> = (
  a: { [key in NonOptionalKeys<T>]: number | string | Date | boolean },
  b: { [key in NonOptionalKeys<T>]: number | string | Date | boolean }
) => number;

export const getComparator = <Key extends keyof any>(
  orderDir: OrderDirT,
  orderBy: string | number
): ((
  a: { [key in Key]: number | string | Date | boolean },
  b: { [key in Key]: number | string | Date | boolean }
) => number) => {
  return orderDir === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T extends any>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export type HeadCellT<T extends any> = {
  disablePadding?: boolean;
  id: keyof T;
  label: string | ReactNode;
  rawLabel?: string;
  numeric?: boolean;
  colSpan?: number;
  hide?: boolean;
  tooltip?: string | ReactElement;
  sortable?: boolean;
  deleteIcon?: boolean;
};

export type NonOptionalKeys<T> = {
  [K in keyof T]-?: T extends { [K1 in K]: any } ? K : never;
}[keyof T];

export interface EnhancedTableHeadPropsI<T> {
  // onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  orderDir?: OrderDirT;
  orderBy?: string;
  rowCount: number;
  headCells: HeadCellT<T>[];
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: NonOptionalKeys<T>
  ) => void;
  onDelete?: () => void;
  rotate?: boolean;
}

export const EnhancedTableHead = <T extends any>(
  props: EnhancedTableHeadPropsI<T>
) => {
  const { orderDir, orderBy, onRequestSort } = props;
  // if (!props.sortable) props.sortable = true;
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort ? onRequestSort(event, property) : null;
    };

  return (
    <TableHead className={"enhanced-table-head"}>
      <TableRow>
        {props.headCells.map((headCell) =>
          headCell.hide ? (
            <React.Fragment
              key={headCell.id as string | number}
            ></React.Fragment>
          ) : (
            <TableCell
              key={headCell.id as string | number}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? orderDir : false}
              {...(headCell.colSpan ? { colSpan: headCell.colSpan } : {})}
            >
              {orderBy &&
              orderDir &&
              (!("sortable" in headCell) || headCell.sortable !== false) ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? orderDir : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  className="table-sort-label"
                >
                  <Box
                    className="enhanced-table-head__label"
                    sx={{ height: "100%" }}
                  >
                    {headCell.tooltip ? (
                      <span>
                        <TooltipIcon type="info" title={headCell.tooltip} />
                      </span>
                    ) : null}
                    <span>{headCell.label}</span>
                  </Box>

                  {orderBy === headCell.id ? (
                    <Box
                      component="span"
                      className="sort-arrow"
                      sx={visuallyHidden}
                    >
                      {orderDir === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <Box
                  className="enhanced-table-head__label"
                  sx={{ height: "100%", pb: "2px" }}
                >
                  {headCell.tooltip ? (
                    <span>
                      <TooltipIcon type="info" title={headCell.tooltip} />
                    </span>
                  ) : null}
                  <span
                    onClick={() => {
                      if (headCell.deleteIcon && props.onDelete) {
                        props.onDelete();
                      }
                    }}
                  >
                    {headCell.label}
                  </span>
                </Box>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export const handleRequestSort = <T extends any>(
  event: React.MouseEvent<unknown>,
  property: NonOptionalKeys<T>,
  orderDir: OrderDirT,
  setOrderDir: (value: React.SetStateAction<OrderDirT>) => void,
  orderBy: NonOptionalKeys<T>,
  setOrderBy: (value: React.SetStateAction<NonOptionalKeys<T>>) => void
) => {
  const isAsc = orderBy === property && orderDir === "asc";
  setOrderDir(isAsc ? "desc" : "asc");
  setOrderBy(property);
};

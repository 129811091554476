import { Box } from "@mui/material";

interface DegebaLogoI {
  className?: string;
}

export const DegebaLogo = ({ className }: DegebaLogoI) => {
  return (
    <Box
      className={"logo degeba-logo " + className}
      sx={{
        height: 100,
        backgroundPosition: "center",
        opacity: 0.5,
        width: 200,
      }}
    ></Box>
  );
};

import { Box, Modal, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface TestModusErrorModalPropsI {
  open: boolean;
  attempt: number;
}

export const TestModusErrorModal = ({
  open,
  attempt,
}: TestModusErrorModalPropsI) => {
  return (
    <Modal open={open} className="modal-backdrop">
      <Box className="modal" sx={{ textAlign: "center" }}>
        <WarningAmberIcon sx={{ color: "red", fontSize: "8rem" }} />
        <Typography variant="h2" component="h2" sx={{ color: "red" }}>
          Speichern fehlgeschlagen!
        </Typography>
        <br />
        Das Testergebnis konnte nicht gespeichert werden. Bitte stellen Sie eine{" "}
        <b>stabile Internetverbindung</b> her und{" "}
        <b>lassen Sie dieses Fenster geöffnet!</b>
        <br />
        <br />
        Sobald Sie mit dem Internet verbunden sind, wird Ihr Ergebnis
        automatisch gespeichert und Sie erhalten eine Erfolgsmeldung.
        <br />
        <br />
        {attempt > 0 && <>Versuch {attempt}. Bitte warten ...</>}
      </Box>
    </Modal>
  );
};

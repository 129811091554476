import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FileT } from "./api";
import { useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextField from "@mui/material/TextField";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { Grid } from "@mui/material";

interface FileLinkPropsI {
  file: FileT;
}

export const FileLink = ({ file }: FileLinkPropsI) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const textAreaRef = useRef<HTMLInputElement>(null);

  const handleClose = (reason: string) => {
    setOpen(false);
  };

  return (
    <>
      <TooltipIcon
        type="link"
        title="Datei Link anzeigen"
        onClick={() => setOpen(true)}
      />

      <Dialog
        open={open}
        onClose={(e: object, reason: string) => handleClose(reason)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Datei-Link</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <TextField
                sx={{ width: "100%" }}
                ref={textAreaRef}
                value={"/storage/" + file.filePath}
              />
            </Grid>
            <Grid item>
              <CopyToClipboard
                text={"/storage/" + file.filePath}
                onCopy={() => setCopied(true)}
              >
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  startIcon={<ContentCopyIcon />}
                  color={copied ? "success" : "primary"}
                >
                  Copy
                </Button>
              </CopyToClipboard>
            </Grid>
            <Grid item>
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                color={copied ? "success" : "primary"}
                onClick={() => setOpen(false)}
              >
                Schließen
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

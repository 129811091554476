import React, { createContext, useState } from "react";
import { SystemHealthCheckT } from "./api";

type ContextPropsType = {
  systemHealthCheckList: SystemHealthCheckT[];
  setSystemHealthCheckList: React.Dispatch<
    React.SetStateAction<SystemHealthCheckT[]>
  >;
  loadingDone: boolean;
  setLoadingDone: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface SystemHealthCheckChangedT extends SystemHealthCheckT {
  error?: boolean;
}

export const SystemHealthCheckContext = createContext<ContextPropsType>({
  systemHealthCheckList: [],
  setSystemHealthCheckList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
});
export const SystemHealthCheckProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [systemHealthCheckList, setSystemHealthCheckList] = useState<
    SystemHealthCheckT[]
  >([]);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <SystemHealthCheckContext.Provider
      value={{
        systemHealthCheckList,
        setSystemHealthCheckList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </SystemHealthCheckContext.Provider>
  );
};

import { updateUserPassword } from "../user/api";
import { useContext } from "react";
import { ErrorContext } from "../error/ErrorContext";

import { FeedbackContext } from "../feedback/FeedbackContext";

import { ChangePasswordForm } from "./ChangePasswordForm";

export const ChangePassword = () => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const onSave = (password: string) => {
    showProgress();
    updateUserPassword(password)
      .then((res) => {
        if (res.success) {
          openSnackbar("success", "Password geändert");
        } else {
          setError(res.error);
        } //&& res.errors["email"]) setError(error);
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return <ChangePasswordForm onSave={onSave} />;
};

import React, { createContext, useState } from "react";
import { ContextPropsT } from "../shared/types/Context";
import { HelpCategoryT, HelpEntryT, HelpT } from "./api";

export const EMPTYCATEGORY: HelpCategoryT = {
  title: "",
  sortOrder: 0,
  id: 0,
};

export interface HelpCategoryChangedT extends HelpCategoryT {
  error?: boolean;
}

export const EMPTYENTRY: HelpEntryT = {
  title: "",
  content: "",
  sortOrder: 0,
  helpCategoryId: 0,
  id: 0,
};

export interface HelpEntryChangedT extends HelpEntryT {
  error?: boolean;
}

// createContext: erzeugt ein Context-object, das alles möglich sein kann
// in diesem Fall ist es Variable und Setz-Funktion auf dem useState-Hook!
// daher muss die Typisierung dem useState-Hook folgen
// unklar warum erst Dispatch, dann SetStateAction, vermutlich wird es weitergereicht

interface HelpContextPropsType extends ContextPropsT {
  helpCategoryList: HelpCategoryT[];
  setHelpCategoryList: React.Dispatch<React.SetStateAction<HelpCategoryT[]>>;
  changedHelpCategoryList: HelpCategoryChangedT[];
  setChangedHelpCategoryList: React.Dispatch<
    React.SetStateAction<HelpCategoryChangedT[]>
  >;
  helpEntryList: HelpEntryT[];
  setHelpEntryList: React.Dispatch<React.SetStateAction<HelpEntryT[]>>;
  changedHelpEntryList: HelpEntryChangedT[];
  setChangedHelpEntryList: React.Dispatch<
    React.SetStateAction<HelpEntryChangedT[]>
  >;
  helpList: HelpT[];
  setHelpList: React.Dispatch<React.SetStateAction<HelpT[]>>;
}

export const HelpContext = createContext<HelpContextPropsType>({
  helpCategoryList: [],
  setHelpCategoryList: () => {},
  changedHelpCategoryList: [],
  setChangedHelpCategoryList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
  helpEntryList: [],
  setHelpEntryList: () => {},
  changedHelpEntryList: [],
  setChangedHelpEntryList: () => {},
  helpList: [],
  setHelpList: () => {},
});
export const HelpProvider = ({ children }: { children: React.ReactNode }) => {
  const [helpCategoryList, setHelpCategoryList] = useState<HelpCategoryT[]>([]);
  const [changedHelpCategoryList, setChangedHelpCategoryList] = useState<
    HelpCategoryChangedT[]
  >([]);
  const [helpEntryList, setHelpEntryList] = useState<HelpEntryT[]>([]);
  const [changedHelpEntryList, setChangedHelpEntryList] = useState<
    HelpEntryChangedT[]
  >([]);
  const [helpList, setHelpList] = useState<HelpT[]>([]);

  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <HelpContext.Provider
      value={{
        helpCategoryList,
        setHelpCategoryList,
        changedHelpCategoryList,
        setChangedHelpCategoryList,
        helpEntryList,
        setHelpEntryList,
        changedHelpEntryList,
        setChangedHelpEntryList,
        helpList,
        setHelpList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};

import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { MainCategoryT } from "./api";
import { ImageSelect } from "./ImageSelect";
import { FeedbackContext } from "../feedback/FeedbackContext";
import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { isEmpty } from "lodash";
import { MainCategoryContext } from "./MainCategoryContext";

interface MainCategoryFormPropsI {
  category?: MainCategoryT;
  onClick: (data: MainCategoryT) => void;
}

const EMPTYCATEGORY: MainCategoryT = {
  id: 0,
  name: "",
  desc: "",
  imgId: 0,
  active: 0,
  order: 0,
};

export const MainCategoryForm = (props: MainCategoryFormPropsI) => {
  const { categoryList } = useContext(MainCategoryContext);

  const [categoryState, setCategoryState] = useState<MainCategoryT>(
    props.category ? props.category : EMPTYCATEGORY
  );

  const { showProgress } = useContext(FeedbackContext);
  const fields2check = ["name"];
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onClick = () => {
    // console.log("onClick ", props);

    let oneError = false;
    fields2check.forEach((f) => {
      const error = categoryState[f as keyof MainCategoryT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });
    if (oneError) return;

    if (!isEmpty(categoryState.name)) {
      const error = categoryList.some(
        (u) =>
          u.name === categoryState.name &&
          (!categoryState.id || categoryState.id !== u.id)
      );

      setErrorState((p) =>
        buildErrorState(p, "name", error, "Dieser Name ist bereits vorhanden!")
      );
      oneError = oneError || error;
    }
    if (oneError) return;

    showProgress();
    props.onClick(categoryState);
  };

  return (
    <div>
      <StyledFormControl required={true}>
        <TextField
          id="name"
          label="Name"
          value={categoryState.name}
          error={errorState.name?.error}
          helperText={
            errorState.name?.error
              ? errorState.name?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setCategoryState({ ...categoryState, name: e.target.value })
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id="desc"
          label="Beschreibung"
          value={categoryState.desc}
          onChange={(e) =>
            setCategoryState({ ...categoryState, desc: e.target.value })
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <InputLabel id="inputLabelImgId">Bild</InputLabel>
        <ImageSelect
          id="imgId"
          labelId="inputLabelImgId"
          value={categoryState.imgId}
          setValue={(v) => setCategoryState({ ...categoryState, imgId: v })}
          label="Bild"
        />
      </StyledFormControl>

      <StyledFormControl>
        <Button variant="contained" color="primary" onClick={onClick}>
          Add
        </Button>
      </StyledFormControl>
    </div>
  );
};

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { AuthContext } from "../../auth/AuthContext";
import { ReactNode, useContext, useState } from "react";
import { useLayout } from "../../testModus/useLayout";
import { Loader } from "../../shared/Loader";
import { Modal2ndWindow } from "../../auth/Modal2ndWindow";
import { Helmet } from "react-helmet";
import { CandidateLoggedOut } from "../../auth/CandidateLoggedOut";

interface CandidateContentI {
  children: ReactNode;
  hideProfessionName?: boolean;
}

export const CandidateContent = ({ children }: CandidateContentI) => {
  const { authState } = useContext(AuthContext);
  if (!authState || !authState.user) return <CandidateLoggedOut />;
  const { loadingDone } = useLayout({
    layout: authState.user.layout,
  });
  const [storageChanged, setStorageChanged] = useState(false);
  if (!loadingDone) return <Loader />;

  window.addEventListener("storage", (e) => {
    setStorageChanged(true);
  });

  return (
    <>
      <Helmet>
        <title>degeba - Testbereich</title>
      </Helmet>
      <Box className="candidate page" data-testid="candidate">
        <CssBaseline />
        {children}
      </Box>
      <Modal2ndWindow open={storageChanged} />
    </>
  );
};

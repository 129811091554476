import { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { QuestionChangedT, QuestionContext } from "./QuestionContext";
import { deleteQuestion, QuestionT, RatingT } from "./api";
import { DeleteButton } from "../shared/forms/DeleteButton";
import {
  debouncedSetUpdatedState,
  deleteStateFromArrayById,
} from "../shared/helper/state";
import { EditableCell } from "../shared/list/InsertCell";
import { checkChanged } from "../shared/helper/object";
import { LayoutSelect } from "./Select/LayoutSelect";
import { Switch, TableCell, TableRow } from "@mui/material";
import { NonOptionalKeys } from "../shared/table/EnhancedTable";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { EvaluationSelect } from "./Select/EvaluationSelect";
import TypeSelect from "./Select/TypeSelect";
import { InfoPopOver } from "../shared/InfoPopover";
import RatingSelect from "./Select/RatingSelect";

interface QuestionPropsI {
  question: QuestionT;
  setShowSave: (showSave: boolean) => void;
  cellIsHidden: (i: NonOptionalKeys<QuestionT>) => boolean;
}

export const Question = ({
  question,
  setShowSave,
  cellIsHidden,
}: QuestionPropsI) => {
  const { setQuestionList, setChangedQuestionList } =
    useContext(QuestionContext);

  const [hasChanged, setHasChanged] = useState(false);

  const [questionState, setQuestionState] =
    useState<QuestionChangedT>(question);

  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(question, questionState, hasChanged, setHasChanged);
  }, [setHasChanged, hasChanged, question, questionState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(questionState, setChangedQuestionList);
  }, [questionState, setChangedQuestionList]);

  return (
    <TableRow hover tabIndex={-1} key={question.id} selected={hasChanged}>
      {!cellIsHidden("id") && <TableCell>{question.id}</TableCell>}
      {!cellIsHidden("example") && (
        <TableCell>
          <Switch
            // value={question.id}
            checked={questionState.example}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setQuestionState((p) => ({
                ...p,
                example: event.target.checked,
              }));
            }}
          />
        </TableCell>
      )}
      {!cellIsHidden("type" as NonOptionalKeys<QuestionT>) && (
        <TableCell>
          <TypeSelect
            id="type"
            value={questionState.type}
            setValue={(v: string) =>
              setQuestionState((prevState) => {
                return { ...prevState, type: v };
              })
            }
          />
        </TableCell>
      )}
      {!cellIsHidden("rating" as NonOptionalKeys<QuestionT>) && (
        <TableCell>
          <RatingSelect
            id="rating"
            value={questionState.rating}
            setValue={(v: RatingT) =>
              setQuestionState((prevState) => {
                return { ...prevState, rating: v };
              })
            }
          />
        </TableCell>
      )}
      <TableCell hidden={cellIsHidden("difficulty")}>
        {questionState.difficulty}
      </TableCell>
      <EditableCell
        id="duration"
        type="number"
        stateObject={questionState}
        setStateObject={setQuestionState}
        hidden={cellIsHidden("duration")}
      />
      {["question", "answerOptions", "answer"].map((id) => (
        <EditableCell
          id={id as keyof QuestionChangedT}
          key={id}
          stateObject={questionState}
          setStateObject={setQuestionState}
          hidden={cellIsHidden(id as NonOptionalKeys<QuestionT>)}
        />
      ))}
      <EditableCell
        id="points"
        type="number"
        stateObject={questionState}
        setStateObject={setQuestionState}
        hidden={cellIsHidden("points")}
      />
      {!cellIsHidden("evaluation") && (
        <TableCell>
          <EvaluationSelect
            id="evaluation"
            value={questionState.evaluation}
            setValue={(v: string) =>
              setQuestionState((prevState) => {
                return { ...prevState, evaluation: v };
              })
            }
          />
        </TableCell>
      )}
      {!cellIsHidden("layout") && (
        <TableCell>
          <LayoutSelect
            id="layout"
            value={questionState.layout}
            setValue={(v: string) =>
              setQuestionState((prevState) => {
                return { ...prevState, layout: v };
              })
            }
          />
        </TableCell>
      )}

      <TableCell align="right">
        <Link to={"/admin/question/" + question.id}>
          <TooltipIcon type="edit" />
        </Link>
      </TableCell>
      <TableCell align="right">
        <DeleteButton
          id={questionState.id}
          deleteFunc={deleteQuestion}
          successFunc={() =>
            setQuestionList((p) =>
              deleteStateFromArrayById(p, questionState.id)
            )
          }
          confirmContent="Soll diese Frage inkl. der Zuordnungen wirklich gelöscht werden?"
        />
      </TableCell>
      <TableCell align="right">
        <InfoPopOver id={question.id} list={question.subCategories} />
      </TableCell>
    </TableRow>
  );
};

import { updateUserPassword } from "./api";
import { useContext } from "react";
import { ErrorContext } from "../error/ErrorContext";

import { FeedbackContext } from "../feedback/FeedbackContext";

import { ChangePasswordForm } from "./ChangePasswordForm";
import Box from "@mui/system/Box";
import { AuthContext } from "../auth/AuthContext";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export const ChangePasswordForce = () => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const { setAuthState } = useContext(AuthContext);

  const onSave = (password: string) => {
    showProgress();
    updateUserPassword(password)
      .then((res) => {
        if (res.success) {
          setAuthState((p) => ({
            ...p,
            user: { ...p.user, force_change_password: 0 },
          }));
          openSnackbar("success", "Password geändert");
        } else {
          setError(res.error);
        } //&& res.errors["email"]) setError(error);
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h2">
          Bitte ändern Sie Ihr Passwort
        </Typography>
        <ChangePasswordForm onSave={onSave} />
      </Box>
    </Container>
  );
};

import { useContext, useState } from "react";
import {
  addRelation,
  deleteRelation,
  RelationT,
  SubRelationT,
  updateRelationBulk,
} from "./api";
import { RelationContext } from "./RelationContext";
import { FilteredSubCategoryT } from "./RelationTable";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ellipsisSx } from "../shared/styles/sx";
import { EditableEntry } from "../shared/list/EditableEntry";
import { MoveButton } from "./MoveButton";

import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { RELATIONREDUCERACTIONS } from "./relationReducer";
import Typography from "@mui/material/Typography";
import { SelTreeT, SetSelTreeT } from "./useSelTree";
import { MainCategoryT } from "../mainCategory/api";
import { DelRelationIcon } from "./Icons/DelRelationIcon";
import { AddRelationIcon } from "./Icons/AddRelationIcon";
import { Tooltip } from "../shared/forms/Tooltip";

interface SubCategoryPropsI {
  subCategory: FilteredSubCategoryT;
  selTree: SelTreeT;
  setSelTree: SetSelTreeT;
  moveSubCategory: (direction: string, id: number) => void;
  onAddRel: (a: number) => void;
  isSubCategorySelected: (a: number) => SubRelationT | undefined;
  isSubCategorySelectedByOther: (a: number) => RelationT | undefined;
  onClick: (s: number) => void;
  mainCategoryList: MainCategoryT[];
}
export const SubCategory = ({
  subCategory,
  selTree,
  setSelTree,
  moveSubCategory,
  onAddRel,
  isSubCategorySelected,
  isSubCategorySelectedByOther,
  onClick,
  mainCategoryList,
}: SubCategoryPropsI) => {
  const { setRelationList } = useContext(RelationContext);
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const relOwn = isSubCategorySelected(subCategory.id);
  const relOther = isSubCategorySelectedByOther(subCategory.id);
  const [subName, setSubName] = useState(relOwn?.subCategoryName);

  const countQuestions = relOwn?.questionRelations.length || 0;

  const saveSubName = (rel: SubRelationT) => {
    updateRelationBulk([
      {
        type: "sub",
        id: relOwn?.id || 0,
        subCategoryName: subName,
      },
    ]);

    if (subName)
      setRelationList({
        action: RELATIONREDUCERACTIONS.SAVESUB,
        data: {
          professionId: selTree.professionId,
          mainCategoryId: selTree.mainCategoryId,
          subCategoryId: subCategory.id,
          subName: subName,
        },
      });
  };

  /**
   * new relation between Profession / MainCat / SubCat
   *
   */
  const onClickAddRelation = (subCategoryId: number) => {
    showProgress();
    setSelTree({ subCategoryId });

    addRelation({
      type: "sub",
      professionId: selTree.professionId,
      mainCategoryId: selTree.mainCategoryId,
      subCategoryId: subCategoryId,
    })
      .then((result) => {
        if (result.success) {
          setRelationList({
            action: RELATIONREDUCERACTIONS.ADDSUB,
            data: {
              professionId: selTree.professionId,
              mainCategoryId: selTree.mainCategoryId,
              add: result.data as SubRelationT,
            },
          });

          onAddRel(subCategoryId);

          openSnackbar("success", "Kategorie zugeordnet");
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onClickDelRelation = (relation: SubRelationT) => {
    showProgress();
    deleteRelation("sub", relation.id)
      .then((result) => {
        if (result.success) {
          setRelationList({
            action: RELATIONREDUCERACTIONS.DELSUB,
            data: {
              professionId: selTree.professionId,
              mainCategoryId: selTree.mainCategoryId,
              relationId: relation.id,
            },
          });

          openSnackbar("success", "Kategorie zurückgesetzt");
        } else setError("inernal error");
      })
      .catch((error) => {
        setError(error);
      });
  };

  /** ************************************************************************
   *
   * inserts one SubCat in the List
   * cat-name is editable if subCategory is selected and owned ( = relation exists)
   * alt. name will be save onClickAway
   *^
   */
  return (
    <Box
      key={subCategory.id}
      sx={{
        background: subCategory.id === selTree.subCategoryId ? "lightgray" : "",
        fontWeight: relOwn ? "bold" : "",
        mb: 1,
      }}
    >
      <Grid container spacing={0}>
        <Grid
          item
          xs={6}
          sx={{ ...ellipsisSx }}
          onClick={() => onClick(subCategory.id)}
        >
          <Tooltip title={"id:" + subCategory.id + " o:" + subCategory.order}>
            <Typography
              variant="body2"
              sx={{
                ...(isSubCategorySelected(subCategory.id)
                  ? { fontWeight: "bold" }
                  : {}),
              }}
            >
              {subCategory.name}
            </Typography>
          </Tooltip>
          {countQuestions >= 0 && isSubCategorySelected(subCategory.id) && (
            <Typography
              variant="body2"
              sx={{
                fontWeight: "bold",
                color: countQuestions === 0 ? "red" : "",
              }}
            >
              ({countQuestions})
            </Typography>
          )}
          {subCategory.id === selTree.subCategoryId && relOwn && (
            <EditableEntry
              id="subNameEditable"
              value={subName || ""}
              setValue={(v) => setSubName(v as string)}
              showIcon={true}
              onClickAway={() => saveSubName(relOwn)}
              label="Alternat. Name"
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {selTree.mainCategoryId > 0 &&
            (relOther ? (
              <Tooltip
                title={
                  "Bereits in Kategorie " +
                  mainCategoryList.find((c) => c.id === relOther.mainCategoryId)
                    ?.name
                }
              >
                <NotInterestedOutlinedIcon color="disabled" />
              </Tooltip>
            ) : relOwn ? (
              <DelRelationIcon
                selTree={selTree}
                onClick={() => onClickDelRelation(relOwn)}
              />
            ) : (
              <AddRelationIcon
                selTree={selTree}
                onClick={() => onClickAddRelation(subCategory.id)}
              />
            ))}
        </Grid>
        <Grid item xs={2}>
          {subCategory.order > subCategory.minOrder &&
          subCategory.order < 10000 ? (
            <MoveButton
              direction="up"
              onClick={() => moveSubCategory("up", subCategory.id)}
            />
          ) : (
            <MoveButton direction="up" disabled={true} />
          )}
        </Grid>
        <Grid item xs={2}>
          {subCategory.order < subCategory.maxOrder ? (
            <MoveButton
              direction="down"
              onClick={() => moveSubCategory("down", subCategory.id)}
            />
          ) : (
            <MoveButton direction="down" disabled={true} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

import { Typography } from "@mui/material";
import { useContext } from "react";
import { ResultsMainList } from "../candidate/results/ResultsMainList";
import { ProfessionContext } from "./ProfessionContext";

export const TestResults = () => {
  const { testResultList } = useContext(ProfessionContext);

  console.log(
    "%cTestResults.tsx line:9 testResultList",
    "color: #007acc;",
    testResultList
  );

  return (
    <>
      <Typography variant="h2" component="h2">
        Test Ergebnisse{" "}
      </Typography>

      <div id="results">
        <ResultsMainList results={testResultList} />
      </div>
    </>
  );
};

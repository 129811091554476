import { updateUserPassword } from "../user/api";
import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";

import { FeedbackContext } from "../feedback/FeedbackContext";

import { ChangePasswordForm } from "../user/ChangePasswordForm";
import { Dialog } from "../shared/Dialog";
import { TooltipIcon } from "../shared/forms/TooltipIcon";

interface ChangePasswordPropsI {
  userId: number;
}
export const ChangePassword = ({ userId }: ChangePasswordPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const [open, setOpen] = useState(false);

  const onSave = (password: string) => {
    showProgress();
    updateUserPassword(password, userId)
      .then((res) => {
        if (res.success) {
          openSnackbar("success", "Password geändert");
          setOpen(false);
        } else {
          setError(res.error);
        } //&& res.errors["email"]) setError(error);
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <>
      <TooltipIcon
        type="password"
        title="Passwort ändern"
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} setOpen={setOpen} title="Passwort ändern">
        <ChangePasswordForm onSave={onSave} />
      </Dialog>
    </>
  );
};

import Button from "@mui/material/Button";
import { buttonLikeSearchBox } from "../styles/sx";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface AddButtonPropsI {
  onClick: () => void;
  title: string;
}

export const AddButton = ({ onClick, title }: AddButtonPropsI) => {
  return (
    <Button
      variant="contained"
      startIcon={<AddCircleOutlineIcon />}
      onClick={onClick}
      data-testid="add-button"
      sx={{ width: 220 }}
    >
      {title}
    </Button>
  );
};

import { useState } from "react";

export const usePagination = (
  paginationId: string,
  options?: { rowsPerPage?: number; forceRowsPerPage?: number }
) => {
  const identPage = "page:" + paginationId;
  const identRows = "rowsPerPage:" + paginationId;

  const [page, setPage] = useState(
    parseInt(localStorage.getItem(identPage) || "0")
  );
  const localStorageRowsPerPage = localStorage.getItem(identRows);
  const [rowsPerPageState, setRowsPerPage] = useState(
    options?.forceRowsPerPage
      ? options?.forceRowsPerPage
      : localStorageRowsPerPage
      ? parseInt(localStorageRowsPerPage)
      : options?.rowsPerPage
      ? options?.rowsPerPage
      : 10
  );

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    localStorage.setItem(identPage, newPage.toString());
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (paginationId)
      localStorage.setItem(
        identRows,
        parseInt(event.target.value, 10).toString()
      );
    handleChangePage(0);
  };

  return {
    page,
    setPage,
    rowsPerPage: rowsPerPageState,
    setRowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

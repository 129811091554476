import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { EMPTYENTRY } from "./HelpContext";
import { HelpEntryT } from "./api";

interface HelpEntryFormPropsI {
  onClick: (data: HelpEntryT) => void;
  button: string;
}

const fields2check = ["title"];

export const HelpEntryForm = (props: HelpEntryFormPropsI) => {
  const { showProgress } = useContext(FeedbackContext);
  const [helpEntryState, setHelpEntryState] = useState(EMPTYENTRY);
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f: any) => {
      const error = helpEntryState[f as keyof HelpEntryT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });

    if (oneError) return;

    showProgress();
    props.onClick(helpEntryState);
  };

  return (
    <div>
      <StyledFormControl>
        <TextField
          id="title"
          label="Titel"
          value={helpEntryState.title}
          error={errorState.title?.error}
          helperText={
            errorState.title?.error
              ? errorState.title?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setHelpEntryState((prevState) => {
              return { ...prevState, title: e.target.value };
            })
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id="content"
          label="Inhalt"
          value={helpEntryState.content}
          error={errorState.content?.error}
          helperText={
            errorState.content?.error
              ? errorState.content?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setHelpEntryState((prevState) => {
              return { ...prevState, content: e.target.value };
            })
          }
          multiline
          rows={6}
        />
      </StyledFormControl>

      <div>
        <StyledFormControl>
          <Button variant="contained" color="primary" onClick={onClick}>
            {props.button}
          </Button>
        </StyledFormControl>
      </div>
    </div>
  );
};

import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface TypeSelectPropI {
  id: string;
  value?: string;
  setValue: (value: string) => void;
  label?: string;
  labelId?: string;
}

export const TypeSelect = (props: TypeSelectPropI) => {
  return (
    <Select
      labelId={props.labelId}
      id={props.id}
      value={props.value ? props.value : ""}
      onChange={(e: SelectChangeEvent<string>) => {
        props.setValue(e.target.value);
      }}
      label="{props.label}"
      size="small"
      sx={{ fontSize: (theme) => theme.typography.body2.fontSize }}
    >
      <MenuItem value="multiple-choice">multiple-choice</MenuItem>
      <MenuItem value="dnd_1u1_zuordnungen">dnd_1u1_zuordnungen</MenuItem>
      <MenuItem value="klickmatrix">klickmatrix</MenuItem>
      <MenuItem value="lueckentext">lueckentext</MenuItem>
    </Select>
  );
};

export default TypeSelect;

import React, { ReactElement, useContext, useEffect, useRef } from "react";
import { AuthContext, buildAuthState } from "./AuthContext";
import { logout } from "./api";
import { removeStoredAuthToken } from "./localStore";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { redirectAfterLoggedout } from "../shared/helper/logout";
import { removeTestModusLocalStore } from "../testModus/TestModusContext";

interface LogoutPropsI {
  admin?: boolean;
}

export const Logout = ({ admin = false }: LogoutPropsI): ReactElement => {
  console.log("Logout");
  const { authState, setAuthState } = useContext(AuthContext);
  const { showProgress } = useContext(FeedbackContext);

  const updateDone = useRef(false);
  useEffect(() => {
    if (updateDone.current) return;
    updateDone.current = true;

    showProgress();
    if (authState.loggedIn) {
      logout().then((result) => {
        if (result.success) {
          removeStoredAuthToken();
          setAuthState(buildAuthState());
          redirectAfterLoggedout();
          removeTestModusLocalStore();
        } else {
          console.log("logout error");
        }
      });
    }
  }, [authState, setAuthState, showProgress]);

  return <div>Logging out ... </div>;
};

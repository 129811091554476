import { Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CandidateContext } from "../CandidateContext";
import { ResultsData } from "./ResultsData";

interface ResultsPropsI {
  candidateId: number;
}
export const Results = (props: ResultsPropsI) => {
  const [printNow, setPrintNow] = useState(false);
  const isPrint = window.location.href.includes("print");

  const { candidateList } = useContext(CandidateContext);

  let candidateId = props.candidateId;

  useEffect(() => {
    setPrintNow(true);
  }, []);

  useEffect(() => {
    if (!candidateId || !printNow || !isPrint) return;
    setTimeout(() => window.print(), 500);
  }, [printNow, candidateId, isPrint]);

  if (candidateId) {
    const candidate = candidateList.find((c) => c.id === candidateId);
    if (candidate) return <ResultsData candidate={candidate} />;
  }
  return (
    <Typography variant="h1" component="h1">
      Keine Ergebnisse
    </Typography>
  );
};

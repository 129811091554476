import { SxProps, Theme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface GenderSelectPropI {
  id: string;
  value?: string;
  setValue: (value: string) => void;
  label?: string;
  labelId?: string;
  size?: "small" | "medium" | undefined;
  onMouseDown?: (e: any) => void;
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
}

type GenderT = {
  [key: string]: string;
};
export const GENDER: GenderT = {
  m: "Herr",
  f: "Frau",
  d: "Divers",
};

export const GenderSelect = (props: GenderSelectPropI) => {
  const defaultValue =
    "default"; /* Material-UI Select component treats an empty string "" as no selection */
  return (
    <Select
      labelId={props.labelId}
      id={props.labelId}
      value={props.value || defaultValue}
      onChange={(e: SelectChangeEvent<string>) => {
        props.setValue(e.target.value === defaultValue ? "" : e.target.value);
      }}
      label={props.label}
      size={props.size || "medium"}
      sx={{ ...props.sx, fontSize: (theme) => theme.typography.body2.fontSize }}
      onMouseDown={props.onMouseDown}
      style={props.style}
    >
      <MenuItem value={defaultValue}>-</MenuItem>
      <MenuItem value="m">Herr</MenuItem>
      <MenuItem value="f">Frau</MenuItem>
      <MenuItem value="d">Divers</MenuItem>
    </Select>
  );
};

export default GenderSelect;

import { useEffect, useContext, useRef, useState } from "react";
import {
  fetchQuestions,
  TestModusQuestionT,
  IMGPATH,
  FetchQuestionsResultT,
} from "./api";
import { TestModusContext } from "./TestModusContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseQuestionsResultT extends UseResultT {
  questionList: TestModusQuestionT[];
  setQuestionList: React.Dispatch<React.SetStateAction<TestModusQuestionT[]>>;
}

export interface UseTestModusQuestionsPropsI extends UsePropsI {
  professionId?: number;
}

export const useTestModusQuestions = ({
  professionId = 0,
}: UseTestModusQuestionsPropsI): UseQuestionsResultT => {
  const [tmp, setTmp] = useState<FetchQuestionsResultT | undefined>();

  const {
    questionList,
    setQuestionList,
    setMainCategoryInfo,
    setSubCategoryInfo,
    setProfession,
    loadingDone,
    setLoadingDone,
    setCandidateSettings,
  } = useContext(TestModusContext);

  const { hideProgress } = useContext(FeedbackContext);

  useLoadData({
    fetchFunc: () => fetchQuestions({ professionId }),
    list: tmp,
    setList: setTmp,
    setLoadingDone: setLoadingDone,
    dontHideProgress: true,
  });

  /* all imgs from questions to cache */
  const effectDone = useRef(false);
  useEffect(() => {
    if (tmp) {
      setQuestionList(tmp[0]);
      setProfession(tmp[1]);
      setSubCategoryInfo(tmp[2]);
      setMainCategoryInfo(tmp[3]);
      setCandidateSettings(tmp[4]);
    }
  }, [tmp, setQuestionList, setSubCategoryInfo, setCandidateSettings]);

  useEffect(() => {
    if (questionList.length <= 0) return;

    if (effectDone.current) return;
    effectDone.current = true;

    const imgs: string[] = [];

    questionList.forEach((q) => {
      if (q.type === "klickmatrix" && q.answerOptionsDecoded)
        (q.answerOptionsDecoded.content as any[]).forEach((obj) => {
          Object.values(obj).forEach((array) => {
            (array as any[]).forEach(({ img }: { img: string }) => {
              if (img) imgs.push(img);
            });
          });
        });
    });

    if (imgs.length > 0) cacheImages(imgs).then(() => hideProgress());
    else hideProgress();
  }, [questionList, hideProgress]);

  return {
    questionList,
    setQuestionList,
    loadingDone,
  };
};

export const cacheImages = async (srcArray: string[]) => {
  const promises: Promise<void>[] = await srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = IMGPATH + src;
      img.onload = () => resolve();
      img.onerror = () => reject({ error: "img not found", img: src });
    });
  });

  Promise.race(promises)
    .then(() => console.log("race success"))
    .catch((e) => console.log("race catch error:", e));
};

import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import {
  buildEmptyErrorState,
  buildErrorState,
} from "../shared/helper/formError";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { StyledFormControl } from "../shared/forms/StyledFormControl";
import { EMPTYCATEGORY, HelpContext } from "./HelpContext";
import { HelpCategoryT } from "./api";

interface HelpCategoryFormPropsI {
  onClick: (data: HelpCategoryT) => void;
  button: string;
}

const fields2check = ["title"];

export const HelpCategoryForm = (props: HelpCategoryFormPropsI) => {
  const { showProgress } = useContext(FeedbackContext);
  const [helpCategoryState, setHelpCategoryState] = useState(EMPTYCATEGORY);
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(fields2check)
  );

  const onClick = () => {
    let oneError = false;
    fields2check.forEach((f: any) => {
      const error = helpCategoryState[f as keyof HelpCategoryT] ? false : true;
      setErrorState((p) => buildErrorState(p, f, error));
      oneError = oneError || error;
    });

    if (oneError) return;

    showProgress();
    props.onClick(helpCategoryState);
  };

  return (
    <div>
      <StyledFormControl>
        <TextField
          id="title"
          label="Titel"
          value={helpCategoryState.title}
          error={errorState.title?.error}
          helperText={
            errorState.title?.error
              ? errorState.title?.helperText || "Bitte ausfüllen"
              : ""
          }
          onChange={(e) =>
            setHelpCategoryState((prevState) => {
              return { ...prevState, title: e.target.value };
            })
          }
        />
      </StyledFormControl>

      <div>
        <StyledFormControl>
          <Button variant="contained" color="primary" onClick={onClick}>
            {props.button}
          </Button>
        </StyledFormControl>
      </div>
    </div>
  );
};

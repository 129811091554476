import { CartItemT, ShopArticleT } from "./api";

export const addToCart = (item: CartItemT) => {
  const cart = getStoredCart() || [];
  const index = cart.findIndex((a) => a.artnr === item.artnr);
  if (index === -1) {
    cart.push(item);
  } else {
    cart[index] = item;
  }
  storeCart(cart);
};

export const removeFromCart = (item: CartItemT) => {
  const cart = getStoredCart() || [];
  const index = cart.findIndex((a) => a.orderId === item.orderId);
  if (index === -1) {
    return;
  } else {
    cart.splice(index, 1);
  }
  storeCart(cart);
};

export const isInCart = (orderId: number) => {
  const cart = getStoredCart() || [];
  const index = cart.findIndex((a) => a.orderId === orderId);
  return index !== -1;
};

export const storeCart = (cart: CartItemT[]) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

export const removeStoredCart = () => {
  localStorage.removeItem("cart");
};

export const getStoredCart = (): CartItemT[] => {
  const str = localStorage.getItem("cart");
  if (str) return JSON.parse(str);
  return [];
};

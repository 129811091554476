import { get, post, put, del, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";
import { checkApiResult } from "../shared/lib/checkApi";

export type RatingT = "easy" | "medium" | "hard" | "none";

export interface QuestionT {
  id: number;
  type: string;
  rating: RatingT;
  difficulty: number;
  duration: number;
  question: string;
  questionDecoded?: string;
  answerOptions: string;
  answer: string;
  comment: string;
  answerOptionsDecoded?: any[] | any;
  answerDecoded?: any[] | any;
  answerOptionsMaxHeight?: number;
  answerOptionsMaxWidth?: number;
  mainCategoryName?: string;
  mainCategoryId?: number;
  subCategoryName?: string;
  subCategoryId?: number;
  points: number;
  layout: string;
  evaluation: string;
  order: number;
  example: boolean;
  isImage: boolean;
  subCategories?: {
    id: number;
    name: string;
  }[];
  countTotal?: number;
}

export const EMPTYQUESTION: QuestionT = {
  id: 0,
  type: "",
  rating: "none",
  difficulty: 0,
  duration: 0,
  question: "",
  answerOptions: "",
  answer: "",
  comment: "",
  points: 0,
  layout: "",
  order: 0,
  example: true,
  evaluation: "normal",
  isImage: false,
};

export interface QuestionDataT extends Omit<QuestionT, "example" | "isImage"> {
  example: number;
  isImage?: number;
}

const data2question = (data: QuestionDataT): QuestionT => {
  checkApiResult("data2question", data, {
    id: "number",
    type: "string",
    rating: "string",
    difficulty: "number",
    duration: "number",
    question: "string",
    answerOptions: "string",
    answer: "string",
    comment: "string",
    points: "number",
    layout: "string",
    evaluation: "string",
    order: "number",
    example: "number",
    answerOptionsMaxHeight: "number",
    answerOptionsMaxWidth: "number",
  });
  return {
    ...data,
    example: data.example ? true : false,
    isImage: data.isImage ? true : false,
  };
};

const data2questions = (data: QuestionDataT[]): QuestionT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2question(d));
};

const question2data = (question: QuestionT): QuestionDataT => {
  return {
    id: question.id,
    type: question.type,
    difficulty: question.difficulty,
    rating: question.rating,
    duration: question.duration,
    question: question.question || "",
    answerOptions: question.answerOptions,
    answer: question.answer,
    comment: question.comment || "",
    order: question.order,
    points: question.points,
    layout: question.layout,
    evaluation: question.evaluation,
    example: question.example ? 1 : 0,
    isImage: question.isImage ? 1 : 0,
  };
};

const questions2data = (categories: QuestionT[]): QuestionDataT[] => {
  return categories.map((question) => question2data(question));
};

const APIPATH = "/question";

/** ************************************************************************
 *
 * @returns
 */
export const uploadQuestion = async (
  data: any
): Promise<CallApiResultT<number>> => {
  const res = await post<number>("/question-upload", data, {
    "Content-Type": "multipart/form-data",
  });

  console.log("uploadQuestion", res);

  if (res.status === 400) {
    return {
      success: false,
      status: res.status,
      error: res.error,
      errors: res.errors,
    };
  }

  return res;
};

/** ************************************************************************
 *
 *
 */
interface FetchQuestionsPropsI {
  limit?: number;
  subCategoryId?: number;
}
export const fetchQuestions = async ({
  limit = 0,
  subCategoryId = 0,
}: FetchQuestionsPropsI): Promise<CallApiResultT<QuestionT[]>> => {
  const res = await get(APIPATH, {
    ...(limit ? { limit: limit } : {}),
    ...(subCategoryId ? { subCategoryId: subCategoryId } : {}),
  });

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchQuestions] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2questions(res.data),
  };
};

/** ************************************************************************
 *
 * @returns addedQuestion
 */
export const addQuestion = async (
  question: QuestionT
): Promise<CallApiResultT<QuestionT>> => {
  // console.log("addMedia", question);

  const res = await post<QuestionDataT>(APIPATH, question2data(question));

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2question(res.data as QuestionDataT),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateQuestion = async (
  question: QuestionT
): Promise<CallApiResultT<number>> => {
  const res = await put<number>(
    APIPATH + "/" + question.id,
    question2data(question)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateQuestionBulk = async (
  questions: QuestionT[]
): Promise<CallApiResultT<number>> => {
  const res = await put<number>(APIPATH, questions2data(questions));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteQuestion = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`);

  return res;
};

import { useContext } from "react";
import { fetchCountries, CountryT } from "./api";
import { CountryContext } from "./CountryContext";
import { useLoadData } from "../shared/hooks/useLoadData";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import React from "react";

export interface UseCountrysResultT extends UseResultT {
  countryList: CountryT[];
  setCountryList: React.Dispatch<React.SetStateAction<CountryT[]>>;
}

interface UseCountrysPropsI extends UsePropsI {
  check?: boolean;
}

export const useCountries = ({
  dontShowProgress = false,
  dontHideProgress = false,
  check = false,
}: UseCountrysPropsI): UseCountrysResultT => {
  const { countryList, setCountryList, loadingDone, setLoadingDone } =
    useContext(CountryContext);

  useLoadData({
    fetchFunc: fetchCountries,
    list: countryList,
    setList: setCountryList,
    setLoadingDone,
    dontHideProgress,
    dontShowProgress,
    name: "fetchCountrys",
  });

  return { countryList, setCountryList, loadingDone };
};

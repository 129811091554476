import { useEffect, useReducer } from "react";
import { jsonDecode } from "../shared/helper/json";
import { VIEW } from "./RelationTable";
import { viewReducer } from "./viewReducer";

export const useView = () => {
  const last = jsonDecode(localStorage.getItem("relView") || "");

  const [view, setView] = useReducer(viewReducer, {
    [VIEW.PROF]: last ? last[VIEW.PROF] : 3,
    [VIEW.MAIN]: last ? last[VIEW.MAIN] : 3,
    [VIEW.SUB]: last ? last[VIEW.SUB] : 3,
    [VIEW.QUEST]: last ? last[VIEW.QUEST] : 3,
  });

  console.log("%cuseView.ts line:16 view", "color: #007acc;", view);

  useEffect(() => {
    localStorage.setItem("relView", JSON.stringify(view));
  }, [view]);

  return [view, setView] as const;
};

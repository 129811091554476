import { Button, Modal, Typography, styled } from "@mui/material";
import Box from "@mui/system/Box";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { useContext, useState } from "react";
import { LayoutContext } from "../../testModus/LayoutContext";
import { CustomerHead } from "./CustomerHead";
import { AuthContext } from "../../auth/AuthContext";
import { addBodyClass } from "../../shared/lib/useBodyClass";
import { DegebaLogo } from "./DegebaLogo";
import { CandidatePrivacy } from "./CandidatePrivacy";

export const CandidateStart = () => {
  const [privacy, setPrivacy] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const { layout } = useContext(LayoutContext);
  const { authState } = useContext(AuthContext);

  addBodyClass("start");

  return (
    <>
      <Box className="candidate-start">
        <CustomerHead />

        {authState.user.professionDesc ? (
          <Box
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{
              __html: authState.user.professionDesc,
            }}
          ></Box>
        ) : (
          <Box>
            <Box sx={{ fontWeight: "bold", fontSize: "18px", mt: 2 }}>
              Liebe Bewerberin, lieber Bewerber,
            </Box>
            <br />
            herzlichen Dank für Ihre Teilnahme. Um allen Bewerber*innen eine
            faire Chance zu geben, nutzen wir diesen Online-Test. Wenn Sie den
            Datenschutzbestimmungen zugestimmt haben, können Sie den Test
            starten.
            <br />
            <br />
            Bitte beachten Sie folgende Bearbeitungshinweise:
            <ul>
              <li>
                <span>Planen Sie ausreichend Zeit ein.</span>
              </li>
              <li>
                <span>
                  Arbeiten Sie in einem ruhigen, störungsfreien Raum und stellen
                  Sie Ihr Smartphone auf &bdquo;Nicht stören&ldquo;.
                </span>
              </li>
              <li>
                <span>
                  Nutzen Sie am besten einen Desktop-PC oder Laptop mit Maus, an
                  Mobilgeräten ist die Bearbeitung nicht optimal.
                </span>
              </li>
              <li>
                <span>
                  Für den Test brauchen Sie eine stabile Internetverbindung und
                  einen aktuellen Browser (z.B. Chrome, Firefox, Safari, Opera,
                  Edge). Deaktivieren Sie ggf. den &bdquo;Dark Mode&ldquo;
                  (Dunkelmodus) Ihres Betriebssystems bzw. Browsers.
                </span>
              </li>
              <li>
                <span>
                  Legen Sie sich einen Taschenrechner sowie Stift und Papier für
                  Vermerke oder Nebenrechnungen bereit.
                </span>
              </li>
            </ul>
            <br /> Dieser Test beinhaltet mehrere Kategorien und
            Unterkategorien. Innerhalb einer Unterkategorie können Sie in den
            Aufgaben vor- und zurückblättern.
            <br />
            <br />
            Lesen Sie sich die Hinweise und Erklärungen gründlich durch,
            bearbeiten Sie die Fragen möglichst schnell und sorgfältig. Bewahren
            Sie Ruhe – kaum jemand schafft es, in der vorgegebenen
            Bearbeitungszeit alle Aufgaben richtig zu beantworten.
            <br />
            <br /> Wenn Ihnen eine Frage nicht auf Anhieb lösbar erscheint, dann
            wechseln Sie zur nächsten Aufgabe. Falls Sie innerhalb einer
            Unterkategorie am Ende noch Zeit haben, können Sie zu den
            unbeantworteten Fragen zurückblättern.
            <br />
            <br />
          </Box>
        )}
        <Box>
          <input
            type="checkbox"
            id="checkbox"
            onChange={(e) => setPrivacy(e.target.checked)}
          />{" "}
          <label htmlFor="checkbox">
            Ja, ich habe die{" "}
            <a
              href="#"
              onClick={() => {
                setShowPrivacy(true);
                return false;
              }}
            >
              Datenschutzbestimmungen
            </a>{" "}
            gelesen und stimme Ihnen zu.
          </label>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 0,
            mb: 2,
          }}
        >
          <Link
            component={RouterLink}
            to={privacy ? "/candidate/testmodus" : ""}
            underline="none"
          >
            <Button
              variant="contained"
              disabled={!privacy}
              sx={{
                mt: 2,
                ...(layout && layout.logo ? {} : {}),
              }}
            >
              weiter
            </Button>
          </Link>
        </Box>
      </Box>
      {layout && layout.logo && (
        <DegebaLogo className="start degeba-logo-trans" />
      )}
      <CandidatePrivacy
        showPrivacy={showPrivacy}
        setShowPrivacy={setShowPrivacy}
      />
    </>
  );
};

import { get, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";

type SystemHealthCheckDataT = {
  id: number;
  name: string;
  details: string;
  createdAt: string;
  data: string;
};

export type SystemHealthCheckT = {
  id: number;
  name: string;
  details: string;
  createdAt: Date;
  data: string;
};

const data2systemhealthcheck = (
  data: SystemHealthCheckDataT
): SystemHealthCheckT => {
  return { ...data, createdAt: new Date(data.createdAt) };
};

const data2systemhealthchecks = (
  data: SystemHealthCheckDataT[]
): SystemHealthCheckT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2systemhealthcheck(d));
};

/** ************************************************************************
 *
 *
 */
export const fetchSystemHealthChecks = async (): Promise<
  CallApiResultT<SystemHealthCheckT[]>
> => {
  const res = await get("/systemhealthcheck");
  console.log("fetchSystemHealthChecks res:", res);
  if (!res.success || !(res.data && isArray(res.data))) {
    throw Error("[fetchSystemHealthChecks] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2systemhealthchecks(res.data),
  };
};

import { useProfessions } from "../profession/useProfessions";
import { useCandidates } from "./useCandidates";
import { AddCandidate } from "./AddCandidate";
import { Loader } from "../shared/Loader";
import { CandidateSwitch } from "./CandidateSwitch";
import { useCountries } from "../country/useCountries";
import { useHideProgress } from "../feedback/useHideProgress";

export const CandidateLoader = () => {
  const { candidateList, loadingDone: loadingDoneCa } = useCandidates({
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneProf } = useProfessions({
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneCo } = useCountries({
    dontHideProgress: true,
  });

  if (!useHideProgress(loadingDoneCa && loadingDoneProf && loadingDoneCo))
    return <Loader />;

  // if (candidateList.length === 0) return <AddCandidate />;
  return <CandidateSwitch />;
};

import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { memo } from "react";
import { Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { getYearArray, actYear } from "../helper/datetime";

interface YearSelectPropI {
  id: string;
  value?: number;
  setValue: (value: number) => void;
  label?: string;
  labelId?: string;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}

export const YearSelect = memo((props: YearSelectPropI) => {
  return (
    <Typography variant="body2" component="span">
      <Select
        labelId={props.labelId}
        id={props.id}
        value={props.value ? props.value : actYear()}
        onChange={(e: SelectChangeEvent<number>) => {
          props.setValue(e.target.value as unknown as number);
        }}
        label="{props.label}"
        size={props.size ? props.size : "small"}
        sx={{
          ...(props.size === "medium"
            ? {}
            : { fontSize: (theme) => theme.typography.body2.fontSize }),
          ...props.sx,
        }}
        data-testid="YearSelect"
      >
        {getYearArray().map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </Typography>
  );
});

import { Loader } from "../shared/Loader";
import { AddProfession } from "./AddProfession";
import { ProfessionTable } from "./ProfessionTable";
import { useProfessions } from "./useProfessions";

export const ProfessionLoader = () => {
  const { professionList, loadingDone } = useProfessions({ check: false });

  if (!loadingDone) return <Loader />;

  if (professionList.length === 0) {
    if (loadingDone) return <AddProfession />;
    else return <Loader />;
  }

  return <ProfessionTable />;
};

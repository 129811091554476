import { useContext } from "react";
import { fetchCategories, MainCategoryT } from "./api";
import { MainCategoryContext } from "./MainCategoryContext";

import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseMainCategoriesResultT extends UseResultT {
  categoryList: MainCategoryT[];
  setCategoryList: React.Dispatch<React.SetStateAction<MainCategoryT[]>>;
}

interface UseMainCategoriesPropsI extends UsePropsI {
  onlyActive?: boolean;
}

export const useMainCategories = ({
  onlyActive = false,
  dontHideProgress = false,
}: UseMainCategoriesPropsI): UseMainCategoriesResultT => {
  const { categoryList, setCategoryList, loadingDone, setLoadingDone } =
    useContext(MainCategoryContext);

  useLoadData({
    fetchFunc: () => fetchCategories(onlyActive),
    list: categoryList,
    setList: setCategoryList,
    setLoadingDone,
    dontHideProgress,
  });

  return { categoryList, setCategoryList, loadingDone };
};

import { Typography } from "@mui/material";
import Box from "@mui/system/Box";
import { ProfessionT } from "../../profession/api";
import { AdminProfessionWidget } from "./AdminProfessionWidget";
import { SystemHealthCheckWidget } from "./SystemHealthCheckWidget";

interface AdminDashboardI {
  professionList: ProfessionT[];
}

export const AdminDashboard = ({ professionList }: AdminDashboardI) => {
  return (
    <Box>
      <Typography variant="h1" component="h1">
        Admin Dashboard
      </Typography>
      <AdminProfessionWidget professionList={professionList} />
      <SystemHealthCheckWidget />
    </Box>
  );
};

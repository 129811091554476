import { ReactElement, useContext, useEffect, useState } from "react";
import { checkResetPasswortToken, setNewPasswordToken } from "./api";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as RouterLink, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";
import Alert from "@mui/material/Alert";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ChangePasswordForm } from "../user/ChangePasswordForm";
import { NotFound404 } from "../error/NotFound404";
import { Loader } from "../shared/Loader";
import { ResetTokenExpired } from "../error/ResetTokenExpired";

interface ResertPasswortPropsI {
  buttonText?: string;
  successText?: string;
}

export const ResetPassword = (props: ResertPasswortPropsI): ReactElement => {
  const params = useParams();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [role, setRole] = useState("");
  const [checkDone, setCheckDone] = useState(0);
  const { showProgress, hideProgress } = useContext(FeedbackContext);

  useEffect(() => {
    if (!("token" in params) || !params.token?.match(/^[a-z0-9]{32}$/i)) return;
    checkResetPasswortToken({ token: params.token })
      .then((result) => {
        if (result.success) {
          setCheckDone(1);
          setRole(result.data?.role || "");
        } else setCheckDone(2);
      })
      .catch((e) => {
        setCheckDone(2);
      });
  }, [params]);

  if (!("token" in params) || !params.token?.match(/^[a-z0-9]{32}$/i))
    return <NotFound404 />;

  console.log("checkDone:", checkDone);
  if (!checkDone) return <Loader />;
  if (checkDone === 2) return <ResetTokenExpired />;

  const clickHandler = (newPassword: string) => {
    console.log("new:", newPassword);

    setSuccess("");
    setError("");
    showProgress();
    setNewPasswordToken({ token: params.token || "", password: newPassword })
      .then((result) => {
        setSuccess(props.successText || "Passwort erfolgreich geändert.");
        hideProgress();
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="login-box"
      >
        <Box className="logo degeba-logo"></Box>

        <Typography component="h1" variant="h5">
          Neues Passwort setzen
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ width: "100%" }}>
            {success}
          </Alert>
        )}
        <Box component="form" noValidate sx={{ mt: 1 }}>
          {success === "" && (
            <ChangePasswordForm
              onSave={clickHandler}
              buttonText={props.buttonText}
            />
          )}
          <Link
            component={RouterLink}
            to={"/" + role + ""}
            sx={{ pt: 2, ml: 1, display: "inline-block" }}
          >
            Login
          </Link>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

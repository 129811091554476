import { useContext, useEffect, useState } from "react";
import { ProfessionChangedT, ProfessionContext } from "./ProfessionContext";
import {
  copyProfession,
  deleteProfession,
  ProfessionT,
  updateProfession,
} from "./api";

import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";

import { ErrorContext } from "../error/ErrorContext";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { DeleteButton } from "../shared/forms/DeleteButton";
import { FeedbackContext } from "../feedback/FeedbackContext";

import { deleteStateFromArrayById } from "../shared/helper/state";

import { EditProfession } from "./EditProfession";
import { Active } from "../shared/forms/Active";
import { Grid, Typography } from "@mui/material";
import { ProfessionCheckResult } from "./ProfessionCheckResult";
import { TooltipIcon } from "../shared/forms/TooltipIcon";
import { Link, useOutlet, useRoutes } from "react-router-dom";
import { CandidateCount } from "./CandidateCount";
import { useSelTree } from "../relation/useSelTree";
import { Tooltip } from "../shared/forms/Tooltip";
import { ProfessionTypeT, TRANSLATE_TYPE } from "./TypeSelect";

interface ProfessionPropsI {
  profession: ProfessionT;
  setShowVersions: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Profession = ({
  profession,
  setShowVersions,
}: ProfessionPropsI) => {
  const [showEdit, setShowEdit] = useState(false);

  const { setProfessionList } = useContext(ProfessionContext);

  const [professionState, setProfessionState] =
    useState<ProfessionChangedT>(profession);

  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  const onClickCopy = (version = false) => {
    copyProfession(professionState.id, version)
      .then((result) => {
        if (result.success && result.data) {
          openSnackbar(
            "success",
            version ? "Neue Version angelegt" : "Beruf kopiert"
          );
          setProfessionList((p) =>
            p
              .map((e) =>
                e.id === result.data?.parentId
                  ? { ...e, childId: result.data.id }
                  : e
              )
              .concat(result.data as ProfessionT)
          );
        } else {
          setError("Copy Error");
        }
      })
      .catch((error) => {
        // console.log("setActive catch", error);
        setError(error);
      });
  };

  const { selTree, setSelTree } = useSelTree();

  const setActive = (active: boolean) => {
    console.log(
      "%cProfession.tsx line:83 professionState",
      "color: #007acc;",
      professionState
    );
    showProgress();
    setProfessionState({ ...professionState, active: active }); // we don't use prevState here to avoid 2 versions in db and state
    updateProfession({ ...professionState, active: active })
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setProfessionList((p) =>
            p.map((e) => {
              if (e.id === professionState.id) return { ...e, active: active };
              return e;
            })
          );
          if (selTree.professionId === professionState.id) {
            setSelTree({ professionIsActive: active });
          }
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const setDemo = (demo: boolean) => {
    showProgress();
    setProfessionState({ ...professionState, demo: demo });
    updateProfession({ ...professionState, demo: demo })
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setProfessionList((p) =>
            p.map((e) => {
              if (e.id === professionState.id) return { ...e, demo: demo };
              return e;
            })
          );
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={profession.id}
        sx={{ backgroundColor: profession.childId ? "lightgray" : "" }}
      >
        <Tooltip
          title={showEdit ? "Weniger anzeigen" : "Details anzeigen"}
          placement="right"
        >
          <TableCell
            component="th"
            scope="row"
            padding="none"
            onClick={(e) => setShowEdit((p) => !p)}
            sx={{ width: 50 }}
          >
            {showEdit ? (
              <ArrowCircleUpOutlinedIcon />
            ) : (
              <ArrowCircleDownOutlinedIcon />
            )}
          </TableCell>
        </Tooltip>
        {["testId", "name", "targetValue"].map((id) => (
          <TableCell component="th" scope="row" padding="none" key={id}>
            <Typography variant="body2" onClick={(e) => setShowEdit(true)}>
              {(profession as any)[id]}
            </Typography>
          </TableCell>
        ))}
        <TableCell component="th" scope="row" padding="none" key="type">
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2" onClick={(e) => setShowEdit(true)}>
                {TRANSLATE_TYPE[profession.type as ProfessionTypeT]}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {profession.childId && (
                <TooltipIcon
                  type="versionNewer"
                  title="neuere Version vorhanden"
                  onClick={() => setShowVersions(true)}
                  iconProps={{ fontSize: "small", color: "info" }}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              {profession.parentId && (
                <TooltipIcon
                  type="versionOlder"
                  title="ältere Version vorhanden"
                  onClick={() => setShowVersions(true)}
                  iconProps={{ fontSize: "small", color: "info" }}
                />
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          padding="none"
          key="countQuestions"
        >
          <span onClick={(e) => setShowEdit(true)}>
            {profession.countQuestions || 0}
          </span>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          padding="none"
          key="candidateCount"
        >
          <CandidateCount
            count={profession.candidateCount || 0}
            professionId={profession.id}
            setProfessionList={setProfessionList}
          />
        </TableCell>
        <TableCell component="th" scope="row" padding="none" key="checkResult">
          <span onClick={(e) => setShowEdit(true)}>
            <ProfessionCheckResult result={profession.checkResult || ""} />
          </span>
        </TableCell>
        <TableCell padding="none" sx={{ width: 240 }} align="center">
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={1}>
              <a
                href={
                  "/admin/testmodus/" +
                  profession.id +
                  "/" +
                  profession.name.replace(/\//g, "%2F")
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <TooltipIcon
                  type="login"
                  title={
                    profession.checkResult === "ok"
                      ? "Testen"
                      : "Testen nicht möglich - Status nicht ok"
                  }
                  iconProps={{
                    ...(profession.checkResult === "ok"
                      ? {}
                      : {
                          color: "disabled",
                        }),
                  }}
                />
              </a>
            </Grid>
            <Grid item xs={1}>
              <Link to={"/admin/results/" + profession.id}>
                <TooltipIcon
                  type="results"
                  title={
                    profession.testResultCount && profession.testResultCount > 0
                      ? "Test-Ergebnisse"
                      : "keine Test-Ergebnisse vorhanden"
                  }
                  iconProps={{
                    ...(profession.testResultCount &&
                    profession.testResultCount > 0
                      ? {}
                      : {
                          color: "disabled",
                        }),
                  }}
                />
              </Link>
            </Grid>
            <Active
              active={profession.active ? true : false}
              setActive={setActive}
              disableSetActive={
                (!profession.active && profession.checkResult !== "ok") ||
                (profession.active && Boolean(profession.resultCount)) ||
                Boolean(profession.transactionCount)
              }
              useOutsideGrid={true}
            />
            <Grid item xs={1}>
              <DeleteButton
                id={profession.id}
                deleteFunc={deleteProfession}
                successFunc={() =>
                  setProfessionList((p) =>
                    deleteStateFromArrayById(p, profession.id)
                  )
                }
                {...(profession.relationCount || profession.candidateCount
                  ? {
                      disabled: true,
                      tooltipTitle: profession.relationCount
                        ? "Diesem Beruf wurden bereits Kategorien zugeordnet"
                        : "Diesem Beruf wurden bereits Kandidaten zugeordnet",
                    }
                  : {})}
              />
            </Grid>
            <Grid item xs={1}>
              <TooltipIcon
                type="version"
                title="Neue Version erstellen"
                onClick={() => onClickCopy(true)}
              />
            </Grid>
            <Grid item xs={1}>
              <TooltipIcon
                type="copy"
                title="Kopieren"
                onClick={() => onClickCopy(false)}
              />
            </Grid>
            <Grid item xs={1}>
              <TooltipIcon
                type="demo"
                off={profession.demo ? false : true}
                title={
                  profession.demo
                    ? "Demo-Modus aktiv"
                    : "Demo-Modus nicht aktiv"
                }
                onClick={(e: any) => setDemo(!profession.demo)}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      {showEdit && (
        <TableRow>
          <TableCell colSpan={8}>
            <EditProfession
              setShowEdit={setShowEdit}
              profession={profession}
              setProfession={setProfessionState}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

import { post, CallApiResultT, get } from "../shared/lib/api";
import { QuestionT, QuestionDataT } from "../question/api";
import { ClickMatrixMappingT } from "./questionTypes/QuestionClickMatrix";
import { DnDMappingT } from "./questionTypes/QuestionDnD";
import { checkApiResult } from "../shared/lib/checkApi";
import { ClozeTextMappingT } from "./questionTypes/QuestionClozeText";
import { ProfessionT } from "../profession/api";
import axios from "axios";

export const IMGPATH = "/storage/content/";

export interface TestModusQuestionT
  extends Omit<
    QuestionT,
    "order" | "subCategories" | "answer" | "answerDecoded"
  > {
  noInSubCategory: number;
  mainCategoryName: string;
  mainCategoryId: number;
  mainCategoryDesc: string;
  mainCategoryImg: string;
  subCategoryName: string;
  subCategoryId: number;
  subCategoryDesc: string;
  subCategoryMemText: string;
  subCategoryMemTime: number;
  candidateAnswer?:
    | string
    | DnDMappingT
    | ClickMatrixMappingT
    | ClozeTextMappingT;
  questionDecoded: string;
  answer?: string; // only in preview-modus
  answerDecoded?: any; // only in preview-modus
}

export interface TestModusQuestionDataT
  extends Omit<
    QuestionDataT,
    "order" | "subCategories" | "answer" | "answerDecoded"
  > {
  noInSubCategory?: number; // optional, because not when example
  mainCategoryName: string;
  mainCategoryId: number;
  mainCategoryDesc: string;
  mainCategoryImg?: string;
  subCategoryName: string;
  subCategoryId: number;
  subCategoryDesc: string;
  subCategoryMemText: string;
  subCategoryMemTime: number;
  questionDecoded: string;
  answerOptionsDecoded: any;
  answer?: string; // only in preview-modus
  answerDecoded?: any; // only in preview-modus
}

export interface TestModusCategoryInfoT {
  [id: number]: {
    count: number;
    duration: number;
    no: number;
  };
}

export interface TestModusMainCategoryInfoT {
  [id: number]: {
    imgName: string;
    imgData: string;
  };
}

export interface TestModusMetaDataT {
  subCategoryInfo: TestModusCategoryInfoT;
  profession: ProfessionT;
  mainCategoryInfo: TestModusMainCategoryInfoT;
}

export interface SettingsDataT {
  compensationPercent: number;
  compensationPause: number;
}

export interface DataT {
  questions: TestModusQuestionDataT[];
  meta: TestModusMetaDataT;
  settings: SettingsDataT;
}

export type TestModusSubCategoryTimeT = {
  [subCategoryId: number]: number;
  total: number;
};

export type CandidateSettingsT = {
  compensationPercent: number;
  compensationPause: number;
};

export type FetchQuestionsResultT = [
  TestModusQuestionT[],
  ProfessionT,
  TestModusCategoryInfoT,
  TestModusMainCategoryInfoT,
  CandidateSettingsT
];

/**
 * data from api is divided in an array with
 * questions and meta
 *
 */
export const data2questionsMeta = (data: DataT): FetchQuestionsResultT => {
  checkApiResult("data2questionsMeta", data, {
    questions: "array",
    meta: "object",
    settings: "object",
  });
  checkApiResult("data2questionsMeta", data.meta, {
    subCategoryInfo: "object",
  });
  checkApiResult("data2questionsMeta", data.meta, {
    profession: "object",
  });

  return [
    (data.questions as TestModusQuestionDataT[]).map((d) => data2question(d)),
    data.meta.profession,
    data.meta.subCategoryInfo,
    data.meta.mainCategoryInfo,
    data.settings,
  ];
};

const data2question = (data: TestModusQuestionDataT): TestModusQuestionT => {
  checkApiResult("data2question id:" + data.id, data, {
    id: "number",
    type: "string",
    difficulty: "number",
    duration: "number",
    question: "string",
    questionDecoded: "string",
    answerOptions: "string",
    answerOptionsDecoded: "object",
    comment: "string",
    points: "number",
    layout: "string",
    evaluation: "string",
    mainCategoryName: "string",
    mainCategoryId: "number",
    mainCategoryDesc: "string",
    subCategoryName: "string",
    subCategoryId: "number",
    subCategoryDesc: "string",
    subCategoryMemTime: "number",
    subCategoryMemText: "string",
    example: "number",
    answerOptionsMaxHeight: "number",
    answerOptionsMaxWidth: "number",
  });

  return {
    id: data.id,
    type: data.type,
    rating: data.rating,
    difficulty: data.difficulty || 0,
    duration: data.duration || 0,
    question: data.question || "",
    questionDecoded: data.questionDecoded || "",
    answerOptions: data.answerOptions || "",
    comment: data.comment,
    answerOptionsDecoded: data.answerOptionsDecoded || [],
    answerOptionsMaxHeight: data.answerOptionsMaxHeight || 0,
    answerOptionsMaxWidth: data.answerOptionsMaxWidth || 0,
    mainCategoryName: data.mainCategoryName,
    mainCategoryId: data.mainCategoryId,
    mainCategoryDesc: data.mainCategoryDesc,
    mainCategoryImg: data.mainCategoryImg || "",
    subCategoryName: data.subCategoryName,
    subCategoryId: data.subCategoryId,
    subCategoryDesc: data.subCategoryDesc,
    subCategoryMemText: data.subCategoryMemText,
    subCategoryMemTime: data.subCategoryMemTime,
    points: data.points,
    layout: data.layout,
    evaluation: data.evaluation,
    example: data.example ? true : false,
    isImage: data.isImage ? true : false,
    noInSubCategory: data.noInSubCategory || 0,
    countTotal: data.countTotal || 0,
  };
};

const results2data = (questions: TestModusQuestionT[]): any => {
  return questions.map((r) => result2data(r));
};

const result2data = (question: TestModusQuestionT): any => {
  if (question.type === "dnd_1u1_zuordnungen")
    return {
      questionId: question.id,
      answer:
        question.candidateAnswer !== null
          ? JSON.stringify(question.candidateAnswer)
          : undefined,
    };
  else if (question.type === "klickmatrix")
    return {
      questionId: question.id,
      answer:
        question.candidateAnswer !== null
          ? JSON.stringify(question.candidateAnswer)
          : undefined,
    };
  else if (question.type === "lueckentext")
    return {
      questionId: question.id,
      answer:
        question.candidateAnswer !== null
          ? JSON.stringify(question.candidateAnswer)
          : undefined,
    };
  else
    return {
      questionId: question.id,
      answer:
        question.candidateAnswer !== null
          ? question.candidateAnswer
          : undefined,
    };
};

const times2data = (data: TestModusSubCategoryTimeT) => {
  return Object.entries(data)
    .map(([subCategoryId, time]) =>
      subCategoryId === "total"
        ? null
        : { subCategoryId: subCategoryId, time: time }
    )
    .filter((c) => c !== null);
};

/** ************************************************************************
 *
 *
 */
interface FetchQuestionsI {
  professionId?: number;
}
export const fetchQuestions = async ({
  professionId,
}: FetchQuestionsI): Promise<CallApiResultT<FetchQuestionsResultT>> => {
  console.log("%capi.ts line:238 getQuestions", "color: #007acc;");
  const res = await get<DataT>("/question", { professionId: professionId });
  console.log("%capi.ts line:240 res", "color: #007acc;", res);

  if (!res.data) {
    throw Error("[fetchQuestions] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2questionsMeta(res.data),
  };
};

/** ************************************************************************
 *
 */
export const addResult = async (
  data: TestModusQuestionT[],
  candidateSubCategoryTime: TestModusSubCategoryTimeT,
  professionId?: number
): Promise<CallApiResultT<number>> => {
  const res = await post<number>("/result", {
    results: results2data(data),
    times: times2data(candidateSubCategoryTime),
    professionId: professionId,
  });

  return res;
};

export const saveEmergencyResult = async (data: any) => {
  axios.post("https://www.devww.de/degeba/degeba.php", data, {
    withCredentials: false,
  });
};

/** ************************************************************************
 *
 */
export const addTmpResult = async (
  data: TestModusQuestionT[],
  json: string,
  professionId?: number
): Promise<CallApiResultT<number>> => {
  professionId;
  const res = await post<number>("/tmpresult", {
    professionId: professionId,
    results: results2data(data),
    json: json,
  });

  return res;
};

/** ************************************************************************
 *
 */
export const logCandidate = (type: string): Promise<CallApiResultT<number>> => {
  return post<number>("/candidatelog", {
    type: type,
  })
    .then((res) => res)
    .catch(() => {
      const timestamp = new Date().getTime();

      return new Promise<CallApiResultT<number>>((resolve) => {
        const intervalId = setInterval(() => {
          post<number>("/candidatelog", {
            type: type,
            timestamp,
          })
            .then((result) => {
              clearInterval(intervalId);
              resolve(result);
            })
            .catch(() => {});
        }, 5000);
      });
    });
};

import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface SearchFieldPropsI {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sx?: SxProps<Theme>;
  id?: string;
  onChange?: () => void;
  handleChangePage?: (newPage: number) => void;
  className?: string;
}

export const SearchField = (props: SearchFieldPropsI) => {
  const id = props.id || "filterText";
  const onChange = (searchValue: string) => {
    localStorage.setItem("search:" + id, searchValue);
    props.setValue(searchValue);
    props.onChange && props.onChange();
    props.handleChangePage && props.handleChangePage(0);
  };
  return (
    <TextField
      data-testid="searchField"
      size="small"
      placeholder="Search..."
      id={id}
      type="search"
      value={props.value}
      onChange={(e) => onChange(e.target.value)}
      className={props.className}
      sx={{
        ...(props.value !== ""
          ? {
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "primary.main",
              backgroundColor: "lightgray",
            }
          : {}),
        ...props.sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

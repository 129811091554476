import { useShopOrders } from "./useShopOrders";
import { ShopOrderTable } from "./ShopOrderTable";
import { Loader } from "../shared/Loader";
import { useContext } from "react";
import { Box } from "@mui/material";
import { ShopCartTable } from "./ShopCartTable";
import { AdminShopOrderTable } from "./AdminShopOrderTable";
import { AuthContext } from "../auth/AuthContext";

interface ShopOrderLoaderPropsI {
  cart?: boolean;
}

export const ShopOrderLoader = ({ cart }: ShopOrderLoaderPropsI) => {
  const { authState } = useContext(AuthContext);

  const { orderList, loadingDone } = useShopOrders({});

  if (!loadingDone) return <Loader />;

  if (authState.role === "admin") return <AdminShopOrderTable />;

  if (orderList.length === 0)
    return <Box>Sie haben noch keine Bestellung bei uns aufgegeben.</Box>;
  return cart ? <ShopCartTable /> : <ShopOrderTable />;
};

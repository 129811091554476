import { addStateToArray } from "../shared/helper/state";
import { QuestionRelationT, RelationT, SubRelationT } from "./api";
import { FilteredQuestionT } from "./QuestionRelationTable";
import { FilteredMainCategoryT, FilteredSubCategoryT } from "./RelationTable";

export enum RELATIONREDUCERACTIONS {
  INIT = "INIT",
  MOVEMAIN = "MOVEMAIN",
  SAVEMAIN = "SAVEMAIN",
  ADDSUB = "ADDSUB",
  DELSUB = "DELSUB",
  MOVESUB = "MOVESUB",
  SAVESUB = "SAVESUB",
  MOVEQUESTION = "MOVEQUESTION",
  ADDQUESTION = "ADDQUESTION",
  ADDQUESTIONBULK = "ADDQUESTIONBULK",
  DELQUESTION = "DELQUESTION",
  DELQUESTIONBULK = "DELQUESTIONBULK",
}

interface ProfT {
  professionId: number;
}
interface ProfMainT extends ProfT {
  mainCategoryId: number;
}
interface ProfMainSubT extends ProfMainT {
  subCategoryId: number;
}

interface MoveMainT extends ProfT {
  categories: FilteredMainCategoryT[];
}

interface SaveMainT extends ProfMainT {
  mainName: string;
}

interface AddSubT extends ProfMainT {
  add: SubRelationT;
}

interface DelSubT extends ProfMainT {
  relationId: number;
}

interface MoveSubT extends ProfMainT {
  categories: FilteredSubCategoryT[];
}

interface SaveSubT extends ProfMainSubT {
  subName: string;
}

interface MoveQuestionT extends ProfMainSubT {
  questions: FilteredQuestionT[];
}

interface AddQuestionT extends ProfMainSubT {
  add: QuestionRelationT;
}

interface AddQuestionBulkT extends ProfMainSubT {
  add: QuestionRelationT[];
}

interface DelQuestionT extends ProfMainSubT {
  relationId: number;
}
interface DelQuestionBulkT extends ProfMainSubT {}

// M muss vom Typ eines Objectes sein, dass einen index:string enthält und als value ein array von blocks
type ActionMapT<
  M extends {
    [index: string]:
      | RelationT[]
      | MoveMainT
      | SaveMainT
      | AddSubT
      | DelSubT
      | MoveSubT
      | SaveSubT
      | MoveQuestionT
      | AddQuestionT
      | AddQuestionBulkT
      | DelQuestionT
      | DelQuestionBulkT;
  }
> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        action: string | number;
      }
    : {
        action: string | number;
        data: M[Key];
      };
};

// PayloadObject kann unterschiedlich sein, je nach verwendeter Action
// action -> parameter
export type PayloadDataPropsT = {
  [RELATIONREDUCERACTIONS.INIT]: RelationT[];
  [RELATIONREDUCERACTIONS.SAVEMAIN]: SaveMainT;
  [RELATIONREDUCERACTIONS.MOVEMAIN]: MoveMainT;
  [RELATIONREDUCERACTIONS.ADDSUB]: AddSubT;
  [RELATIONREDUCERACTIONS.DELSUB]: DelSubT;
  [RELATIONREDUCERACTIONS.SAVESUB]: SaveSubT;
  [RELATIONREDUCERACTIONS.MOVESUB]: MoveSubT;
  [RELATIONREDUCERACTIONS.ADDQUESTION]: AddQuestionT;
  [RELATIONREDUCERACTIONS.ADDQUESTIONBULK]: AddQuestionBulkT;
  [RELATIONREDUCERACTIONS.MOVEQUESTION]: MoveQuestionT;
  [RELATIONREDUCERACTIONS.DELQUESTION]: DelQuestionT;
  [RELATIONREDUCERACTIONS.DELQUESTIONBULK]: DelQuestionBulkT;
};

// nur den hinteren Teil der Zuordnung als Type
export type RelationReducerPropsPayloadT =
  ActionMapT<PayloadDataPropsT>[keyof ActionMapT<PayloadDataPropsT>];

export const relationReducer = (
  prevState: RelationT[],
  payload: RelationReducerPropsPayloadT
): RelationT[] => {
  if (payload.action === RELATIONREDUCERACTIONS.INIT)
    return payload.data as unknown as RelationT[];

  /*
.___  ___.      ___       __  .__   __.   ______     ___   .___________.
|   \/   |     /   \     |  | |  \ |  |  /      |   /   \  |           |
|  \  /  |    /  ^  \    |  | |   \|  | |  ,----'  /  ^  \ `---|  |----`
|  |\/|  |   /  /_\  \   |  | |  . `  | |  |      /  /_\  \    |  |     
|  |  |  |  /  _____  \  |  | |  |\   | |  `----./  _____  \   |  |     
|__|  |__| /__/     \__\ |__| |__| \__|  \______/__/     \__\  |__|     
                                                                        
*/

  if (payload.action === RELATIONREDUCERACTIONS.MOVEMAIN) {
    const act = (
      payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEMAIN]
    ).categories[0];
    const change = (
      payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEMAIN]
    ).categories[1];

    return prevState.map((r) => {
      if (
        r.professionId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEMAIN])
          .professionId
      ) {
        if (r.mainCategoryId === act.id)
          return { ...r, mainCategoryOrder: change.order };
        if (r.mainCategoryId === change.id)
          return { ...r, mainCategoryOrder: act.order || 0 };
      }
      return r;
    });
  }
  if (payload.action === RELATIONREDUCERACTIONS.SAVEMAIN) {
    return prevState.map((r) =>
      r.professionId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.SAVEMAIN])
          .professionId &&
      r.mainCategoryId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.SAVEMAIN])
          .mainCategoryId
        ? {
            ...r,
            mainCategoryName: (
              payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.SAVEMAIN]
            ).mainName,
          }
        : r
    );
  }

  /*
         _______. __    __  .______     ______     ___   .___________.
        /       ||  |  |  | |   _  \   /      |   /   \  |           |
       |   (----`|  |  |  | |  |_)  | |  ,----'  /  ^  \ `---|  |----`
        \   \    |  |  |  | |   _  <  |  |      /  /_\  \    |  |     
    .----)   |   |  `--'  | |  |_)  | |  `----./  _____  \   |  |    
    |_______/     \______/  |______/   \______/__/     \__\  |__|     
    */

  if (payload.action === RELATIONREDUCERACTIONS.ADDSUB) {
    const result = prevState;

    if (
      !prevState.some(
        (r) =>
          r.professionId ===
            (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB])
              .professionId &&
          r.mainCategoryId ===
            (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB])
              .mainCategoryId
      )
    ) {
      result.push({
        id: 0,
        mainCategoryOrder:
          (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB]).add
            .mainAddedOrder || 0,
        professionId: (
          payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB]
        ).professionId,
        mainCategoryId: (
          payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB]
        ).mainCategoryId,
        subRelations: [
          (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB])
            .add,
        ],
      });
      return result;
    }
    return result.map((r) =>
      r.professionId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB])
          .professionId &&
      r.mainCategoryId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB])
          .mainCategoryId
        ? {
            ...r,
            subRelations: addStateToArray(
              r.subRelations,
              (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDSUB])
                .add
            ),
          }
        : r
    );
  }
  if (payload.action === RELATIONREDUCERACTIONS.DELSUB) {
    return prevState.flatMap((r) => {
      if (
        r.professionId ===
          (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELSUB])
            .professionId &&
        r.mainCategoryId ===
          (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELSUB])
            .mainCategoryId
      ) {
        const subRelationsFiltered = r.subRelations.filter(
          (s) =>
            s.id !==
            (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELSUB])
              .relationId
        );
        if (subRelationsFiltered.length === 0) return [];
        return {
          ...r,
          subRelations: subRelationsFiltered,
        };
      }
      return r;
    });
  }
  if (payload.action === RELATIONREDUCERACTIONS.SAVESUB) {
    return prevState.map((r) =>
      r.professionId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.SAVESUB])
          .professionId &&
      r.mainCategoryId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.SAVESUB])
          .mainCategoryId
        ? {
            ...r,
            subRelations: r.subRelations.map((s) =>
              s.subCategoryId ===
              (
                payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.SAVESUB]
              ).subCategoryId
                ? {
                    ...s,
                    subCategoryName: (
                      payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.SAVESUB]
                    ).subName,
                  }
                : s
            ),
          }
        : r
    );
  }
  if (payload.action === RELATIONREDUCERACTIONS.MOVESUB) {
    const act = (
      payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVESUB]
    ).categories[0];
    const change = (
      payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVESUB]
    ).categories[1];

    return prevState.map((r) => {
      if (
        r.professionId ===
          (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVESUB])
            .professionId &&
        r.mainCategoryId ===
          (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVESUB])
            .mainCategoryId
      ) {
        return {
          ...r,
          subRelations: r.subRelations.map((s) => {
            if (change && s.subCategoryId === act.id) {
              return {
                ...s,
                subCategoryOrder: change.order,
              };
            }
            if (change && s.subCategoryId === change.id) {
              return { ...s, subCategoryOrder: act.order || 0 };
            }
            return s;
          }),
        };
      }
      return r;
    });
  }

  /*    
      ______      __    __   _______     _______.___________. __    ______   .__   __.      _______.
     /  __  \    |  |  |  | |   ____|   /       |           ||  |  /  __  \  |  \ |  |     /       |
    |  |  |  |   |  |  |  | |  |__     |   (----`---|  |----`|  | |  |  |  | |   \|  |    |   (----`
    |  |  |  |   |  |  |  | |   __|     \   \       |  |     |  | |  |  |  | |  . `  |     \   \    
    |  `--'  '--.|  `--'  | |  |____.----)   |      |  |     |  | |  `--'  | |  |\   | .----)   |   
     \_____\_____\\______/  |_______|_______/       |__|     |__|  \______/  |__| \__| |_______/    
                                                                                                
    */
  if (payload.action === RELATIONREDUCERACTIONS.ADDQUESTION) {
    return prevState.map((r) =>
      r.professionId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTION])
          .professionId &&
      r.mainCategoryId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTION])
          .mainCategoryId
        ? {
            ...r,
            subRelations: r.subRelations.map((sr) =>
              sr.subCategoryId ===
              (
                payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTION]
              ).subCategoryId
                ? {
                    ...sr,
                    questionRelations: addStateToArray(
                      sr.questionRelations,
                      (
                        payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTION]
                      ).add
                    ),
                  }
                : sr
            ),
          }
        : r
    );
  }

  if (payload.action === RELATIONREDUCERACTIONS.ADDQUESTIONBULK) {
    return prevState.map((r) =>
      r.professionId ===
        (
          payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTIONBULK]
        ).professionId &&
      r.mainCategoryId ===
        (
          payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTIONBULK]
        ).mainCategoryId
        ? {
            ...r,
            subRelations: r.subRelations.map((sr) =>
              sr.subCategoryId ===
              (
                payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTIONBULK]
              ).subCategoryId
                ? {
                    ...sr,
                    questionRelations: sr.questionRelations.concat(
                      (
                        payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.ADDQUESTIONBULK]
                      ).add
                    ),
                  }
                : sr
            ),
          }
        : r
    );
  }

  if (payload.action === RELATIONREDUCERACTIONS.DELQUESTION) {
    return prevState.map((r) =>
      r.professionId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELQUESTION])
          .professionId &&
      r.mainCategoryId ===
        (payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELQUESTION])
          .mainCategoryId
        ? {
            ...r,
            subRelations: r.subRelations.map((sr) =>
              sr.subCategoryId ===
              (
                payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELQUESTION]
              ).subCategoryId
                ? {
                    ...sr,
                    questionRelations: sr.questionRelations.filter((qr) => {
                      return (
                        qr.id !==
                        (
                          payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELQUESTION]
                        ).relationId
                      );
                    }),
                  }
                : sr
            ),
          }
        : r
    );
  }

  if (payload.action === RELATIONREDUCERACTIONS.DELQUESTIONBULK) {
    return prevState.map((r) =>
      r.professionId ===
        (
          payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELQUESTIONBULK]
        ).professionId &&
      r.mainCategoryId ===
        (
          payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELQUESTIONBULK]
        ).mainCategoryId
        ? {
            ...r,
            subRelations: r.subRelations.map((sr) =>
              sr.subCategoryId ===
              (
                payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.DELQUESTIONBULK]
              ).subCategoryId
                ? {
                    ...sr,
                    questionRelations: [],
                  }
                : sr
            ),
          }
        : r
    );
  }

  if (payload.action === RELATIONREDUCERACTIONS.MOVEQUESTION) {
    const actQuestion = (
      payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEQUESTION]
    ).questions[0];
    const changeQuestion = (
      payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEQUESTION]
    ).questions[1];

    return prevState.map((r) => {
      if (
        r.professionId ===
          (
            payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEQUESTION]
          ).professionId &&
        r.mainCategoryId ===
          (
            payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEQUESTION]
          ).mainCategoryId
      ) {
        return {
          ...r,
          subRelations: r.subRelations.map((s) => {
            if (
              s.subCategoryId ===
              (
                payload.data as PayloadDataPropsT[RELATIONREDUCERACTIONS.MOVEQUESTION]
              ).subCategoryId
            ) {
              return {
                ...s,
                questionRelations: s.questionRelations.map((qr) => {
                  if (qr.questionId === actQuestion.id) {
                    return {
                      ...qr,
                      questionOrder: changeQuestion?.order || 0,
                    };
                  }
                  if (qr.questionId === changeQuestion.id) {
                    return { ...qr, questionOrder: actQuestion.order || 0 };
                  }
                  return qr;
                }),
              };
            }
            return s;
          }),
        };
      }
      return r;
    });
  }

  return prevState;
};

import { useContext, useState, useRef, useEffect, useCallback } from "react";
import { LSKEYS, TestModusContext } from "./TestModusContext";
import { Button, Typography, Box } from "@mui/material";
import { useTimer } from "./useTimer";
import { DIALOGOPTIONS, TestModusDialog } from "./TestModusDialog";
import { replaceImages } from "./helper/img";
import { TestModusHeader } from "./TestModusHeader";
import { CustomerLogo } from "../dashboard/candidate/CustomerLogo";
import { LayoutContext } from "./LayoutContext";
import { DegebaLogo } from "../dashboard/candidate/DegebaLogo";
import { set } from "lodash";

export const TestModusSubCategoryStart = () => {
  const {
    questionList,
    questionNo,
    setActSubCategory,
    subCategoryInfo,
    candidateSubCategoryTime,
    initialTimes,
  } = useContext(TestModusContext);

  const [dialogOpen, setDialogOpen] = useState(DIALOGOPTIONS.CLOSED);
  const { layout } = useContext(LayoutContext);

  /**
   * M E M O R Y - T I M E
   */
  const {
    time: timeMem,
    clearTimer: clearTimerMem,
    startTimer: startTimerMem,
  } = useTimer(initialTimes[LSKEYS.TIME_MEM], {
    name: LSKEYS.TIME_MEM,
  });

  const handleYesTime = useCallback(() => {
    setActSubCategory(questionList[questionNo].subCategoryId);
    setDialogOpen(DIALOGOPTIONS.CLOSED);
  }, [setActSubCategory, setDialogOpen]);

  const handleYesMemClick = useCallback(() => {
    setActSubCategory(questionList[questionNo].subCategoryId);
    setDialogOpen(DIALOGOPTIONS.CLOSED);
  }, [setDialogOpen]);

  const handleNoMemClick = useCallback(() => {
    setDialogOpen(DIALOGOPTIONS.CLOSED);
  }, [setDialogOpen]);

  /**
   * D I A L O G - T I M E
   * always 5 seconds
   */
  const {
    time: timeDialog,
    startTimer: startTimerDialog,
    clearTimer: clearTimerDialog,
  } = useTimer(0, {
    endTime: 5,
    endFunc: () => {
      clearTimerDialog();
      setDialogOpen(DIALOGOPTIONS.CLOSED);
      handleYesTime();
    },
    name: "timeDialog",
  });

  useEffect(() => {
    /* mobile */
    const candidate = document.getElementsByClassName("candidate")[0];
    if (candidate && typeof candidate.scrollTo === "function")
      candidate.scrollTo(0, 0);
    /* desktop */
    const main = document.getElementById("main");
    if (main && typeof main.scrollTo === "function") main.scrollTo(0, 0);
  }, [questionNo]);

  /* start timer for mem-time */
  const timerMemStarted = useRef(false);
  useEffect(() => {
    if (timerMemStarted.current) return;
    if (questionList[questionNo].subCategoryMemTime) {
      startTimerMem();
      timerMemStarted.current = true;
    }
  }, [startTimerMem, questionList[questionNo].subCategoryMemTime]);

  /** time for memorization is over */
  useEffect(() => {
    if (
      questionList[questionNo].subCategoryMemTime &&
      timeMem >= questionList[questionNo].subCategoryMemTime
    ) {
      setDialogOpen(DIALOGOPTIONS.MEM);
      startTimerDialog();
      clearTimerMem();
    }
  }, [
    timeMem,
    setDialogOpen,
    questionList[questionNo].subCategoryMemTime,
    clearTimerMem,
    startTimerDialog,
  ]);

  useEffect(() => {
    if (dialogOpen === DIALOGOPTIONS.MEM)
      localStorage.setItem(LSKEYS.COUNTDOWN_MEM, "1");
  }, [dialogOpen]);

  return (
    <>
      <TestModusDialog
        open={dialogOpen === DIALOGOPTIONS.MEM}
        handleYes={handleYesTime}
        type="mem"
        timeClose={5 - timeDialog}
      />
      <TestModusDialog
        open={dialogOpen === DIALOGOPTIONS.MEMCLICK}
        handleYes={handleYesMemClick}
        handleNo={handleNoMemClick}
        type="memclick"
      />

      <CustomerLogo className="left" />

      <Box component="main" className="subcategory-start">
        <Box
          data-testid="subcategory-start-container"
          className="subcategory-start-container"
        >
          <TestModusHeader
            question={{
              ...questionList[questionNo],
              noInSubCategory: 0,
              evaluation: "normal",
              type: "subcategory",
            }}
            timeTotal={candidateSubCategoryTime.total}
            timeAct={0}
            timeMem={timeMem}
            subCategoryInfo={subCategoryInfo}
            isSubCategoryStart={true}
          />

          {questionList[questionNo].subCategoryMemTime > 0 && (
            <div
              className="subcategory-start-text"
              dangerouslySetInnerHTML={{
                __html: replaceImages(
                  questionList[questionNo].subCategoryMemText
                ),
              }}
            />
          )}
          <div
            className="subcategory-start-text"
            dangerouslySetInnerHTML={{
              __html: replaceImages(questionList[questionNo].subCategoryDesc),
            }}
          />

          <div className="subcategory-start-button">
            <Button
              variant="contained"
              onClick={() => {
                if (timeMem > 0) {
                  setDialogOpen(DIALOGOPTIONS.MEMCLICK);
                } else {
                  setActSubCategory(questionList[questionNo].subCategoryId);
                }
              }}
              data-testid="next-step-button"
            >
              weiter
            </Button>
          </div>
        </Box>
        {layout && layout.logo && <DegebaLogo className="degeba-logo-trans" />}
      </Box>
    </>
  );
};

import { SxProps, Theme } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React from "react";
import { useDrag } from "react-dnd";
import {
  ELEMENT_HEIGHT,
  ELEMENT_WIDTH,
  ELEMENT_PB,
  ELEMENT_WIDTH_IMG,
  ELEMENT_HEIGHT_IMG,
} from "../QuestionDnD";

interface DragableElemPropsI {
  content: string;
  dragIndex: string | number;
  itemType: string;
  sx?: SxProps<Theme>;
  isImage?: boolean;
  isPreviewResult?: boolean;
  position: "left" | "right";
}

export const DragableElem = React.memo(
  ({
    content,
    dragIndex,
    itemType,
    sx = {},
    isImage = false,
    isPreviewResult = false,
    position,
  }: DragableElemPropsI) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: itemType,
      item: { content, dragIndex, position },
      // end: (item, monitor) => {
      //   // const dropResult = monitor.getDropResult<DropResult>();
      // },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }));

    const modifiedContent =
      isPreviewResult && content
        ? content.replace(/<img /, '<img style="opacity: 0.5" ')
        : content;

    return (
      <Box
        ref={drag}
        sx={{
          ...sx,
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          border: isImage ? "" : "1px solid black",
          maxWidth: isImage ? ELEMENT_WIDTH_IMG : ELEMENT_WIDTH,
          minWidth: 120,
          height: isImage ? ELEMENT_HEIGHT_IMG : ELEMENT_HEIGHT,
          textAlign: "center",
          pt: "1pt",
          mb: ELEMENT_PB + "px",
        }}
      >
        <Typography variant="body1" className="noselect">
          <span
            data-testid={dragIndex}
            dangerouslySetInnerHTML={{
              __html: modifiedContent,
            }}
          />
        </Typography>
      </Box>
    );
  }
);

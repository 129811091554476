import { get, post, put, del, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";
import { checkApiResult } from "../shared/lib/checkApi";

export type MainCategoryT = {
  id: number;
  name: string;
  desc: string;
  active: number;
  imgId: number;
  order: number;
  relationCount?: number;
  professions?: {
    id: number;
    name: string;
  }[];
};

type MainCategoryDataT = {
  id: number;
  name: string;
  desc: string;
  active: number;
  categoryImgId?: number;
  order: number;
  relationCount?: number;
};

const data2category = (data: MainCategoryDataT): MainCategoryT => {
  checkApiResult("data2category", data, {
    id: "number",
    name: "string",
    desc: "string",
    active: "number",
    // order: "number",
  });

  return {
    ...data,
    desc: data.desc || "",
    active: data.active || 0,
    imgId: data.categoryImgId || 0,
  };
};

const data2categories = (data: MainCategoryDataT[]): MainCategoryT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2category(d));
};

const category2data = (category: MainCategoryT): MainCategoryDataT => {
  return {
    id: category.id,
    name: category.name,
    desc: category.desc,
    active: category.active,
    categoryImgId: category.imgId,
    order: category.order,
  };
};

const categories2data = (categories: MainCategoryT[]): MainCategoryDataT[] => {
  return categories.map((category) => category2data(category));
};

const APIPATH = "/maincategory";

/** ************************************************************************
 *
 *
 */
export const fetchCategories = async (
  onlyActive = false
): Promise<CallApiResultT<MainCategoryT[]>> => {
  const res = await get(APIPATH, onlyActive ? { active: 1 } : {});

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchCategories(main)] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2categories(res.data),
  };
};

/** ************************************************************************
 *
 * @returns addedCategory
 */
export const addCategory = async (
  category: MainCategoryT
): Promise<CallApiResultT<MainCategoryT>> => {
  // console.log("addMedia", category);

  const res = await post(APIPATH, category2data(category));

  if (!res.data) {
    throw Error("[addCategory(main)] " + res.error);
  }

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2category(res.data as MainCategoryDataT),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateCategory = async (
  category: MainCategoryT
): Promise<CallApiResultT<number>> => {
  // console.log("updateCategory", category, category2data(category));

  const res = await put<number>(
    `${APIPATH}/${category.id}`,
    category2data(category)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateCategoryBulk = async (
  categories: MainCategoryT[]
): Promise<CallApiResultT<number>> => {
  // console.log("updateCategory", categories, categories2data(categories));

  const res = await put<number>(APIPATH, categories2data(categories));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteCategory = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`);

  return res;
};

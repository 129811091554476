import { useState } from "react";
import { fetchTransactions, TransactionT } from "./api";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseTransactionsResultT extends UseResultT {
  transactionList: TransactionT[];
  setTransactionList: React.Dispatch<React.SetStateAction<TransactionT[]>>;
}

interface UseTransactionsPropsI extends UsePropsI {
  userId: number;
}

export const useTransactions = ({
  userId,
  dontHideProgress,
}: UseTransactionsPropsI): UseTransactionsResultT => {
  const [transactionList, setTransactionList] = useState<TransactionT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);

  useLoadData({
    fetchFunc: () => fetchTransactions(userId),
    list: transactionList,
    setList: setTransactionList,
    setLoadingDone,
    dontHideProgress: dontHideProgress,
  });

  return { transactionList, setTransactionList, loadingDone };
};

import { Typography, Grid } from "@mui/material";
import { ResultIcon } from "./ResultIcon";

interface ResultNumbersPropsI {
  correct: number;
  incorrect: number;
}
export const ResultNumbers = ({ correct, incorrect }: ResultNumbersPropsI) => {
  return (
    <>
      <Typography variant="body2" component="div" className="questions">
        {correct + incorrect} Aufgaben
      </Typography>
      <Typography variant="body2" component="div" className="correct">
        {correct || 0}
      </Typography>
      <ResultIcon correct />
      <Typography variant="body2" component="div" className="incorrect">
        {incorrect}
      </Typography>
      <ResultIcon incorrect />
    </>
  );
};

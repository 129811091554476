import { useProfessions } from "../profession/useProfessions";
import { useUsers } from "../user/useUsers";
import { CustomerTable } from "./CustomerTable";
import { Loader } from "../shared/Loader";
import { useTransactions } from "./useTransactions";
import { useTable } from "../shared/table/useTable";
import { HeadCellT } from "../shared/table/EnhancedTable";
import { UserT } from "../user/api";
import { useContext, useEffect } from "react";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { useCountries } from "../country/useCountries";
import { useShopOrders } from "../order/useShopOrders";

const headCellsDefault: HeadCellT<UserT>[] = [
  { id: "id", disablePadding: true, label: "ID" },
  { id: "testAccount", disablePadding: true, label: "Typ" },
  {
    id: "firstname",
    disablePadding: true,
    label: "Vorname",
  },
  {
    id: "lastname",
    disablePadding: true,
    label: "Nachname",
  },
  { id: "email", disablePadding: true, label: "E-Mail" },
  { id: "emailInvoice", disablePadding: true, label: "Rechnungs-E-Mail" },
  { id: "gender", disablePadding: false, label: "M/W/D" },
  {
    id: "shippingAddress",
    disablePadding: true,
    label: "Lieferadr.",
  },
  { id: "company", disablePadding: true, label: "Firma" },
  { id: "street", disablePadding: true, label: "Adresse" },
  { id: "phone", disablePadding: true, label: "Telefon" },
  { id: "fax", disablePadding: true, label: "Fax" },
  { id: "createdAt", disablePadding: true, label: "Kunde seit" },
  { id: "newsletter", disablePadding: true, label: "Benachr." },
  { id: "candidateCount", disablePadding: true, label: "# Kand. " },
  { id: "candidateResultCount", disablePadding: true, label: "# Tests" },
  { id: "active", disablePadding: true, label: "Aktionen" },
];

export const CustomerLoader = () => {
  const { hideProgress } = useContext(FeedbackContext);
  const { loadingDone: loadingDoneU } = useUsers({
    role: "customer",
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneP } = useProfessions({
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneT } = useTransactions({
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneC } = useCountries({
    dontHideProgress: true,
  });
  const { loadingDone: loadingDoneSO } = useShopOrders({});

  const { headCells } = useTable<UserT>("customer", headCellsDefault, 6);

  useEffect(() => {
    if (
      !loadingDoneP ||
      !loadingDoneU ||
      !loadingDoneT ||
      !loadingDoneC ||
      !loadingDoneSO ||
      headCells.length === 0
    )
      return;
    hideProgress();
  }, [loadingDoneP, loadingDoneU, loadingDoneT, headCells, hideProgress]);

  if (
    !loadingDoneP ||
    !loadingDoneU ||
    !loadingDoneT ||
    !loadingDoneC ||
    headCells.length === 0
  )
    return <Loader />;
  return <CustomerTable />;
};

export type FormErrorStateValueT = {
  error: boolean;
  helperText?: string;
};

export type FormErrorStateT = {
  [index: string]: FormErrorStateValueT;
};

export const buildEmptyErrorState = (fields: string[]): FormErrorStateT =>
  Object.fromEntries(
    fields.map((index: string) => [
      index,
      {
        error: false,
      },
    ])
  );

export const buildErrorState = (
  prevState: FormErrorStateT,
  field: string,
  error: boolean,
  helperText?: string
): FormErrorStateT => ({
  ...prevState,
  [field]: { error: error, helperText: helperText || "" },
});

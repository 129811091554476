import { debounce } from "lodash";
import { Key } from "react";

export const updateStateArrayById = <T extends { id: number }>(
  prevState: T[],
  newState: T
): T[] => {
  return prevState.map((elem) => {
    if (newState.id === elem.id) return newState;
    return elem;
  });
};

export const updateStateArrayByKey = <
  T extends { [key: string | number]: any }
>(
  prevState: T[],
  newState: T,
  key: string | number
): T[] => {
  return prevState.map((elem) => {
    if (newState[key] === elem[key]) return newState;
    return elem;
  });
};

export const updatePartOfStateArrayById = <T extends { id: number }>(
  prevState: T[],
  id: number,
  newStatePart: object
): T[] => {
  return prevState.map((elem) => {
    if (id === elem.id)
      return {
        ...elem,
        ...newStatePart,
      };
    return elem;
  });
};

export const addStateToArray = <T>(prevState: T[], newState: T): T[] =>
  prevState.concat(newState);

export const updateOrInsertStateArrayById = <T extends { id: number }>(
  prevState: T[],
  newState: T
): T[] => {
  if (prevState.find((e) => e.id === newState.id)) {
    return updateStateArrayById(prevState, newState);
  }
  return addStateToArray(prevState, newState);
};

export const updateOrInsertStateArrayByKey = <
  T extends { [key: string | number]: any }
>(
  prevState: T[],
  newState: T,
  key: string | number
): T[] => {
  if (prevState.find((e) => e[key] === newState[key])) {
    return updateStateArrayByKey(prevState, newState, key);
  }
  return addStateToArray(prevState, newState);
};

export const deleteStateFromArrayById = <T extends { id: number }>(
  prevState: T[],
  id: number
): T[] => prevState.filter((elem) => elem.id !== id);

export const deleteStateFromArrayByKey = <T extends { id: number }>(
  prevState: T[],
  id: number,
  key: string | number
): T[] => prevState.filter((elem: any) => (elem[key] as number) !== id);

export const debouncedSetUpdatedState = debounce(
  (newState: any, setChanged: any) => {
    setChanged((prevState: any) =>
      updateOrInsertStateArrayById(prevState, newState)
    );
  },
  300
);

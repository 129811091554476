import { useContext, useState, useEffect, useRef } from "react";
import {
  MainCategoryChangedT,
  MainCategoryContext,
} from "./MainCategoryContext";
import { deleteCategory, updateCategory, MainCategoryT } from "./api";

import { ImageSelect } from "./ImageSelect";

import { ErrorContext } from "../error/ErrorContext";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { DeleteButton } from "../shared/forms/DeleteButton";
import { FeedbackContext } from "../feedback/FeedbackContext";

import {
  deleteStateFromArrayById,
  debouncedSetUpdatedState,
} from "../shared/helper/state";
import { EditableCell } from "../shared/list/InsertCell";
import { Active } from "../shared/forms/Active";
import { checkChanged } from "../shared/helper/object";
import Grid from "@mui/material/Grid";
import { InfoPopOver } from "../shared/InfoPopover";

interface MainCategoryPropsI {
  category: MainCategoryT;
  setShowSave: (showSave: boolean) => void;
}

export const MainCategory = ({ category, setShowSave }: MainCategoryPropsI) => {
  console.log("category:", category);
  const { setCategoryList, categoryList, setChangedCategoryList } =
    useContext(MainCategoryContext);

  const [hasChanged, setHasChanged] = useState(false);

  const [categoryState, setCategoryState] =
    useState<MainCategoryChangedT>(category);

  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);

  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(category, categoryState, hasChanged, setHasChanged);
  }, [setHasChanged, hasChanged, category, categoryState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(categoryState, setChangedCategoryList);
  }, [categoryState, setChangedCategoryList]);

  const setActive = (active: boolean) => {
    showProgress();
    setCategoryState({ ...categoryState, active: active ? 1 : 0 }); // we don't use prevState here to avoid 2 versions in db and state
    updateCategory({ ...categoryState, active: active ? 1 : 0 })
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setCategoryList(
            categoryList.map((e) => {
              if (e.id === categoryState.id)
                return { ...e, active: active ? 1 : 0 };
              return e;
            })
          );
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <TableRow hover tabIndex={-1} key={category.id} selected={hasChanged}>
      <EditableCell
        id="name"
        isObligatory={true}
        label="Name"
        stateObject={categoryState}
        setStateObject={setCategoryState}
        checkDup={(newName: string) => {
          return categoryList.some(
            (c) => c.name === newName && c.id !== categoryState.id
          )
            ? "Dieser Name wird bei einer anderen Kategorie verwendet!"
            : "";
        }}
      />
      <EditableCell
        id="desc"
        label="Beschreibung"
        stateObject={categoryState}
        setStateObject={setCategoryState}
      />
      <TableCell>
        <ImageSelect
          id="imgId"
          value={categoryState.imgId}
          setValue={(v: number) =>
            setCategoryState((prevState) => {
              return { ...prevState, imgId: v };
            })
          }
        />
      </TableCell>
      <TableCell padding="none" sx={{ width: 150 }} align="center">
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item xs={8}>
            <Active
              active={categoryState.active ? true : false}
              setActive={setActive}
              disableSetActive={
                categoryState.active && categoryState.relationCount
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs={4}>
            <DeleteButton
              id={categoryState.id}
              deleteFunc={deleteCategory}
              successFunc={() =>
                setCategoryList((p) =>
                  deleteStateFromArrayById(p, categoryState.id)
                )
              }
              {...(categoryState.relationCount
                ? {
                    disabled: true,
                    tooltipTitle:
                      "Diese Kategorie wird bereits in einem Beruf verwendet",
                  }
                : {})}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right">
        <InfoPopOver id={category.id} list={category.professions} />
      </TableCell>
    </TableRow>
  );
};

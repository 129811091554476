import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  CandidateChangedT,
  CandidateContext,
} from "../candidate/CandidateContext";
import { ProfessionContext } from "../profession/ProfessionContext";

import { useContext, useEffect, useRef, useState } from "react";
import { date_hr } from "../shared/helper/datetime";
import { Tooltip } from "../shared/forms/Tooltip";
import { FilteredCandidateT } from "./CandidateResultTable";
import { CandidateResultsModal } from "./CandidateResultsModal";
import { Box } from "@mui/material";
import { EditableCell } from "../shared/list/InsertCell";
import { checkChanged } from "../shared/helper/object";
import { debouncedSetUpdatedState } from "../shared/helper/state";

interface CandidateResultEntryPropsI {
  candidate: FilteredCandidateT;
  hasConcentration: boolean;
  mainCategoryNames?: string[];
  setShowSave: (show: boolean) => void;
}
/* eslint-disable no-extra-boolean-cast */
const isEmpty = (x: any) => !Boolean(x);

export const CandidateResultEntry = ({
  candidate,
  hasConcentration,
  mainCategoryNames,
  setShowSave,
}: CandidateResultEntryPropsI) => {
  const { setCandidateList, setChangedCandidateList } =
    useContext(CandidateContext);
  const [openProfession, setOpenProfession] = useState(false);
  const [dialogSendPasswordOpen, setDialogSendPasswordOpen] = useState(false);
  const { professionList } = useContext(ProfessionContext);

  const [showResults, setShowResults] = useState(0);
  const [candidateState, setCandidateState] = useState<CandidateChangedT>(
    candidate.data as CandidateChangedT
  );
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    checkChanged(
      {
        ...candidate.data,
        professionId: null,
        results: null,
        mailLogs: null,
        resultsPercent: null,
        resultsMain: null,
      },
      {
        ...candidateState,
        professionId: null,
        results: null,
        mailLogs: null,
        resultsPercent: null,
        resultsMain: null,
      },
      hasChanged,
      setHasChanged
    );
  }, []);

  useEffect(() => {
    setShowSave(hasChanged);
  }, [setShowSave, hasChanged]);

  useEffect(() => {
    checkChanged(
      {
        ...candidate.data,
        professionId: null,
        results: null,
        mailLogs: null,
        resultsPercent: null,
        resultsMain: null,
      },
      {
        ...candidateState,
        professionId: null,
        results: null,
        mailLogs: null,
        resultsPercent: null,
        resultsMain: null,
      },
      hasChanged,
      setHasChanged
    );
  }, [setHasChanged, hasChanged, candidate, candidateState]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debouncedSetUpdatedState(candidateState, setChangedCandidateList);
  }, [candidateState, setChangedCandidateList]);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={candidate.id}
        data-testid={candidate.email}
        className="candidate-result-entry"
      >
        <TableCell component="th" scope="row" padding="normal">
          {candidate.lastname}
        </TableCell>
        <TableCell component="th" scope="row" padding="normal">
          {candidate.firstname}
        </TableCell>
        <TableCell component="th" scope="row" padding="normal">
          {candidate.dateBirth && <>{date_hr(candidate.dateBirth)}</>}
        </TableCell>

        <TableCell component="th" scope="row" padding="normal">
          {candidate.testedAt ? <>{date_hr(candidate.testedAt)}</> : <></>}
        </TableCell>

        <TableCell component="th" scope="row">
          <Tooltip title="Auswertung anzeigen">
            {candidate.hasOnlyConcentration ? (
              <>&ndash;</>
            ) : (
              <a onClick={() => setShowResults(candidate.id || 0)}>
                {candidate.resultPercent
                  ? candidate.resultPercent + " %"
                  : "0 %"}
              </a>
            )}
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          <Tooltip title="Auswertung anzeigen">
            <a onClick={() => setShowResults(candidate.id || 0)}>
              {candidate.hasTarget ? "erreicht" : "nicht erreicht"}
            </a>
          </Tooltip>
        </TableCell>
        {hasConcentration && (
          <TableCell>
            <Tooltip title="Auswertung anzeigen">
              {!candidate.hasConcentration ? (
                <>&ndash;</>
              ) : (
                <a onClick={() => setShowResults(candidate.id || 0)}>
                  {candidate.concentrationPercent
                    ? candidate.concentrationPercent + " %"
                    : "0 %"}
                </a>
              )}
            </Tooltip>
          </TableCell>
        )}

        <EditableCell
          id="comment"
          isObligatory={true}
          label="Bemerkungen"
          stateObject={candidateState}
          setStateObject={setCandidateState}
          sx={{ paddingTop: "0 !important", maxWidth: 250 }}
        />

        {/* {candidate.resultsMain?.find((r) => r.name === name)
  ?.percent || 0}{" "} */}
        {mainCategoryNames &&
          mainCategoryNames
            .filter((name: string) => !name.match(/Konzentration/))
            .map((name: string) => (
              <TableCell key={name}>
                <a onClick={() => setShowResults(candidate.id || 0)}>
                  <>
                    {name in candidate
                      ? candidate[name as keyof FilteredCandidateT]
                      : 0}
                  </>
                  %
                </a>
              </TableCell>
            ))}
      </TableRow>

      {showResults > 0 && (
        <CandidateResultsModal
          showResults={showResults}
          setShowResults={setShowResults}
        />
      )}
    </>
  );
};

import Grid from "@mui/material/Grid";

import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { TooltipIcon } from "./TooltipIcon";
import { Tooltip } from "./Tooltip";

interface ActivePropsI {
  active: boolean;
  setActive: (b: boolean) => void;
  disableSetActive?: boolean;
  useGrid?: boolean;
  useOutsideGrid?: boolean;
  outsideGridXs?: number;
}

export const Active = ({
  active,
  setActive,
  disableSetActive = false,
  useGrid = true,
  useOutsideGrid = false,
  outsideGridXs = 1,
}: ActivePropsI) => {
  const statusIcon = active ? (
    <DoneIcon color="success" />
  ) : (
    <ClearIcon color="error" />
  );

  const button = active ? (
    <TooltipIcon
      type="setInactive"
      iconProps={{ ...(disableSetActive ? { color: "disabled" } : {}) }}
      title={
        disableSetActive
          ? "Inaktiv schalten nicht möglich - Testergebnisse oder Transaktionen vorhanden"
          : "Inaktiv schalten"
      }
    />
  ) : (
    <TooltipIcon
      type="setActive"
      iconProps={{ ...(disableSetActive ? { color: "disabled" } : {}) }}
      title={
        disableSetActive
          ? "Aktiv schalten nicht möglich - Status nicht ok"
          : "Aktiv schalten"
      }
    />
  );

  return useGrid && !useOutsideGrid ? (
    <Grid container spacing={1}>
      <Tooltip title={active ? "Aktiv" : "Inaktiv"}>
        <Grid item xs={6}>
          {statusIcon}
        </Grid>
      </Tooltip>

      <Grid
        item
        xs={6}
        onClick={() => {
          if (disableSetActive) return;
          setActive(!active);
        }}
      >
        {button}
      </Grid>
    </Grid>
  ) : useOutsideGrid ? (
    <>
      <Grid item xs={outsideGridXs}>
        <Tooltip title={active ? "Aktiv" : "Inaktiv"}>{statusIcon}</Tooltip>
      </Grid>

      <Grid
        item
        xs={outsideGridXs}
        onClick={() => {
          if (disableSetActive) return;
          setActive(!active);
        }}
      >
        {button}
      </Grid>
    </>
  ) : (
    <div
      onClick={() => {
        if (disableSetActive) return;
        setActive(!active);
      }}
    >
      {statusIcon}
      {button}
    </div>
  );
};

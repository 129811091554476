import { Loader } from "../../shared/Loader";
import { useQuestions } from "../useQuestions";
import { Editor } from "./Editor";

export const EditorLoader = () => {
  const { loadingDone } = useQuestions({});

  if (loadingDone) return <Editor />;

  return <Loader />;
};

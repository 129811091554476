import { Box, Typography } from "@mui/material";
import { DndProvider } from "react-dnd-multi-backend";
import { Preview } from "react-dnd-preview";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { DropContainer } from "./DnD/DropContainer";
import { DragableElem } from "./DnD/DragableElem";
import React from "react";
import { TestModusQuestionPropsI } from "../QuestionSwitch";

export type DnDMappingT = {
  [key: string | number]: string;
};

export type PositionT = "left" | "right";

export const ELEMENT_HEIGHT_IMG = 70;
export const ELEMENT_WIDTH_IMG = 70;
export const ELEMENT_HEIGHT = 30;
export const ELEMENT_WIDTH = 200;
export const ELEMENT_PB = 5; // Pixel(!)

const generatePreview = ({ item, style }: { item: any; style: any }) => {
  console.log("%cQuestionDnD.tsx line:32 item", "color: purple;", item);
  return (
    <div className="item-list__item dbd-box" style={style}>
      <Box
        sx={{
          opacity: 0.5,
          border: item.isImage ? "" : "1px solid black",
          maxWidth: item.isImage ? ELEMENT_WIDTH_IMG : ELEMENT_WIDTH,
          minWidth: 120,
          height: item.isImage ? ELEMENT_HEIGHT_IMG : ELEMENT_HEIGHT,
          textAlign: "center",
          pt: "1pt",
          mb: ELEMENT_PB + "px",
        }}
      >
        {item.content}
      </Box>
    </div>
  );
};

export const QuestionDnD = React.memo(
  ({ question, setQuestionList }: TestModusQuestionPropsI) => {
    /* where has user dragged what */
    const mapping = (question.candidateAnswer as DnDMappingT) || {};

    const aoDecoded = question.answerOptionsDecoded;
    const answer = question.answerDecoded;

    const onDrop = (
      dropIndex: string | number,
      val: string,
      dragPos: PositionT,
      dropPos: PositionT
    ) => {
      if (dragPos === "right" && dropPos === "right") return;

      /* 1st: we remove the dragged entry from the list */
      /* eslint-disable @typescript-eslint/no-unused-vars */
      if (setQuestionList)
        setQuestionList((prevState) => {
          return prevState.map((q) => {
            if (question.id === q.id) {
              return {
                ...q,
                candidateAnswer: Object.fromEntries(
                  Object.entries(q.candidateAnswer || {}).filter(
                    ([index, v]) => v !== val
                  )
                ),
              };
            }
            return q;
          });
        });

      /* maybe this was dragged back to the right */
      if (dragPos === "left" && dropPos === "right") return;
      /* we save the new dragged elem now */
      if (setQuestionList)
        setQuestionList((prevState) =>
          prevState.map((elem) => {
            if (question.id === elem.id)
              return {
                ...elem,
                candidateAnswer: {
                  ...(elem.candidateAnswer as DnDMappingT),
                  [dropIndex]: val,
                },
              };
            return elem;
          })
        );
    };

    return (
      <DndProvider options={HTML5toTouch}>
        <Box data-testid="questiondnd" className="dnd-container">
          {/* *** LEFT SIDE *** */}
          <div className="dnd-left">
            {Object.entries(aoDecoded.given).map(
              ([key, content]: [key: any, content: any]) => {
                return (
                  <Box
                    key={question.id + "givenFrag" + key}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {/* description of drop-area e.g. "given" */}
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        variant="body1"
                        className="noselect"
                        sx={{ pr: 1 }}
                      >
                        <span
                          data-testid={key}
                          dangerouslySetInnerHTML={{
                            __html: content,
                          }}
                        />
                      </Typography>
                    </Box>
                    <Box className="dnd-box">
                      {mapping[key] ? (
                        /* user has already dragged here */
                        <DragableElem
                          key={question.id + "givenDrag" + key}
                          dragIndex={mapping[key]}
                          content={aoDecoded.options[mapping[key]]}
                          itemType="GIVEN"
                          isImage={question.isImage}
                          position="left"
                        />
                      ) : answer ? (
                        /* preview mode, show correct answer */
                        <DragableElem
                          key={question.id + "givenDrag" + key}
                          dragIndex={key}
                          content={aoDecoded.options[answer[key]]}
                          itemType="GIVEN"
                          sx={{ color: "gray" }}
                          isImage={question.isImage}
                          isPreviewResult={true}
                          position="left"
                        />
                      ) : (
                        /* empty drop-container waiting for action */
                        <DropContainer
                          key={question.id + "givenDrop" + key}
                          dropIndex={key}
                          dropPosition="left"
                          onDrop={onDrop}
                          isImage={question.isImage}
                          accept="GIVEN"
                        />
                      )}
                    </Box>
                  </Box>
                );
              }
            )}
          </div>
          {/* *** RIGHT SIDE *** */}
          <div className="dnd-right">
            {/* for dragging and dropping back the elements */}
            <DropContainer
              dropIndex="9"
              dropPosition="right"
              onDrop={onDrop}
              accept="GIVEN"
              key={question.id + "givenDrop"}
              isImage={question.isImage}
            >
              {Object.entries(aoDecoded.options).map(
                ([key, content]: [key: any, content: any]) =>
                  /* was already dragged from here, so this is empty */
                  Object.values(mapping).some((v) => v === key) ? (
                    ""
                  ) : (
                    /* starting point, waiting to be dragged */
                    <DragableElem
                      key={question.id + "options" + key}
                      dragIndex={key}
                      content={content}
                      itemType="GIVEN"
                      isImage={question.isImage}
                      position="right"
                    />
                  )
              )}
            </DropContainer>
          </div>
        </Box>
        <Preview generator={generatePreview} />
      </DndProvider>
    );
  }
);

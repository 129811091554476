import { get, post, put, del, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";
import { TokenDataT } from "../auth/api";
import { uppercaseFirst } from "../shared/helper/string";

const APIPATH = "/user";

export type UserT = {
  id: number;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  role: string;
  active: number;
  testAccount: boolean;
  gender?: string;
  company?: string;
  misc?: string;
  street?: string;
  streetno?: string;
  zipcode?: string;
  city?: string;
  countryId?: number;
  phone?: string;
  fax?: string;
  emailInvoice?: string;
  newsletter?: boolean;
  professionIds?: number[];
  uuid: string;
  forceChangePassword: number;
  candidateCount?: number;
  candidateResultCount?: number;
  shippingAddress?: boolean;
  shippingGender?: string;
  shippingFirstname?: string;
  shippingLastname?: string;
  shippingCompany?: string;
  shippingMisc?: string;
  shippingStreet?: string;
  shippingStreetno?: string;
  shippingZipcode?: string;
  shippingCity?: string;
  shippingCountryId?: number;
  shippingPhone?: string;
  shippingFax?: string;
  mailLogs?: MailLogsT[];
  createdAt?: Date;
};

export type AddressT = {
  type: "billing" | "shipping";
  gender: string;
  firstname: string;
  lastname: string;
  company: string;
  misc: string;
  street: string;
  streetno: string;
  zipcode: string;
  city: string;
  countryId: string;
  phone: string;
  fax: string;
  email: string;
  newsletter: boolean;
};

export interface MailLogsT {
  userId: number;
  mailTemplateId: number;
  createdAt: string | Date;
}

export type UserDataT = {
  id: number;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  role: string;
  gender: string;
  active: number;
  testAccount: number;
  uuid: string;
  forceChangePassword: number;
  address?: AddressT[];
  professions?: {
    professionId: number;
  }[];
  candidateCount?: number;
  candidateResultCount?: number;
  mailLogs?: MailLogsT[];
  createdAt?: string;
};

export const data2user = (data: UserDataT): UserT => {
  const user: any = {
    active: data.active ? true : false,
    testAccount: data.testAccount ? true : false,
    role: data.role || "user",
    gender: data.gender || "",
    professionIds: [],
  };
  ["id", "email", "password", "firstname", "lastname", "uuid"].forEach(
    (f) => (user[f] = data[f as keyof UserDataT] || "")
  );

  if (data.address) {
    const addressFields = [
      "company",
      "misc",
      "street",
      "streetno",
      "zipcode",
      "city",
      "phone",
      "fax",
    ];
    user.shippingAddress = false;
    data.address.forEach((a) => {
      if (a.type === "billing") {
        addressFields.forEach((f) => (user[f] = a[f as keyof AddressT] || ""));
        user.newsletter = a.newsletter ? true : false;
        user.countryId = a.countryId || 0;
        user.emailInvoice = a.email || "";
      }
      if (a.type === "shipping") {
        user.shippingAddress = true;
        user.shippingCountryId = a.countryId || 0;
        addressFields
          .concat(["gender", "firstname", "lastname"])
          .forEach(
            (f: string) =>
              (user["shipping" + uppercaseFirst(f)] =
                a[f as keyof AddressT] || "")
          );
      }
    });
  }

  user.candidateCount = data.candidateCount;
  user.mailLogs = data.mailLogs;
  user.candidateResultCount = data.candidateResultCount;

  if (data.professions)
    user["professionIds"] = data.professions.map((e) => e.professionId);

  if (data.createdAt) user.createdAt = new Date(data.createdAt);

  return user as UserT;
};

const data2users = (data: UserDataT[]): UserT[] => {
  return data.map((d) => data2user(d));
};

const user2data = (user: UserT): UserDataT => {
  const data: any = {};
  [
    "id",
    "email",
    "password",
    "firstname",
    "lastname",
    "gender",
    "role",
  ].forEach((f) => (data[f] = user[f as keyof UserT]));
  data["gender"] = user.gender || "";
  data["testAccount"] = user.testAccount ? 1 : 0;

  data["address"] = [
    {
      countryId: user.countryId || 0,
      newsletter: user.newsletter ? 1 : 0,
      type: "billing",
      email: user.emailInvoice || "",
    },
  ];

  [
    // "gender",
    // "firstname",
    // "lastname",
    "company",
    "misc",
    "street",
    "streetno",
    "zipcode",
    "city",
    "countryId",
    "phone",
    "fax",
  ].forEach((f) => (data.address[0][f] = user[f as keyof UserT] || ""));

  if (user.shippingAddress) {
    data["address"].push({
      type: "shipping",
      gender: user.shippingGender || "",
      firstname: user.shippingFirstname || "",
      lastname: user.shippingLastname || "",
      company: user.shippingCompany || "",
      street: user.shippingStreet || "",
      streetno: user.shippingStreetno || "",
      zipcode: user.shippingZipcode || "",
      city: user.shippingCity || "",
      countryId: user.shippingCountryId || null,
      phone: user.shippingPhone || "",
      fax: user.shippingFax || "",
    });
  }

  return data as UserDataT;
};

const users2data = (user: UserT[]): UserDataT[] => {
  return user.map((d) => user2data(d));
};

/** ************************************************************************
 *
 *
 */
export const fetchUsers = async (
  role?: string
): Promise<CallApiResultT<UserT[]>> => {
  const res = await get(APIPATH, role ? { role } : {});
  if (!res.success || !(res.data && isArray(res.data))) {
    throw Error("[fetchUsers] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: data2users(res.data),
  };
};

/** ************************************************************************
 *
 *
 */
export const fetchUserSelf = async (): Promise<CallApiResultT<UserT>> => {
  const res = await get("/user-self");
  if (!res.success || !res.data) {
    throw Error("[fetchUsers] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: data2user(res.data as UserDataT),
  };
};

/** ************************************************************************
 *
 * @returns addedUser
 */
export const addUser = async (User: UserT): Promise<CallApiResultT<UserT>> => {
  const res = await post<UserDataT>(APIPATH, User);
  return {
    success: res.status < 300,
    error: res.error,
    status: res.status,
    data: res.status < 300 ? data2user(res.data as UserDataT) : undefined,
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateUser = async (
  user: UserT
): Promise<CallApiResultT<UserT>> => {
  const res = await put<UserT>(`${APIPATH}/${user.id}`, user2data(user));
  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateUserSelf = async (
  user: UserT
): Promise<CallApiResultT<UserT>> => {
  const res = await put<UserT>(`/update-self/`, user2data(user));
  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateUserPassword = async (
  password: string,
  userId?: number
): Promise<CallApiResultT<UserT>> => {
  const res = await put<UserT>("/password", {
    password,
    ...(userId ? { user_id: userId } : {}),
  });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateUserBulk = async (
  users: UserT[]
): Promise<CallApiResultT<UserT>> => {
  const res = await put<UserT>(APIPATH, users2data(users));

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteUser = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const setPasswordUser = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await get<number>(`/user-password-mail/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const sendWelcomeUser = async (
  mailTemplateId: number,
  userId: number,
  subject: string,
  content: string
): Promise<CallApiResultT<number>> => {
  const res = await post<number>(`/user-welcome-mail/`, {
    mailTemplateId,
    userId,
    subject,
    content,
  });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const sendCandidateLink = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await get<number>(`/user-link/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const userLogin = async (
  uuid: string
): Promise<CallApiResultT<TokenDataT>> => {
  const res = await get<TokenDataT>(`/user-login/${uuid}`);

  return res;
};

export interface TransactionT {
  id: number;
  id2?: number;
  id3?: number;
  id4?: number;
  userId: number;
  professionId: number;
  number: number;
  numberUsed?: number;
  numberDone?: number;
  createdAt?: Date;
  expiredAt?: Date;
  target?: number;
  orderItemId?: number;
}

interface TransactionDataT extends Omit<TransactionT, "createdAt"> {
  createdAt?: string;
}

export type UserTransactionT = {
  [userId: number]: TransactionT[];
};

const data2transaction = (data: TransactionDataT): TransactionT => {
  return {
    ...data,
    createdAt: data.createdAt ? new Date(data.createdAt) : new Date(),
    ...(data.expiredAt ? { expiredAt: new Date(data.expiredAt) } : {}),
    id2: data.id,
    id3: data.id,
    id4: data.id,
  };
};

const dataArray2transactions = (user: TransactionDataT[]): TransactionT[] => {
  return user.map((d) => data2transaction(d));
};

/** ************************************************************************
 *
 *
 */
export const fetchTransactions = async (
  userId?: number
): Promise<CallApiResultT<TransactionT[]>> => {
  const res = await get("/transaction", { user_id: userId });
  if (!res.success || !(res.data && isArray(res.data))) {
    throw Error("[fetchTransactions] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: dataArray2transactions(res.data),
  };
};

/** ************************************************************************
 *
 * @returns addedUser
 */
export const addTransaction = async (
  transaction: TransactionT
): Promise<CallApiResultT<TransactionT>> => {
  transaction.number = transaction.number * 1; // sometimes there's a leading 0
  const res = await post<TransactionDataT>("/transaction", transaction);

  if (!res.success)
    return {
      success: false,
      error: res.error,
      status: res.status,
    };

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2transaction(res.data as TransactionDataT),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteTransaction = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`/transaction/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateSettings = async (settings: {
  disability: boolean;
}): Promise<boolean> => {
  const res = await put<any>(`/customer-settings`, settings);

  if (!res.success) return false;
  if (res.data.message === "false") return false;
  return true;
};

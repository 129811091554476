import * as Sentry from "@sentry/react";
import axios, { Method, ResponseType } from "axios";
import {
  getStoredAuthToken,
  removeStoredAuthToken,
} from "../../auth/localStore";
import { redirectAfterLoggedout } from "../helper/logout";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

export const fetchCSRFCookie = () =>
  // new Promise((resolve, reject) => {
  //   resolve(true);
  // });
  axios
    .get(import.meta.env.VITE_APIURL + "/sanctum/csrf-cookie")
    // .then((res) => {
    //   if (
    //     import.meta.env.VITE_APILOG &&
    //     parseInt(import.meta.env.VITE_APILOG) > 0 &&
    //   )
    //     console.log("csrf fetched", res);
    // })
    .catch((error) => {
      // error.name = "crsrf-cookie-error";
      throw error;
    });

const defaults = {
  baseURL: import.meta.env.VITE_APIURL + "/api",
  headers: () => ({
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: getStoredAuthToken() ? `Bearer ${getStoredAuthToken()}` : "",
  }),
  error: {
    code: "INTERNAL_ERROR",
    message:
      "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
  withCredentials: true,
};

interface addConfigI {
  responseType: ResponseType;
}

interface CallApiArgsI {
  url: string;
  method: Method;
  queryData?: object;
  headers?: object;
  addConfig?: addConfigI;
}

export type CallApiResultT<T> = {
  success: boolean;
  data?: T;
  error: string;
  errors?: object;
  status: number;
};

const checkMethodStatus = (method: Method, status: number): boolean => {
  if (method === "get" && status === 200) return true;
  if (method === "post" && (status === 200 || status === 201)) return true;
  if (method === "put" && status === 200) return true;
  if (method === "delete" && status === 200) return true;

  return false;
};

export const callApi = async <T>({
  url,
  method,
  queryData = {},
  headers = {},
  addConfig,
}: CallApiArgsI): Promise<CallApiResultT<T>> => {
  fetchCSRFCookie();
  const consError = "background: #222; color: red";
  const logDetails = (error?: any) => {
    console.error(
      "%c ######################################################### ",
      consError
    );
    if (error) console.log("%c error:", consError, error.response);
    console.log("%c url:", consError, url);
    console.log("%c method:", consError, method);
    console.log("%c queryData:", consError, queryData);
  };
  const sendToSentry = (error: any) => {
    // Send error to Sentry
    error.name = "api-error";
    Sentry.captureException(error);
  };

  try {
    const config = {
      url: `${defaults.baseURL}${url}`,
      method: method,
      withCredentials: defaults.withCredentials,
      headers: { ...defaults.headers(), ...headers },
      params: {},
      data: {},
      ...(addConfig && "responseType" in addConfig ? addConfig : {}),
    };
    if (method === "get" || method === "delete") config.params = queryData;
    else config.data = queryData;

    const res = await axios(config);

    if (
      import.meta.env.VITE_APILOG &&
      parseInt(import.meta.env.VITE_APILOG) > 0
    ) {
      console.log(
        "%c lib/api " + method + " " + url + ":",
        "color: green",

        queryData,
        " RES",
        res
      );
    }
    if (res) {
      if (checkMethodStatus(method, res.status)) {
        return { success: true, status: res.status, data: res.data, error: "" };
      } else {
        logDetails();
        throw new Error("[callApi] wrong status " + res.status);
      }
    }
  } catch (error) {
    if (error instanceof Error && axios.isAxiosError(error)) {
      console.log(
        "%c ######################################################### ",
        consError
      );
      console.log("%c lib/api ", consError, method, url + ":", queryData);
      console.log("isAxiosError", error.toJSON());
      console.log("response:", error.response);

      // UNAUTHORIZED
      if (error.response?.status === 401 || error.response?.status === 419) {
        //throw new Error("[callApi] UNAUTHORIZED " + error.response?.status);
        if (url === "/login")
          return {
            success: false,
            status: error.response.status,
            error: "Login failed",
          };

        if (error.response?.status === 419) sendToSentry(error);
        removeStoredAuthToken();
        redirectAfterLoggedout();
      } else if (error.response?.status === 404) {
        // 404 not found
        return {
          success: false,
          status: error.response.status,
          error: "error 404",
        };
      } else if (error.response?.status === 406) {
        // 406 unacceptable -> means
        return {
          success: false,
          status: error.response.status,
          error: "error 406",
        };
      } else if (
        error.response?.status === 422 || // 422 Unprocessable Entity --> wrong form data
        error.response?.status === 400 // 400 Bad Request --> could also be wrong form data
      ) {
        // let's get any error messages, Sentry not necessary
        console.log("422 oder 400", error.response.data);

        const errorResponseData: any = error.response.data;
        if (errorResponseData.errors) {
          // console.log(error.response.data.message);
          console.log(
            errorResponseData.errors,
            typeof errorResponseData.errors
          );

          return {
            success: false,
            status: error.response.status,
            errors: errorResponseData.errors,
            error: JSON.stringify(errorResponseData.errors),
          };
        } else if (errorResponseData.message) {
          // console.log(errorResponseData.message);
          // console.log(errorResponseData.errors);
          return {
            success: false,
            status: error.response.status,
            errors: errorResponseData.message.errors
              ? errorResponseData.message.errors
              : ["400/422 unknown error 129"],
            error: errorResponseData.message.errors
              ? JSON.stringify(errorResponseData.message.errors)
              : "400/422 unknown error 132",
          };
        } else if (error.response.data) {
          // console.log(error.response.data.message);
          // console.log(error.response.data.errors);
          return {
            success: false,
            status: error.response.status,
            errors: [error.response.data],
            error: error.response.data as string,
          };
        }
        throw error;
      } else {
        logDetails(error);
        sendToSentry(error);
        throw error;
      }
    }
    sendToSentry(error);
    throw error;
  }

  return { success: false, status: -1, error: "unknown api error" };
};

export const get = <T>(url: string, queryData?: object) =>
  callApi<T>({ url, method: "get", queryData });
export const post = <T>(
  url: string,
  queryData: object,
  headers?: object,
  addConfig?: addConfigI
) => callApi<T>({ url, method: "post", queryData, headers, addConfig });
export const put = <T>(url: string, queryData: object) =>
  callApi<T>({ url, method: "put", queryData });
export const del = <T>(url: string, queryData?: object) =>
  callApi<T>({ url, method: "delete", queryData });

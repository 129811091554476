import React from "react";
import { QuestionMultipleChoice } from "./questionTypes/QuestionMultipleChoice";
import { QuestionClozeText } from "./questionTypes/QuestionClozeText";
import { QuestionClickMatrix } from "./questionTypes/QuestionClickMatrix";
import { QuestionDnD } from "./questionTypes/QuestionDnD";
import { TestModusQuestionT } from "./api";

export interface TestModusQuestionPropsI {
  question: TestModusQuestionT;
  setQuestionList?: React.Dispatch<React.SetStateAction<TestModusQuestionT[]>>;
}

export const QuestionSwitch = React.memo(
  ({ question, setQuestionList }: TestModusQuestionPropsI) => {
    return (
      <div className="question-container">
        <div className="question">
          <p
            className="question-text noselect"
            dangerouslySetInnerHTML={{
              __html: question.questionDecoded
                ? question.questionDecoded
                : question.question,
            }}
          />
        </div>
        {
          {
            "multiple-choice": (
              <QuestionMultipleChoice
                key="mc"
                question={question}
                setQuestionList={setQuestionList}
              />
            ),
            lueckentext: (
              <QuestionClozeText
                key="ct"
                question={question}
                setQuestionList={setQuestionList}
              />
            ),
            klickmatrix: (
              <QuestionClickMatrix
                key={"cm" + question.id}
                question={question}
                setQuestionList={setQuestionList}
              />
            ),
            dnd_1u1_zuordnungen: (
              <QuestionDnD
                key="dnd"
                question={question}
                setQuestionList={setQuestionList}
              />
            ),
          }[question.type]
        }
      </div>
    );
  }
);

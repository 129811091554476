import { useContext } from "react";
import { HelpContext } from "./HelpContext";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export const Help = () => {
  const { helpList } = useContext(HelpContext);
  return (
    <Box sx={{ width: 870 }} className="help-container">
      <Typography variant="h1" component="h1" sx={{ ml: 1, mt: 2, mb: 1 }}>
        Häufig gestellte Fragen
      </Typography>
      {helpList.map((h) => {
        return (
          <Box key="h.id" sx={{ mb: 4 }}>
            <Typography
              variant="h2"
              component="h2"
              sx={{ ml: 1, mt: 2, mb: 1 }}
            >
              {h.title}
            </Typography>

            {h.entries.map((e) => {
              return (
                <Accordion key="h.id">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="e.id"
                  >
                    <Typography variant="h3" component="h3">
                      {e.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    key="e.id"
                    sx={{ padding: "0 16px 16px 16px" }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: e.content }} />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

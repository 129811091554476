import React, { ReactElement, useContext, useState } from "react";
import { sendNewPassword } from "./api";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { FeedbackContext } from "../feedback/FeedbackContext";

interface ForgotPasswordPropsI {
  type: string;
}

export const ForgotPassword = ({
  type,
}: ForgotPasswordPropsI): ReactElement => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { showProgress, hideProgress } = useContext(FeedbackContext);

  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    // console.log("email", email, "password", password);
    e.preventDefault();
    setSuccess("");
    setError("");
    showProgress();

    sendNewPassword({ email })
      .then((result) => {
        hideProgress();
        if (result.success) {
          console.log("token:", result);
          setSuccess("Ein Link zum Ändern des Passworts wurde versendet.");
        } else {
          console.log("Login failed");
          if (result.status === 406) setError("User not found");
          else setError("Password sending failed. Error: " + result.status);
        }
      })
      .catch((error) => {
        hideProgress();
        setError(error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="login-box"
      >
        <Box className="logo degeba-logo"></Box>

        <Typography component="h1" variant="h5">
          Passwort zurücksetzen
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: "100%" }}>
            {typeof error === "string" ? error : JSON.stringify(error)}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ width: "100%" }}>
            {success}
          </Alert>
        )}
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-Mail"
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoFocus
          />
          <Button
            onClick={clickHandler}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Link zum zurücksetzen versenden
          </Button>
          <Link component={RouterLink} to={"/" + type + ""}>
            Login
          </Link>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

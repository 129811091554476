import { Box, styled } from "@mui/material";
import { LayoutContext } from "../../testModus/LayoutContext";
import { useContext } from "react";
import { AuthContext } from "../../auth/AuthContext";

const StyledImg = styled("img")({
  // width: 200,
});
interface CustomerLogoProsI {
  sx?: any;
  className?: string;
}

export const CustomerLogo = ({ sx, className }: CustomerLogoProsI) => {
  const { layout } = useContext(LayoutContext);
  const { authState } = useContext(AuthContext);

  return (
    <>
      {layout && layout.logo ? (
        <Box
          sx={{
            height: 100,
            backgroundPosition: "center",
            width: "100% !important",
            display: "flex",
            justifyContent: "center",
            ...sx,
          }}
          className={"logo image-container " + className}
        >
          <StyledImg src={import.meta.env.VITE_IMGURL + "/" + layout.logo} />
        </Box>
      ) : (
        <Box
          className={"logo degeba-logo " + className}
          sx={{
            height: 100,
            backgroundPosition: "center",
            width: "100% !important",
          }}
        ></Box>
      )}
    </>
  );
};

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext } from "react";
import { FeedbackContext } from "./FeedbackContext";

export const BackdropProgress = () => {
  // console.log("render BackdropProgress");
  const { openProgress } = useContext(FeedbackContext);

  return (
    <Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={openProgress}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

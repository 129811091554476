import TocIcon from "@mui/icons-material/Toc";
import Button from "@mui/material/Button";
import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { TableContext } from "./TableContext";
import { HeadCellT } from "./EnhancedTable";
import { Tooltip } from "../forms/Tooltip";

export const TableColumnSelect = () => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const { headCells, setHeadCells } = useContext(TableContext);

  const openDrawer = () => {
    setOpen(true);
    setHeight(140);
  };
  const closeDrawer = () => {
    setOpen(false);
    setHeight(0);
  };

  const onChange = (c: HeadCellT<any>) => {
    setHeadCells((p) =>
      p.map((e) => {
        return e.id === c.id ? { ...e, hide: !(c.hide || false) } : e;
      })
    );
  };

  return (
    <>
      <Tooltip title="Sichtbare Tabellenspalten">
        <Button
          variant="contained"
          startIcon={<TocIcon />}
          onClick={openDrawer}
          sx={{ height: 38 }}
        ></Button>
      </Tooltip>
      <Box sx={{ ...(height ? { height: height } : { height: 0 }) }}></Box>
      <Drawer
        anchor="top"
        open={open}
        onClose={closeDrawer}
        variant="temporary"
        sx={{}}
      >
        <Box
          component="div"
          sx={{
            p: 1,
            width: "auto",
            backgroundColor: "gray",
            height: 150,
          }}
        >
          <FormGroup>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Sichtbare Tabellenspalten:
                </Typography>
              </Grid>
              {headCells.map((c) => (
                <Grid item key={"check" + c.label}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!(c.hide || false)}
                        onChange={() => onChange(c)}
                      />
                    }
                    label={c.label as string}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Box>
      </Drawer>
    </>
  );
};

import { Typography, Grid } from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";

interface ResultNumbersPropsI {
  sumPoints: number;
  sumPointsCorrect: number;
  mainCategoryTarget?: number;
}
export const ResultPercent = ({
  sumPoints,
  sumPointsCorrect,
  mainCategoryTarget,
}: ResultNumbersPropsI) => {
  return (
    <Typography variant="body2" component="div" className="percent">
      {sumPoints > 0 ? ((sumPointsCorrect / sumPoints) * 100).toFixed(0) : 0} %
      {mainCategoryTarget && (
        <span>&nbsp;(Sollprofil: {Math.round(mainCategoryTarget)} %)</span>
      )}
    </Typography>
  );
};

import React, { createContext, useState } from "react";
import { ContextPropsT } from "../shared/types/Context";
import { MailTemplateT } from "./api";

// createContext: erzeugt ein Context obects, das alles möglich sein kann
// in diesem Fall ist es Variable und Setz-Funktion auf dem useState-Hook!
// daher muss die Typisierung dem useState-Hook folgen
// unklar warum erst Dispatch, dann SetStateAction, vermutlich wird es weitergereicht

interface MailTemplateContextPropsType extends ContextPropsT {
  mailTemplateList: MailTemplateT[];
  setMailTemplateList: React.Dispatch<React.SetStateAction<MailTemplateT[]>>;
}

export interface MailTemplateChangedT extends MailTemplateT {
  error?: boolean;
}

export const MailTemplateContext = createContext<MailTemplateContextPropsType>({
  mailTemplateList: [],
  setMailTemplateList: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
});
export const MailTemplateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [mailTemplateList, setMailTemplateList] = useState<MailTemplateT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <MailTemplateContext.Provider
      value={{
        mailTemplateList,
        setMailTemplateList,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </MailTemplateContext.Provider>
  );
};

import React, { Fragment, useState } from "react";
import { Box, styled } from "@mui/system";
import { IMGPATH } from "../api";
import { TestModusQuestionPropsI } from "../QuestionSwitch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Loader } from "../../shared/Loader";

const StyledImg = styled("img")({});

export type ClickMatrixMappingT = { [key: number]: number[] };

export const QuestionClickMatrix = ({
  question: q,
  setQuestionList,
}: TestModusQuestionPropsI) => {
  const initMatrix: boolean[][] = [];

  if (q && q.answerOptionsDecoded) {
    Object.entries(q.answerOptionsDecoded.content).forEach(
      ([row, option]: [row: string | number, option: any]) => {
        initMatrix[row as number] = [];
        Object.keys(option).forEach((col: any) => {
          initMatrix[row as number][col] =
            q.candidateAnswer && q.candidateAnswer[row as number]
              ? (q.candidateAnswer as ClickMatrixMappingT)[row as number].some(
                  (f) => f === parseInt(col)
                )
              : false;
        });
      }
    );
  }
  const [clickMatrix, setClickMatrix] = useState<boolean[][]>(initMatrix);
  if (!q) return <Loader />;

  const onClick = (row: number, col: number) => {
    /* see note above for speciality */
    setClickMatrix((p) => {
      p[row][col] = !p[row][col];
      return p;
    });

    const rowP = row;

    if (setQuestionList)
      setQuestionList((prevState) =>
        prevState.map((elem) => {
          if (q.id === elem.id)
            return {
              ...elem,
              candidateAnswer: {
                ...(elem.candidateAnswer as ClickMatrixMappingT),
                [rowP]:
                  elem.candidateAnswer && elem.candidateAnswer[rowP]
                    ? (elem.candidateAnswer as ClickMatrixMappingT)[rowP].some(
                        (f) => f === col
                      )
                      ? (elem.candidateAnswer as ClickMatrixMappingT)[
                          rowP
                        ].filter((f) => f !== col)
                      : (elem.candidateAnswer as ClickMatrixMappingT)[
                          rowP
                        ].concat(col)
                    : [col],
              },
            };
          return elem;
        })
      );
  };

  return (
    <TableContainer component={Paper} className="click-matrix-table-container">
      <Table className="click-matrix-table">
        {q && q.answerOptionsDecoded && q.answerOptionsDecoded.titlerow && (
          <TableHead>
            <TableRow>
              {q.answerOptionsDecoded.titlerow.map((c: any) => (
                <TableCell key={c} sx={{ background: "lightgray" }}>
                  {c}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody data-testid="questionclickmatrix">
          {q &&
            q.answerOptionsDecoded &&
            q.answerOptionsDecoded.content.map((obj: any, rowIdx: number) =>
              Object.entries(obj).map(
                ([rowIdent, option]: [
                  rowIdent: string | number,
                  option: any
                ]) => (
                  <TableRow key={"row" + rowIdx}>
                    <Fragment>
                      {q.answerOptionsDecoded.titlecolumn && (
                        <TableCell sx={{ background: "lightgray" }}>
                          {rowIdent}
                        </TableCell>
                      )}
                      {option.map(
                        (
                          o: string | { w: number; h: number; img: string },
                          col: number
                        ) => {
                          return (
                            <ClickMatrixCell
                              key={q.id + "cell" + rowIdx + "|" + col}
                              clicked={
                                clickMatrix[col] && clickMatrix[rowIdx][col]
                              }
                              w={typeof o === "string" ? 0 : o.w}
                              h={typeof o === "string" ? 0 : o.h}
                              img={typeof o === "string" ? "" : o.img}
                              content={typeof o === "string" ? o : ""}
                              row={rowIdx}
                              col={col}
                              onClick={onClick}
                              correct={
                                q.answerDecoded &&
                                q.answerDecoded[rowIdx] &&
                                q.answerDecoded[rowIdx].some(
                                  (a: number) => parseInt(a.toString()) === col
                                )
                                  ? true
                                  : false
                              }
                            />
                          );
                        }
                      )}
                    </Fragment>
                  </TableRow>
                )
              )
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ClickMatrixCell = React.memo(
  ({
    clicked,
    content,
    img,
    w,
    h,
    row,
    col,
    onClick,
    correct = false,
  }: {
    clicked: boolean;
    content: string;
    img: string;
    w: any;
    h: any;
    row: number;
    col: number;
    onClick: (row: number, col: number) => void;
    correct?: boolean;
  }) => {
    /**
     * Problem: apparently updating the array does not provoke re-render, since the reference does not change
     * cloning whole object was to slow
     * so we update it and build the rerender in this component
     */

    const [actClicked, setActClicked] = useState(clicked);

    /* preview mode, show correct answer */

    if (img)
      return (
        <TableCell
          onClick={() => {
            setActClicked((p) => !p);
            onClick(row, col);
          }}
        >
          <StyledImg
            key={"img" + row + "|" + col}
            src={IMGPATH + img}
            alt={img}
            width={w ? w : 25}
            height={h ? h : 25}
            sx={{
              outline: actClicked ? "1px solid green" : "",
              background: correct ? "pink" : "",
            }}
          ></StyledImg>
        </TableCell>
      );

    return (
      <TableCell
        className={"click-matrix-cell " + (actClicked ? "clicked" : "")}
        onClick={() => {
          setActClicked((p) => !p);
          onClick(row, col);
        }}
      >
        <Box
          data-testid={"cell" + row + "|" + col}
          key={"cell" + row + "|" + col}
          component="span"
        >
          {content}
        </Box>
      </TableCell>
    );
  }
);

import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";

import { Dialog } from "../shared/Dialog";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { addStateToArray } from "../shared/helper/state";
import { AddButton } from "../shared/forms/AddButton";
import { addHelpEntry, HelpEntryT } from "./api";
import { HelpContext } from "./HelpContext";
import { HelpEntryForm } from "./HelpEntryForm";
import { Box } from "@mui/material";

interface AddCustomerPropsI {
  categoryId: number;
}
export const AddHelpEntry = ({ categoryId }: AddCustomerPropsI) => {
  const { setError } = useContext(ErrorContext);

  const [open, setOpen] = useState(false);
  const { openSnackbar } = useContext(FeedbackContext);
  const { setHelpEntryList } = useContext(HelpContext);

  const onClick = (data: HelpEntryT) => {
    data.helpCategoryId = categoryId;
    addHelpEntry(data)
      .then((res) => {
        if (res.success) {
          setHelpEntryList((p) =>
            addStateToArray<HelpEntryT>(p, res.data as HelpEntryT)
          );
          openSnackbar("success", "Eintrag gespeichert");
          setOpen(false);
        } else {
          if (res.errors) {
            if (
              "email" in res.errors &&
              (res.errors as any).email[0].indexOf("already") > 0
            ) {
              setError("Diese E-Mail ist bereits vorhanden!");
            } else setError(res.error);
          }
        }
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  return (
    <>
      <AddButton onClick={() => setOpen(true)} title="Eintrag" />

      <Dialog open={open} setOpen={setOpen} title="Neuer Eintrag">
        <Box sx={{ width: 600 }}>
          <HelpEntryForm onClick={onClick} button="Speichern" />
        </Box>
      </Dialog>
    </>
  );
};

import { get, put, del, post, CallApiResultT } from "../shared/lib/api";
import { isArray } from "lodash";

export type MailTemplateT = {
  id: number;
  userId: number;
  type: string;
  description: string;
  fromName: string;
  fromEmail: string;
  subject: string;
  content: string;
  salutationM?: string;
  salutationF?: string;
  salutationD?: string;
  pos: number;
  sentAt?: Date;
};

type MailTemplateDataT = {
  id: number;
  userId: number;
  type: string;
  description: string;
  fromName: string;
  fromEmail: string;
  subject: string;
  content: string;
  salutationM?: string;
  salutationF?: string;
  salutationD?: string;
  pos: number;
};

const data2mailTemplate = (data: MailTemplateDataT): MailTemplateT => {
  return {
    id: data.id,
    userId: data.userId,
    type: data.type,
    description: data.description,
    fromName: data.fromName || "",
    fromEmail: data.fromEmail || "",
    subject: data.subject || "",
    content: data.content || "",
    salutationM: data.salutationM || "",
    salutationF: data.salutationF || "",
    salutationD: data.salutationD || "",
    pos: data.pos,
  };
};

const data2mailTemplates = (data: MailTemplateDataT[]): MailTemplateT[] => {
  if (data.length === 0) return [];
  return data.map((d) => data2mailTemplate(d));
};

const mailTemplate2data = (mailTemplate: MailTemplateT): MailTemplateDataT => {
  return {
    id: mailTemplate.id,
    userId: mailTemplate.userId,
    type: mailTemplate.type,
    description: mailTemplate.description,
    fromName: mailTemplate.fromName || "",
    fromEmail: mailTemplate.fromEmail || "",
    subject: mailTemplate.subject || "",
    content: mailTemplate.content || "",
    salutationM: mailTemplate.salutationM || "",
    salutationF: mailTemplate.salutationF || "",
    salutationD: mailTemplate.salutationD || "",
    pos: mailTemplate.pos,
  };
};

// const mailTemplates2data = (
//   mailTemplates: MailTemplateT[]
// ): MailTemplateDataT[] => {
//   return mailTemplates.map((mailTemplate) => mailTemplate2data(mailTemplate));
// };

const APIPATH = "/mailtemplate";

/** ************************************************************************
 *
 *
 */
export const fetchMailTemplates = async (): Promise<
  CallApiResultT<MailTemplateT[]>
> => {
  const res = await get(APIPATH);

  if (!(res.data && isArray(res.data))) {
    throw Error("[fetchMailTemplates(main)] " + res.error);
  }

  if (!res.success)
    return {
      success: false,
      error: res.error,
      status: res.status,
      data: [],
    };

  return {
    success: true,
    error: "",
    status: res.status,
    data: data2mailTemplates(res.data),
  };
};

/** ************************************************************************
 *
 * @returns status
 */
export const addMailTemplate = async (
  mailTemplate: MailTemplateT
): Promise<CallApiResultT<MailTemplateT>> => {
  // console.log("updateMailTemplate", mailTemplate, mailTemplate2data(mailTemplate));

  const res = await post<MailTemplateT>(
    `${APIPATH}`,
    mailTemplate2data(mailTemplate)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateMailTemplate = async (
  mailTemplate: MailTemplateT
): Promise<CallApiResultT<number>> => {
  // console.log("updateMailTemplate", mailTemplate, mailTemplate2data(mailTemplate));

  const res = await put<number>(
    `${APIPATH}/${mailTemplate.id}`,
    mailTemplate2data(mailTemplate)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteMailTemplate = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const sendTestMail = async (
  mailTemplateId: number,
  email: string
): Promise<CallApiResultT<number>> => {
  const res = await post<number>(`/send-testmail/`, {
    mailTemplateId,
    email,
  });

  return res;
};

export const sendMail = async (
  mailTemplateId: number,
  candidateId: number,
  subject: string,
  content: string
): Promise<CallApiResultT<number>> => {
  const res = await post<number>(`/candidate-send-mail/`, {
    mailTemplateId,
    candidateId,
    subject,
    content,
  });

  return res;
};

export const sendMailBulk = async (
  mailTemplateId: number,
  candidateIds: number[],
  subject: string,
  content: string
): Promise<CallApiResultT<number>> => {
  const res = await post<number>(`/candidate-send-mail-bulk/`, {
    mailTemplateId,
    candidateIds,
    subject,
    content,
  });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const move = async (ids: number[]): Promise<CallApiResultT<number>> => {
  const res = await post<number>(`/mailtemplate-move/`, {
    ids,
  });

  return res;
};

import { debounce, isEqual, clone } from "lodash";

export const checkChanged = (
  obj1: any,
  obj2: any,
  hasChanged: boolean,
  setHasChanged: React.Dispatch<React.SetStateAction<boolean>>,
  ignore: string[] = []
) => {
  const a = clone(obj1);
  const b = clone(obj2);
  if ("active" in a) delete a.active;
  if ("active" in b) delete b.active;
  ignore.forEach((e) => {
    if (e in a) delete a[e];
    if (e in b) delete b[e];
  });

  const isEq = isEqual(a, b);
  if ((!isEq && !hasChanged) || (isEq && hasChanged)) {
    setHasChanged((b) => !b);
  }
};

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

interface ResultIconPropsI {
  correct?: boolean;
  incorrect?: boolean;
  color?:
    | "inherit"
    | "success"
    | "disabled"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "warning";
}

export const ResultIcon = ({
  correct = false,
  incorrect = false,
  color,
}: ResultIconPropsI) => {
  if (correct)
    return (
      <CheckIcon
        color={color || "success"}
        data-testid="correct-icon"
        className="correct-icon"
      />
    );
  else
    return (
      <ClearIcon
        color={color || "error"}
        data-testid="incorrect-icon"
        className="incorrect-icon"
      />
    );
};

import { useTestModusQuestions } from "./useTestModusQuestions";
import { TestModusStart } from "./TestModusStart";
import { useLoadDataTimeout } from "../shared/hooks/useLoadDataTimeout";
import { Loader } from "../shared/Loader";
import { AutoLoginError } from "../auth/AutoLoginError";

export const TestModusLoader = () => {
  const { questionList } = useTestModusQuestions({});
  const { timedOut } = useLoadDataTimeout(5, questionList.length > 0);

  if (timedOut) return <AutoLoginError />;
  if (questionList.length === 0) return <Loader />;

  return <TestModusStart />;
};

import React from "react";
import { AnswersMultipleChoice } from "./AnswersMultipleChoice";
import "../../../css/styles.scss";
import { TestModusQuestionPropsI } from "../QuestionSwitch";

export const QuestionMultipleChoice = React.memo(
  (props: TestModusQuestionPropsI) => {
    return (
      <>
        <AnswersMultipleChoice {...props} />
      </>
    );
  }
);

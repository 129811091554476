import { Grid } from "@mui/material";
import { deleteStateFromArrayById } from "../shared/helper/state";
import { useContext } from "react";
import { setPasswordUser, UserT } from "../user/api";
import { Admin } from "./Admin";
import { AddAdmin } from "./AddAdmin";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ErrorContext } from "../error/ErrorContext";
import { UserContext } from "../user/UserContext";
import { Typography } from "@mui/material";

export const AdminList = () => {
  const { userList, setUserList, setAllUserEmails } = useContext(UserContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const { setError } = useContext(ErrorContext);

  const onClickPassword = (id: number) => {
    showProgress();
    setPasswordUser(id)
      .then((res) => {
        if (res.success) openSnackbar("success", "Passwort-Mail gesendet");
        else setError("User not found");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Typography variant="body1" component="span">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AddAdmin />
        </Grid>
        <Grid item xs={12}>
          {userList &&
            userList.map((user: UserT) => (
              <Admin
                user={user}
                deleteSuccessFunc={() => {
                  setUserList((p) => deleteStateFromArrayById(p, user.id));
                  setAllUserEmails((p) =>
                    p.filter((u) => u.email !== user.email)
                  );
                }}
                onClickPassword={onClickPassword}
                key={user.id}
              />
            ))}
        </Grid>
      </Grid>
    </Typography>
  );
};

import { default as MuiTooltip } from "@mui/material/Tooltip";
import { ReactElement } from "react";

interface TooltipPropsI {
  title?: string | ReactElement;
  placement?: "bottom" | "left" | "right" | "top";
  children: ReactElement;
}

export const Tooltip = (props: TooltipPropsI) => {
  return (
    <MuiTooltip
      title={<div className="tooltip-text">{props.title}</div>}
      placement={props.placement || "bottom"}
    >
      {props.children}
    </MuiTooltip>
  );
};

import React, { createContext, useState } from "react";

export interface LayoutT {
  logo: string;
  primaryColor: string;
  secondaryColor: string;
}

type ContextPropsType = {
  layout: LayoutT;
  setLayout: React.Dispatch<React.SetStateAction<LayoutT>>;
  loadingDone: boolean;
  setLoadingDone: React.Dispatch<React.SetStateAction<boolean>>;
};

const emptyLayout: LayoutT = {
  logo: "",
  primaryColor: "",
  secondaryColor: "",
};

export const layoutContextDefaults: ContextPropsType = {
  layout: emptyLayout,
  setLayout: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
};

export const LayoutContext = createContext<ContextPropsType>(
  layoutContextDefaults
);
export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [layout, setLayout] = useState<LayoutT>(emptyLayout);
  const [loadingDone, setLoadingDone] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        layout,
        setLayout,
        loadingDone,
        setLoadingDone,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

import { useContext } from "react";
import { fetchSystemHealthChecks, SystemHealthCheckT } from "./api";
import { useLoadData } from "../shared/hooks/useLoadData";
import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { SystemHealthCheckContext } from "./SystemHealthCheckContext";

export interface UseSystemHealthCheckResultT extends UseResultT {
  systemHealthCheckList: SystemHealthCheckT[];
  setSystemHealthCheckList: React.Dispatch<
    React.SetStateAction<SystemHealthCheckT[]>
  >;
}

interface UseshcsPropsI extends UsePropsI {
  onlyActive?: boolean;
  check?: boolean;
}

export const useSystemHealthCheck = ({
  dontShowProgress = false,
  dontHideProgress = false,
  onlyActive = false,
  check = false,
}: UseshcsPropsI): UseSystemHealthCheckResultT => {
  const {
    systemHealthCheckList,
    setSystemHealthCheckList,
    loadingDone,
    setLoadingDone,
  } = useContext(SystemHealthCheckContext);

  useLoadData({
    fetchFunc: () => fetchSystemHealthChecks(),
    list: systemHealthCheckList,
    setList: setSystemHealthCheckList,
    setLoadingDone,
    dontHideProgress,
    dontShowProgress,
  });

  return { systemHealthCheckList, setSystemHealthCheckList, loadingDone };
};

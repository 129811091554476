import { useContext } from "react";
import { fetchMailTemplates, MailTemplateT } from "./api";
import { MailTemplateContext } from "./MailTemplateContext";

import { UsePropsI, UseResultT } from "../shared/types/hooks";
import { useLoadData } from "../shared/hooks/useLoadData";

export interface UseMailTemplatesResultT extends UseResultT {
  mailTemplateList: MailTemplateT[];
  setMailTemplateList: React.Dispatch<React.SetStateAction<MailTemplateT[]>>;
}

interface UseMailTemplatesPropsI extends UsePropsI {}

export const useMailTemplates = ({
  dontHideProgress = false,
}: UseMailTemplatesPropsI): UseMailTemplatesResultT => {
  const { mailTemplateList, setMailTemplateList, loadingDone, setLoadingDone } =
    useContext(MailTemplateContext);

  useLoadData({
    fetchFunc: () => fetchMailTemplates(),
    list: mailTemplateList,
    setList: setMailTemplateList,
    setLoadingDone,
    dontHideProgress,
  });

  return { mailTemplateList, setMailTemplateList, loadingDone };
};
